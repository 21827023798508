import React from 'react';

import { Grid } from 'back/imports/materialUi.js';
import { ActiveBreadcrumbs, BusinessTable } from 'back/imports/components.js';
import GlobalAlert from 'back/components/GlobalAlert/GlobalAlert';

export default function Businesses() {
	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid container spacing={3} style={{ marginTop: '10px' }}>
				<Grid item lg={12} xs={12}>
					<BusinessTable />
				</Grid>
			</Grid>
		</>
	);
}
