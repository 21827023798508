import styled from 'styled-components';

export const FranchiseBodyCont = styled.div`
	display: block;
	background-color: white;
	margin-top: 450px;
`;

export const FranchiseIcon = styled.div`
	display: block;
	width: 300px;
	height: 300px;
	overflow: hidden;
	border-radius: 500px;
	padding: 20px;
	background-color: white;
	margin: auto auto;
	margin-top: -150px;
	& img {
		margin: auto auto;
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-radius: 500px;
		background-color: #7da7d1;
	}
`;

export const FranchiseTitle = styled.label`
	display: block;
	font-size: 35px;
	margin-top: 50px;
	font-weight: bold;
	color: #7da7d1;
`;

export const FranchiseTitleSub = styled.label`
	display: block;
	margin-top: 20px;
	font-size: 25px;
	font-weight: bold;
	margin-bottom: 10px;
	& span {
		display: block;
		font-size: 16px;
		font-weight: normal;
	}
`;

export const AccordionCont = styled.div`
	display: block;
	background-color: #eee;
	padding: 30px;
	margin-bottom: 50px;
	& p {
		font-size: 14px;
	}
`;

export const FranchiseDetailsHeader = styled.label`
	font-weight: bold;
	cursor: pointer;
`;
