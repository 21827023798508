import { Card, makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({}));

export const SearchKeywordCont = styled.div`
	padding: 50px 30px 50px 30px;
	background-color: white;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	z-index: 5;
	border-bottom: 2px solid red;

	box-shadow: 0px -14px 16px 0px rgba(0, 0, 0, 0.75);
	-webkit-box-shadow: 0px -14px 16px 0px rgba(0, 0, 0, 0.75);
	-moz-box-shadow: 0px -14px 16px 0px rgba(0, 0, 0, 0.75);

	& button {
		margin-top: 15px;
		padding: 17px 22px;
	}
	& .keywordButton {
		font-size: 10px;
		padding: 5px 10px;
		margin-right: 13px;
		margin-left: -4px;
		margin-bottom: 5px;
	}
`;

export const SearchResultCont = styled.div`
	padding-top: 30px;
	& .search-result-navi-cont {
		& .MuiFormControl-root {
			margin-top: 0px !important;
		}
		& .viewTypeCont {
			font-size: 14px;
			text-align: right;
			& button {
				background-color: #eee;
				margin: 0px 5px;
			}
			& button.active {
				background-color: #003366;
				color: white;
			}
		}
	}
`;

export const ResultLabel = styled.label`
	display: block;

	& span {
		font-weight: bold;
		font-size: 25px;
	}
`;

export const CardResultGrid = styled(Card)`
	height: 100%;
	position: relative;
	padding-bottom: 100px;
	& .MuiCardContent-root {
		& h2 {
			margin-bottom: 20px;
		}
		& p {
			overflow: hidden;
		}
	}
	& .cardInvestDiv {
		position: absolute;
		padding-left: 15px;
		margin-bottom: 50px;
		bottom: 0;
		& label {
			font-weight: bold;
			display: block;
		}
		& span {
			color: green;
			display: block;
		}
	}
`;

export const SearchResultListEmpty = styled.div`
	text-align: center;

	& label {
		font-size: 25px;
		color: #aaa;
		margin-top: 15px;
		padding: 50px 0;
		& span {
			margin-right: 10px;
			& svg {
				font-size: 40px;
				color: red;
			}
		}
	}
`;

export const MightLikeCont = styled.div`
	display: block;
	& .mightLikeHeader {
		color: white;
		padding: 20px 0;
		display: block;
		font-size: 20px;
		text-align: center;
		background-color: #7da7d1;
		border-radius: 5px;
	}
`;

export const SearchLoadingCont = styled.div`
	display: block;
	text-align: center;
	padding: 30px 0 70px 0;
	& label {
		& span {
			display: block;
			margin-top: 20px;
		}
	}
`;
