import React from 'react';
import { Grid } from 'back/imports/materialUi';

import {
	ProfileInfo,
	ProfileHistory,
	ProfileSummary,
} from 'back/components/myProfile/';
import { ActiveBreadcrumbs } from 'back/components';
import GlobalAlert from 'back/components/GlobalAlert/GlobalAlert';

const MyProfile = () => {
	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid container spacing={3} style={{ marginTop: '50px' }}>
				<Grid item lg={8} xs={12}>
					<ProfileInfo />

					<ProfileHistory />
				</Grid>
				<Grid item lg={4} xs={12}>
					<ProfileSummary />
				</Grid>
			</Grid>
		</>
	);
};

export default MyProfile;
