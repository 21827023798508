import React, { useContext, useEffect, useRef, useState } from 'react';

import { Paper, Grid, CircularProgress } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faHotel,
	faEnvelope,
	faCoins,
} from '@fortawesome/free-solid-svg-icons';
import { uniqueStyle, SummaryNum } from './myProfileStyle';
import { generalStyle } from 'back/assets/style/generalStyle';
import clsx from 'clsx';
import { BusinessListContext } from 'back/context/businessList';
import { UserDataContext } from 'UniversalContext/userData';

const ProfileSummary = () => {
	const classes = uniqueStyle();
	const generalClasses = generalStyle();
	const loading = useRef(true);
	const [, setCounterLoading] = useState(true);
	const { businessListValue } = useContext(BusinessListContext);
	const { userDataValue } = useContext(UserDataContext);
	const [data, setData] = useState({ business: 0, inquiry: 0, points: 0 });

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken !== '') {
			setData({
				business: businessListValue.length,
				inquiry: userDataValue.UserInfo.inquiry.length,
				points: 0,
			});

			loading.current = false;
			setCounterLoading(false);
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken, businessListValue]);

	return (
		<>
			<Grid container spacing={2}>
				<Grid item lg={12} xs={12}>
					<Paper
						className={clsx([
							generalClasses.centerContent,
							classes.summaryBox,
						])}>
						<Grid container style={{ height: '100%' }}>
							<Grid item xs={2} className={generalClasses.verticalAlignItems}>
								<FontAwesomeIcon
									icon={faHotel}
									className={classes.summaryIcon}
									style={{ backgroundColor: '#003366', fontSize: '45px' }}
								/>
							</Grid>
							<Grid item xs={3} className={generalClasses.verticalAlignItems}>
								<SummaryNum color="#003366">
									{loading.current ? <CircularProgress /> : data.business}
								</SummaryNum>
							</Grid>
							<Grid item xs={7} className={generalClasses.verticalAlignItems}>
								<label>Total Businesses Posted</label>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item lg={12} xs={12}>
					<Paper
						className={clsx([
							generalClasses.centerContent,
							classes.summaryBox,
						])}>
						<Grid container style={{ height: '100%' }}>
							<Grid item xs={2} className={generalClasses.verticalAlignItems}>
								<FontAwesomeIcon
									style={{ backgroundColor: 'green' }}
									icon={faEnvelope}
									className={classes.summaryIcon}
								/>
							</Grid>
							<Grid item xs={3} className={generalClasses.verticalAlignItems}>
								<SummaryNum color="green">
									{loading.current ? <CircularProgress /> : data.inquiry}
								</SummaryNum>
							</Grid>
							<Grid item xs={7} className={generalClasses.verticalAlignItems}>
								<label>Inquiries received</label>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item lg={12} xs={12}>
					<Paper
						className={clsx([
							generalClasses.centerContent,
							classes.summaryBox,
						])}>
						<Grid container style={{ height: '100%' }}>
							<Grid item xs={2} className={generalClasses.verticalAlignItems}>
								<FontAwesomeIcon
									style={{ backgroundColor: 'blue' }}
									icon={faCoins}
									className={classes.summaryIcon}
								/>
							</Grid>
							<Grid item xs={3} className={generalClasses.verticalAlignItems}>
								<SummaryNum color="blue">
									{loading.current ? <CircularProgress /> : 0}
								</SummaryNum>
							</Grid>
							<Grid item xs={7} className={generalClasses.verticalAlignItems}>
								<label>Overall Accumulated Points </label>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default ProfileSummary;
