import { Grid } from '@material-ui/core';
import { ActiveBreadcrumbs } from 'back/components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import AdminPanel from 'back/components/administrator/AdminPanel';
import AdminActionCenter from 'back/components/administrator/AdminActionCenter';
import {
	AdminContentHouseKeep,
	AdminContentPaidAds,
} from 'back/components/administrator/AdminContentSection';
import { UserDataContext } from 'UniversalContext/userData';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { useHistory } from 'react-router-dom';
import GlobalAlert from 'back/components/GlobalAlert/GlobalAlert';

const Administrator = () => {
	const history = useHistory();
	const { userDataValue } = useContext(UserDataContext);
	const axiosInstance = AxiosConfigSecured();
	const [panelValues, setPanelValues] = useState({
		activeAds: 0,
		pendingAds: 0,
		activeAcc: 0,
		disabledAcc: 0,
	});
	const loading = useRef(true);
	var handleInterval = useRef();

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			if (userDataValue.UserInfo.permission !== 3) {
				history.push('/account/dashboard');
			}

			function getPanelDetails() {
				axiosInstance
					.get('/administrator/getPanelDetails', { withCredentials: true })
					.then(async (res) => {
						let activeBizCount = 0;
						let pendingBizCount = 0;

						await res.data.business.forEach((bizOwner) => {
							bizOwner.businessList.forEach((business) => {
								if (business.status === 'pending') {
									pendingBizCount++;
								} else if (business.status === 'active') {
									activeBizCount++;
								}
							});
						});

						loading.current = false;
						setPanelValues((prevState) => ({
							...prevState,
							activeAds: activeBizCount,
							pendingAds: pendingBizCount,
							activeAcc: res.data.activeUser,
							disabledAcc: res.data.disabledUser,
						}));
					});
			}

			getPanelDetails();

			handleInterval.current = setInterval(() => {
				getPanelDetails();
			}, 5000);
		}

		return () => clearInterval(handleInterval.current);

		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<AdminPanel panelValues={panelValues} loading={loading} />

			<Grid container spacing={3} className="mt-4">
				<Grid item lg={9} xs={12}>
					<Grid container spacing={3}>
						<Grid item lg={6} xs={12}>
							<AdminContentPaidAds />
						</Grid>
						<Grid item lg={6} xs={12}>
							<AdminContentHouseKeep />
						</Grid>
					</Grid>
				</Grid>
				<Grid item lg={3} xs={12}>
					<AdminActionCenter panelValues={panelValues} />
				</Grid>
			</Grid>
		</>
	);
};

export default Administrator;
