import { makeStyles, Button } from 'back/imports/materialUi';

import styled from 'styled-components';

export const BusinessTableStyle = makeStyles((theme) => ({
	SortContainer: {
		backgroundColor: '',
	},
	FormContainer: {
		width: '100%',
		backgroundColor: 'white',
	},

	centerText: {
		textAlign: 'center',
	},
	textWhite: {
		color: 'white',
	},

	bgBlack: {
		backgroundColor: '#3f6f9e',
	},

	newBusinessBtn: {
		backgroundColor: 'green',
		width: '100%',
		marginTop: '8px',
		'&:hover': {
			backgroundColor: 'darkgreen',
		},
	},
	showAll: {
		backgroundColor: '#115293',
		color: 'white',
	},
	showActive: {
		backgroundColor: 'green',
		color: 'white',
	},
	showExpired: {
		backgroundColor: 'red',
		color: 'white',
	},
}));

export const SortDiv = styled.div`
	padding: 15px;
	width: 100%;
`;

export const BusinessListCont = styled.div`
	background-color: white;
	height: 200px;
	width: 100%;
	border: 1px solid #eee;
	border-radius: 5px;
`;

export const BusinessListDetails = styled.div`
	background-color: #fafafa;
	height: 100%;
	width: 100%;
`;

export const DisabledHoverButton = styled(Button)`
	background-color: ${(props) => `${props.active} !important`};
	margin-bottom: 4px;
	margin-top: 4px;
	color: ${(props) => (props.active ? 'white !important' : 'black !important')};
	&:hover {
		background-color: ${(props) => props.active};
	}
`;

export const BusinessEmptyList = styled.div`
	margin: 97px 0px 97px 0px;
	font-size: 20px;
`;
