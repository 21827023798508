import React, { useState, useContext } from 'react';
import { Container, Form, Button, InputGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faLock,
	faEye,
	faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import jwtDecode from 'jwt-decode';
import { UserDataContext } from '../../UniversalContext/userData';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import loginIcon from 'front/assets/images/loginIcon.png';
import { checkNull } from 'back/imports/logicCode';

import 'front/assets/styles/loginPage.css';
import { CircularProgress } from '@material-ui/core';
import { CustomSnackbar } from 'back/components/snackbar';
import SignInOption from 'front/components/signInOption/signInOption';
import biztayologo from 'front/assets/images/biztayo_fb.png';

const LoginPage = (props) => {
	const history = useHistory();
	const [user, setUser] = useState({});
	const [errorLogin, setErrorLogin] = useState(false);
	const { setUserDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const axiosInstance = AxiosConfigUnsecured();
	const [showPassword, setShowPassword] = useState(false);
	const [loading, setloading] = useState(false);

	const handleChange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const loginUser = (e) => {
		e.preventDefault();
		setloading(true);
		axiosInstance
			.post('user/login', { ...user })
			.then(async (data) => {
				if (data.data?.isAlreadyLogin) {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							customColor: 'error',
							customMessage: 'Logged in detected, refreshing page in 4 seconds',
							customSnackbar: true,
						},
					}));
					setTimeout(() => {
						window.location.href =
							process.env.REACT_APP_BUILD_STATUS === 'DEV'
								? 'http://localhost:3000/account/dashboard'
								: 'https://www.biztayo.ph/account/dashboard';
					}, 4000);
				} else {
					const decodedToken = await jwtDecode(data.data.accessToken);
					await setUserDataValue((prevState) => ({
						...prevState,
						UserInfo: {
							...prevState.UserInfo,
							accessToken: data.data.accessToken,
							userID: decodedToken.userID,
							fname: data.data.fname,
							lname: data.data.lname,
							email: data.data.email,
							contactNum: checkNull(data.data.contactNum),
							address: data.data.address,
							permission: data.data.permission,
							notif: [...data.data.notif.reverse()],
							status: data.data.status,
							disableReason: data.data.disableReason,
							inquiry: data.data.inquiry.reverse(),
							platform: 'local',
						},
						isLogin: true,
					}));
					await setPageDataValue((prevState) => ({
						...prevState,
						snackBar: { ...prevState.snackBar, error: false },
					}));

					history.push('/account/dashboard');
				}
			})
			.catch((err) => {
				if (err.response) {
					setErrorLogin(true);
					setloading(false);
				} else if (err.request) {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: { ...prevState.snackBar, error: true },
					}));
				}
			});
	};

	const handleShowPass = (data) => {
		setShowPassword(!showPassword);
	};

	return (
		<React.Fragment>
			<div className="login-page-bg"></div>
			<div className="loginBody-cont">
				<br />
				<center>
					<LinkContainer to="/">
						<div className="login-header">
							<img src={biztayologo} alt="biztayo_logo" />
							<label className="title-1 cursor-pointer">BIZTAYO</label>
							<label className="title-3 cursor-pointer">.PH</label>
						</div>
					</LinkContainer>
				</center>

				<div className="login-body">
					<img src={loginIcon} alt="login_img" />
					<Container>
						<Form onChange={handleChange} onSubmit={loginUser}>
							{!errorLogin ? (
								<label
									className="text-center d-block font-weight-bold mb-3 mt-2"
									style={{ fontWeight: 'bold' }}>
									Welcome to Biztayo.ph
								</label>
							) : (
								<label className="text-center d-block font-weight-bold mb-3 mt-2 text-danger">
									Incorrect email or password provided
								</label>
							)}
							<InputGroup className="mb-1">
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faEnvelope} />
									</label>
								</InputGroup.Text>
								<FloatingLabel label="Email address" className="flex-grow-1">
									<Form.Control
										name="username"
										placeholder="Email address"
										autoFocus
									/>
								</FloatingLabel>
							</InputGroup>

							<InputGroup className="mb-3">
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faLock} />
									</label>
								</InputGroup.Text>
								<FloatingLabel label="Password" className="flex-grow-1">
									<Form.Control
										name="password"
										placeholder="Password"
										type={showPassword ? 'textbox' : 'password'}
									/>
								</FloatingLabel>
								<InputGroup.Text
									style={{ cursor: 'pointer' }}
									onClick={handleShowPass}>
									<label style={{ cursor: 'pointer' }}>
										<FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
									</label>
								</InputGroup.Text>
							</InputGroup>
							<div className="d-grid gap-2">
								<Button
									type="submit"
									className="mt-3"
									disabled={loading}
									style={{ backgroundColor: '#003366', position: 'relative' }}>
									Sign in
									{loading && (
										<CircularProgress
											size={24}
											thickness={5}
											style={{
												position: 'absolute',
												// top: '0%',
												left: '50%',
												color: 'white',
											}}
										/>
									)}
								</Button>
							</div>
							<Link
								to="/forgotPassword"
								style={{ textDecoration: 'none', cursor: 'pointer' }}>
								<label className="  mt-2 text-left cursor-pointer">
									<small>Forgot password?</small>
								</label>
							</Link>
							<br />
							<label className=" d-block text-left">
								<small>
									Don't have an account yet?{' '}
									<Link to="/register">
										<label
											className="text-primary font-weight-bold"
											style={{ cursor: 'pointer' }}>
											Sign-up now
										</label>
									</Link>
								</small>
							</label>
							<SignInOption />
						</Form>
					</Container>
				</div>
				<br />
				<br />
			</div>

			<CustomSnackbar />
		</React.Fragment>
	);
};

export default LoginPage;
