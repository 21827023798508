import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faFacebookSquare,
	faInstagramSquare,
	faTwitterSquare,
} from '@fortawesome/free-brands-svg-icons';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { UserDataContext } from 'back/imports/components';
import { useCookies } from 'react-cookie';
import { ContactDetailsCont, uniqueStyle } from './footerStyle';
import { MailOutline, NearMe, Phone } from '@material-ui/icons';
import biztayoLogo from 'front/assets/images/biztayo_fb.png';

const FooterBody = () => {
	const history = useHistory();
	const { userDataValue } = useContext(UserDataContext);
	const [cookies] = useCookies([]);
	const currentTime = new Date().getTime() / 1000;
	const classes = uniqueStyle();

	function handleLink(path) {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		history.push(path);
	}

	return (
		<React.Fragment>
			<div className="footerBody-bg">
				<Container>
					<Grid container spacing={3} justifyContent="space-between">
						<Grid item lg={4} xs={12}>
							<img
								style={{
									width: '100px',
									height: '100px',
									marginBottom: '10px',
								}}
								src={biztayoLogo}
								alt="BiztayoLogo"
							/>

							<h2 className="font-weight-bold mb-4">BIZTAYO.PH</h2>

							<p
								className="font-weight-lighter text-justify"
								style={{ fontSize: '14px', color: '#888' }}>
								Helping Franchisor to promote their businesses online with the
								help of our platform. Providing a pool of choices for the
								Franchisee.
							</p>
						</Grid>

						<Grid
							lg={3}
							xs={12}
							className="text-right"
							style={{
								paddingLeft: '10px',
								paddingRight: '10px',
								marginBottom: '10px',
								marginTop: '10px',
							}}>
							<label className="d-block font-weight-bold mb-3">
								Contact Us!
							</label>

							<ContactDetailsCont>
								<label>
									<span>
										<MailOutline />
									</span>
									<b>Email </b>: Support@biztayo.ph
								</label>
								<label>
									<span>
										<Phone />
									</span>
									<b>Phone </b>: 0976-339-6251
								</label>
							</ContactDetailsCont>

							<label className="d-block font-weight-bold mb-2">
								Follow us on:
							</label>
							<a
								href="https://www.facebook.com/biztayo.phl"
								target={'_blank'}
								rel="noreferrer">
								<label
									style={{
										fontSize: '30px',
										marginLeft: '20px',
										cursor: 'pointer',
										color: '#888',
									}}>
									<FontAwesomeIcon icon={faFacebookSquare} />
								</label>
							</a>
							<label
								style={{
									fontSize: '30px',
									marginLeft: '20px',
									cursor: 'pointer',
									color: '#888',
								}}>
								<FontAwesomeIcon icon={faInstagramSquare} />
							</label>
							<label
								style={{
									fontSize: '30px',
									marginLeft: '20px',
									cursor: 'pointer',
									color: '#888',
								}}>
								<FontAwesomeIcon icon={faTwitterSquare} />
							</label>
						</Grid>

						<Grid
							lg={2}
							xs={12}
							style={{
								paddingLeft: '10px',
								paddingRight: '10px',
								marginTop: '10px',
							}}
							className={classes.footerLinks}>
							<label className="d-block font-weight-bold mb-4">
								Useful Links
							</label>

							<label
								className=" cursor-pointer mt-1 mb-1 Flinks"
								onClick={() => handleLink('/bt/search')}>
								<span>
									<NearMe />
								</span>
								Own a Business
							</label>
							<br />

							<label
								className=" cursor-pointer mt-1 mb-1 Flinks"
								onClick={() => handleLink('/bt/whyFranchise')}>
								<span>
									<NearMe />
								</span>
								Why Franchise?
							</label>
							<br />
							<label
								className=" cursor-pointer mt-1 mb-1 Flinks"
								onClick={() => handleLink('/login')}>
								<span>
									<NearMe />
								</span>
								{userDataValue.UserInfo.accessToken ||
								cookies.tokenExpiration > currentTime
									? 'My Dashboard'
									: 'Account Log-in'}
							</label>
							<br />
							<label
								className="  cursor-pointer mt-1 mb-1 Flinks"
								onClick={() => handleLink('/bt/aboutUs')}>
								<span>
									<NearMe />
								</span>
								About Us
							</label>
							<br />
							<label
								className="cursor-pointer mt-1 mb-1 Flinks"
								onClick={() => handleLink('/bt/terms&Conditions')}>
								<span>
									<NearMe />
								</span>
								Terms & Conditions
							</label>
							<br />
							<label
								className=" cursor-pointer mt-1 mb-1 Flinks"
								onClick={() => handleLink('/bt/privacyPolicy')}>
								<span>
									<NearMe />
								</span>
								Privacy Policy
							</label>
						</Grid>
					</Grid>
				</Container>
			</div>
			<div className="footerBody-bot">
				<Container>
					<hr style={{ border: '2px solid #fd6503' }} />
					<label className="d-block text-center text-muted">
						© 2023 Biztayo.ph
					</label>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default FooterBody;
