import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import React from 'react';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';

const SignInOption = () => {
	const axiosInstance = AxiosConfigUnsecured();
	const { setPageDataValue } = useContext(PageDataContext);

	function handleSignInLink(link) {
		axiosInstance.get(link).then((res) => {
			if (res.data.isAlreadyLogin) {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customColor: 'error',
						customMessage: 'Logged in detected, refreshing page in 4 seconds',
						customSnackbar: true,
					},
				}));
				setTimeout(() => {
					window.location.href =
						process.env.REACT_APP_BUILD_STATUS === 'DEV'
							? 'http://localhost:3000/account/dashboard'
							: 'https://www.biztayo.ph/account/dashboard';
				}, 4000);
			} else {
				window.location.href =
					process.env.REACT_APP_BUILD_STATUS === 'DEV'
						? `http://localhost:5000${link}/validated`
						: `https://backend.biztayo.ph${link}/validated`;
			}
		});
	}

	return (
		<>
			<div
				style={{
					position: 'relative',
					height: '30px',
					marginTop: '30px',
					textAlign: 'center',
				}}>
				<label
					className=" text-center"
					style={{
						fontSize: '14px',
						backgroundColor: 'white',
						padding: '5px 15px 5px 15px',
						position: 'absolute',
						zIndex: '3',
						margin: 'auto auto',
						marginTop: '-18px',
						marginLeft: '-20px',
						display: 'inline-block',
					}}>
					OR
				</label>
				<hr />
			</div>
			<div className="d-grid grip-2">
				{/* <a
					href={
						process.env.REACT_APP_BUILD_STATUS === 'DEV'
							? 'http://localhost:5000/auth/facebook'
							: 'https://backend.biztayo.ph/auth/facebook'
					}
					style={{ width: '100%' }}> */}
				<Button
					className=" text-white border-0"
					onClick={() => handleSignInLink('/auth/facebook')}
					style={{
						width: '100%',
						fontSize: '16px',
						padding: '10px',
						backgroundColor: '#5890FF',
					}}>
					<FontAwesomeIcon
						icon={faFacebookF}
						style={{ fontSize: '20px', marginRight: '10px' }}
					/>
					Sign in with Facebook
				</Button>
				{/* </a>
				<a
					href={
						process.env.REACT_APP_BUILD_STATUS === 'DEV'
							? 'http://localhost:5000/auth/google'
							: 'https://backend.biztayo.ph/auth/google'
					}
					style={{ width: '100%' }}> */}
				<Button
					className=" text-white mt-3 border-0 "
					onClick={() => handleSignInLink('/auth/google')}
					style={{
						width: '100%',
						fontSize: '16px',
						backgroundColor: 'red',
						padding: '10px',
					}}>
					<FontAwesomeIcon
						icon={faGoogle}
						style={{ fontSize: '20px', marginRight: '10px' }}
					/>
					Sign in with Google
				</Button>
				{/* </a> */}
			</div>
		</>
	);
};

export default SignInOption;
