import { makeStyles } from 'back/imports/materialUi';
import styled from 'styled-components';

const InfoStyle = makeStyles({
	InfoIconCont: {
		display: 'block',
		position: 'relative',
		'& svg': {
			fill: (props) => props.color,
			display: 'block',
			fontSize: '60px',
			position: 'absolute',
			right: '0',
			marginRight: '15px',
			marginTop: '60px',
			opacity: '.2',
		},
	},
});

const Container = styled.div`
	display: block;
	position: relative;
`;

const Title = styled.div`
	display: block;
	position: absolute;
	color: white;
	font-size: 20px;
	font-weight: bold;
	text-align: center;
	padding: 15px;
	width: 90%;
	left: 0;
	right: 0;
	margin: auto auto;
	margin-top: -20px;
	border-radius: 5px;
	background: linear-gradient(
		to right,
		${(props) => props.firstColor},
		${(props) => props.secondColor}
	);
`;

const Content = styled.div`
	display: block;
	margin-top: 15px;
`;

const Footer = styled.a`
	display: inline-block;
	padding: 15px;
	cursor: pointer;
	margin-left: 10px;
	text-decoration: none;
`;

export const BoxContent = styled.label`
	font-size: 50px;
	font-family: 'Raleway';
	font-weight: bold;
	margin-left: 50px;
	width: 100%;
	display: inline-block;
`;

export const BoxSubContent = styled.label`
	margin-left: 15px;
	font-size: 15px;
	font-family: arial;
	color: green;
`;

const InfoBoxStyle = { InfoStyle, Container, Title, Content, Footer };

export default InfoBoxStyle;
