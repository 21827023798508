export const checkNull = (data) => {
	return data ? data : '';
};

export const getFullDate = (rawDate) => {
	let newDate, fullDate;
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	newDate = new Date(parseInt(rawDate));
	fullDate =
		months[newDate.getMonth()] +
		' ' +
		newDate?.getDate() +
		', ' +
		newDate?.getFullYear();

	return fullDate;
};

export const currentTime = (rawDate) => {
	const date = new Date(parseInt(rawDate));
	const getHour =
		date.getHours().toString().length === 1
			? '0' + date.getHours()
			: date.getHours();
	const getMinute =
		date.getMinutes().toString().length === 1
			? '0' + date.getMinutes()
			: date.getMinutes();

	return getHour + ':' + getMinute;
};
