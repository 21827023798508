import { makeStyles } from 'back/imports/materialUi';
import styled from 'styled-components';

export const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: '5px',
		border: '1px solid',
		padding: theme.spacing(1),
		zIndex: '5000',
		backgroundColor: 'red',
	},
	activeIconBtn: {
		backgroundColor: 'white',
		color: '#003366',
		'&:hover': {
			backgroundColor: 'white',
		},
	},
}));

export const PopperDiv = styled.div`
	margin-top: 5px;
	border: 1px solid black;
	padding: 5px;
	background-color: white;
	width: 300px;
	margin-right: 5px;
	color: black;
	border-radius: 3px;
	box-shadow: -2px 2px 10px #555;
	overflow: hidden;
	& hr {
		margin: 0;
	}
	& .MuiListItemIcon-root {
		min-width: 40px;
	}
`;

export const TitleGuide = styled.label`
	display: block;
	width: inherit;
	font-size: 11px;
	color: #999;
	padding-bottom: 10px;
	padding-top: 10px;
`;

export const TitleValue = styled.div`
	font-size: 15px;
	font-weight: Bold;
	display: block;
	width: inherit;
	color: black;
`;

export const LastPageNote = styled.div`
	margin-top: 30px;
	width: inherit;
	& label {
		color: #999;
		font-size: 12px;
		display: block;
	}
`;
