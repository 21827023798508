import React, { useContext } from 'react';
import { generalStyle } from 'back/assets/style/generalStyle';
import { Button, Paper } from '@material-ui/core';
import { CreditCard, FindReplace } from '@material-ui/icons';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { PageDataContext } from 'back/context/pageData';

export const AdminContentPaidAds = () => {
	const generalClasses = generalStyle();

	return (
		<>
			<Paper>
				<label className={generalClasses.paperTitle}>Paid Advertisement</label>
				<div className={generalClasses.paperContent}>
					<ul className={generalClasses.guidePhrase}>
						<li>
							Advertisement that will be displayed in every page for a given
							time
						</li>
						<li>Set up paid advertisment thru below button</li>
					</ul>

					<Button
						variant="outlined"
						color="primary"
						size="large"
						style={{
							fontWeight: 'bold',
							marginRight: '15px',
							float: 'right',
							marginBottom: '25px',
						}}>
						<CreditCard /> &nbsp; Paid Ads Setting
					</Button>
					<div style={{ clear: 'both' }} />
				</div>
			</Paper>
		</>
	);
};

export const AdminContentHouseKeep = () => {
	const generalClasses = generalStyle();
	const axiosInstance = AxiosConfigSecured();
	const { setPageDataValue } = useContext(PageDataContext);

	function handleHousekeepAdsList() {
		axiosInstance
			.post('/administrator/housekeepAdsList', {}, { withCredentials: true })
			.then((res) => {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: { ...prevState.snackBar, success: true },
					backdrop: false,
				}));
			});
	}
	return (
		<>
			<Paper>
				<label className={generalClasses.paperTitle}>
					Housekeep Advertisement
				</label>
				<div className={generalClasses.paperContent}>
					<ul className={generalClasses.guidePhrase}>
						<li>Check for expiring active advertisements</li>
						<li>Delete dormant advertisements</li>
					</ul>

					<Button
						// onClick={handleHousekeepAdsList}
						disabled
						variant="outlined"
						color="primary"
						size="large"
						style={{
							fontWeight: 'bold',
							marginRight: '15px',
							float: 'right',
							marginBottom: '25px',
						}}>
						<FindReplace /> &nbsp; Housekeep Ads list
					</Button>
					<div style={{ clear: 'both' }} />
				</div>
			</Paper>
		</>
	);
};
