import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import { matchPath, useHistory, useParams } from 'react-router-dom';

import { routes } from 'back/imports/components';
import _ from 'lodash';

function ActiveBreadcrumbs() {
	const history = useHistory();
	const { id } = useParams();
	//if urlMatch=null save current path
	// const urlToCompare = urlMatch?.path || window.location.pathname;

	function handleClick(data) {
		let linkArray = data.split('/');
		let newLink = '';

		if (_.includes(linkArray, ':id')) {
			linkArray[linkArray.indexOf(':id')] = id;
			newLink = linkArray.join('/');
		}

		history.push(newLink ? newLink : data);
	}

	return (
		<Breadcrumbs aria-label="breadcrumb">
			{routes.map((routesData, index) => {
				//checking if specified url are match
				const urlMatch = matchPath(window.location.pathname, routesData.path);
				return urlMatch?.isExact
					? routesData.breadcrumbs.map((activeRoutesData, index) =>
							index === routesData.breadcrumbs.length - 1 ? (
								<Link color="textPrimary" style={{ textDecoration: 'none' }}>
									{activeRoutesData}
								</Link>
							) : (
								<Link
									color="inherit"
									onClick={() => handleClick(activeRoutesData.path)}
									style={{ cursor: 'pointer' }}>
									{activeRoutesData.details}
								</Link>
							)
					  )
					: null;
			})}
		</Breadcrumbs>
	);
}

export default ActiveBreadcrumbs;
