import styled from 'styled-components';
import { InfoBox, AdsBox, ActiveBreadcrumbs } from 'back/components/';
import {
	useTheme,
	Grid,
	MessageIcon,
	StoreIcon,
	SpellcheckIcon,
	StopScreenShareIcon,
} from 'back/imports/materialUi';

import { useContext, useEffect, useState } from 'react';
import { AdsBoxEmpty, AdsBoxSkeleton } from 'back/components/AdsBox/AdsBox';
import { BusinessListContext } from 'back/context/businessList';
import GlobalAlert from 'back/components/GlobalAlert/GlobalAlert';
import { AnnouncementCont } from 'back/assets/style/generalStyle';
import { NotificationImportant } from '@material-ui/icons';
import { UserDataContext } from 'UniversalContext/userData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { HandlePesoRender } from 'back/MicroComponents/numbersFormat';
import PaidAds from 'front/components/paidAds/paidAds';

export const DfaTitle = styled.label`
	display: block;
	text-align: left;
	margin-top: 20px;
	margin-bottom: 20px;
	font-size: 18px;
`;

const adsBoxDetails = (business) => {
	const getPrimaryImage = business.images.filter(
		(image) => image.primaryPhoto === 1
	);

	return {
		title: business.name,
		description: business.description.split(' ').slice(0, 40).join(' '),
		totalInvestment: HandlePesoRender({
			value: business.initialInvest || business.franchiseFee,
		}),
		id: business._id,
		image: getPrimaryImage[0].imageName,
	};
};

export default function Dashboard() {
	const theme = useTheme();
	const [loading, setCounterLoading] = useState(true);
	const { businessListValue } = useContext(BusinessListContext);
	const { userDataValue } = useContext(UserDataContext);
	const [infoBoxValue, setInfoBoxValue] = useState({
		totalInquiries: 0,
		totalNewInquiries: 0,
		totalBusiness: 0,
		totalActiveBusiness: 0,
		totalExpired: 0,
	});
	const axiosInstance = AxiosConfigUnsecured();
	const [featuredBusinessList, setFeaturedBusinessList] = useState([]);

	useEffect(() => {
		axiosInstance.get('/public/getFeaturedBiz').then(async (res) => {
			const bizOwnerList = res.data;
			const businessListHolder = [];

			for (const businesses of bizOwnerList) {
				for (const business of businesses.businessList) {
					if (business.status === 'active') {
						businessListHolder.push(business);
					}
				}
			}

			const finalSixBusinessList = await businessListHolder
				.sort((a, b) => b.points - a.points)
				.slice(0, 6);

			setCounterLoading(false);
			setFeaturedBusinessList(finalSixBusinessList);
		});

		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			const totalInquiries = userDataValue.UserInfo.inquiry.length;
			const totalNewInquiries = userDataValue.UserInfo.inquiry.filter(
				(inquiry) => inquiry.status === 'new'
			).length;
			const totalBusiness = businessListValue.length;
			const totalActiveBusiness = businessListValue.filter(
				(business) => business.status === 'active'
			).length;
			const totalExpired = businessListValue.filter(
				(business) => business.status === 'expired'
			).length;

			setInfoBoxValue({
				totalInquiries,
				totalNewInquiries,
				totalBusiness,
				totalActiveBusiness,
				totalExpired,
			});
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken, businessListValue]);

	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid container spacing={3} style={{ marginTop: '10px' }}>
				<Grid item lg={3} md={6} sm={6} xs={12} style={{ marginTop: '20px' }}>
					<InfoBox
						myIcon={<MessageIcon />}
						loading={loading}
						title="Inquiries"
						content={infoBoxValue.totalInquiries}
						subContent={`(${infoBoxValue.totalNewInquiries}) New`}
						footer="See all inquiries"
						link="/account/inquiry"
						firstColor={theme.palette.primary.main}
						secondColor={theme.palette.primary.dark}
					/>
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12} style={{ marginTop: '20px' }}>
					<InfoBox
						myIcon={<StoreIcon />}
						loading={loading}
						title="Total Businesses"
						content={infoBoxValue.totalBusiness}
						footer="Go to my businesses"
						link="/account/businesses"
						firstColor={theme.palette.info.main}
						secondColor={theme.palette.info.dark}
					/>
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12} style={{ marginTop: '20px' }}>
					<InfoBox
						myIcon={<SpellcheckIcon />}
						loading={loading}
						title="Active Listing"
						content={infoBoxValue.totalActiveBusiness}
						footer="Go to my businesses"
						link="/account/businesses"
						firstColor={theme.palette.success.main}
						secondColor={theme.palette.success.dark}
					/>
				</Grid>
				<Grid item lg={3} md={6} sm={6} xs={12} style={{ marginTop: '20px' }}>
					<InfoBox
						myIcon={<StopScreenShareIcon />}
						loading={loading}
						title="Expired Listing"
						content={infoBoxValue.totalExpired}
						footer="Go to my businesses"
						link="/account/businesses"
						firstColor={theme.palette.error.main}
						secondColor={theme.palette.error.dark}
					/>
				</Grid>
			</Grid>

			<Grid container spacing={3} style={{ marginTop: '10px' }}>
				<Grid item lg={9} xs={12}>
					<div style={{ marginTop: '35px' }}>
						<DfaTitle>Most Viewed Franchises</DfaTitle>
						<Grid container spacing={1}>
							{loading &&
								[...Array(6)].map((data, index) => (
									<Grid item xl={6} lg={12} xs={12} key={'featuredAds' + index}>
										<AdsBoxSkeleton />
									</Grid>
								))}
							{!loading &&
								featuredBusinessList.map((data, index) => (
									<Grid item xl={6} lg={12} xs={12} key={'featuredAds' + index}>
										<AdsBox {...adsBoxDetails(data)} showLearnMore />
									</Grid>
								))}
							{!loading &&
								6 - featuredBusinessList.length !== 0 &&
								[...Array(6 - featuredBusinessList.length)].map(
									(data, index) => (
										<Grid
											item
											xl={6}
											lg={12}
											xs={12}
											key={'featuredAds' + index}>
											<AdsBoxEmpty />
										</Grid>
									)
								)}
						</Grid>
					</div>
				</Grid>
				<Grid item lg={3} xs={12}>
					<DfaTitle style={{ marginTop: '35px' }}>Announcements</DfaTitle>
					<AnnouncementCont>
						<label className="Anmt-title">Advertisement Points Mechanics</label>
						<ul>
							<li style={{ marginBottom: '10px' }}>
								When someone visited your advertisement, it will automatically
								gain points.
							</li>
							<li>
								Six (6) advertisements with the highest points will be displayed
								in our system as "Featured Ads"
							</li>
						</ul>{' '}
						<label className="anmt-logo">
							<NotificationImportant />
						</label>
					</AnnouncementCont>

					{window.innerWidth <= 1285 ? (
						<>
							<br />
							<br />
							<PaidAds adsType="horizontal" />
							<br />
							<br />
						</>
					) : (
						<PaidAds adsType="vertical" />
					)}
				</Grid>
			</Grid>
		</>
	);
}
