import {
	Button,
	FormControl,
	Grid,
	InputLabel,
	Select,
	TextField,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { PageDataContext } from 'back/imports/components';
import clsx from 'clsx';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { generalStyle } from 'front/assets/styles/generalStyle';
import { SearchContext } from 'front/context/searchContext';
import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import NumberFormat from 'react-number-format';
import { useParams } from 'react-router-dom';
import { SearchKeywordCont } from './searchStyle';

function NumbersOnlyFormat(props) {
	const { onChange, inputRef, ...others } = props;
	return (
		<NumberFormat
			{...others}
			thousandSeparator
			prefix={'₱ '}
			getInputRef={inputRef}
			onValueChange={(values) =>
				onChange({
					target: {
						value: values.value,
						name: props.name,
					},
				})
			}
		/>
	);
}

export const SearchKeyword = (props) => {
	const params = useParams();
	const [keywordButton, setKeywordButton] = useState(
		parseInt(params?.searchType) || 0
	);
	const { searchDataValue, setSearchDataValue } = useContext(SearchContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [keywordValue, setKeywordValue] = useState({
		bizName:
			searchDataValue.keyword ||
			(params?.keyword ? decodeURIComponent(params?.keyword) : ''),
		investFrom: params?.investFrom ? parseInt(params?.investFrom) : null,
		investTo: params?.investTo ? parseInt(params?.investTo) : null,
		category:
			// searchDataValue.category ||
			// (params?.category ? decodeURIComponent(params?.category) : 'All'),
			(params?.category && decodeURIComponent(params?.category)) ||
			searchDataValue.category,
	});
	const axiosInstance = AxiosConfigUnsecured();
	const [errorHandler, setErrorHandler] = useState({
		bizName: false,
		investFrom: false,
		investTo: false,
	});
	const generalClasses = generalStyle();
	const [open, setOpen] = useState(false);
	const [businessNameList, setBusinessNameList] = useState([]);

	function handleKeyword(data) {
		setKeywordButton(data);
		setSearchDataValue((prevState) => ({ ...prevState, searchType: data }));
	}

	function handleChange(e) {
		e.target.value === '' ? setOpen(false) : setOpen(true);

		if (keywordButton === 0) {
			setKeywordValue((prevState) => ({
				...prevState,
				bizName: e.target.value,
			}));
		} else {
			if (e.target.name === 'investFrom') {
				setErrorHandler((prevState) => ({
					...prevState,
					investTo:
						parseInt(e.target.value) > parseInt(keywordValue.investTo)
							? true
							: false,
					[e.target.name]:
						!e.target.value.length || e.target.value === '' ? true : false,
				}));
			} else {
				setErrorHandler((prevState) => ({
					...prevState,
					[e.target.name]:
						!e.target.value.length ||
						e.target.value === '' ||
						parseInt(keywordValue.investFrom) > parseInt(e.target.value)
							? true
							: false,
				}));
			}
			setKeywordValue((prevState) => ({
				...prevState,
				[e.target.name]: e.target.value,
			}));
		}
	}

	function handleChangeCategory(e) {
		setKeywordValue((prevState) => ({
			...prevState,
			category: e.target.value,
		}));
	}

	function handleSearch(e) {
		e.preventDefault();

		if (keywordButton === 1) {
			if (!keywordValue.investFrom || !keywordValue.investTo) {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customMessage: 'Kindly provide needed details.',
						customColor: 'error',
					},
				}));

				setErrorHandler((prevState) => ({
					...prevState,
					investFrom: !keywordValue.investFrom ? true : false,
					investTo: !keywordValue.investTo ? true : false,
				}));
				setSearchDataValue((prevState) => ({
					...prevState,
					keyword: keywordValue.bizName,
					investFrom: keywordValue.investFrom,
					investTo: keywordValue.investTo,
					category: keywordValue.category,
					searchType: keywordButton,
				}));

				return false;
			}

			if (parseInt(keywordValue.investFrom) > parseInt(keywordValue.investTo)) {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customMessage: 'Invalid Investment range provided!',
						customColor: 'error',
					},
				}));

				setSearchDataValue((prevState) => ({
					...prevState,
					keyword: keywordValue.bizName,
					investFrom: keywordValue.investmentFrom,
					investTo: keywordValue.investmentTo,
					category: keywordValue.category,
					searchType: keywordButton,
				}));

				return false;
			}
		}

		setSearchDataValue((prevState) => ({ ...prevState, loading: true }));

		axiosInstance
			.post('/public/searchBusiness', { keywordButton, keywordValue })
			.then((res) => {
				const businessOwnerList = res.data;
				var businessListHandler = [];

				if (businessOwnerList.length) {
					//business result list if not empty
					for (const businessOwner of businessOwnerList) {
						for (const business of businessOwner.businessList) {
							if (keywordButton === 0) {
								//enter here if searched via bizName
								if (keywordValue.category !== 'All') {
									//enter here if searched via bizName and category is not all
									business.name
										.toLowerCase()
										.includes(keywordValue.bizName.toLowerCase()) &&
										business.status === 'active' &&
										business.category === keywordValue.category &&
										businessListHandler.push(business);
								} else {
									//enter here if searched via bizName and category is all
									business.name
										.toLowerCase()
										.includes(keywordValue.bizName.toLowerCase()) &&
										business.status === 'active' &&
										businessListHandler.push(business);
								}
							} else {
								//enter here if searched via range
								if (keywordValue.category !== 'All') {
									//enter here if searched via range and category is not all
									business.initialInvest >= parseInt(keywordValue.investFrom) &&
										business.initialInvest <= parseInt(keywordValue.investTo) &&
										business.status === 'active' &&
										business.category === keywordValue.category &&
										businessListHandler.push(business);
								} else {
									//enter here if searched via range and category is all
									business.initialInvest >= parseInt(keywordValue.investFrom) &&
										business.initialInvest <= parseInt(keywordValue.investTo) &&
										business.status === 'active' &&
										businessListHandler.push(business);
								}
							}
						}
					}

					// randomizing value in array before displaying
					for (var i = businessListHandler.length - 1; i > 0; i--) {
						const randomNum = Math.floor(Math.random() * (i + 1));

						[businessListHandler[i], businessListHandler[randomNum]] = [
							businessListHandler[randomNum],
							businessListHandler[i],
						];
					}

					setSearchDataValue((prevState) => ({
						...prevState,
						keyword: keywordValue.bizName,
						investFrom: keywordValue.investFrom,
						investTo: keywordValue.investTo,
						searchType: keywordButton,
						category: keywordValue.category,
						searchResultList: businessListHandler,
						searchResultSortedList: businessListHandler,
						page: 0,
						perPage: 4,
						loading: false,
						freshEntry: false,
					}));
				} else {
					//if business result list is empty

					setSearchDataValue((prevState) => ({
						...prevState,
						keyword: keywordValue.bizName,
						investFrom: keywordValue.investFrom,
						investTo: keywordValue.investTo,
						searchType: keywordButton,
						category: keywordValue.category,
						searchResultList: [],
						searchResultSortedList: [],
						page: 0,
						perPage: 4,
						loading: false,
						freshEntry: false,
					}));
				}
			});
	}

	function handleOnclose() {
		setOpen(false);
	}

	useEffect(() => {
		// if (keywordValue.bizName || keywordValue.category !== 'All') {
		if (!searchDataValue.freshEntry) {
			setSearchDataValue((prevState) => ({ ...prevState, loading: true }));

			axiosInstance
				.post('/public/searchBusiness', { keywordButton, keywordValue })
				.then((res) => {
					const businessOwnerList = res.data;
					var businessListHandler = [];

					if (businessOwnerList.length) {
						for (const businessOwner of businessOwnerList) {
							for (const business of businessOwner.businessList) {
								if (keywordButton === 0) {
									//enter here if searched via bizName
									if (keywordValue.category !== 'All') {
										//enter here if searched via bizName and category is not all
										business.name
											.toLowerCase()
											.includes(keywordValue.bizName.toLowerCase()) &&
											business.status === 'active' &&
											business.category === keywordValue.category &&
											businessListHandler.push(business);
									} else {
										//enter here if searched via bizName and category is all
										business.name
											.toLowerCase()
											.includes(keywordValue.bizName.toLowerCase()) &&
											business.status === 'active' &&
											businessListHandler.push(business);
									}
								} else {
									//enter here if searched via range
									if (keywordValue.category !== 'All') {
										//enter here if searched via range and category is not all
										business.initialInvest >=
											parseInt(keywordValue.investFrom) &&
											business.initialInvest <=
												parseInt(keywordValue.investTo) &&
											business.status === 'active' &&
											business.category === keywordValue.category &&
											businessListHandler.push(business);
									} else {
										//enter here if searched via range and category is all
										business.initialInvest >=
											parseInt(keywordValue.investFrom) &&
											business.initialInvest <=
												parseInt(keywordValue.investTo) &&
											business.status === 'active' &&
											businessListHandler.push(business);
									}
								}
							}
						}

						// randomizing value in array before displaying
						for (var i = businessListHandler.length - 1; i > 0; i--) {
							const randomNum = Math.floor(Math.random() * (i + 1));

							[businessListHandler[i], businessListHandler[randomNum]] = [
								businessListHandler[randomNum],
								businessListHandler[i],
							];
						}

						setSearchDataValue((prevState) => ({
							...prevState,
							keyword: keywordValue.bizName,
							investFrom: keywordValue.investFrom,
							investTo: keywordValue.investTo,
							searchType: keywordButton,
							category: keywordValue.category,
							searchResultList: businessListHandler,
							searchResultSortedList: businessListHandler,
							page: 0,
							perPage: 4,
							loading: false,
							freshEntry: false,
						}));
					} else {
						setSearchDataValue((prevState) => ({
							...prevState,
							keyword: keywordValue.bizName,
							investFrom: keywordValue.investFrom,
							investTo: keywordValue.investTo,
							category: keywordValue.category,
							searchType: keywordButton,
							searchResultList: [],
							searchResultSortedList: [],
							page: 0,
							perPage: 4,
							loading: false,
							freshEntry: false,
						}));
					}
				});
		}
		//eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (params?.category) {
			const elementToScroll = document.getElementById('scrollToView-reference');

			setTimeout(
				() => elementToScroll.scrollIntoView({ behavior: 'smooth' }),
				100
			);
		}

		//get list businesses onload for textbox suggestion
		axiosInstance.get('/public/getFeaturedBiz').then((res) => {
			var rawListHandler = [];

			for (const businessOwner of res.data) {
				for (const business of businessOwner.businessList) {
					if (business.status === 'active') {
						rawListHandler.push(business.name);
					}
				}
			}
			setBusinessNameList(rawListHandler);
		});

		//eslint-disable-next-line
	}, []);

	return (
		<SearchKeywordCont>
			<div id="scrollToView-reference" />
			<Button
				variant="contained"
				color={keywordButton === 0 ? 'primary' : 'default'}
				className="keywordButton"
				onClick={() => handleKeyword(0)}>
				Business Name
			</Button>
			<Button
				variant="contained"
				color={keywordButton === 1 ? 'primary' : 'default'}
				className="keywordButton"
				onClick={() => handleKeyword(1)}>
				Investment Range
			</Button>

			<Grid container spacing={3}>
				<Grid item lg={7} xs={12} className="p-2">
					{keywordButton === 0 && (
						<form onSubmit={handleSearch}>
							<Autocomplete
								id="fucking-box"
								freeSolo
								open={open}
								onClose={handleOnclose}
								disableClearable
								defaultValue={keywordValue.bizName}
								onChange={(e, value) =>
									setKeywordValue((prevState) => ({
										...prevState,
										bizName: value,
									}))
								}
								onFocus={() =>
									keywordValue.bizName ? setOpen(true) : setOpen(false)
								}
								options={businessNameList}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Search business name"
										className={clsx({
											[generalClasses.errorTextbox]: errorHandler.bizName,
										})}
										value={keywordValue.bizName}
										onChange={handleChange}
										placeholder="Search business name"
									/>
								)}
							/>
						</form>
					)}
					{keywordButton === 1 && (
						<Grid container spacing={3}>
							<Grid item lg={6} xs={12}>
								<form onSubmit={handleSearch}>
									<TextField
										variant="outlined"
										name="investFrom"
										label="Investment Range From"
										placeholder="Investment Range From"
										className={clsx({
											[generalClasses.errorTextbox]: errorHandler.investFrom,
										})}
										InputProps={{ inputComponent: NumbersOnlyFormat }}
										value={keywordValue.investFrom}
										onChange={handleChange}
									/>
								</form>
							</Grid>
							<Grid item lg={6} xs={12}>
								<form onSubmit={handleSearch}>
									<TextField
										variant="outlined"
										name="investTo"
										value={keywordValue.investTo}
										onChange={handleChange}
										className={clsx({
											[generalClasses.errorTextbox]: errorHandler.investTo,
										})}
										label="Investment Range To"
										placeholder="Investment Range To"
										InputProps={{ inputComponent: NumbersOnlyFormat }}
									/>
								</form>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid item lg={3} xs={12} className="p-2">
					<FormControl variant="outlined">
						<InputLabel htmlFor="filled-age-native-simple">Category</InputLabel>
						<Select
							native
							value={keywordValue.category}
							placeholder="Choose Category"
							onChange={handleChangeCategory}
							inputProps={{
								name: 'Category',
								id: '',
							}}>
							<option aria-label="None" value="All">
								All
							</option>
							<option value="House Related">House Related</option>
							<option value="Food/Beverages">Food/Beverages</option>
							<option value="Health Industry">Health Industry</option>
							<option value="Car Industry">Car Industry</option>
							<option value="Manpower">Manpower</option>
							<option value="Art Related">Art Related</option>
							<option value="Apparel">Apparel</option>
							<option value="Others">Others</option>
						</Select>
					</FormControl>
				</Grid>
				<Grid item lg={2} xs={12} className="p-2">
					<Button
						variant="contained"
						size="large"
						color="primary"
						startIcon={<Search />}
						onClick={handleSearch}
						fullWidth>
						Search
					</Button>
				</Grid>
			</Grid>
		</SearchKeywordCont>
	);
};
