import { Grid } from '@material-ui/core';
import ActiveBreadcrumbs from 'back/components/Breadcrumbs/Breadcrumbs';
import GlobalAlert from 'back/components/GlobalAlert/GlobalAlert';
import React from 'react';
import InquiryDisplay from 'back/components/inquiry/inquiryDisplay';

const Inquiry = () => {
	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid
				container
				spacing={3}
				style={{ marginTop: '10px', marginBottom: '70px' }}>
				<Grid item lg={12} md={12} xs={12}>
					<InquiryDisplay />
				</Grid>
			</Grid>
		</>
	);
};

export default Inquiry;
