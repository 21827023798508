import React from 'react';
// import paidAdsImageVert from 'front/assets/images/banner_vertical.jpg';
// import paidAdsImageHorizontal from 'front/assets/images/banner_horizontal.jpg';

import paidAdsImageVert from 'front/assets/images/house_ads.png';
import paidAdsImageHorizontal from 'front/assets/images/house_ads_horizontal.png';

const PaidAds = (props) => {
	return (
		<>
			{props.adsType === 'vertical' ? (
				<div style={{ width: '100%' }}>
					<a
						href="https://www.facebook.com/filipinoqualityhomes.ph"
						rel="noreferrer"
						target="_blank">
						<img
							src={paidAdsImageVert}
							alt="paidAdsImage"
							style={{ width: '100%', marginTop: '80px' }}
						/>
					</a>
				</div>
			) : (
				<div style={{ width: '100%' }}>
					<a
						href="https://www.facebook.com/filipinoqualityhomes.ph"
						rel="noreferrer"
						target="_blank">
						<img
							src={paidAdsImageHorizontal}
							alt="paidAdsImage"
							style={{ width: '100%', marginTop: '10px' }}
						/>
					</a>
				</div>
			)}
		</>
	);
};

export default PaidAds;
