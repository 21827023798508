import styled from 'styled-components';

const FooterCont = styled.div`
	width: 100%;
	background-color: #000;
	z-index: 2000;
	position: fixed;
	bottom: 0;
	color: #aaa;
	padding: 10px;
	text-align: center;
`;

const Footer = () => {
	return <FooterCont>© 2023 Biztayo.ph</FooterCont>;
};

export default Footer;
