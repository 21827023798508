import React, { useEffect, useState, memo } from 'react';

import {
	Stepper as MUIStepper,
	Step,
	StepLabel,
} from 'back/imports/materialUi';

const Stepper = memo((props) => {
	const steps = [
		'Business Info',
		'Money talks',
		'Upload images',
		'Finishing up',
	];
	const [currentSteps, setCurrentSteps] = useState(props.stepsValue);

	useEffect(() => {
		setCurrentSteps(props.stepsValue);
	}, [props]);

	return (
		<div style={{ marginTop: '30px' }}>
			<MUIStepper activeStep={currentSteps} alternativeLabel>
				{steps.map((label) => (
					<Step key={label}>
						<StepLabel>{label}</StepLabel>
					</Step>
				))}
			</MUIStepper>
		</div>
	);
});

export default Stepper;
