import React, { useState, useContext, useEffect } from 'react';
import {
	Grid,
	OutlinedInput,
	Paper,
	FormControl,
	InputLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
	Popover,
	Typography,
	TextField,
} from '@material-ui/core';
import { AccountCircle, Info } from '@material-ui/icons';
import { ErrorLabel, uniqueStyle } from './myProfileStyle';
import { useHistory, Link } from 'react-router-dom';
import { UserDataContext } from 'UniversalContext/userData';
import _ from 'lodash';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { PageDataContext } from 'back/context/pageData';
import { checkNull } from 'back/imports/logicCode';
import handleNumberOnly from 'back/MicroComponents/numbersFormat';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';

const ProfileEdit = () => {
	const classes = uniqueStyle();
	const history = useHistory();
	const [popoverLoc, setPopoverLoc] = useState(null);
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [rawUserData, setRawUserData] = useState({
		fname: userDataValue.UserInfo.fname,
		lname: userDataValue.UserInfo.lname,
		address: userDataValue.UserInfo.address,
		contactNum: userDataValue.UserInfo.contactNum,
		permission: userDataValue.UserInfo.permission,
	});
	const [errorHandler, setErrorHandler] = useState({
		fname: false,
		lname: false,
	});
	const [checkedBtnHandler, setCheckedBtnHandler] = useState(true);
	const [updateBtnClicked, setUpdateBtnClicked] = useState(false);
	const axiosInstance = AxiosConfigSecured();

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken !== '') {
			setRawUserData((prevState) => ({
				...prevState,
				fname: userDataValue.UserInfo.fname,
				lname: userDataValue.UserInfo.lname,
				address: userDataValue.UserInfo.address,
				contactNum: userDataValue.UserInfo.contactNum,
				permission: userDataValue.UserInfo.permission,
			}));
		}
	}, [userDataValue.UserInfo]);

	const dataSafeguards = (event) => {
		var detectSpacesOnly = event.target.value.trim();

		//safeguards for fname and lname
		if (
			((event.target.name === 'fname' || event.target.name === 'lname') &&
				detectSpacesOnly.length <= 0) ||
			((event.target.name === 'fname' || event.target.name === 'lname') &&
				event.target.value.length > 20)
		) {
			setErrorHandler((prevState) => ({
				...prevState,
				[event.target.name]: true,
			}));
			return false;
		}

		//safeguards for address
		if (event.target.name === 'address' && event.target.value.length > 130) {
			setErrorHandler((prevState) => ({
				...prevState,
				[event.target.name]: true,
			}));
			return false;
		}

		//safeguards for contact number
		if (event.target.name === 'contactNum' && event.target.value.length > 15) {
			setErrorHandler((prevState) => ({
				...prevState,
				[event.target.name]: true,
			}));
			return false;
		}

		if (event.target.name === 'checkBtn') {
			setErrorHandler((prevState) => ({
				...prevState,
				[event.target.name]: event.target.checked,
			}));

			return false;
		}

		setErrorHandler((prevState) => ({
			...prevState,
			[event.target.name]: false,
		}));
	};

	const handleChange = (event) => {
		var detectMultiSpaces = event.target.value.replace(/\s\s+/g, ' ');

		//data safeguards
		dataSafeguards(event);

		setRawUserData((prevState) => ({
			...prevState,
			[event.target.name]: detectMultiSpaces,
		}));
	};

	const handleUpdate = () => {
		if (_.includes(errorHandler, true) || checkedBtnHandler) {
			setUpdateBtnClicked(true);
			return false;
		}

		setPageDataValue((prevState) => ({ ...prevState, backdrop: true }));

		axiosInstance
			.post(
				'/user/updateProfile',
				{
					userId: userDataValue.UserInfo.userID,
					fname: rawUserData.fname,
					lname: rawUserData.lname,
					address: rawUserData.address,
					contactNum: rawUserData.contactNum,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: { ...prevState, success: true },
					backdrop: false,
				}));

				setUserDataValue((prevState) => ({
					...prevState,
					UserInfo: {
						...prevState.UserInfo,
						fname: res.data.fname,
						lname: res.data.lname,
						address: checkNull(res.data.address),
						contactNum: checkNull(res.data.contactNum),
						notif: res.data.notif.reverse(),
					},
				}));

				history.push('/account/myProfile');
			});
	};

	const open = popoverLoc ? true : false;

	return (
		<>
			<ActiveBreadcrumbs />
			<Grid container style={{ marginTop: '50px' }} justifyContent="center">
				<Grid item lg={5} xs={12}>
					<Paper>
						<center>
							<AccountCircle className={classes.userLogo} />
						</center>

						<label className={classes.updateTitle}>
							Updating Account Details
						</label>

						<div className="p-3">
							<Grid container spacing={1}>
								<Grid item lg={12} xs={12}>
									<FormControl variant="outlined" style={{ width: '100%' }}>
										<InputLabel htmlFor="outlined-adornment-password">
											Email address
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="Email"
											name="email"
											defaultValue={userDataValue.UserInfo.email}
											disabled
										/>
									</FormControl>
								</Grid>
								<Grid item lg={6} xs={12}>
									<FormControl
										variant="outlined"
										style={{ width: '100%' }}
										className={errorHandler.fname ? classes.errorTextbox : ''}>
										<InputLabel htmlFor="outlined-adornment-password">
											First Name
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="fname"
											name="fname"
											required
											value={rawUserData.fname}
											onChange={handleChange}
											inputProps={{ maxLength: 20 }}
										/>
									</FormControl>
								</Grid>
								<Grid item lg={6} xs={12}>
									<FormControl
										variant="outlined"
										style={{ width: '100%' }}
										className={errorHandler.lname ? classes.errorTextbox : ''}>
										<InputLabel htmlFor="outlined-adornment-password">
											Last Name
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="lname"
											name="lname"
											required
											value={rawUserData.lname}
											onChange={handleChange}
											inputProps={{ maxLength: 20 }}
										/>
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<FormControl
										variant="outlined"
										style={{ width: '100%' }}
										className={
											errorHandler.address ? classes.errorTextbox : ''
										}>
										<InputLabel htmlFor="outlined-adornment-password">
											Current Address
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="Current Address"
											name="address"
											multiline
											rows={4}
											value={rawUserData.address}
											onChange={handleChange}
											inputProps={{ maxLength: 130 }}
										/>
									</FormControl>
								</Grid>
								<Grid item lg={6} xs={12}>
									<TextField
										fullWidth
										variant="outlined"
										label="Contact Number"
										name="contactNum"
										placeholder="+63"
										InputProps={{
											inputComponent: handleNumberOnly,
										}}
										value={rawUserData.contactNum}
										onChange={handleChange}
									/>
								</Grid>
								<Grid item lg={6} xs={12}>
									<FormControl variant="outlined" style={{ width: '100%' }}>
										<InputLabel htmlFor="outlined-adornment-password">
											Account Permission
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="Instagram"
											name="instagramLink"
											disabled
											value={rawUserData.permission}
											endAdornment={
												<Info
													style={{ cursor: 'pointer' }}
													onClick={(event) =>
														setPopoverLoc(event.currentTarget)
													}
												/>
											}
										/>
									</FormControl>
									<Popover
										open={open}
										anchorEl={popoverLoc}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'center',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'center',
										}}
										onClose={() => setPopoverLoc(null)}>
										<Typography className={classes.popoverDetails}>
											<b>Level 1:</b> Not allowed to publish Business <br />
											<b>Level 2:</b> Allowed to publish Business (Done with the
											verification sent in email)
										</Typography>
									</Popover>
								</Grid>
								<Grid item lg={12} xs={12}>
									<FormGroup className="mt-2 mb-3">
										<center>
											<FormControlLabel
												control={
													<Checkbox
														checked={rawUserData.checkedBtn}
														color="primary"
														name="checkBtn"
														onChange={(event) =>
															setCheckedBtnHandler(!event.target.checked)
														}
													/>
												}
												label={
													<>
														I agree to{' '}
														<Link to="/bt/terms&Conditions" target="_blank">
															Terms and Conditions
														</Link>{' '}
														of this platform.
													</>
												}
											/>
										</center>
									</FormGroup>
								</Grid>
								<Grid item lg={12} xs={12} align="center" className="mb-3">
									<Button
										variant="contained"
										size="large"
										onClick={() => history.push('/account/myProfile')}
										style={{ marginRight: '15px' }}>
										Cancel
									</Button>
									<Button
										variant="contained"
										size="large"
										color="primary"
										onClick={handleUpdate}
										disabled={checkedBtnHandler}>
										Update
									</Button>
								</Grid>
								<ErrorLabel>
									&nbsp;
									{_.includes(errorHandler, true) &&
										updateBtnClicked &&
										'Please provide details needed in the red box/es'}
								</ErrorLabel>
							</Grid>
						</div>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default ProfileEdit;
