import {
	Button,
	CircularProgress,
	Grid,
	Paper,
	Tab,
	Tabs,
	TextField,
} from '@material-ui/core';
import React, { useState } from 'react';

import {
	AdsDetailsDiv,
	AdsLogo,
	AdsTitle,
	InfoLabel,
	InquiryContainer,
	OtherImagesContainer,
	PartitionTitle,
	TableDesign,
	uniqueStyle,
} from './adsDisplayDetailsStyle';
import {
	AccountCircle,
	ContactPhone,
	Email,
	Facebook,
	GroupWork,
	Instagram,
	Language,
	MonetizationOn,
	PinDrop,
	PlaylistAddCheck,
	Today,
	Twitter,
	WatchLater,
} from '@material-ui/icons';
import ImageGallery from 'react-image-gallery';

import 'react-image-gallery/styles/css/image-gallery.css';

import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useContext } from 'react';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { FrontBusinessContext } from 'front/context/business';
import NumberFormat from 'react-number-format';
import { Skeleton } from '@material-ui/lab';
import clsx from 'clsx';
import { PageDataContext } from 'back/context/pageData';
import { generalStyle } from 'front/assets/styles/generalStyle';
import _ from 'lodash';
import handleNumberOnly from 'back/MicroComponents/numbersFormat';
import { SocketContext } from 'back/context/socket';
import { ImgDisplay } from '../imgDisplay/imgDisplay';
import PaidAds from '../paidAds/paidAds';

const InquiryComponent = (props) => {
	const axiosInstance = AxiosConfigUnsecured();
	const { socket } = useContext(SocketContext);
	const [inquiryDetails, setInquiryDetails] = useState({
		fullname: '',
		email: '',
		contactNum: '',
		message: '',
	});
	const [errorHandler, setErrorHandler] = useState({
		fullname: false,
		email: false,
		contactNum: false,
		message: false,
	});
	const classes = uniqueStyle();
	const [loading, setLoading] = useState(false);
	const { setPageDataValue } = useContext(PageDataContext);
	const generalClasses = generalStyle();
	const [timer, setTimer] = useState(
		parseInt(localStorage.getItem('inquiryInterval')) || null
	);
	const date = new Date();
	const getCurrentTime = date.getTime();

	function checkInputs() {
		let errors = {
			fullname: false,
			email: false,
			contactNum: false,
			message: false,
		};

		!inquiryDetails.fullname || inquiryDetails.fullname === ''
			? (errors.fullname = true)
			: (errors.fullname = false);
		!inquiryDetails.email || inquiryDetails.email === ''
			? (errors.email = true)
			: (errors.email = false);
		!inquiryDetails.contactNum || inquiryDetails.contactNum === ''
			? (errors.contactNum = true)
			: (errors.contactNum = false);
		!inquiryDetails.message || inquiryDetails.message === ''
			? (errors.message = true)
			: (errors.message = false);

		setErrorHandler(errors);

		return _.includes(errors, true);
	}

	function handleChange(e) {
		if (e.target.name === 'message' && inquiryDetails.message.length >= 500) {
			return false;
		}
		if (e.target.name !== 'message' && e.target.value.length >= 75) {
			return false;
		}

		setInquiryDetails((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));

		!e.target.value || e.target.value === ''
			? setErrorHandler((prevState) => ({
					...prevState,
					[e.target.name]: true,
			  }))
			: setErrorHandler((prevState) => ({
					...prevState,
					[e.target.name]: false,
			  }));
	}

	function handleSubmit({ businessDetails }) {
		if (checkInputs()) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: 'Please provide needed details',
					customColor: 'error',
				},
			}));
			return true;
		}

		if (timer + 60000 >= getCurrentTime) {
			const getRemainingTime = (timer + 60000 - getCurrentTime) / 1000;

			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: `Please wait ${parseInt(
						getRemainingTime
					)} seconds before sending another inquiry`,
					customColor: 'warning',
				},
			}));
			return false;
		}

		setLoading(true);
		axiosInstance
			.post('/public/saveInquiry', {
				inquiryDetails,
				bizId: props.businessDetails._id,
				bizName: props.businessDetails.name,
			})
			.then((res) => {
				setLoading(false);
				setInquiryDetails({
					fullname: '',
					email: '',
					contactNum: '',
					message: '',
				});
				setErrorHandler({
					fullname: false,
					email: false,
					contactNum: false,
					message: false,
				});
				socket.emit('inquiry:new', { bizId: props.businessDetails._id });
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customMessage:
							'Successfully submitted your inquiry to business owner',
						customColor: 'success',
					},
				}));
				setTimer(getCurrentTime);

				//saved to localstorage so even page refresh, we can still detect the time remaining in sending new inquiry
				localStorage.setItem('inquiryInterval', getCurrentTime);
			});
	}

	return (
		<InquiryContainer>
			<label className="inquiryTitle">Leave a message</label>

			<TextField
				fullWidth
				variant="outlined"
				label="Full name"
				name="fullname"
				placeholder="Provide fullname"
				size="small"
				className={clsx({
					[generalClasses.errorTextbox]: errorHandler.fullname,
				})}
				value={inquiryDetails.fullname}
				onChange={handleChange}
			/>

			<TextField
				fullWidth
				variant="outlined"
				label="Email Address"
				name="email"
				placeholder="Provide email address"
				size="small"
				className={clsx({ [generalClasses.errorTextbox]: errorHandler.email })}
				value={inquiryDetails.email}
				onChange={handleChange}
			/>

			<TextField
				fullWidth
				variant="outlined"
				label="Contact Number"
				name="contactNum"
				placeholder="+63## ### ####"
				size="small"
				className={clsx({
					[generalClasses.errorTextbox]: errorHandler.contactNum,
				})}
				InputProps={{
					inputComponent: handleNumberOnly,
				}}
				value={inquiryDetails.contactNum}
				onChange={handleChange}
			/>

			<TextField
				fullWidth
				variant="outlined"
				label="Message"
				name="message"
				placeholder="Pleave provide your message/Inquiry"
				size="small"
				multiline
				className={clsx({
					[generalClasses.errorTextbox]: errorHandler.message,
				})}
				value={inquiryDetails.message}
				onChange={handleChange}
				rows={6}
			/>

			<div className={classes.root}>
				<div className={classes.wrapper}>
					<Button
						variant="contained"
						color="primary"
						size="small"
						className={clsx({
							[classes.buttonDisabled]: loading,
							[classes.buttonActive]: !loading,
						})}
						fullWidth
						disabled={loading || !props.businessDetails ? true : false}
						onClick={handleSubmit}>
						Submit
					</Button>
					{loading && (
						<CircularProgress size={24} className={classes.buttonProgress} />
					)}
				</div>
			</div>
		</InquiryContainer>
	);
};

const ImgGalleryContainer = ({ photos }) => {
	const axiosInstance = AxiosConfigUnsecured();
	const [photosItem, setPhotosItem] = useState([]);

	useEffect(() => {
		for (const image of photos) {
			axiosInstance
				.get(`/getBusinessImage?imageId=${encodeURIComponent(image.imageName)}`)
				.then((signedUrl) => {
					setPhotosItem((prevState) => [
						...prevState,
						{
							original: signedUrl.data,
							thumbnail: signedUrl.data,
						},
					]);
				})
				.catch((err) => console.log(err));
		}
		//eslint-disable-next-line
	}, [photos]);

	return (
		<>
			{photos.length === photosItem.length && (
				<ImageGallery
					showPlayButton={false}
					additionalClass="imageGallery"
					items={photosItem}
				/>
			)}
		</>
	);
};

const AdsDisplayDetails = () => {
	const [tabValue, setTabValue] = useState(0);
	const classes = uniqueStyle();
	const { id } = useParams();
	const axiosInstance = AxiosConfigUnsecured();
	const { frontBusinessListValue } = useContext(FrontBusinessContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [businessDetails, setBusinessDetails] = useState();
	const history = useHistory();
	const [photos, setPhotos] = useState({
		primary: '',
		display: [],
	});

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
		setBusinessDetails(null);
		if (!frontBusinessListValue.length) {
			axiosInstance
				.post('/public/getBusinessDetails', { bizId: id })
				.then(async (res) => {
					if (res.data !== 'err') {
						const business = res.data;

						const primaryImageHolder = await business.images.find(
							(img) => img.primaryPhoto === 1
						);

						const displayPhoto = await business.images.filter((img) => {
							if (img.primaryPhoto === 0) {
								return true;
							}
							return false;
						});

						setPhotos({
							primary: primaryImageHolder.imageName,
							display: displayPhoto,
						});
						setBusinessDetails(res.data);
					} else {
						setPageDataValue((prevState) => ({
							...prevState,
							snackBar: {
								...prevState.snackBar,
								customMessage: 'Invalid URL detected, Please try again',
								customSnackbar: true,
								customColor: 'error',
							},
						}));
						history.push('/bt/search');
					}
				});
		} else {
			//do something here once frontBusinessList is populated
		}
		//eslint-disable-next-line
	}, [id]);

	function getFullDate(rawDate) {
		const date = new Date(rawDate);
		const monthNames = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December',
		];

		return (
			monthNames[date.getMonth()] +
			' ' +
			date.getDate() +
			', ' +
			date.getFullYear()
		);
	}

	function MoneyTab() {
		return (
			<TableDesign style={{ width: '100%' }}>
				<tbody>
					<tr>
						<th>
							<InfoLabel>
								<span>
									<WatchLater /> Franchise Terms
								</span>
							</InfoLabel>
						</th>
						<td>: {businessDetails?.franchiseTerm} Year/s</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<MonetizationOn /> Initial Investment
								</span>
							</InfoLabel>
						</th>
						<td>
							:&nbsp;
							<NumberFormat
								displayType="text"
								value={businessDetails?.initialInvest}
								prefix="₱ "
								thousandSeparator=","
							/>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<MonetizationOn /> Franchise Fee
								</span>
							</InfoLabel>
						</th>
						<td>
							:&nbsp;
							<NumberFormat
								displayType="text"
								value={businessDetails?.franchiseFee}
								prefix="₱ "
								thousandSeparator=","
							/>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<MonetizationOn /> Marketing Fee
								</span>
							</InfoLabel>
						</th>
						<td>: {businessDetails?.marketingFee} %</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<MonetizationOn /> Royalty fee
								</span>
							</InfoLabel>
						</th>
						<td>: {businessDetails?.royaltyFee} %</td>
					</tr>
				</tbody>
			</TableDesign>
		);
	}

	function BizInfoTab() {
		return (
			<TableDesign style={{ width: '100%' }}>
				<tbody>
					<tr>
						<th>
							<InfoLabel>
								<span>
									<GroupWork /> Category
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.category}</label>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<Today /> Date Established
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {getFullDate(businessDetails?.date)}</label>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<PinDrop />
									Business Address
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.address}</label>
						</td>
					</tr>

					<tr>
						<th style={{ verticalAlign: 'top' }}>
							<InfoLabel>
								<span>
									<PlaylistAddCheck /> Requirements:
								</span>
							</InfoLabel>
						</th>
						<td>
							{businessDetails?.requirement.map((req, index) => (
								<div key={`req_${index}`}>
									<label>: {req}</label>
									<br />
								</div>
							))}
						</td>
					</tr>
				</tbody>
			</TableDesign>
		);
	}

	function ContactTab() {
		return (
			<TableDesign style={{ width: '100%' }}>
				<tbody>
					<tr>
						<th>
							<InfoLabel>
								<span>
									<AccountCircle /> Full name
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.fullname}</label>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<Email /> Email Address
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.email}</label>
						</td>
					</tr>

					<tr>
						<th style={{ verticalAlign: 'top' }}>
							<InfoLabel>
								<span>
									<ContactPhone /> Contact Number
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>
								:&nbsp;
								<NumberFormat
									displayType="text"
									value={businessDetails?.contactNum}
									prefix="+63"
									format="+63###-###-####"
								/>
							</label>
						</td>
					</tr>
				</tbody>
			</TableDesign>
		);
	}

	function LinkTab() {
		return (
			<TableDesign style={{ width: '100%' }}>
				<tbody>
					<tr>
						<th>
							<InfoLabel>
								<span>
									<Facebook /> Facebook
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.facebook}</label>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<Instagram /> Instagram
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.instagram}</label>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<Twitter /> Twitter
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.twitter}</label>
						</td>
					</tr>

					<tr>
						<th>
							<InfoLabel>
								<span>
									<Language /> Website
								</span>
							</InfoLabel>
						</th>
						<td>
							<label>: {businessDetails?.website}</label>
						</td>
					</tr>
				</tbody>
			</TableDesign>
		);
	}

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	return !businessDetails ? (
		<AdsDisplayDetailsSkeleton />
	) : (
		<>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				style={{ marginTop: '20px' }}>
				<Grid item lg={6} xs={12}>
					<AdsLogo>
						<ImgDisplay
							imageId={photos.primary}
							skeletonComponent={{
								variant: 'circle',
								style: {
									height: '250px',
									margin: 'auto auto',
									width: '250px',
								},
							}}
						/>
					</AdsLogo>

					<AdsTitle>{businessDetails?.name}</AdsTitle>
				</Grid>
			</Grid>

			<Grid container spacing={3} style={{ marginTop: '20px' }}>
				<Grid item lg={9} xs={12}>
					<Grid container spacing={2}>
						<Grid item lg={12} xs={12}>
							<PartitionTitle>
								<label>Business Description</label>
							</PartitionTitle>

							<AdsDetailsDiv>{businessDetails?.description}</AdsDetailsDiv>
						</Grid>
						<Grid item lg={12} xs={12} style={{ marginTop: '30px' }}>
							<OtherImagesContainer>
								{photos?.display.length !== 0 && (
									<ImgGalleryContainer photos={photos?.display} />
								)}
							</OtherImagesContainer>
						</Grid>

						<Grid item lg={12} xs={12} style={{ marginTop: '30px' }}>
							<Paper>
								<Tabs
									value={tabValue}
									indicatorColor="primary"
									textColor="primary"
									onChange={handleTabChange}
									variant="fullWidth"
									aria-label="disabled tabs example">
									<Tab label="Business Information" />
									<Tab label="Contact Details" />
									<Tab label="Links" />
									<Tab label="Money talks" />
								</Tabs>
							</Paper>

							<div className={classes.tabPanel}>
								{tabValue === 0 && <BizInfoTab />}
								{tabValue === 1 && <ContactTab />}
								{tabValue === 2 && <LinkTab />}
								{tabValue === 3 && <MoneyTab />}
							</div>
						</Grid>
					</Grid>
				</Grid>

				<Grid item lg={3} xs={12}>
					<InquiryComponent businessDetails={businessDetails} />
					<br />
					<br />
					{window.innerWidth <= 1285 ? (
						<PaidAds adsType="horizontal" />
					) : (
						<PaidAds adsType="vertical" />
					)}
				</Grid>
			</Grid>
			<br />
			<br />
		</>
	);
};

const AdsDisplayDetailsSkeleton = () => {
	return (
		<>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				style={{ marginTop: '20px' }}>
				<Grid item lg={6}>
					<AdsLogo>
						<Skeleton
							variant="circle"
							style={{ height: '250px', margin: 'auto auto', width: '250px' }}
						/>
					</AdsLogo>

					<AdsTitle>
						<Skeleton
							variant="text"
							style={{ width: '100%', borderRadius: '10px' }}
						/>
					</AdsTitle>
				</Grid>
			</Grid>

			<Grid container spacing={3} style={{ marginTop: '20px' }}>
				<Grid item lg={9} xs={12}>
					<Grid container spacing={2}>
						<Grid item lg={12} xs={12}>
							<PartitionTitle>
								<label>Business Description</label>
							</PartitionTitle>

							<AdsDetailsDiv>
								<Skeleton
									variant="rect"
									style={{ height: '300px', borderRadius: '10px' }}
								/>
							</AdsDetailsDiv>
						</Grid>
						<Grid item lg={12} xs={12} style={{ marginTop: '30px' }}>
							<Skeleton
								variant="rect"
								style={{ height: '50px', borderRadius: '10px' }}
							/>
						</Grid>

						<Grid item lg={12} xs={12} style={{ marginTop: '30px' }}>
							<Skeleton
								variant="rect"
								style={{ height: '200px', borderRadius: '10px' }}
							/>
						</Grid>
					</Grid>
				</Grid>

				<Grid item lg={3} md={12} xs={12}>
					<InquiryComponent businessDetails={null} />

					{window.innerWidth <= 1285 ? (
						<PaidAds adsType="horizontal" />
					) : (
						<PaidAds adsType="vertical" />
					)}
				</Grid>
			</Grid>
			<br />
			<br />
		</>
	);
};

export default AdsDisplayDetails;
