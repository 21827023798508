import { Button, Grid, Paper } from '@material-ui/core';
import React, { useEffect, useContext, useState, useRef } from 'react';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';
import GlobalAlert from '../GlobalAlert/GlobalAlert';
import { generalStyle } from 'back/assets/style/generalStyle';
import { ActionContainer, DetailsCont } from './inquiryDisplayStyle';
import { DeleteForever, KeyboardBackspace } from '@material-ui/icons';
import { useHistory, useParams } from 'react-router-dom';
import { Skeleton } from '@material-ui/lab';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { UserDataContext } from 'UniversalContext/userData';
import {
	PageDataContext,
	pageDataDialogBoxCleanup,
} from 'back/context/pageData';

const InquiryDetails = () => {
	const generalClasses = generalStyle();
	const history = useHistory();
	const URLparams = useParams();
	const axiosInstance = AxiosConfigSecured();
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const [inquiryDetails, setInquiryDeteails] = useState();
	const loading = useRef(true);

	useEffect(() => {
		if (URLparams?.id && userDataValue.UserInfo.accessToken) {
			axiosInstance
				.post(
					'/account/getInquiryDetails',
					{ id: URLparams.id },
					{ withCredentials: true }
				)
				.then((res) => {
					if (res.data) {
						loading.current = false;

						setInquiryDeteails(res.data);
						setUserDataValue((prevState) => {
							prevState.UserInfo.inquiry.map((prevData) => {
								if (prevData._id === URLparams.id) {
									prevData.status = 'old';

									return prevData;
								} else {
									return prevData;
								}
							});

							return prevState;
						});
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
						}));
					} else {
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
							snackBar: {
								...prevState.snackBar,
								customSnackbar: true,
								customMessage: "Inquiry selected doesn't exist anymore",
								customColor: 'error',
							},
						}));
						history.push('/account/inquiry');
					}
				});
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken, URLparams?.id]);

	//this will detect if user deleted some inquiry
	useEffect(() => {
		// if (userDataValue.UserInfo.accessToken && inquiries.length) {
		if (pageDataValue.dialogBox.taskStatus) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: { ...prevState.snackBar, success: true },
				dialogBox: pageDataDialogBoxCleanup,
			}));
			history.push('/account/inquiry');
		}
		//eslint-disable-next-line
	}, [pageDataValue.dialogBox.taskStatus]);

	function handleDelete() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState.dialogBox,
				status: true,
				color: 'error',
				title: 'Deleting inquiry',
				message: `Are you sure you want to delete this inquiry?`,
				buttonName: 'Delete inquiry',
				payloadId: URLparams.id,
			},
		}));
	}

	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid container spacing={3} style={{ marginTop: '10px' }}>
				<Grid item lg={12} xs={12}>
					<Paper>
						<label
							className={generalClasses.paperTitle}
							style={{ borderBottom: '1px solid #ccc' }}>
							Inquiry Details
						</label>

						<ActionContainer>
							<Button
								variant="outlined"
								size="small"
								className="backBtn"
								startIcon={
									<>
										<KeyboardBackspace />
									</>
								}
								onClick={() => history.push('/account/inquiry')}>
								Return
							</Button>

							<Button
								variant="outlined"
								size="small"
								color="secondary"
								className="deleteBtn"
								onClick={handleDelete}
								startIcon={
									<>
										<DeleteForever />
									</>
								}>
								Delete
							</Button>
						</ActionContainer>

						{!loading.current && (
							<Grid container justifyContent="center">
								<Grid item lg={7} xs={12}>
									<DetailsCont>
										<label className="inquiryDate">{inquiryDetails.date}</label>
										<div style={{ clear: 'both' }} />
										<label className="inquiryTitle">
											<span>Business Name:</span> {inquiryDetails.bizName}
										</label>
										<label className="inquiryGreetings">
											Good Day franchisor
										</label>
										<label className="inquiryDescription">
											{inquiryDetails.message}
										</label>
										<label className="inquirySender">
											{inquiryDetails.fullname} <br /> {inquiryDetails.email}{' '}
											<br /> {inquiryDetails.contactNum}
										</label>
									</DetailsCont>
								</Grid>
							</Grid>
						)}

						{loading.current && (
							<Grid container justifyContent="center">
								<Grid item lg={7} xs={12}>
									<DetailsCont>
										<label className="inquiryDate">
											<Skeleton variant="rect" width={150} height={25} />
										</label>
										<div style={{ clear: 'both' }} />
										<label className="inquiryTitle">
											<span>Business Name:</span>
											<center>
												<Skeleton variant="rect" width={150} height={25} />
											</center>
										</label>
										<label className="inquiryGreetings">
											<Skeleton variant="rect" width={150} height={25} />
										</label>
										<label className="inquiryDescription">
											<Skeleton variant="rect" height={300} />
										</label>
										<label className="inquirySender">
											<Skeleton
												variant="rect"
												width={150}
												height={25}
												style={{ float: 'right' }}
											/>
											<div style={{ clear: 'both' }} />
											<br />
											<Skeleton
												variant="rect"
												width={150}
												height={25}
												style={{ float: 'right' }}
											/>
											<div style={{ clear: 'both' }} />
											<br />
											<Skeleton
												variant="rect"
												width={150}
												height={25}
												style={{ float: 'right' }}
											/>
										</label>
									</DetailsCont>
								</Grid>
							</Grid>
						)}
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default InquiryDetails;
