import { useContext } from 'react';
import axios from 'axios';
import { UserDataContext } from 'UniversalContext/userData';
import { PageDataContext } from 'back/context/pageData';

const AxiosConfigSecured = () => {
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);

	const instance = axios.create({
		withCredentials: true,
		baseURL:
			process.env.REACT_APP_BUILD_STATUS === 'DEV'
				? 'http://localhost:5000/'
				: 'https://backend.biztayo.ph/',
		headers: { authorization: `bearer ${userDataValue.UserInfo.accessToken}` },
	});

	instance.interceptors.request.use((req) => {
		// //display backdrop on every request sent
		req.method !== 'get' &&
			setPageDataValue((prevState) => ({ ...prevState, backdrop: true }));
		return req;
	});

	instance.interceptors.response.use(
		(res) => res,
		(err) => {
			if (err.message === 'Network Error') {
				setPageDataValue((prevState) => ({
					...prevState,
					backdrop: false,
					snackBar: { ...prevState.snackBar, error: true },
				}));
			} else if (err.response.status === 401) {
				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				setUserDataValue((prevState) => ({ ...prevState, isLogin: false }));
			} else {
				setPageDataValue((prevState) => ({
					...prevState,
					backdrop: false,
					snackBar: { ...prevState, error: true },
				}));
			}

			return Promise.reject(err);
		}
	);

	return instance;
};

const AxiosConfigUnsecured = () => {
	const { setPageDataValue } = useContext(PageDataContext);

	const instance = axios.create({
		withCredentials: true,
		baseURL:
			process.env.REACT_APP_BUILD_STATUS === 'DEV'
				? 'http://localhost:5000/'
				: 'https://backend.biztayo.ph/',
	});

	instance.interceptors.response.use(
		(res) => res,
		(err) => {
			if (err.message === 'Network Error') {
				setPageDataValue((prevState) => ({
					...prevState,
					backdrop: false,
					snackBar: { ...prevState.snackBar, error: true },
				}));
			} else if (err.response.status === 401) {
				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
			} else {
				setPageDataValue((prevState) => ({
					...prevState,
					backdrop: false,
					snackBar: { ...prevState, error: true },
				}));
			}

			return Promise.reject(err);
		}
	);

	return instance;
};

export { AxiosConfigSecured, AxiosConfigUnsecured };
