import { Grid, Paper } from '@material-ui/core';
import React from 'react';
import {
	ImgContainer,
	ImgDiv,
	ImgPrimaryIndicator,
} from './BusinessDisplayStyle';
import { generalStyle } from 'back/assets/style/generalStyle';
import clsx from 'clsx';
import { ImgDisplay } from 'front/components/imgDisplay/imgDisplay';

const BusinessDisplayImage = ({ businessValue, setBusinessValue }) => {
	const generalClasses = generalStyle();

	return (
		<>
			<Grid container>
				<Grid item lg={12} xs={12}>
					<Paper style={{ marginTop: '40px', padding: '50px' }}>
						<label
							className={clsx([
								generalClasses.guidePhrase,
								generalClasses.center,
								'd-block',
							])}>
							Maximum of 4 photos per business
						</label>
						<Grid container justifyContent="center">
							<Grid item lg={8} xs={12}>
								<Grid container justifyContent="center" className="mt-5">
									{businessValue.images.map((img, index) => (
										<Grid
											item
											key={`images_${index}`}
											lg={3}
											md={6}
											xs={12}
											style={{
												maxHeight: '250px',
												height: '250px',
												width: 'inherit',
												marginTop: '20px',
											}}>
											<ImgContainer>
												<ImgDiv>
													<ImgDisplay imageId={img.imageName} />
												</ImgDiv>
												{img.primaryPhoto === 1 && (
													<ImgPrimaryIndicator>
														Display Photo
													</ImgPrimaryIndicator>
												)}
											</ImgContainer>
										</Grid>
									))}
								</Grid>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default BusinessDisplayImage;
