import React, { useState } from 'react';

import { SearchResultList } from './searchResultList';
import { SearchResultGrid } from './searchResultGrid';
import {
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	Select,
	TablePagination,
} from '@material-ui/core';
import {
	ResultLabel,
	SearchLoadingCont,
	SearchResultCont,
} from './searchStyle';
import { Apps, Reorder } from '@material-ui/icons';
import { SearchContext } from 'front/context/searchContext';
import { useContext } from 'react';
import _ from 'lodash';
import SearchResultEmpty from './searchResultEmpty';

export const SearchResult = (props) => {
	const { searchDataValue, setSearchDataValue } = useContext(SearchContext);
	const [resultDisplay, setResultDisplay] = useState('list');
	const [sort, setSort] = useState('Default');

	function handleResultDisplay(data) {
		setResultDisplay(data);
	}

	function handleChangePage(e, nextPage) {
		setSearchDataValue((prevState) => ({ ...prevState, page: nextPage }));

		const elementToScroll = document.getElementById('scrollToView-reference');

		setTimeout(
			() => elementToScroll.scrollIntoView({ behavior: 'smooth' }),
			100
		);
	}

	function handleChangePerPAge(e, value) {
		setSearchDataValue((prevState) => ({
			...prevState,
			perPage: e.target.value,
		}));
	}

	function handleChangeSort(e) {
		setSort(e.target.value);

		const clonedResultList = _.cloneDeep(searchDataValue.searchResultList);
		if (parseInt(e.target.value) === 2) {
			clonedResultList.sort((a, b) => a.name.localeCompare(b.name));
			setSearchDataValue((prevState) => ({
				...prevState,
				searchResultSortedList: clonedResultList,
			}));
		} else if (parseInt(e.target.value) === 3) {
			clonedResultList.sort(
				(a, b) => parseInt(b.initialInvest) - parseInt(a.initialInvest)
			);

			setSearchDataValue((prevState) => ({
				...prevState,
				searchResultSortedList: clonedResultList,
			}));
		} else if (parseInt(e.target.value) === 4) {
			clonedResultList.sort(
				(a, b) => parseInt(a.initialInvest) - parseInt(b.initialInvest)
			);

			setSearchDataValue((prevState) => ({
				...prevState,
				searchResultSortedList: clonedResultList,
			}));
		} else {
			setSearchDataValue((prevState) => ({
				...prevState,
				searchResultSortedList: searchDataValue.searchResultList,
			}));
		}
	}

	return (
		<SearchResultCont>
			{searchDataValue.searchResultList.length !== 0 &&
				!searchDataValue.loading && (
					<>
						<Grid
							container
							spacing={3}
							alignItems="center"
							className="search-result-navi-cont">
							<Grid item lg={4} xs={12}>
								<ResultLabel>
									Result: <span>{searchDataValue.searchResultList.length}</span>
								</ResultLabel>
							</Grid>
							<Grid item lg={4} xs={12}>
								<FormControl variant="outlined" size="small">
									<InputLabel htmlFor="filled-age-native-simple">
										Sort by
									</InputLabel>
									<Select
										native
										value={sort}
										disabled={
											searchDataValue.searchResultList.length === 0
												? true
												: false
										}
										placeholder="Choose Category"
										onChange={handleChangeSort}
										inputProps={{
											name: 'Category',
											id: 'filled-age-native-simple',
										}}>
										<option value={1}>Default</option>
										<option value={2}>Alphabetical</option>
										<option value={3}>Investment: Highest to Lowest</option>
										<option value={4}>Investment: Lowest to Highest</option>
									</Select>
								</FormControl>
							</Grid>
							<Grid item lg={4} xs={12}>
								<div className="viewTypeCont">
									<label>
										View type:{' '}
										<IconButton
											color="primary"
											disabled={
												searchDataValue.searchResultList.length === 0
													? true
													: false
											}
											className={resultDisplay === 'list' ? 'active' : ''}
											onClick={() => handleResultDisplay('list')}>
											<Reorder />
										</IconButton>
										<IconButton
											disabled={
												searchDataValue.searchResultList.length === 0
													? true
													: false
											}
											className={resultDisplay === 'grid' ? 'active' : ''}
											color="primary"
											onClick={() => handleResultDisplay('grid')}>
											<Apps />
										</IconButton>
									</label>
								</div>
							</Grid>
						</Grid>
						<hr />
					</>
				)}
			{!searchDataValue.loading ? (
				<>
					<Grid container spacing={3}>
						<Grid item lg={12} xs={12}>
							{!searchDataValue.searchResultList.length && (
								<SearchResultEmpty />
							)}
							{searchDataValue.searchResultList.length !== 0 &&
								resultDisplay === 'list' && <SearchResultList />}
							{searchDataValue.searchResultList.length !== 0 &&
								resultDisplay === 'grid' && <SearchResultGrid />}
						</Grid>
					</Grid>

					{searchDataValue.searchResultList.length !== 0 && (
						<>
							<br />
							<br />
							<TablePagination
								component="div"
								count={searchDataValue.searchResultList.length}
								rowsPerPageOptions={[4, 8, 12, 16, 20]}
								page={searchDataValue.page}
								rowsPerPage={searchDataValue.perPage}
								labelRowsPerPage="Ads per page"
								onPageChange={handleChangePage}
								onRowsPerPageChange={handleChangePerPAge}
							/>
						</>
					)}
				</>
			) : (
				<SearchLoadingCont>
					<label>
						Fetching data, please wait...{' '}
						<span>
							<CircularProgress color="primary" />
						</span>
					</label>
				</SearchLoadingCont>
			)}
			<br />
		</SearchResultCont>
	);
};
