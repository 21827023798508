import { makeStyles } from '@material-ui/styles';

export const uniqueClasses = makeStyles((theme) => ({
	errorDialogBox: {
		'& .MuiDialogTitle-root': {
			backgroundColor: 'red',
			color: 'white',
		},
		'& .MuiButtonBase-root.mainButton': {
			backgroundColor: 'red',
			color: 'white',
		},
	},
	warningDialogBox: {
		'& .MuiDialogTitle-root': {
			backgroundColor: theme.palette.warning.main,
			color: 'white',
		},
		'& .MuiButtonBase-root.mainButton': {
			backgroundColor: theme.palette.warning.main,
			color: 'white',
		},
	},
}));
