import NumberFormat from 'react-number-format';

export const HandlePesoRender = (data) => {
	return (
		<NumberFormat
			value={data.value}
			displayType={'text'}
			thousandSeparator={true}
			prefix={'₱ '}
		/>
	);
};

const handleNumberOnly = ({ onChange, inputRef, name, ...others }) => {
	return (
		<NumberFormat
			{...others}
			format="+63### ### ####"
			onValueChange={(values) =>
				onChange({
					target: {
						value: values.value,
						name: name,
					},
				})
			}
			getInputRef={inputRef}
			allowLeadingZeros={true}
		/>
	);
};

export default handleNumberOnly;

/* 
Sample DOM

<TextField
	fullWidth
	variant="outlined"
	label="Contact Number"
	name="contactNumber"
	boxBelong="infoBox"
	placeholder="+63"
	InputProps={{ inputComponent: handleNumberOnly }}
	className={generalClasses.textFieldSpacing}
/> */
