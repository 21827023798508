import { Grid } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { siteSupportEmail } from 'back/context/biztayoDetails';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import React, { useContext, useState } from 'react';
import { UserDataContext } from 'UniversalContext/userData';

const GlobalAlert = () => {
	const axiosInstance = AxiosConfigUnsecured();
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [timer, setTimer] = useState(
		parseInt(localStorage.getItem('sendEmailVerifRequest')) || null
	);

	function handleResendVerify() {
		const getCurrentTime = new Date().getTime();

		if (timer + 60000 >= getCurrentTime) {
			const getRemainingTime = (timer + 60000 - getCurrentTime) / 1000;

			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: `Please wait ${parseInt(
						getRemainingTime
					)} seconds before sending another verification email`,
					customColor: 'warning',
				},
			}));
			return false;
		}

		setPageDataValue((prevState) => ({ ...prevState, backdrop: true }));

		axiosInstance
			.post('/user/sendverifEmail', {
				userId: userDataValue.UserInfo.userID,
				email: userDataValue.UserInfo.email,
			})
			.then((res) => {
				if (res.data) {
					localStorage.setItem('sendEmailVerifRequest', getCurrentTime);
					setTimer(getCurrentTime);
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: {
							...prevState.snackBar,
							customColor: 'success',
							customSnackbar: 'true',
							customMessage: 'sent an instruction to registered email.',
						},
					}));
				} else {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: { ...prevState.snackBar, error: true },
					}));
				}
			});
	}

	return (
		<>
			{userDataValue?.UserInfo.status === 'disabled' && (
				<Grid container spacing={3} className="mt-3">
					<Grid item lg={12} xs={12}>
						<Alert severity="error">
							<AlertTitle>Account disabled</AlertTitle>
							{userDataValue?.UserInfo.disableReason}
							<br />
							<label
								style={{ fontSize: '12px', marginTop: '10px', color: '#555' }}>
								Note: Please email <b>{siteSupportEmail}</b> for the
								reactivation of your account.
							</label>
						</Alert>
					</Grid>
				</Grid>
			)}

			{userDataValue?.UserInfo.permission === 1 && (
				<Grid container spacing={3} className="mt-3">
					<Grid item lg={12} xs={12}>
						<Alert severity="warning">
							<AlertTitle>Unverified Account</AlertTitle>
							We've sent an instruction to your email on how to verify your
							account.
							<br />
							<label
								style={{ fontSize: '12px', marginTop: '10px', color: '#555' }}>
								Haven't received our verification email? click{' '}
								<label
									onClick={handleResendVerify}
									style={{
										color: 'blue',
										textDecoration: 'underline',
										cursor: 'pointer',
									}}>
									Resend email
								</label>
							</label>
						</Alert>
					</Grid>
				</Grid>
			)}
		</>
	);
};

export default GlobalAlert;
