import React, { useContext } from 'react';
import clsx from 'clsx';
import {
	Link,
	Link as LinkRouter,
	matchPath,
	useLocation,
} from 'react-router-dom';

import {
	Drawer,
	IconButton,
	ChevronLeftIcon,
	Divider,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
} from 'back/imports/materialUi.js';
import {
	routes,
	Logo,
	PageDataContext,
	SidebarStyle,
	UserDataContext,
} from 'back/imports/components.js';
import { ExitToApp } from '@material-ui/icons';

const SideBarList = () => {
	const classes = SidebarStyle();
	const location = useLocation();
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const { userDataValue } = useContext(UserDataContext);

	const toggleDrawer = () => {
		setPageDataValue((prevState) => ({
			...prevState,
			drawerStatus: false,
		}));
	};

	return (
		<>
			<List>
				{
					//eslint-disable-next-line
					routes.map((data, index) => {
						const urlMatchParent = matchPath(location.pathname, data.path);
						let urlMatchChild;

						//if data.childPath is existing in routes,
						//then will check if path is included in data.childpath using forEact
						//if matchPath.isExact then urlMatchChild = true
						data.childPath &&
							data.childPath.forEach((childPath) => {
								matchPath(location.pathname, childPath)?.isExact &&
									(urlMatchChild = true);
								if (urlMatchChild) return false; //return false if childpath is found to stop forEach
							});

						if (data.title === 'divider') {
							return <Divider key={data.title + index} />;
						}
						if (
							data.type === 'parent' &&
							data.permission <= userDataValue.UserInfo.permission
						) {
							return (
								<ListItem
									button
									className={clsx({
										[classes.activeMenu]:
											urlMatchParent?.isExact || urlMatchChild ? true : false,
									})}
									selected={data.path === location.pathname ? true : false}
									component={LinkRouter}
									key={data.path + index}
									to={data.path}
									onClick={
										pageDataValue.userViewpoint === 'mobile'
											? toggleDrawer
											: null
									}>
									<ListItemIcon>
										<data.icon />
									</ListItemIcon>
									<ListItemText primary={data.title} />
								</ListItem>
							);
						}
					})
				}
			</List>
		</>
	);
};

function SideBar(props) {
	const classes = SidebarStyle();
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);

	const handleDrawer = () => {
		setPageDataValue({
			...pageDataValue,
			drawerStatus: !pageDataValue.drawerStatus,
		});
	};

	const toggleDrawer = () => {
		setPageDataValue((prevState) => ({
			...prevState,
			drawerStatus: false,
		}));
	};

	return (
		<>
			{pageDataValue.userViewpoint === 'browser' ? (
				<Drawer
					variant="permanent"
					className={clsx(classes.drawer, {
						[classes.drawerOpen]: pageDataValue.drawerStatus,
						[classes.drawerClose]: !pageDataValue.drawerStatus,
					})}
					classes={{
						paper: clsx({
							[classes.drawerOpen]: pageDataValue.drawerStatus,
							[classes.drawerClose]: !pageDataValue.drawerStatus,
						}),
					}}
					style={{ position: 'relative' }}>
					<div className={classes.toolbar}>
						<Link to="/" style={{ textDecoration: 'none' }}>
							<Logo />
						</Link>
						<IconButton onClick={handleDrawer}>
							<ChevronLeftIcon />
						</IconButton>
					</div>
					<Divider />
					<SideBarList />

					<ListItem
						button
						component={Link}
						to="/"
						style={{ position: 'absolute', bottom: '0', marginBottom: '40px' }}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText
							primary="Exit Dashboard"
							onClick={
								pageDataValue.userViewpoint === 'mobile' ? toggleDrawer : null
							}
						/>
					</ListItem>
				</Drawer>
			) : (
				<SwipeableDrawer
					anchor="left"
					open={pageDataValue.drawerStatus}
					onClose={toggleDrawer}
					onOpen={toggleDrawer}>
					<div className={classes.toolbar}>
						<Logo center />
					</div>
					<Divider />
					<SideBarList />

					<ListItem
						button
						component={Link}
						to="/"
						style={{ position: 'absolute', bottom: '0', marginBottom: '40px' }}>
						<ListItemIcon>
							<ExitToApp />
						</ListItemIcon>
						<ListItemText
							primary="Exit Dashboard"
							onClick={
								pageDataValue.userViewpoint === 'mobile' ? toggleDrawer : null
							}
						/>
					</ListItem>
				</SwipeableDrawer>
			)}
		</>
	);
}

export default SideBar;
