import { makeStyles } from '@material-ui/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	paperBg: {
		padding: '10px 10px 30px 10px',
		width: '100%',
		borderBottom: 'none',
	},
	topPaperBg: {
		backgroundColor: '#598fc6',
		borderRadius: '5px 5px 0px 0px',
		padding: '5px',
		justifyContent: 'center',
		alignItems: 'center',
	},
	botPaperBg: {
		backgroundColor: '#eee',
		padding: '15px 10px 5px 10px',
		borderBottomLeftRadius: '5px',
		borderBottomRightRadius: '5px',
	},
	userLogo: {
		color: '#598FC6',
		background: 'white',

		width: '150px',
		height: '150px',
		marginTop: '-50px',
		borderRadius: '100px',
		border: '8px solid #598FC6',
	},
	lockLogo: {
		color: '#598FC6',
		background: 'white',

		padding: '10px',
		width: '150px',
		height: '150px',
		marginTop: '-50px',
		borderRadius: '100px',
		border: '8px solid #598FC6',
	},
	editButton: {
		background: '#598fc6',
		color: 'white',
		marginRight: '25px',
		border: '4px solid white',
		'&:hover': {
			background: '#457AB0',
		},
	},
	button: {
		backgroundColor: 'white',
		border: '3px solid white',
		borderRadius: '30px',
		fontWeight: 'bold',
		marginLeft: '20px',
		marginBottom: '10px',
		minWidth: '210px',
		justifyContent: 'left',
		'& svg ': {
			backgroundColor: '#598FC6',
			color: 'white',
			padding: '3px',
			borderRadius: '20px',
			width: '20px',
			height: '20px',
			marginRight: '5px',
		},
		'&:hover': {
			color: 'white',
			backgroundColor: '#598FC6',
			'& svg': {
				backgroundColor: 'white',
				color: '#598FC6',
			},
		},
	},
	summaryBox: {
		paddingLeft: '5%',
		paddingRight: '5%',
		height: '100px',
		width: '100%',
		'& label': {
			fontSize: '18px',
			fontWeight: 'bold',
		},
	},
	summaryIcon: {
		fontSize: '50px',
		height: '50px',
		color: 'white',
		padding: '15px',
		borderRadius: '500px',
		display: 'block',
		margin: 'auto auto',
	},
	updateTitle: {
		display: 'block',
		fontSize: '30px',
		fontWeight: 'bold',
		textAlign: 'center',
		marginTop: '20px',
	},
	popoverDetails: {
		padding: '5px',
		color: 'white',
		backgroundColor: '#598FC6',
	},
	errorTextbox: {
		//static red
		'& .MuiInputLabel-root': { color: 'red' },
		'& .MuiInputLabel-outlined.Mui-focused': {
			backgroundColor: 'red !important',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid red',
		},

		//focused red
		'& .MuiOutlinedInput-root.Mui-focused': {
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid red',
			},
		},

		//hover red
		'& :hover': {
			'& .MuiInputLabel-root': { color: 'red' },
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid red',
			},
		},
	},
	notifRow: {
		padding: '5px',
		cursor: 'pointer',
		'&.odd': {
			backgroundColor: '#f1f1f1',
		},
		'&:hover': {
			backgroundColor: '#cbe3fa',
		},
	},
}));

export const UserFullName = styled.label`
	font-size: 35px;
	font-weight: bold;
	padding: 10px;
	color: white;
	display: block;
	text-align: ${() =>
		useMediaQuery(useTheme().breakpoints.up('sm')) ? 'left' : 'center'};
`;

export const UserDetails = styled.div`
	display: block;
	margin-top: 50px;
	& svg {
		font-size: 30px;
		margin-right: 10px;
		color: #598fc6;
		margin-bottom: 20px;
		clear: both;
		float: left;
		width: 10%;
	}
	& label {
		font-weight: bold;
		margin-top: 5px;
		margin-bottom: 15px;
		float: left;
		width: 85%;
	}
`;

export const SummaryNum = styled.div`
	color: ${(prop) => prop.color};
	font-size: 40px;
	font-family: 'Raleway';
	font-weight: bold;
	opacity: 0.3;
	text-align: center;
	display: block;
	margin: auto auto;
`;

export const ErrorLabel = styled.label`
	color: red;
	display: block;
	text-align: center;
	margin: auto auto;
	height: 30px;
`;

export const PasswordGuide = styled.label`
	color: #888;
	font-size: 12px;
	margin-bottom: 3px;
	display: block;
	&.title {
		color: black;
	}
	& .guideIconClose {
		color: red;
		font-size: 18px;
	}
	& .guideIconCheck {
		color: green;
		font-size: 18px;
	}
`;

export const NavBottomDiv = styled.div`
	display: block;
	float: right;
	margin-top: 10px;
	cursor: pointer;
	color: white;
	& .active {
		background-color: ${() => useTheme().palette.primary.main} !important;
	}
	& button {
		float: left;
		margin-left: 10px;
		background-color: #eee !important;
		font-size: 20px;

		& span {
			font-size: 50px;
		}
	}
`;

export const IconCont = styled.label`
	color: white;
	display: block;
	text-align: center;
	cursor: pointer;
	& svg {
		background-color: ${(props) => props.color};
		padding: 5px;
		font-size: 35px;
		border-radius: 20px;
	}
`;

export const NoNotifLabel = styled.label`
	display: block;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	font-style: italic;
	& span {
		display: block;
		color: #ccc;
		margin-bottom: 20px;
		& svg {
			font-size: 60px;
		}
	}
`;
