import React, { useState } from 'react';

import {
	Paper,
	Divider,
	Grid,
	makeStyles,
	FormControlLabel,
	Checkbox,
} from 'back/imports/materialUi';

import { generalStyle } from 'back/imports/components';
import { LastPageNote } from '../menuProfile/styleMenuProfile';
import { Link } from 'react-router-dom';

const uniqueStyle = makeStyles((theme) => ({
	gridSpacing: { padding: '30px 0px 100px 0px' },
	DivDetails: {
		padding: '20px 30px 20px 30px',
		backgroundColor: '#eee',
		borderRadius: '5px',
		marginTop: '30px',
	},
	DivDetailsChecked: {
		padding: '19px 29px 19px 29px',
		border: '1px solid ' + theme.palette.primary.light,
		backgroundColor: '#bcd9f5',
		borderRadius: '5px',
		marginTop: '30px',
	},
}));

const NewBizFormLast = (props) => {
	const generalClasses = generalStyle();
	const uniqueClasses = uniqueStyle();
	const [checkBoxes] = useState({
		responsible: false,
		terms: false,
	});

	const handleCheckbox = ({ name, state }) => {
		props.handleCheckbox({ name, state });
		// setCheckBoxes((prevState) => ({ ...prevState, [name]: state }));
	};

	return (
		<Paper style={{ marginTop: '30px' }}>
			<label className={generalClasses.paperTitle}>Finishing up</label>
			<Divider />
			<div className={generalClasses.paperContent}>
				<Grid container justifyContent="center">
					<Grid item lg={6} className={uniqueClasses.gridSpacing}>
						<label>
							By checking the boxes you are agreeing of the following terms:
						</label>
						<div
							className={
								checkBoxes.responsible
									? uniqueClasses.DivDetailsChecked
									: uniqueClasses.DivDetails
							}>
							<FormControlLabel
								control={
									<Checkbox
										color="primary"
										checked={props.checkBoxes.responsible}
										onChange={() =>
											handleCheckbox({
												name: 'responsible',
												state: !props.checkBoxes.responsible,
											})
										}
										name="gilad"
										style={{ marginRight: '50px' }}
									/>
								}
								label="We(BizTayo.ph) are not responsible for any agreement/contract made by the Franchisee and Franchisor. "
							/>
						</div>

						<div
							className={
								checkBoxes.terms
									? uniqueClasses.DivDetailsChecked
									: uniqueClasses.DivDetails
							}>
							<FormControlLabel
								style={{ width: '100%' }}
								control={
									<Checkbox
										color="primary"
										checked={props.checkBoxes.terms}
										onChange={() =>
											handleCheckbox({
												name: 'terms',
												state: !props.checkBoxes.terms,
											})
										}
										name="gilad"
										style={{ marginRight: '50px' }}
									/>
								}
								label={
									<span>
										I have read the{' '}
										<Link to="/bt/terms&Conditions" target={'_blank'}>
											Terms and Conditions
										</Link>
									</span>
								}
							/>
						</div>
						<LastPageNote>
							<label>Note: </label>
							Your submitted ads will be tagged as <b>Pending</b> while BizTayo
							team are validating information provided for this ads.
						</LastPageNote>
					</Grid>
				</Grid>
			</div>
		</Paper>
	);
};

export default NewBizFormLast;
