import {
	Button,
	CardActions,
	CardContent,
	CardMedia,
	Grid,
	Typography,
} from '@material-ui/core';
import { HighlightOff } from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { HandlePesoRender } from 'back/MicroComponents/numbersFormat';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { SearchContext } from 'front/context/searchContext';
import _ from 'lodash';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import emptyAds_bg from 'front/assets/images/emptyAds_bg.jpg';
import {
	CardResultGrid,
	MightLikeCont,
	SearchResultListEmpty,
} from './searchStyle';
import { ImgDisplay } from '../imgDisplay/imgDisplay';

const SkeletonMightLikeGrid = () => {
	return (
		<div className="searchResultGrid-cont">
			<Grid container spacing={3}>
				{[...Array(4)].map((data, index) => (
					<Grid
						item
						lg={3}
						xs={12}
						style={{ height: 'inherit', marginBottom: '30px' }}
						key={`mightLike_${index}`}>
						<CardResultGrid>
							<Skeleton
								variant="rect"
								style={{ height: '300px', width: '100%' }}
							/>
							<CardContent>
								<Typography gutterBottom variant="h5" component="h2">
									<Skeleton variant="text" style={{ width: '100%' }} />
								</Typography>
								<Typography variant="body2" color="textSecondary" component="p">
									<Skeleton variant="text" style={{ width: '100%' }} />
									<Skeleton variant="text" style={{ width: '100%' }} />
									<Skeleton variant="text" style={{ width: '100%' }} />
								</Typography>
							</CardContent>
							<div className="cardInvestDiv">
								<label>Initial Investment</label>
								<span>
									{' '}
									<Skeleton variant="text" style={{ width: '100%' }} />
								</span>
							</div>
							<CardActions
								style={{
									position: 'absolute',
									bottom: 0,
								}}>
								<Button size="small" color="primary">
									Learn More
								</Button>
							</CardActions>
						</CardResultGrid>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

const MightLikeGrid = (props) => {
	const { businessList } = _.cloneDeep(props);
	const { searchDataValue } = useContext(SearchContext);
	const history = useHistory();

	function UrlSearchOption() {
		return `keyword=${encodeURIComponent(
			searchDataValue.keyword
		)}&&investFrom=${searchDataValue.investFrom}&&investTo=${
			searchDataValue.investTo
		}&&page=${searchDataValue.page}&&category=${encodeURIComponent(
			searchDataValue.category
		)}&&searchType=${searchDataValue.searchType}`;
	}

	return (
		<div className="searchResultGrid-cont">
			<Grid container spacing={3}>
				{businessList.map((business, index) => {
					const getPrimaryImage = business.images.filter(
						(image) => image.primaryPhoto === 1
					);
					return (
						<Grid
							item
							lg={3}
							xs={12}
							style={{ height: 'inherit', marginBottom: '30px' }}
							key={`mightLike_${index}`}>
							<CardResultGrid>
								<ImgDisplay
									className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img"
									height="300"
									imageId={getPrimaryImage[0].imageName}
									skeletonComponent={{
										variant: 'rect',
										style: { height: '300px', width: '100%' },
									}}
								/>

								<CardContent>
									<Typography
										gutterBottom
										variant="h5"
										component="h2"
										style={{ wordWrap: 'break-word' }}>
										{business.name}
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
										component="p"
										style={{ wordWrap: 'break-word' }}>
										{business.description.split(' ').slice(0, 40).join(' ')} ...
									</Typography>
								</CardContent>
								<div className="cardInvestDiv">
									<label>Initial Investment</label>
									<span>
										{HandlePesoRender({
											value: business.initialInvest || business.franchiseFee,
										})}
									</span>
								</div>
								<CardActions
									style={{
										position: 'absolute',
										bottom: 0,
									}}>
									<Button
										size="small"
										color="primary"
										onClick={() =>
											history.push(
												`/bt/show/search=${
													business._id
												}&&name=${encodeURIComponent(
													business.name
												)}&&${UrlSearchOption()}`
											)
										}>
										Learn More
									</Button>
								</CardActions>
							</CardResultGrid>
						</Grid>
					);
				})}
				{[...Array(4 - businessList.length)].map((data, index) => {
					return (
						<Grid
							item
							lg={3}
							xs={12}
							style={{ height: 'inherit', marginBottom: '30px' }}
							key={`mightLike_${index}`}>
							<CardResultGrid>
								<div style={{ position: 'relative', height: '300px' }}>
									<CardMedia
										component="img"
										alt="Contemplative Reptile"
										height="300"
										image={emptyAds_bg}
										title="Contemplative Reptile"
										style={{
											filter: 'blur(3px)',
											position: 'static',
										}}
									/>
									<div
										style={{
											height: 'inherit',
											width: '100%',
											position: 'absolute',
											top: 0,
										}}>
										<label
											style={{
												display: 'inline-block',
												verticalAlign: 'middle',
												height: '100%',
											}}
										/>
										<label
											style={{
												display: 'inline-block',
												verticalAlign: 'middle',
												width: '80%',
												marginLeft: '10%',
												textAlign: 'center',
												padding: '10px 0',
												border: '1px solid white',
												color: 'white',
												background: 'rgba(0,0,0, .5)',
											}}>
											Available slot
										</label>
									</div>
								</div>

								<CardContent>
									<Typography
										gutterBottom
										variant="h5"
										component="h2"
										style={{ wordWrap: 'break-word' }}>
										Available franchise slot
									</Typography>
									<Typography
										variant="body2"
										color="textSecondary"
										component="p">
										This is <b style={{ color: 'green' }}>FREE.</b> No payment
										or Credit Card needed. <br />
										Create your account and post your franchise now!!!
									</Typography>
								</CardContent>
								<div className="cardInvestDiv"></div>
								<CardActions
									style={{
										position: 'absolute',
										bottom: 0,
									}}>
									<Button
										size="small"
										color="primary"
										onClick={() => history.push('/login')}>
										Create Advertisement
									</Button>
								</CardActions>
							</CardResultGrid>
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};

const BizYouMightLik = () => {
	const axiosInstance = AxiosConfigUnsecured();
	const [businessList, setBusinessList] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		axiosInstance.get('/public/getMightLikeBusiness').then((res) => {
			setBusinessList(() => {
				const businessListRes = res.data;
				var counter = 1;
				var filteredBusinessList = [];
				var randomBusinessList = [];
				var filteredBusinessListTotal = 0; // use for referencing total number

				for (const businessOwner of businessListRes) {
					for (const business of businessOwner.businessList) {
						if (business.status === 'active') {
							filteredBusinessList.push(business);
						}
					}
				}

				// saving total number of filtered business.
				// can't use filteredBusinessList.length since we are splicing it every loop in while-loop
				filteredBusinessListTotal = filteredBusinessList.length;

				while (counter <= 4 && counter <= filteredBusinessListTotal) {
					var randomNum = Math.floor(
						Math.random() * (filteredBusinessList.length - 1)
					);
					randomBusinessList.push(filteredBusinessList[randomNum]);
					filteredBusinessList.splice(randomNum, 1);
					counter++;
				}

				return randomBusinessList;
			});
			setLoading(false);
		});
		//eslint-disable-next-line
	}, []);

	return (
		<Grid container spacing={3}>
			<Grid item lg={12} xs={12}>
				<MightLikeCont>
					<label className="mightLikeHeader">Businesses you might like</label>
					<br />
					{!loading && <MightLikeGrid businessList={businessList} />}

					{loading && <SkeletonMightLikeGrid />}
				</MightLikeCont>
			</Grid>
		</Grid>
	);
};

const SearchResultEmpty = () => {
	const { searchDataValue } = useContext(SearchContext);

	return (
		<>
			{searchDataValue.freshEntry ? (
				<BizYouMightLik />
			) : (
				<>
					<Grid container spacing={3}>
						<Grid item lg={12} xs={12}>
							<SearchResultListEmpty>
								<label>
									<span>
										<HighlightOff />
									</span>
									Opps! Sorry, No result found
								</label>
							</SearchResultListEmpty>
						</Grid>
						<Grid item lg={12} xs={12}></Grid>
					</Grid>
					<br />
					<BizYouMightLik />
				</>
			)}
		</>
	);
};

export default SearchResultEmpty;
