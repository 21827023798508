import { createContext, useState } from 'react';

export const PageDataContext = createContext();

export const ErrorHandlerContext = createContext();

export const ErrorHandlerFunc = () => {
	const [errorHandler, setErrorHandler] = useState([
		{
			bizDescription: 'empty',
			bizAddress: 'empty',
			bizDateEst: 'empty',
			facebookLink: 'empty',
			instagramLink: 'empty',
			twitterLink: 'empty',
			websiteLink: 'empty',
			fullName: 'empty',
			emailAddress: 'empty',
			contactNumber: 'empty',
			requirement_1: 'empty',
		},
		{
			franchiseTerm: 'empty',
			initialInvestment: 'empty',
			franchiseFee: 'empty',
			marketingFee: 'empty',
			royaltyFee: 'empty',
		},
		{
			image: 'empty',
		},
	]);

	return [errorHandler, setErrorHandler];
};

export const maxLengthHandler = {
	bizName: 75,
	bizDescription: 1500,
	bizAddress: 100,
	facebookLink: 100,
	instagramLink: 100,
	twitterLink: 100,
	websiteLink: 100,
	fullName: 75,
	emailAddress: 50,
	contactNumber: 10,
	requirement: 50,
	initialInvestment: 12,
	franchiseFee: 12,
};

const PageData = () => {
	const [pageDataValue, setPageDataValue] = useState({
		drawerStatus: window.innerWidth <= 600 ? false : true,
		userViewpoint: window.innerWidth <= 600 ? 'mobile' : 'browser',
		snackBar: {
			error: false, // true if encountered an error
			warning: false, // true if there's a warning
			success: false, // true if acciont is successful
			actionError: false, // true if action is failed
			customSnackbar: false,
			customMessage: '',
			customColor: '', //theme color value for this(error,warning,success)
		},
		backdrop: false,
		dialogBox: {
			color: '', //theme color value for this(error,warning,success)
			status: false,
			title: '',
			message: '',
			buttonName: '',
			textboxName: '', //display textbox if not empty
			payloadId: '',
			payloadFunc: '',
			payloadData: '',
			taskStatus: null, // (true, false) can be used as indicator if done using dialog box
			returnPayload: null, // data that will be sent back to the component
		},
	});

	return [pageDataValue, setPageDataValue];
};

export const pageDataDialogBoxCleanup = {
	color: '', //theme color value for this(error,warning,success)
	status: false,
	title: '',
	message: '',
	buttonName: '',
	textboxName: '', //display textbox if not empty
	payloadId: '',
	payloadFunc: '',
	payloadData: '',
	taskStatus: null, // (true, false) can be used as indicator if done using dialog box
	returnPayload: null, // data that will be sent back to the component
};

export default PageData;
