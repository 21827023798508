import styled from 'styled-components';

export const AboutUsCont = styled.div`
	display: block;
	background-color: white;
	margin-top: 450px;
`;

export const AboutTitle = styled.label`
	display: block;
	font-size: 45px;
	margin-top: -250px;
	width: 100%;
	text-align: center;
	float: left;
	color: white;
	background: rgba(0, 0, 0, 0.4);
	padding: 20px 0;
`;

export const AboutBox = styled.div`
	display: block;
	margin-top: -100px;
	margin-bottom: 100px;
	& .topBox {
		background-color: #003366;
		height: 200px;
		width: 200px;
		margin: auto auto;
		border-radius: 200px;
		border: 10px solid white;
		text-align: center;
		& svg {
			color: white;
			margin-top: 25px;
		}
	}
	& .bottomBox {
		background-color: white;
		height: 200px;
		text-align: justify;
		margin-top: 20px;
		margin-bottom: 50px;
		& label {
			display: block;
			text-align: center;
			margin: 20px 0px;
			font-size: 25px;
			font-color: #003366;
			font-weight: bold;
		}
	}
`;

export const BelowDetailsCont = styled.div`
	display: block;
	padding: 100px 0px 150px 0;
`;

export const ImageContainer = styled.div`
	display: block;
	margin-top: -180px;
	margin-bottom: 10px;
	background-color: ${(props) => props.bgColor};
	padding: 40px;
	border-radius: 20px;
	& img {
		width: 100%;
		height: 400px;
		object-fit: cover;
		border-radius: 15px;
	}
`;

export const DetailsContainer = styled.div`
	display: block;
	& span {
		display: block;
		font-weight: bold;
		font-size: 50px;
		margin-top: -20px;
		font-style: italic;
		color: #003366;
	}
	& p {
		font-size: 20px;
		text-align: justify;
		font-style: italic;
	}
`;

export const OurTeamTitle = styled.label`
	display: block;
	text-align: center;
	font-size: 50px;
	color: #003366;
	font-weight: bold;
	font-style: italic;
	margin-bottom: 20px;
`;

export const OurTeamDetails = styled.div`
	width: 100%;
	height: 100%;
	overflow: hidden;
	& svg {
		font-size: 50px;
		background-color: ;
	}
	& img {
		width: 100%;
		object-fit: cover;
		padding: 20px;
		background-color: #ddd;
		border-radius: 300px;
	}
	& div {
		background-color: #003366;
		color: white;
		padding: 160px 10px 20px 10px;
		width: 85%;
		margin: auto auto;
		margin-top: -140px;

		border-bottom-left-radius: 20px;
		border-bottom-right-radius: 20px;

		& label {
			display: block;
			font-weight: bold;
			text-align: center;
			font-size: 20px;
		}
		& span {
			display: block;
			text-align: center;
			font-size: 14px;
			color: #ccc;
		}

		& p {
			margin-top: 20px;
			font-size: 14px;
			font-style: italic;
			text-align: center;
		}
	}
`;
