import React, { useState, useContext } from 'react';
import { useHistory, Link } from 'react-router-dom';
import {
	Container,
	Form,
	Button,
	InputGroup,
	Col,
	Row,
	OverlayTrigger,
	Tooltip,
	FloatingLabel,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faEnvelope,
	faLock,
	faExclamationCircle,
	faUser,
	faEye,
	faEyeSlash,
	faTimes,
	faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import clsx from 'clsx';
import { UserDataContext } from 'UniversalContext/userData';

import loginIcon from 'front/assets/images/loginIcon.png';

import 'front/assets/styles/loginPage.css';
import {
	PasswordGuideDiv,
	uniqueClasses,
} from 'front/assets/styles/loginStyle';
import BackdropLoading from 'back/components/backdropLoading/backdropLoading';
import { PageDataContext } from 'back/context/pageData';
import _ from 'lodash';
import SignInOption from 'front/components/signInOption/signInOption';
import biztayologo from 'front/assets/images/biztayo_fb.png';

const RegisterPage = (props) => {
	const history = useHistory();
	const [user, setUser] = useState({ password: '' });
	const [errorEmail, setErrorEmail] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [errorAccExist, setErrorAccExist] = useState(false);
	const [errorName, setErrorName] = useState({ fname: false, lname: false });
	const axiosInstance = AxiosConfigUnsecured();
	const { setUserDataValue } = useContext(UserDataContext);
	const [errorPass, setErrorPass] = useState({
		length: false,
		spelChar: false,
		num: false,
		match: false,
		null: true,
	});
	const [nullStatus, setNullStatus] = useState({
		fname: false,
		lname: false,
		email: false,
		password: false,
		retypePassword: false,
	});
	const classes = uniqueClasses();
	const { setPageDataValue } = useContext(PageDataContext);
	const [guidePhrase, setGuidePhrase] = useState('Welcome to Biztayo.ph');

	const passwordChecker = (data) => {
		const charRegEx = /[\W_]/;
		const numRegEx = /\d/;
		var checkLength = false;
		var checkSpelChar = false;
		var checkNum = false;

		// check password length
		checkLength = data.length > 8 ? true : false;

		// check if password contains special char
		checkSpelChar = charRegEx.test(data);

		// check if password contains num
		checkNum = numRegEx.test(data);

		return [checkLength, checkSpelChar, checkNum];
	};

	const handleChange = async (e) => {
		await setUser({ ...user, [e.target.name]: e.target.value });

		//checking if boxes is null
		if (e.target.value === null || e.target.value === '') {
			setNullStatus((prevState) => ({ ...prevState, [e.target.name]: true }));
		} else {
			setNullStatus((prevState) => ({ ...prevState, [e.target.name]: false }));
		}

		let re =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

		if (e.target.name === 'fname') {
			if (e.target.value === null || e.target.value === '') {
				setErrorName((prevState) => ({ ...prevState, fname: true }));
			} else {
				setErrorName((prevState) => ({ ...prevState, fname: false }));
			}
		}

		if (e.target.name === 'lname') {
			if (e.target.value === null || e.target.value === '') {
				setErrorName((prevState) => ({ ...prevState, lname: true }));
			} else {
				setErrorName((prevState) => ({ ...prevState, lname: false }));
			}
		}

		if (e.target.name === 'password') {
			//checking for password requirement
			const [lengthRes, spelcharRes, numRes] = passwordChecker(e.target.value);

			setErrorPass({ length: lengthRes, spelChar: spelcharRes, num: numRes });

			//checking if password matched
			if (user.password.length >= 8 && user.retypePassword !== e.target.value) {
				setErrorPass((prevState) => ({ ...prevState, match: false }));
			} else {
				setErrorPass((prevState) => ({ ...prevState, match: true }));
			}
		}

		//validation for email textbox
		if (e.target.name === 'email') {
			if (re.test(e.target.value)) {
				setErrorEmail(false);
			} else {
				setErrorEmail(true);
			}
		}

		//validation for password
		if (e.target.name === 'retypePassword') {
			if (user.password !== null && user.password === e.target.value) {
				setErrorPass((prevState) => ({ ...prevState, match: true }));
			} else {
				setErrorPass((prevState) => ({ ...prevState, match: false }));
			}
		}
	};

	const handleShowPass = (props) => {
		setShowPassword(!showPassword);
	};

	const registerUser = (e) => {
		e.preventDefault();

		//add textbox error property if data is null
		!user?.fname &&
			setNullStatus((prevState) => ({ ...prevState, fname: true }));
		!user?.lname &&
			setNullStatus((prevState) => ({ ...prevState, lname: true }));
		!user?.email &&
			setNullStatus((prevState) => ({ ...prevState, email: true }));
		!user?.password &&
			setNullStatus((prevState) => ({ ...prevState, password: true }));
		!user?.retypePassword &&
			setNullStatus((prevState) => ({ ...prevState, retypePassword: true }));

		if (!user?.fname || !user?.lname || !user?.email || !user?.password) {
			setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
			setGuidePhrase('Please provide all needed details');

			return false;
		}

		setPageDataValue((prevState) => ({ ...prevState, backdrop: true }));
		if (
			_.includes(errorPass, false) ||
			_.includes(errorName, true) ||
			errorEmail
		) {
			setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
			setGuidePhrase('Please provide all needed details');
		} else {
			//password matched
			const userHandler = {
				email: user.email,
				password: user.password,
				fname: user.fname,
				lname: user.lname,
			};
			axiosInstance.post('user/register', { userHandler }).then((response) => {
				if (response.data.registerStatus) {
					setUserDataValue((prevState) => ({
						...prevState,
						UserInfo: {
							...prevState.UserInfo,
							accessToken: response.data.accessToken,
							email: response.data.email,
							userID: response.data.userID,
							fname: response.data.fname,
							lname: response.data.lname,
							status: response.data.status,
							notif: [],
							inquiry: [],
							permission: 1,
							platform: 'local',
						},
						isLogin: true,
					}));
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Welcome to Biztayo.ph family',
							customColor: 'success',
						},
						backdrop: false,
					}));
					history.push('/account/dashboard');
				} else {
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
					}));
					setErrorAccExist(true);
					setGuidePhrase('Email already exist, Please try other email.');
				}
			});
		}
	};

	return (
		<React.Fragment>
			<BackdropLoading />
			<div className="login-page-bg"></div>
			<div className="loginBody-cont">
				<br />
				<center>
					<LinkContainer to="/">
						<div className="login-header">
							<img src={biztayologo} alt="biztayo_logo" />
							<label className="title-1 cursor-pointer">BIZTAYO</label>
							<label className="title-3 cursor-pointer">.PH</label>
						</div>
					</LinkContainer>
				</center>

				<div className="login-body">
					<img src={loginIcon} alt="login_img" />
					<Container>
						<Form onChange={handleChange} onSubmit={registerUser}>
							<label
								className={clsx({
									'text-center d-block font-weight-bold mb-3 mt-2': true,
									'text-danger':
										errorAccExist ||
										guidePhrase === 'Please provide all needed details',
								})}
								style={{ fontWeight: 'bold' }}>
								{guidePhrase}
							</label>

							<Row>
								<Col style={{ paddingRight: '0' }}>
									<InputGroup
										className={clsx({
											[classes.errorBox]: errorName.fname || nullStatus.fname,
										})}>
										<div style={{ display: 'flex' }}>
											<InputGroup.Text>
												<label>
													<FontAwesomeIcon icon={faUser} />
												</label>
											</InputGroup.Text>
											<div className="d-grid grip-1">
												<FloatingLabel label="First Name">
													<Form.Control
														name="fname"
														placeholder="First"
														type="text"
													/>
												</FloatingLabel>
											</div>
											{errorName.fname && (
												<OverlayTrigger
													key="top"
													placement="top"
													overlay={
														<Tooltip id={`tooltip-top`}>
															Please provide First name
														</Tooltip>
													}>
													<InputGroup.Text>
														<label>
															<FontAwesomeIcon icon={faExclamationCircle} />
														</label>
													</InputGroup.Text>
												</OverlayTrigger>
											)}
										</div>
									</InputGroup>
								</Col>
								<Col>
									<InputGroup
										className={clsx({
											[classes.errorBox]: errorName.lname || nullStatus.lname,
										})}>
										<div style={{ display: 'flex' }}>
											<InputGroup.Text>
												<label>
													<FontAwesomeIcon icon={faUser} />
												</label>
											</InputGroup.Text>
											<FloatingLabel label="Last Name" className="flex-grow-1">
												<Form.Control
													name="lname"
													placeholder="Last name"
													aria-label="Last name"
													aria-describedby="basic-addon1"
													type="text"
												/>
											</FloatingLabel>
											{errorName.lname && (
												<OverlayTrigger
													key="top"
													placement="top"
													overlay={
														<Tooltip id={`tooltip-top`}>
															Please provide Last name
														</Tooltip>
													}>
													<InputGroup.Text>
														<label>
															<FontAwesomeIcon icon={faExclamationCircle} />
														</label>
													</InputGroup.Text>
												</OverlayTrigger>
											)}
										</div>
									</InputGroup>
								</Col>
							</Row>
							<InputGroup
								className={clsx({
									[classes.errorBox]:
										errorEmail || errorAccExist || nullStatus.email,
								})}>
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faEnvelope} />
									</label>
								</InputGroup.Text>
								<FloatingLabel className="flex-grow-1" label="Email Address">
									<Form.Control
										name="email"
										placeholder="Email address"
										aria-label="Email address"
										aria-describedby="basic-addon1"
										type="email"
									/>
								</FloatingLabel>
								{errorEmail && (
									<InputGroup.Text>
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id={`tooltip-top`}>
													Invalid email format.
												</Tooltip>
											}>
											<label>
												<FontAwesomeIcon icon={faExclamationCircle} />
											</label>
										</OverlayTrigger>
									</InputGroup.Text>
								)}
							</InputGroup>
							<div>
								<PasswordGuideDiv>
									<label className="title">Password requirements</label>
									<label className="list">
										{errorPass.length ? (
											<FontAwesomeIcon icon={faCheck} className="green" />
										) : (
											<FontAwesomeIcon icon={faTimes} className="red" />
										)}
										Minimum of 9 Characters
									</label>
									<label className="list">
										{errorPass.num ? (
											<FontAwesomeIcon icon={faCheck} className="green" />
										) : (
											<FontAwesomeIcon icon={faTimes} className="red" />
										)}
										Must have atleast one(1) number
									</label>
									<label className="list">
										{errorPass.spelChar ? (
											<FontAwesomeIcon icon={faCheck} className="green" />
										) : (
											<FontAwesomeIcon icon={faTimes} className="red" />
										)}
										Must have atleast one(1) Special character
									</label>
								</PasswordGuideDiv>
							</div>
							<InputGroup
								className={clsx({
									[classes.errorBox]:
										(!errorPass.match && user.retypePassword) ||
										nullStatus.password,
								})}>
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faLock} />
									</label>
								</InputGroup.Text>
								<FloatingLabel className="flex-grow-1" label="Password">
									<Form.Control
										name="password"
										placeholder="Password"
										aria-label="Password"
										aria-describedby="basic-addon1"
										type={showPassword ? 'textbox' : 'password'}
									/>
								</FloatingLabel>
							</InputGroup>
							<InputGroup
								className={clsx({
									[classes.errorBox]:
										(!errorPass.match && user.retypePassword) ||
										nullStatus.retypePassword,
								})}>
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faLock} />
									</label>
								</InputGroup.Text>
								<FloatingLabel className="flex-grow-1" label="Re-type Password">
									<Form.Control
										name="retypePassword"
										placeholder="Re-type password"
										aria-label="Password"
										aria-describedby="basic-addon1"
										type={showPassword ? 'textbox' : 'password'}
										disabled={user.password.length < 8 ? 'disabled' : ''}
									/>
								</FloatingLabel>
								<InputGroup.Text onClick={handleShowPass}>
									<label>
										<FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
									</label>
								</InputGroup.Text>
								{user.retypePassword && !errorPass.match && (
									<InputGroup.Text>
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id={`tooltip-top`}>
													Password Didn't matched, Please try again.
												</Tooltip>
											}>
											<label>
												<FontAwesomeIcon icon={faExclamationCircle} />
											</label>
										</OverlayTrigger>
									</InputGroup.Text>
								)}
							</InputGroup>
							<label style={{ margin: '20px 0', fontSize: '14px' }}>
								by clicking <b>Register</b> you are agreeing to the{' '}
								<Link
									to="/bt/terms&Conditions"
									target="_blank"
									style={{ textDecoration: 'none' }}>
									Terms and Conditions
								</Link>{' '}
								and acknowledging our{' '}
								<Link
									to="/bt/privacyPolicy"
									target="_blank"
									style={{ textDecoration: 'none' }}>
									Privacy policy
								</Link>
							</label>
							<div className="d-grid grip-2">
								<Button
									type="submit"
									className="mt-1"
									style={{ backgroundColor: '#003366' }}>
									Register
								</Button>
							</div>
							<label className=" d-block text-left mt-2">
								<small>
									Already have an account?&nbsp;
									<Link to="/login">
										<label
											className="text-primary font-weight-bold"
											style={{ cursor: 'pointer' }}>
											Sign-in now.
										</label>
									</Link>
								</small>
							</label>
							<SignInOption />
						</Form>
					</Container>
				</div>
				<br />
				<br />
			</div>
		</React.Fragment>
	);
};

export default RegisterPage;
