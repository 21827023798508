import React from 'react';
import NumberFormat from 'react-number-format';

import { Divider, Paper, Grid, Typography } from 'back/imports/materialUi';
import { generalStyle, MCTextBox } from 'back/imports/components';

const NumbersOnlyFormat = (props) => {
	const { onChange, inputRef, ...others } = props;
	return (
		<NumberFormat
			{...others}
			thousandSeparator
			prefix={'₱ '}
			getInputRef={inputRef}
			onValueChange={(values) =>
				onChange({
					target: {
						value: values.value,
						name: props.name,
					},
				})
			}
		/>
	);
};

const NumbersOnlyFormatPercent = (props) => {
	const { onChange, inputRef, ...others } = props;
	return (
		<NumberFormat
			{...others}
			thousandSeparator
			format="###%"
			getInputRef={inputRef}
			onValueChange={(values) =>
				onChange({
					target: {
						value: values.value,
						name: props.name,
					},
				})
			}
		/>
	);
};

const NumbersOnlyFormatYears = (props) => {
	const { onChange, inputRef, ...others } = props;
	return (
		<NumberFormat
			{...others}
			thousandSeparator
			format="## year/s"
			getInputRef={inputRef}
			onValueChange={(values) =>
				onChange({
					target: {
						value: values.value,
						name: props.name,
					},
				})
			}
		/>
	);
};

const NewBizFormMoney = () => {
	const generalClasses = generalStyle();
	return (
		<Paper style={{ marginTop: '30px' }}>
			<label className={generalClasses.paperTitle}>Money talks</label>
			<Divider />
			<div className={generalClasses.paperContent}>
				<Grid container justifyContent="center">
					<Grid
						item
						xs={12}
						lg={4}
						style={{ paddingTop: '20px', paddingBottom: '50px' }}>
						<Typography variant="caption" color="textSecondary">
							Please fill out all needed information before proceeding to the
							next form.
						</Typography>
						<MCTextBox
							fullWidth
							variant="outlined"
							label="Franchise Terms"
							name="franchiseTerm"
							boxBelong="moneyTalk"
							InputProps={{ inputComponent: NumbersOnlyFormatYears }}
							// inputRef={(input) => input.focus()}
							autoFocus
							className={generalClasses.MCTextBoxSpacing}
						/>
						<label className={generalClasses.guidePhrase}>
							Number of years for franchise agreement. Put "0" if not applicable
							answer
						</label>
						<MCTextBox
							fullWidth
							variant="outlined"
							label="Initial Investment"
							name="initialInvestment"
							boxBelong="moneyTalk"
							InputProps={{ inputComponent: NumbersOnlyFormat }}
							className={generalClasses.MCTextBoxSpacing}
						/>
						<label className={generalClasses.guidePhrase}>
							Possible cost in opening a new franchise. Put "0" if not
							applicable answer
						</label>
						<MCTextBox
							fullWidth
							variant="outlined"
							label="Franchise Fee"
							name="franchiseFee"
							boxBelong="moneyTalk"
							InputProps={{ inputComponent: NumbersOnlyFormat }}
							className={generalClasses.MCTextBoxSpacing}
						/>
						<label className={generalClasses.guidePhrase}>
							One time payment given to the franchisor for getting the rights to
							use the product or services. Put "0" if not applicable
						</label>
						<MCTextBox
							fullWidth
							variant="outlined"
							label="Marketing Fee"
							name="marketingFee"
							boxBelong="moneyTalk"
							InputProps={{ inputComponent: NumbersOnlyFormatPercent }}
							className={generalClasses.MCTextBoxSpacing}
						/>
						<label className={generalClasses.guidePhrase}>
							Percentage on franchisee's monthly income. Put "0" if not
							applicable
						</label>
						<MCTextBox
							fullWidth
							variant="outlined"
							label="Royalty Fee"
							name="royaltyFee"
							boxBelong="moneyTalk"
							InputProps={{ inputComponent: NumbersOnlyFormatPercent }}
							className={generalClasses.MCTextBoxSpacing}
						/>
						<label className={generalClasses.guidePhrase}>
							Percentage on franchisee's monthly income. Put "0" if not
							applicable answer answer
						</label>
					</Grid>
				</Grid>
			</div>
		</Paper>
	);
};

export default NewBizFormMoney;
