import { useState, useEffect, useRef } from 'react';
import _ from 'lodash';

//boxBelong and totalReq are props of requirement textbox
export const useDebounceTextbox = (
	{ boxBelong = 'infoBox', ReqLocationIndex = 0 },
	userDataValue,
	setDataValue,
	timeDelay
) => {
	const [eventHandler, setEventHadler] = useState();
	const timeoutID = useRef();

	useEffect(() => {
		if (eventHandler != null) {
			if (boxBelong === 'infoBox') {
				timeoutID.current = setTimeout(
					() =>
						setDataValue((prevState) => ({
							...prevState,
							UserInfo: { ...prevState.UserInfo },
							NewBizData: {
								...prevState.NewBizData,
								basicInfo: {
									...prevState.NewBizData.basicInfo,
									[eventHandler.target.name]: eventHandler.target.value,
								},
							},
						})),
					timeDelay
				);
			} else if (boxBelong === 'moneyTalk') {
				timeoutID.current = setTimeout(
					() =>
						setDataValue((prevState) => ({
							...prevState,
							UserInfo: { ...prevState.UserInfo },
							NewBizData: {
								...prevState.NewBizData,
								moneyTalk: {
									...prevState.NewBizData.moneyTalk,
									[eventHandler.target.name]: eventHandler.target.value,
								},
							},
						})),
					timeDelay
				);
			} else if (boxBelong === 'requirements') {
				let clonedArrayHHandler = _.cloneDeep(
					userDataValue.NewBizData.basicInfo.requirements
				);

				clonedArrayHHandler[ReqLocationIndex] = eventHandler.target.value;

				timeoutID.current = setTimeout(
					() =>
						setDataValue((prevState) => ({
							...prevState,
							UserInfo: { ...prevState.UserInfo },
							NewBizData: {
								...prevState.NewBizData,
								basicInfo: {
									...prevState.NewBizData.basicInfo,
									requirements: clonedArrayHHandler,
								},
							},
						})),
					timeDelay
				);
			}
		}

		return () => clearTimeout(timeoutID.current);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [eventHandler, setDataValue, timeDelay, ReqLocationIndex, boxBelong]);

	return (e) => setEventHadler(e);
};

//iniital value: timeDelay
// function inside useState once call
export const useDebounce = (timeDelay) => {
	const [funcHolder, setFuncHolder] = useState();

	useEffect(() => {
		var timeoutID = setTimeout(funcHolder, timeDelay);

		return () => clearTimeout(timeoutID);
	}, [funcHolder, timeDelay]);

	return [funcHolder, setFuncHolder];
};
