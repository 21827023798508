import React, { useState, useContext } from 'react';
import { Container, Form, Button, InputGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import loginIcon from 'front/assets/images/loginIcon.png';

import 'front/assets/styles/loginPage.css';
import { CircularProgress } from '@material-ui/core';
import { CustomSnackbar } from 'back/components/snackbar';
import { uniqueClasses } from 'front/assets/styles/loginStyle';
import clsx from 'clsx';
import SignInOption from 'front/components/signInOption/signInOption';
import biztayologo from 'front/assets/images/biztayo_fb.png';

const ForgotPasswordPage = (props) => {
	const [email, setEmail] = useState('');
	const [errorHandler, setErrorHandler] = useState({
		invalidEmail: false,
		notExist: false,
		empty: true,
	});
	const { setPageDataValue } = useContext(PageDataContext);
	const axiosInstance = AxiosConfigUnsecured();
	const [loading, setloading] = useState(false);
	const classes = uniqueClasses();

	let emailRegex =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

	const handleChange = (e) => {
		if (!emailRegex.test(e.target.value)) {
			setErrorHandler((prevState) => ({ ...prevState, invalidEmail: true }));
		} else {
			setErrorHandler((prevState) => ({ ...prevState, invalidEmail: false }));
		}

		setEmail(e.target.value);
	};

	const findUser = (e) => {
		const currentTime = new Date().getTime();
		const forgotpasswordTimer =
			parseInt(localStorage.getItem('forgotPasswordInterval')) + 60000;

		e.preventDefault();

		if (forgotpasswordTimer > currentTime) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: `Please wait ${Math.floor(
						(forgotpasswordTimer - currentTime) / 1000
					)} second/s before sending new request`,
					customColor: 'warning',
				},
			}));

			return false;
		}

		setloading(true);

		if (errorHandler.invalidEmail || email === '' || email === null || !email) {
			setErrorHandler((prevState) => ({ ...prevState, invalidEmail: true }));

			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: 'Invalid! please provide valid email address.',
					customColor: 'error',
				},
			}));
			setloading(false);
			return false;
		}

		axiosInstance
			.post('user/forgotPassword', { email })
			.then(async (res) => {
				const { error } = res.data;

				if (!error) {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Instruction sent via email',
							customColor: 'success',
						},
					}));
					setloading(false);
					localStorage.setItem('forgotPasswordInterval', currentTime);
				} else {
					setErrorHandler((prevState) => ({
						...prevState,
						invalidEmail: true,
					}));
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage:
								'Error! Provided email does not exist in our system',
							customColor: 'error',
						},
					}));

					setloading(false);
				}
			})
			.catch((err) => {});
	};

	return (
		<React.Fragment>
			<div className="login-page-bg"></div>
			<div className="loginBody-cont">
				<br />
				<center>
					<LinkContainer to="/">
						<div className="login-header">
							<img src={biztayologo} alt="biztayo_logo" />
							<label className="title-1 cursor-pointer">BIZTAYO</label>
							<label className="title-3 cursor-pointer">.PH</label>
						</div>
					</LinkContainer>
				</center>

				<div className="login-body">
					<img src={loginIcon} alt="login_img" />
					<Container>
						<Form onChange={handleChange} onSubmit={findUser}>
							{!errorHandler.notExist ? (
								<label
									className="text-center d-block font-weight-bold mb-3 mt-2"
									style={{ fontWeight: 'bold' }}>
									Find your account
								</label>
							) : (
								<label className="text-center d-block font-weight-bold mb-3 mt-2 text-danger">
									Invalid email
								</label>
							)}
							<label style={{ fontSize: '14px', textAlign: 'justify' }}>
								Please provide your registered email. You will receive an email
								regarding steps on how to reset your password.
							</label>
							<br />
							<br />
							<InputGroup
								style={{ marginBottom: '10px' }}
								className={clsx({
									[classes.errorBox]: errorHandler.invalidEmail,
								})}>
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faEnvelope} />
									</label>
								</InputGroup.Text>
								<FloatingLabel label="Email address" className="flex-grow-1">
									<Form.Control
										name="email"
										placeholder="Email address"
										autoFocus
									/>
								</FloatingLabel>
							</InputGroup>

							<div className="d-grid gap-2">
								<Button
									type="submit"
									className="mt-3"
									disabled={loading}
									style={{ backgroundColor: '#003366', position: 'relative' }}>
									Send
									{loading && (
										<CircularProgress
											size={24}
											thickness={5}
											style={{
												position: 'absolute',
												// top: '0%',
												left: '50%',
												color: 'white',
											}}
										/>
									)}
								</Button>
							</div>
							<label className=" d-block mt-2 text-left">
								<small>
									Already remembered your account?&nbsp;
									<Link to="/login" style={{ textDecoration: 'none' }}>
										Sign-in now!
									</Link>
								</small>
							</label>
							<label className=" d-block text-left">
								<small>
									Don't have an account yet?{' '}
									<Link to="/register">
										<label
											className="text-primary font-weight-bold"
											style={{ cursor: 'pointer' }}>
											Sign-up now
										</label>
									</Link>
								</small>
							</label>
							<SignInOption />
						</Form>
					</Container>
				</div>
				<br />
				<br />
			</div>

			<CustomSnackbar />
		</React.Fragment>
	);
};

export default ForgotPasswordPage;
