import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	footerLinks: {
		'& label.Flinks': {
			fontSize: '14px',
			color: '#888',
			'& span': {
				marginRight: '5px',
				'& svg ': {
					fontSize: '18px',
				},
			},
		},
		'& label.Flinks:hover': {
			color: 'black',
		},
	},
}));

export const ContactDetailsCont = styled.div`
	display: block;
	margin-bottom: 20px;

	& label {
		display: block;
		font-size: 14px;
		margin: 10px 0;
		color: #888;
		& span svg {
			font-size: 20px;
			margin-right: 10px;
		}
		& b {
			display: inline-block;
			width: 60px;
		}
	}
`;
