import { Grid } from '@material-ui/core';
import React from 'react';
import { NewBizFormInfo } from '../newBizForms';

const UpdateBizFormInfo = ({ businessDetails }) => {
	return (
		<>
			<Grid container spacing={3}>
				<Grid item lg={12}>
					<NewBizFormInfo updateBusiness={businessDetails} />
				</Grid>
			</Grid>
		</>
	);
};

export default UpdateBizFormInfo;
