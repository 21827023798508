import { Grid } from '@material-ui/core';
import { NewBizFormImage } from 'back/imports/components';
import React from 'react';

const UpdateBizFormPhoto = () => {
	return (
		<>
			<Grid container spacing={3} className="mt-4">
				<Grid item lg={12} xs={12}>
					<NewBizFormImage />
				</Grid>
			</Grid>
		</>
	);
};

export default UpdateBizFormPhoto;
