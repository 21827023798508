import React, { useState, memo, useCallback, useContext } from 'react';
import _ from 'lodash';
import Dropzone from 'react-dropzone';

import {
	Divider,
	Paper,
	Grid,
	Typography,
	Popover,
	SettingsIcon,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	DeleteIcon,
	AccountBoxIcon,
} from 'back/imports/materialUi';
import {
	generalStyle,
	NewBizFormStyle,
	UserDataContext,
} from 'back/imports/components';
import { PageDataContext, ErrorHandlerContext } from 'back/context/pageData';
import { AxiosConfigSecured } from 'config/axiosConfig';
import clsx from 'clsx';
import { BusinessListContext } from 'back/context/businessList';
import { useEffect } from 'react';
import { ImgDisplay } from 'front/components/imgDisplay/imgDisplay';

const PreviewImgSettingPopover = memo((props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = 'simple-popover';
	return (
		<>
			<SettingsIcon
				aria-describedby={id}
				onClick={handleClick}
				style={{ cursor: 'pointer' }}
			/>

			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<List>
					<ListItem
						style={{ color: 'red' }}
						button
						onClick={() => {
							setAnchorEl(null);
							props.handleRemovePhoto(props.dataFile);
						}}>
						<ListItemIcon>
							<DeleteIcon style={{ color: 'red' }} />
						</ListItemIcon>
						<ListItemText primary="Remove" />
					</ListItem>
					<ListItem
						style={{ color: '#003366' }}
						button
						onClick={() => {
							setAnchorEl(null);
							props.handlePrimaryPhoto(props.dataFile);
						}}>
						<ListItemIcon>
							<AccountBoxIcon style={{ color: '#003366' }} />
						</ListItemIcon>
						<ListItemText primary="Make display photo" />
					</ListItem>
				</List>
			</Popover>
		</>
	);
});

const ImagePreviewLogic = memo((props) => {
	const uniqueClasses = NewBizFormStyle();
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const axiosInstance = AxiosConfigSecured();
	const { setPageDataValue } = useContext(PageDataContext);
	const { errorHandler, setErrorHandler, errorChecker } =
		useContext(ErrorHandlerContext);
	const [files, setFiles] = useState(userDataValue.NewBizData.images || []);
	const { businessListValue, setBusinessListValue } =
		useContext(BusinessListContext);

	useEffect(() => {
		if (files.length) {
			setErrorHandler((prevState) => {
				prevState[2].image = false;
				return prevState;
			});
		}
		//eslint-disable-next-line
	}, []);

	const handleOnDropRejected = useCallback(
		(rejectedFiles) => {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customColor: 'error',
					customSnackbar: true,
					customMessage: "File doesn't meet the requirements.",
				},
			}));
		},
		[setPageDataValue]
	);

	const onDropAccepted = async (acceptedFiles) => {
		let indexOfPrimaryPhoto;

		if (files.length + acceptedFiles.length <= 4) {
			const dataPreview = acceptedFiles.map((file) =>
				Object.assign(file, { preview: URL.createObjectURL(file) })
			);

			//cloning of files to include dataPreview
			var clonedFile = [...files, ...dataPreview];

			if (files.length !== 0) {
				setFiles(clonedFile);
			} else {
				//if no saved photos, put primaryPhotos in first value
				await Object.assign(clonedFile[0], { primaryPhoto: 1 });
				setFiles(clonedFile);
			}

			//get the current index of primaryPhoto
			clonedFile.forEach(
				(data, index) =>
					_.has(data, 'primaryPhoto') && (indexOfPrimaryPhoto = index)
			);

			let formData = new FormData();
			formData.append('bizId', userDataValue.NewBizData.bizId);
			formData.append('indexOfPrimaryPhoto', indexOfPrimaryPhoto);
			//eslint-disable-next-line
			await acceptedFiles.map((data, index) => {
				formData.append('files', data);
			});

			axiosInstance
				.post('/business/uploadImg', formData, {
					withCredentials: true,
					headers: {
						'content-type': 'multipart/form-data',
					},
				})
				.then((res) => {
					errorHandler[2].image &&
						setErrorHandler((prevState) => {
							prevState[2].image = false;
							return prevState;
						});

					//this is for UPDATE PAGE, if businessListValue is not null
					//save the latest businessList Details
					businessListValue.length &&
						setBusinessListValue(res.data.businessList);

					setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				});

			setUserDataValue((prevState) => ({
				...prevState,
				UserInfo: { ...prevState.UserInfo },
				NewBizData: {
					...prevState.NewBizData,
					images: [...clonedFile],
				},
			}));
		} else {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customColor: 'error',
					customSnackbar: true,
					customMessage: 'Total file exceeded,  Maximum of 4 photos only.',
				},
			}));

			return false;
		}
	};

	//dataFile contains the index of selected image
	const handlePrimaryPhoto = useCallback(
		(dataFile) => {
			if (
				files[dataFile].primaryPhoto === 0 ||
				files[dataFile].primaryPhoto === undefined
			) {
				const updatedFile = files.map((file, index) => {
					return index === dataFile
						? Object.assign(file, { primaryPhoto: 1 })
						: _.omit(file, 'primaryPhoto');
				});

				axiosInstance
					.post(
						'/business/changeDisplayPic',
						{
							indexOfPrimaryPhoto: dataFile,
							bizId: userDataValue.NewBizData.bizId,
						},
						{ withCredentials: true }
					)
					.then((res) => {
						//this is for UPDATE PAGE, if businessListValue is not null
						//save the latest businessList Details
						businessListValue.length && setBusinessListValue(res.data);

						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
						}));
					});

				setFiles(updatedFile);
			}
		},
		//eslint-disable-next-line
		[files, setFiles]
	);

	const handleRemovePhoto = useCallback(
		(dataFile) => {
			//For updating ads: to prevent deleting all photo, must retain atleast 1 photo
			if (userDataValue.NewBizData.status && files.length === 1) {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customMessage: 'Must retain atleast 1 photo',
						customColor: 'error',
					},
				}));

				return false;
			}

			axiosInstance
				.post(
					'/business/deletePhoto',
					{
						indexOfDeletePhoto: dataFile,
						bizId: userDataValue.NewBizData.bizId,
					},
					{
						withCredentials: true,
					}
				)
				.then((res) => {
					res.data.imgLength
						? errorHandler[2].image &&
						  errorChecker(setErrorHandler, 2, 'image', false)
						: !errorHandler[2].image &&
						  errorChecker(setErrorHandler, 2, 'image', true);

					//this is for UPDATE PAGE, if businessListValue is not null
					//save the latest businessList Details
					businessListValue.length &&
						setBusinessListValue(res.data.businessList);

					setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				});

			const newFiles = files.filter((data, index) => dataFile !== index);
			if (newFiles.length) {
				//enter here if there are stil picture selected to prevent undefined for primaryPhoto
				if (!_.some(newFiles, { primaryPhoto: 1 })) {
					newFiles[0].primaryPhoto = 1;
					setFiles(newFiles);
				} else {
					setFiles(newFiles);
				}
			} else {
				// enter here if newFiles is == 0
				setFiles(newFiles);
			}

			setUserDataValue((prevState) => ({
				...prevState,
				NewBizData: {
					...prevState.NewBizData,
					images: [...newFiles],
				},
			}));
		},
		//eslint-disable-next-line
		[files, setFiles, setUserDataValue]
	);

	return (
		<>
			{!files || files.length === 0 ? (
				<Grid item lg={12} xs={12}>
					<Dropzone
						onDropAccepted={onDropAccepted}
						accept={'image/*'}
						minSize={0}
						onDropRejected={handleOnDropRejected}
						maxSize={2097152}>
						{({ getRootProps, getInputProps }) => (
							<div
								{...getRootProps({
									className: clsx([
										uniqueClasses.BlankImgContainer,
										{
											[uniqueClasses.errorBlankImgContainer]:
												errorHandler[2].image === true,
										},
									]),
								})}>
								<input {...getInputProps()} />
								<p>Drag 'n' drop some files here, or click to select files</p>
							</div>
						)}
					</Dropzone>
				</Grid>
			) : (
				[...Array(files.length >= 4 ? 4 : files.length + 1)].map(
					(data, index) => {
						return (
							<Grid item lg={3} xs={12} key={'previewImg' + index}>
								{files.length <= index && files.length !== 4 ? (
									<Dropzone
										onDropAccepted={onDropAccepted}
										accept={'image/*'}
										onDropRejected={handleOnDropRejected}
										minSize={0}
										maxSize={2097152}>
										{({ getRootProps, getInputProps }) => (
											<div
												{...getRootProps({
													className: uniqueClasses.BlankImgContainer,
												})}>
												<input {...getInputProps()} />
												<p>
													Drag 'n' drop some files here, or click to select
													files
												</p>
											</div>
										)}
									</Dropzone>
								) : (
									<div
										className={
											files[index].primaryPhoto
												? uniqueClasses.PreviewImgPrimary
												: uniqueClasses.PreviewImgNormal
										}>
										<div className="PreviewImgHeader">
											<div>
												{files[index].primaryPhoto ? 'Primary Photo' : null}
											</div>
											<div>
												<PreviewImgSettingPopover
													dataFile={index}
													handleRemovePhoto={handleRemovePhoto}
													handlePrimaryPhoto={handlePrimaryPhoto}
												/>
											</div>
										</div>
										<div className="PreviewImgContainer">
											<div className="imgContainer">
												{files[index].preview ? (
													<img src={files[index].preview} alt="" />
												) : (
													<ImgDisplay
														imageId={files[index].imageName}
														skeletonComponent={{
															variant: 'rect',
															style: { width: '100%', height: '100%' },
														}}
													/>
												)}
											</div>
										</div>
									</div>
								)}
							</Grid>
						);
					}
				)
			)}
		</>
	);
});

const NewBizFormImage = memo((props) => {
	const generalClasses = generalStyle();
	return (
		<Paper>
			<label className={generalClasses.paperTitle}>Upload Images</label>
			<Divider />
			<div className={generalClasses.paperContent}>
				<Typography variant="caption" color="textSecondary">
					- Maximum of 4 photos
					<br />- Max filesize is 2MB only.
					<br />- Allowed file type: ".png / .jpg".
				</Typography>
				<br />
				<br />
				<Grid container spacing={3}>
					<ImagePreviewLogic />
				</Grid>
			</div>
		</Paper>
	);
});

export default NewBizFormImage;
