import React from "react";

import { LogoStyle } from "back/imports/components.js";
function BizLogo(props) {
	const classes = LogoStyle(props);

	return (
		<div className={classes.logoCont}>
			<div className="logoBorder">
				<span className="firstWord">BIZ</span>
				<span className="secondWord">TAYO</span>
			</div>
		</div>
	);
}

export default BizLogo;
