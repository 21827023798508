import {
	Button,
	Checkbox,
	ClickAwayListener,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	Paper,
	Popper,
	TablePagination,
} from '@material-ui/core';
import {
	AccountCircle,
	Block,
	DeleteForever,
	Message,
	MoreVert,
	Report,
	Settings,
} from '@material-ui/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';
import {
	ComplaintListDiv,
	DisabledUserListCont,
	EmptyComplaintListDiv,
	SelectedSettingCont,
} from './AdministratorStyle';
import {
	generalStyle,
	PaperTitle,
	PaperTitleCont,
} from 'back/assets/style/generalStyle';
import { AxiosConfigSecured } from 'config/axiosConfig';
import {
	PageDataContext,
	pageDataDialogBoxCleanup,
} from 'back/context/pageData';
import clsx from 'clsx';
import _ from 'lodash';
import { UserDataContext } from 'UniversalContext/userData';
import { SocketContext } from 'back/context/socket';
import GlobalAlert from '../GlobalAlert/GlobalAlert';
import { useHistory } from 'react-router-dom';
import { currentTime, getFullDate } from 'back/imports/logicCode';

const DisablingAccount = () => {
	const generalClasses = generalStyle();
	const axiosInstance = AxiosConfigSecured();
	const { setPageDataValue } = useContext(PageDataContext);
	const { userDataValue } = useContext(UserDataContext);
	const [details, setDetails] = useState({
		disableReason: '',
		accountEmailAddress: '',
	});
	const [errorHandler, setErrorHandler] = useState({
		emptyEmail: false,
		invalidEmail: false,
		emptyReason: false,
	});
	const { socket } = useContext(SocketContext);

	function handleChange(e) {
		const { name, value } = e.target;

		//set error to true if no value
		if (!value) {
			switch (name) {
				case 'accountEmailAddress':
					!errorHandler.emptyEmail &&
						setErrorHandler((prevState) => ({
							...prevState,
							emptyEmail: true,
						}));
					break;
				case 'disableReason':
					!errorHandler.emptyReason &&
						setErrorHandler((prevState) => ({
							...prevState,
							emptyReason: true,
						}));
					break;
				default:
					return false;
			}
		} else {
			//set error to false if there's value
			switch (name) {
				case 'accountEmailAddress':
					errorHandler.invalidEmail &&
						setErrorHandler((prevState) => ({
							...prevState,
							invalidEmail: false,
						}));

					errorHandler.emptyEmail &&
						setErrorHandler((prevState) => ({
							...prevState,
							emptyEmail: false,
						}));
					break;
				case 'disableReason':
					errorHandler.emptyReason &&
						setErrorHandler((prevState) => ({
							...prevState,
							emptyReason: false,
						}));
					break;
				default:
					return false;
			}
		}

		setDetails((prevState) => ({ ...prevState, [name]: value }));
	}

	function handleDisablingAccBtn() {
		if (_.includes(errorHandler, true) || _.includes(details, '')) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: 'Please complete all needed details.',
					customColor: 'error',
				},
			}));

			if (details.disableReason === '') {
				errorHandler.emptyReason = true;
			}
			if (details.accountEmailAddress === '') {
				errorHandler.emptyEmail = true;
			}

			return false;
		}

		axiosInstance
			.post(
				'/administrator/disableAccount',
				{
					referenceData: details.accountEmailAddress, //can pass either email or userId
					reason: details.disableReason,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				if (!res.data.error) {
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: { ...prevState.snackBar, success: true },
					}));
					setDetails({
						disableReason: '',
						accountEmailAddress: '',
					});

					//updating disabledAccountList for admin page enabling account portion
					socket.emit('user:updateDisabledAccountList', {
						userId: userDataValue.UserInfo.userID,
					});

					//sending notif to owner of disabled account
					socket.emit('user:notif', {
						userId: res.data.userDetails._id,
					});
				} else if (res.data.error === 'invalid') {
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Invalid email address. ',
							customColor: 'error',
						},
					}));
					setErrorHandler((prevState) => ({
						...prevState,
						invalidEmail: true,
					}));
				} else {
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Account was already disabled. ',
							customColor: 'error',
						},
					}));
					setErrorHandler((prevState) => ({
						...prevState,
						invalidEmail: true,
					}));
				}
			});
	}

	return (
		<Paper>
			<PaperTitleCont>
				<PaperTitle>
					<Block /> Disabling Account
				</PaperTitle>
			</PaperTitleCont>

			<div className={generalClasses.paperContent}>
				<label className={generalClasses.guidePhrase}>
					Disabled account will experience the following.
					<ul>
						<li>Unable to create new business listing.</li>
						<li>
							Existing business/es will be tagged as <b>disabled</b> and will
							not be viewable by from others
						</li>
					</ul>
				</label>
				<br /> <br />
				<form onChange={(e) => handleChange(e)}>
					<FormControl
						variant="outlined"
						style={{ width: '100%' }}
						className={clsx({
							[generalClasses.errorTextbox]:
								errorHandler.emptyEmail || errorHandler.invalidEmail,
						})}>
						<InputLabel htmlFor="outlined-adornment-password">
							Email address or userId
						</InputLabel>
						<OutlinedInput
							variant="outlined"
							label="accountEmailAddress"
							name="accountEmailAddress"
							required
							type="text"
							value={details.accountEmailAddress}
							inputProps={{ maxLength: 40 }}
							endAdornment={
								<InputAdornment position="end">
									<AccountCircle />
								</InputAdornment>
							}
						/>
					</FormControl>
					<FormControl
						variant="outlined"
						style={{ width: '100%' }}
						className={clsx({
							[generalClasses.errorTextbox]: errorHandler.emptyReason,
						})}>
						<InputLabel htmlFor="outlined-adornment-password">
							Reason
						</InputLabel>
						<OutlinedInput
							rows={4}
							multiline
							variant="outlined"
							label="disableReason"
							name="disableReason"
							value={details.disableReason}
							required
							type="text"
							inputProps={{ maxLength: 300 }}
							endAdornment={
								<InputAdornment position="end">
									<Message />
								</InputAdornment>
							}
						/>
					</FormControl>
				</form>
				<Grid container alignItems="center">
					<Grid item lg={8} md={6} xs={12}></Grid>
					<Grid item lg={4} md={6} xs={12}>
						<Button
							variant="contained"
							size="large"
							style={{ float: 'right' }}
							color="secondary"
							className="mt-4 mb-4"
							onClick={handleDisablingAccBtn}>
							Disable account
						</Button>
					</Grid>
				</Grid>
			</div>
		</Paper>
	);
};

const ActivatingAccount = () => {
	const generalClasses = generalStyle();
	const loading = useRef(false);
	const axiosInstance = AxiosConfigSecured();
	const [disableAccountList, setDisabledAccountList] = useState([]);
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [selectedAccount, setSelectedAccount] = useState([]);
	const { socket } = useContext(SocketContext);

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			axiosInstance
				.get('/administrator/getDisabledAccountList', { withCredentials: true })
				.then((res) => {
					if (res.data) {
						setDisabledAccountList(res.data);
						loading.current = false;
					}
				});

			socket.on(
				'user:updateDisabledAccountList',
				({ disabledAccountListRes }) => {
					setDisabledAccountList(disabledAccountListRes);
				}
			);
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	function handleSelectEmail(userId) {
		if (!_.includes(selectedAccount, userId)) {
			setSelectedAccount((prevState) => [...prevState, userId]);
		} else {
			setSelectedAccount((prevState) => {
				prevState.splice(prevState.indexOf(userId), 1);

				return [...prevState];
			});
		}
	}

	function handleActivateAccount() {
		if (!selectedAccount.length) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: 'Please select atleast 1 email for activation.',
					customColor: 'error',
				},
			}));

			return false;
		}

		axiosInstance
			.post(
				'/administrator/activateAccount',
				{ selectedAccount },
				{ withCredentials: true }
			)
			.then((res) => {
				//sending notif to owner of disabled account
				socket.emit('user:notif', {
					userId: selectedAccount,
				});

				setDisabledAccountList(res.data);
				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				setSelectedAccount([]);
			});
	}

	return (
		<Paper>
			<PaperTitleCont>
				<PaperTitle>
					<AccountCircle /> Enabling Account
				</PaperTitle>
			</PaperTitleCont>
			<div className={generalClasses.paperContent}>
				<label className={generalClasses.guidePhrase}>
					Reactivating account can experience the following.
					<ul>
						<li>Able to create new business listing.</li>
						<li>Existing business/es will return to its previous status</li>
					</ul>
				</label>

				<label
					className="mb-2 mt-2"
					style={{ display: 'block', fontWeight: 'bold' }}>
					List of disabled account
				</label>
				<DisabledUserListCont>
					{disableAccountList
						? disableAccountList.map((user, index) => (
								<label
									key={`disabledEmail_${index}`}
									onClick={() => handleSelectEmail(user._id)}
									className={clsx([
										{ selected: _.includes(selectedAccount, user._id) },
									])}>
									{user.email ? user.email : user._id}
								</label>
						  ))
						: 'none'}
				</DisabledUserListCont>

				<Button
					size="large"
					variant="contained"
					color="primary"
					className="mt-4 mb-4"
					onClick={handleActivateAccount}
					style={{
						backgroundColor: 'green',
						float: 'right',
					}}>
					Activate account
				</Button>

				<div style={{ clear: 'both' }} />
			</div>
		</Paper>
	);
};

function ComplaintPopper(props) {
	const { setPageDataValue } = useContext(PageDataContext);

	function handleDelete() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState,
				color: 'error',
				status: true,
				title: 'Deleting Business Complaint',
				message: 'Are you sure you want to delete this complaint?',
				buttonName: 'Delete Complaint',
				payloadId: props.selectedReportId,
			},
		}));
	}

	return (
		<Popper
			open={props.popperDetails.status}
			anchorEl={props.popperDetails.anchorEl}
			placement="left">
			<Paper>
				<Button
					variant="outlined"
					color="secondary"
					onClick={handleDelete}
					startIcon={<DeleteForever />}>
					Delete
				</Button>
			</Paper>
		</Popper>
	);
}

function ComplaintCenter() {
	const history = useHistory();
	const axiosInstance = AxiosConfigSecured();
	const { userDataValue } = useContext(UserDataContext);
	const [reportListHolder, setReportListHolder] = useState([]);
	const { socket } = useContext(SocketContext);
	const [popperDetails, setPopperDetails] = useState({
		status: false,
		anchorEl: '',
	});
	const [popperDetailsMultiple, setPopperDetailsMultiple] = useState({
		status: false,
		anchorEl: '',
	});
	const [page, setPage] = useState(0);
	const [selectedItems, setSelectedItems] = useState([]);
	const [selectedReportId, setSelectedReportId] = useState(null);
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);

	function handleOpenPopper(e, reportId) {
		if (popperDetails.status && e.currentTarget === popperDetails.anchorEl) {
			setPopperDetails({ status: false, anchorEl: '' });
		} else {
			setSelectedReportId(reportId);
			setPopperDetails({ status: true, anchorEl: e.currentTarget });
		}
	}

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			axiosInstance.get('/account/getReportAdsList').then((res) => {
				setReportListHolder(res.data.reverse());
			});

			socket.on('reportAds:notif', ({ allReport }) => {
				setReportListHolder([...allReport].reverse());
			});
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	//listener once deleting task has been completed.
	useEffect(() => {
		if (pageDataValue.dialogBox.taskStatus) {
			socket.emit('reportAds:updateCount');

			setSelectedItems([]);
			setSelectedReportId('');
			setReportListHolder([...pageDataValue.dialogBox.returnPayload].reverse());
			setPopperDetails({
				status: false,
				anchorEl: '',
			});
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: pageDataDialogBoxCleanup,
			}));
		}
		//eslint-disable-next-line
	}, [pageDataValue.dialogBox.taskStatus]);

	function getListPerPage(data) {
		return reportListHolder.slice(5 * page, 5 * (page + 1));
	}

	function handleChangePage(e, newPage) {
		setPopperDetails({
			status: false,
			anchorEl: '',
		});
		setPage(newPage);
	}

	function handleSelectItems(reportId) {
		if (selectedItems.find((data) => data === reportId)) {
			setSelectedItems((prevState) => {
				const updatedItems = prevState.filter((items) => items !== reportId);

				return updatedItems;
			});
		} else {
			setSelectedItems((prevState) => [...prevState, reportId]);
		}
	}

	function handleOpenPopperMultiple(e) {
		if (popperDetailsMultiple.status) {
			setPopperDetailsMultiple({ status: false, anchorEl: '' });
		} else {
			setPopperDetailsMultiple({ status: true, anchorEl: e.currentTarget });
		}
	}

	function handleCloseMultiple() {
		setPopperDetailsMultiple({ status: false, anchorEl: '' });
	}

	function handleDeleteMultiple() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState,
				color: 'error',
				status: true,
				title: 'Deleting Business Complaint',
				message: `Are you sure you want to delete these ${selectedItems.length} selected complaint/s?`,
				buttonName: 'Delete Complaint',
				payloadId: selectedItems,
			},
		}));
	}

	return (
		<Paper>
			<PaperTitleCont>
				<PaperTitle>
					<Report /> List of Complaints
				</PaperTitle>
			</PaperTitleCont>
			<ComplaintPopper
				popperDetails={popperDetails}
				setPopperDetails={setPopperDetails}
				selectedReportId={selectedReportId}
			/>

			<ComplaintListDiv>
				{reportListHolder.length !== 0 && (
					<table>
						<tbody>
							{getListPerPage(reportListHolder).map((data, index) => (
								<tr>
									<th>
										<center>
											<Checkbox
												color="secondary"
												checked={
													selectedItems.find((item) => item === data._id)
														? true
														: false
												}
												onChange={() => handleSelectItems(data._id)}
											/>
										</center>
									</th>
									<td
										onClick={() =>
											history.push(
												`/account/adminPanel/UserAccountSetting/complaintDetails/${data._id}`
											)
										}>
										<Grid container spacing={3}>
											<Grid item lg={6} xs={12}>
												<label>
													<span>
														Business Name: {data.status === 'new' && <i>New</i>}
													</span>{' '}
													{data.bizName}
												</label>
											</Grid>
											<Grid item lg={6} xs={12}>
												<label>
													<span>Date: </span>{' '}
													{`${getFullDate(data.date)} - ${currentTime(
														data.date
													)}`}
												</label>
											</Grid>
										</Grid>
									</td>
									<th>
										<IconButton
											onClick={(e) => handleOpenPopper(e, data._id)}
											name="popper">
											<center>
												<MoreVert />
											</center>
										</IconButton>
									</th>
								</tr>
							))}
						</tbody>
					</table>
				)}
				{reportListHolder.length === 0 && (
					<EmptyComplaintListDiv>
						<label>
							<span>
								<Report />
							</span>
							No complaints available yet
						</label>
					</EmptyComplaintListDiv>
				)}
				<br />
			</ComplaintListDiv>
			<Divider />
			{reportListHolder.length !== 0 && (
				<Grid container>
					<Grid item lg={5} xs={5}>
						{selectedItems.length >= 1 && (
							<SelectedSettingCont>
								<ClickAwayListener onClickAway={handleCloseMultiple}>
									<div>
										<IconButton onClick={handleOpenPopperMultiple}>
											<Settings />
										</IconButton>
										<Popper
											open={popperDetailsMultiple.status}
											anchorEl={popperDetailsMultiple.anchorEl}
											placement="top-start">
											<Paper>
												<Button
													variant="outlined"
													color="secondary"
													onClick={handleDeleteMultiple}
													startIcon={<DeleteForever />}>
													Delete Selected
												</Button>
											</Paper>
										</Popper>
									</div>
								</ClickAwayListener>
								<label>
									Selected: <b>{selectedItems.length}</b>
								</label>
							</SelectedSettingCont>
						)}
					</Grid>
					<Grid item lg={7} xs={7}>
						<TablePagination
							component="div"
							count={reportListHolder.length}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPageOptions={[]}
							rowsPerPage={5}
							labelRowsPerPage={false}
							style={{ marginTop: '3px' }}
						/>
					</Grid>
				</Grid>
			)}
		</Paper>
	);
}

const AdminUserAccountSetting = () => {
	const generalClasses = generalStyle();

	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid container className="mt-4" spacing={3}>
				<Grid item lg={12} xs={12}>
					<Paper>
						<label className={generalClasses.paperTitle}>
							User Account Setting
						</label>
					</Paper>
					<br />
					<Grid container spacing={3}>
						<Grid item lg={6} xs={12}>
							<DisablingAccount />
						</Grid>
						<Grid item lg={6} xs={12}>
							<ActivatingAccount />
						</Grid>
					</Grid>
				</Grid>

				<Grid item lg={12} xs={12} className="mt-4">
					<Paper>
						<label className={generalClasses.paperTitle}>
							Report / Complaint Center
						</label>
					</Paper>
					<br />

					<ComplaintCenter />
				</Grid>
			</Grid>
		</>
	);
};

export default AdminUserAccountSetting;
