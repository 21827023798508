import React, { useState, memo, useContext } from 'react';
import _ from 'lodash';

import {
	generalStyle,
	MCTextBox,
	UserDataContext,
	MCLinkTextbox,
} from 'back/imports/components';
import {
	Paper,
	Divider,
	Grid,
	Button,
	FormControl,
	Typography,
	InputLabel,
	FacebookIcon,
	InstagramIcon,
	LanguageIcon,
	TwitterIcon,
	TextField,
	Select,
	MenuItem,
} from 'back/imports/materialUi';
import clsx from 'clsx';
import { ErrorHandlerContext } from 'back/context/pageData';
import handleNumberOnly from 'back/MicroComponents/numbersFormat';

const RequirementsComponent = memo(() => {
	const generalClasses = generalStyle();
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);

	const { errorHandler, setErrorHandler } = useContext(ErrorHandlerContext);

	//setting default value to 3 if requirements data is < 3
	const [reqRowCount, setReqRowCount] = useState(
		userDataValue.NewBizData.basicInfo.requirements.length < 3
			? 3
			: userDataValue.NewBizData.basicInfo.requirements.length
	);

	const rowCountChange = () => setReqRowCount(reqRowCount + 1);

	const rowCountRemove = async () => {
		if (reqRowCount !== 1) {
			const clonedReqValue = _.cloneDeep(
				userDataValue.NewBizData.basicInfo.requirements
			);
			const clonedErrorHandler = _.cloneDeep(errorHandler);
			delete clonedErrorHandler[0][`requirement_${reqRowCount}`];
			setErrorHandler(clonedErrorHandler);

			clonedReqValue.splice(reqRowCount - 1, 1);
			setUserDataValue((prevState) => ({
				...prevState,
				NewBizData: {
					...prevState.NewBizData,
					basicInfo: {
						...prevState.NewBizData.basicInfo,
						requirements: clonedReqValue,
					},
				},
			}));
			setReqRowCount(reqRowCount - 1);
		}
	};

	return (
		<>
			<Typography variant="h6" className={generalClasses.textFieldSpacing}>
				Requirements
			</Typography>
			<Typography variant="caption" color="textSecondary">
				Atleast one (1) requirement needed, If no data, Please leave the other
				textbox blank.
			</Typography>
			<Grid container spacing={3} style={{ marginTop: '5px' }}>
				{[...Array(reqRowCount)].map((data, index) => {
					return (
						<Grid item xs={12} lg={4} key={'reqRowCount' + index}>
							<MCTextBox
								variant="outlined"
								label={'Requirements # ' + (index + 1)}
								fullWidth
								ReqLocationIndex={index}
								name={`requirement_${index + 1}`}
								boxBelong="requirements"
							/>
						</Grid>
					);
				})}
			</Grid>
			<Button
				variant="contained"
				color="secondary"
				size="small"
				style={{ marginTop: '10px' }}
				onClick={rowCountRemove}>
				Remove 1 box
			</Button>
			&nbsp;&nbsp;
			<Button
				variant="contained"
				color="primary"
				size="small"
				style={{ marginTop: '10px' }}
				onClick={rowCountChange}>
				Add more
			</Button>
		</>
	);
});

const ContactComponent = memo(() => {
	const generalClasses = generalStyle();

	return (
		<div className={generalClasses.paperContent}>
			{/* <label className={generalClasses.guidePhrase}>
				Please make sure that all your contacts are active.
			</label> */}
			<Typography variant="h6">Business Links</Typography>
			<Typography variant="caption" color="textSecondary">
				Please make sure that all your contacts are active.
			</Typography>
			<MCTextBox
				fullWidth
				variant="outlined"
				label="Full name"
				name="fullName"
				boxBelong="infoBox"
			/>
			<MCTextBox
				fullWidth
				variant="outlined"
				label="Email Address"
				name="emailAddress"
				boxBelong="infoBox"
				className={generalClasses.textFieldSpacing}
			/>
			<MCTextBox
				fullWidth
				variant="outlined"
				label="Contact Number"
				name="contactNumber"
				boxBelong="infoBox"
				placeholder="+63"
				InputProps={{ inputComponent: handleNumberOnly }}
				className={generalClasses.textFieldSpacing}
			/>
		</div>
	);
});

const LinkComponent = () => {
	const generalClasses = generalStyle();

	const { errorHandler } = useContext(ErrorHandlerContext);

	return (
		<div className={generalClasses.paperContent} style={{ marginTop: '45px' }}>
			<Typography variant="h6">Business Links</Typography>
			<Typography variant="caption" color="textSecondary">
				Please put "N/a" if data is not available.
			</Typography>
			<FormControl
				variant="outlined"
				style={{ width: '100%' }}
				className={clsx({
					[generalClasses.errorTextbox]: errorHandler[0].facebookLink === true,
				})}>
				<InputLabel htmlFor="outlined-adornment-password">Facebook</InputLabel>
				<MCLinkTextbox
					label="Facebook"
					name="facebookLink"
					endAdornment={<FacebookIcon />}
				/>
			</FormControl>
			<FormControl
				variant="outlined"
				style={{ width: '100%' }}
				className={clsx([
					generalClasses.textFieldSpacing,
					{
						[generalClasses.errorTextbox]:
							errorHandler[0].instagramLink === true,
					},
				])}>
				<InputLabel htmlFor="outlined-adornment-password">Instagram</InputLabel>
				<MCLinkTextbox
					variant="outlined"
					label="Instagram"
					name="instagramLink"
					endAdornment={<InstagramIcon />}
				/>
			</FormControl>
			<FormControl
				variant="outlined"
				style={{ width: '100%' }}
				className={clsx([
					generalClasses.textFieldSpacing,
					{
						[generalClasses.errorTextbox]:
							errorHandler[0]['twitterLink'] === true,
					},
				])}>
				<InputLabel htmlFor="outlined-adornment-password">Twitter</InputLabel>
				<MCLinkTextbox
					variant="outlined"
					label="Twitter"
					name="twitterLink"
					endAdornment={<TwitterIcon />}
				/>
			</FormControl>
			<FormControl
				variant="outlined"
				style={{ width: '100%' }}
				className={clsx([
					generalClasses.textFieldSpacing,
					{
						[generalClasses.errorTextbox]: errorHandler[0].websiteLink === true,
					},
				])}>
				<InputLabel htmlFor="outlined-adornment-password">Website</InputLabel>
				<MCLinkTextbox
					variant="outlined"
					label="Website"
					name="websiteLink"
					endAdornment={<LanguageIcon />}
				/>
			</FormControl>
		</div>
	);
};

const NewBizFormInfo = memo(() => {
	const generalClasses = generalStyle();
	const { userDataValue } = useContext(UserDataContext);

	const selectMenuList = [
		'House Related',
		'Food/Beverages',
		'Health Industry',
		'Car Industry',
		'Manpower',
		'Art Related',
		'Apparel',
		'Others',
	];

	return (
		<Paper style={{ marginTop: '30px' }}>
			<form>
				<label className={generalClasses.paperTitle}>Business Info</label>
				<Divider />
				<Grid
					container
					direction="row"
					justifyContent="space-between"
					spacing={3}>
					<Grid item xs={12} lg={8}>
						<div
							className={generalClasses.paperContent}
							style={{ marginTop: '33px' }}>
							<Typography variant="caption" color="textSecondary">
								Please fill out all needed information before proceeding to the
								next form.
							</Typography>
							<TextField
								className={generalClasses.textFieldSpacing}
								label="Business Name"
								variant="outlined"
								fullWidth
								disabled
								defaultValue={userDataValue.NewBizData.basicInfo.bizName}
							/>
							<MCTextBox
								className={generalClasses.textFieldSpacing}
								label="Say something about your business."
								multiline
								rows={5}
								fullWidth
								autoFocus
								variant="outlined"
								name="bizDescription"
								boxBelong="infoBox"
							/>
							<MCTextBox
								className={generalClasses.textFieldSpacing}
								variant="outlined"
								fullWidth
								label="Address"
								name="bizAddress"
								boxBelong="infoBox"
							/>
							<Grid container spacing={3}>
								<Grid item xs={12} lg={6}>
									<MCTextBox
										id="datetime-local"
										label="Date established"
										type="date"
										InputLabelProps={{
											shrink: true,
										}}
										variant="outlined"
										fullWidth
										className={generalClasses.textFieldSpacing}
										name="bizDateEst"
										boxBelong="infoBox"
									/>
								</Grid>
								<Grid item xs={12} lg={6}>
									<FormControl
										variant="outlined"
										style={{ width: '100%' }}
										className={generalClasses.textFieldSpacing}>
										<InputLabel id="businessCategorySelect">
											Business Category
										</InputLabel>
										<Select
											label="Business Category"
											id="businessCategerySelectID"
											name="bizCategory"
											defaultValue={
												userDataValue.NewBizData.basicInfo.bizCategory
											}
											disabled>
											{selectMenuList.map((data, index) => (
												<MenuItem value={data} key={'select_' + index}>
													{data}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>

							<RequirementsComponent />
							<br />
							<br />
						</div>
					</Grid>
					<Grid item xs={12} lg={4}>
						<ContactComponent />

						<LinkComponent />
					</Grid>
				</Grid>

				<br />
			</form>
		</Paper>
	);
});

export default NewBizFormInfo;
