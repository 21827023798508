import {
	DashboardRoundedIcon,
	BusinessRoundedIcon,
} from 'back/imports/materialUi';
import { Dashboard, Businesses, NewBusiness } from 'back/imports/components.js';
import MyProfile from 'back/views/myProfile';
import { ProfileEdit } from './components/myProfile';
import ProfileChangePass from './components/myProfile/profileChangePass';
import BusinessDisplay from './components/businessDisplay/BusinessDisplay';
import UpdateBusiness from './views/UpdateBusiness';
import Administrator from './views/Administrator';
import { Comment, SupervisorAccount } from '@material-ui/icons';
import AdminApproveAds from './components/administrator/AdminApproveAds';
import AdminUserAccountSetting from './components/administrator/AdminUserAccountSetting';
import Inquiry from './views/inquiry';
import InquiryDetails from './components/inquiry/inquiryDetails';
import AdminComplaint from './views/AdminComplaint';

const routes = [
	{
		type: 'parent',
		title: 'Dashboard',
		path: '/account/dashboard',
		component: Dashboard,
		icon: DashboardRoundedIcon,
		permission: 1,
		breadcrumbs: ['Dashboard'],
	},
	{
		title: 'divider',
	},
	{
		type: 'parent',
		title: 'My Businesses',
		path: '/account/businesses',
		component: Businesses,
		icon: BusinessRoundedIcon,
		permission: 1,
		breadcrumbs: ['My Businesses'],
		childPath: [
			'/account/businesses/new',
			'/account/businesses/details/:id',
			'/account/businesses/update/:type/:id',
		],
	},
	{
		type: 'child', //under of My Business
		title: 'My Businesses',
		path: '/account/businesses/new',
		component: NewBusiness,
		breadcrumbs: [
			{ path: '/account/businesses', details: 'My Businesses' },
			'New Business Listing',
		],
	},
	{
		type: 'child', //under of My Business
		title: 'My Businesses',
		path: '/account/businesses/details/:id',
		component: BusinessDisplay,
		breadcrumbs: [
			{ path: '/account/businesses', details: 'My Businesses' },
			'Business Overview',
		],
	},
	{
		type: 'child', //under of My Business
		title: 'My Businesses',
		path: '/account/businesses/update/:type/:id',
		component: UpdateBusiness,
		breadcrumbs: [
			{ path: '/account/businesses', details: 'My Businesses' },
			{ path: '/account/businesses/details/:id', details: 'Business Overview' },
			'Update Details',
		],
	},
	{
		type: 'child', //under of My Profile
		title: 'My Profile',
		path: '/account/myProfile',
		component: MyProfile,
		breadcrumbs: ['My Profile'],
	},
	{
		type: 'child', //under of My Profile
		title: 'Update Profile',
		path: '/account/myProfile/update',
		component: ProfileEdit,
		breadcrumbs: [
			{ path: '/account/myProfile', details: 'My Profile' },
			'Update Profile',
		],
	},
	{
		type: 'child', //under of My Profile
		title: 'Change Password',
		path: '/account/myProfile/changePass',
		component: ProfileChangePass,
		breadcrumbs: [
			{ path: '/account/myProfile', details: 'My Profile' },
			'Change Password',
		],
	},

	{
		type: 'parent',
		title: 'Inquiries',
		path: '/account/inquiry',
		component: Inquiry,
		icon: Comment,
		permission: 1,
		breadcrumbs: ['Business Inquiries'],
		childPath: ['/account/inquiry/:id'],
	},
	{
		type: 'child', //under of Administrator
		title: 'Inquiries',
		path: '/account/inquiry/:id',
		component: InquiryDetails,
		breadcrumbs: [
			{ path: '/account/inquiry', details: 'Business Inquiries' },
			'Message Details',
		],
	},
	{
		type: 'parent',
		title: 'Administrator',
		path: '/account/adminPanel',
		component: Administrator,
		icon: SupervisorAccount,
		permission: 3,
		breadcrumbs: ['Administrator Panel'],
		childPath: [
			'/account/adminPanel/adsReview/',
			'/account/adminPanel/userAccountSetting/',
			'/account/adminPanel/userAccountSetting/complaintDetails/:id',
		],
	},
	{
		type: 'child', //under of Administrator
		title: 'Administrator',
		path: '/account/adminPanel/adsReview/',
		component: AdminApproveAds,
		permission: 3,
		breadcrumbs: [
			{ path: '/account/adminPanel', details: 'Administrator Panel' },
			'Advertisement Review',
		],
	},
	{
		type: 'child', //under of Administrator
		title: 'Administrator',
		path: '/account/adminPanel/userAccountSetting/',
		component: AdminUserAccountSetting,
		permission: 3,
		breadcrumbs: [
			{ path: '/account/adminPanel', details: 'Administrator Panel' },
			'Account Management',
		],
	},
	{
		type: 'child', //under of Administrator
		title: 'Administrator',
		path: '/account/adminPanel/userAccountSetting/complaintDetails/:id',
		component: AdminComplaint,
		permission: 3,
		breadcrumbs: [
			{ path: '/account/adminPanel', details: 'Administrator Panel' },
			{
				path: '/account/adminPanel/userAccountSetting/',
				details: 'Account Management',
			},
			'Complaint Details',
		],
	},
];

export default routes;
