import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';

import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { SearchContext } from 'front/context/searchContext';
import { HandlePesoRender } from 'back/MicroComponents/numbersFormat';
import { ImgDisplay } from '../imgDisplay/imgDisplay';

export const SearchResultList = (props) => {
	const history = useHistory();
	const { searchDataValue } = useContext(SearchContext);

	function UrlSearchOption() {
		return `keyword=${encodeURIComponent(
			searchDataValue.keyword
		)}&&investFrom=${searchDataValue.investFrom}&&investTo=${
			searchDataValue.investTo
		}&&page=${searchDataValue.page}&&category=${encodeURIComponent(
			searchDataValue.category
		)}&&searchType=${searchDataValue.searchType}`;
	}

	return (
		<div className="searchResultGrid-cont">
			{searchDataValue.searchResultSortedList
				.slice(
					searchDataValue.perPage * searchDataValue.page,
					searchDataValue.perPage * (searchDataValue.page + 1)
				)
				.map((business, index) => {
					const getPrimaryImage = business.images.filter(
						(image) => image.primaryPhoto === 1
					);

					return (
						<React.Fragment key={`businessResult_${index}`}>
							<Row className="pt-4 pb-3 result-list-row">
								<Col lg={12} className="">
									<div className="result-list-img-cont">
										<ImgDisplay
											imageId={getPrimaryImage[0].imageName}
											alt={`ads_image_${getPrimaryImage[0].imageName}`}
											className="result-list-img"
										/>
									</div>
									<div className="result-list-details-cont">
										<h3
											className="font-weight-bold"
											style={{
												wordWrap: 'break-word',
											}}>
											{business.name}
										</h3>

										<p
											className="h6 mt-3 text-muted"
											style={{
												fontWeight: 'normal',
												width: '95%',
												overflow: 'hidden',
												wordWrap: 'break-word',
											}}>
											{business.description.split(' ').slice(0, 40).join(' ')}{' '}
											...
										</p>
										<br />

										<Grid
											container
											spacing={3}
											style={{ marginBottom: '12px' }}>
											<Grid
												item
												lg={2}
												md={4}
												xs={12}
												style={{ padding: '0px 12px 0px 12px' }}>
												<label className="list-details-value">
													Initial Investment
												</label>
											</Grid>
											<Grid
												item
												lg={10}
												md={8}
												xs={12}
												style={{ padding: '0px 12px 0px 12px' }}>
												<label style={{ color: 'green' }}>
													{HandlePesoRender({
														value: parseInt(business.initialInvest),
													})}
												</label>
											</Grid>
										</Grid>
										<Grid
											container
											spacing={3}
											style={{ marginBottom: '12px' }}>
											<Grid
												item
												lg={2}
												md={4}
												xs={12}
												style={{ padding: '0px 12px 0px 12px' }}>
												<label className="list-details-value">
													Franchising Fee:
												</label>
											</Grid>
											<Grid
												item
												lg={10}
												md={8}
												xs={12}
												style={{ padding: '0px 12px 0px 12px' }}>
												<label>
													{HandlePesoRender({
														value: parseInt(business.franchiseFee),
													})}
												</label>
											</Grid>
										</Grid>

										<Grid
											container
											spacing={3}
											style={{ marginBottom: '12px' }}>
											<Grid
												item
												lg={2}
												md={4}
												xs={12}
												style={{ padding: '0px 12px 0px 12px' }}>
												<label className="list-details-value">
													Marketing Fee:
												</label>
											</Grid>
											<Grid
												item
												lg={10}
												md={8}
												xs={12}
												style={{ padding: '0px 12px 0px 12px' }}>
												<label>{business.marketingFee} %</label>
											</Grid>
										</Grid>
										<Button
											variant="primary mt-2"
											style={{ fontSize: '12px' }}
											onClick={() =>
												history.push(
													`/bt/show/search=${
														business._id
													}&&name=${encodeURIComponent(
														business.name
													)}&&${UrlSearchOption()}`
												)
											}>
											Read more!
										</Button>
									</div>
								</Col>
							</Row>
							<hr />
						</React.Fragment>
					);
				})}
		</div>
	);
};
