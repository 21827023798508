import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Grid,
	Typography,
} from '@material-ui/core';
import {
	AccordionCont,
	FranchiseBodyCont,
	FranchiseDetailsHeader,
	FranchiseIcon,
	FranchiseTitle,
	FranchiseTitleSub,
} from 'front/assets/styles/franchiseGuideStyle';
import FeaturedAds from 'front/components/featuredAds/featuredAds';
import React from 'react';
import { Container } from 'react-bootstrap';
import franchiseImage from 'front/assets/images/franchisePhoto.png';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PaidAds from 'front/components/paidAds/paidAds';

const WhyFranchise = () => {
	return (
		<div>
			<div className="mainBody-bg"></div>
			<br />
			<FranchiseBodyCont>
				<Container>
					<Grid
						container
						spacing={3}
						justifyContent="flex-end"
						direction="row-reverse">
						<Grid item lg={4} xs={12}>
							<FranchiseIcon>
								<img src={franchiseImage} alt="franchise Icon" />
							</FranchiseIcon>
						</Grid>
						<Grid item lg={8} xs={12}>
							<FranchiseTitle>
								Franchising the best business for you
							</FranchiseTitle>
						</Grid>
					</Grid>
					<Grid container>
						<Grid item lg={12} xs={12}>
							<FranchiseTitleSub>Why Franchise?</FranchiseTitleSub>

							<AccordionCont>
								<label style={{ textAlign: 'justify' }}>
									Franchising business gives you ease in thinking what
									product/services to put up from scratch. Well-known brands
									have already got their loyal customers, you just need to make
									it accessible from them.
								</label>
							</AccordionCont>
						</Grid>
						<Grid item lg={12} xs={12}>
							<div style={{ clear: 'both' }} />

							<FranchiseTitleSub>
								The keys to successful franchising
								<span>
									by{' '}
									<a
										href="https://chinkeetan.com/"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: 'none' }}>
										Chinkee Tan
									</a>
								</span>
								<span>
									Reference video:{' '}
									<a
										href="https://www.youtube.com/watch?v=Na0eDO188BI"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: 'none' }}>
										Business Tips: Keys To Successful FRANCHISING
									</a>
								</span>
							</FranchiseTitleSub>

							<AccordionCont>
								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Legitimacy of the Business / franchise owner
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											Check thoroughly the legitimacy of the business if it is
											properly documented and registered to a regulatory bodies
											here in Philippines.
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Support of the Franchisor
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											build-phase and after sales support from the owner is very
											important in franchising a business, providing trainings,
											managing strategies, properly handling customers, and
											earning supplier's trust are some of the ingredients of a
											successful franchising. <br />
											<br />
											Best way to validate if the owner will still support you
											even after acquiring franchise is to get feedbacks from
											other franchisees,
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Location in a franchise is very important
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											Make sure your location have your target customers,
											criteria of a good locations are: Outstanding foot
											traffic, Accessible for all types of customers, and no
											similar business in the area.
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Maintain the quality of the product or services
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											Consistent quality of products/services is a must, this
											will give you loyal customers.
										</Typography>
									</AccordionDetails>
								</Accordion>
							</AccordionCont>

							<FranchiseTitleSub>
								Advantages of Franchising a Business
								<span>
									by{' '}
									<a
										href="https://www.youtube.com/@franchiserepublic"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: 'none' }}>
										Franchise Republic
									</a>
								</span>
								<span>
									Reference video:{' '}
									<a
										href="https://www.youtube.com/watch?v=1wqKgs0Vcl4&t=502s"
										target="_blank"
										rel="noreferrer"
										style={{ textDecoration: 'none' }}>
										Franchise Business 101 | How to Start Franchising in the
										Philippines | Franchise Republic
									</a>
								</span>
							</FranchiseTitleSub>

							<AccordionCont>
								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Recognized and Established Brand
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											When you buy a franchise, you have the right to use the
											franchisor's trade name and trademark or company logo.
											<br />
											<br />
											This gives you access to the well-known brands customer
											base, so attracting and finding your first customers won't
											be that difficult.
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Ready-Made Operating Systems
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											One could say that franchising is simply replicating
											existing business with already existing procedures,
											systems, and practices - you just need to follow them.
											<br />
											<br />
											Franchising suits you if you have no background or
											experience in business management.
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Franchising support
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											Depending on the agreement, franchisors could provide the
											franchisees a wide-range of pre-opening and ongoing
											support on things like: site selection, crew training, and
											marketing.
											<br />
											<br />
											Being backed by reputable franchisors also help with loan
											approvals and negotiation with vendors.
										</Typography>
									</AccordionDetails>
								</Accordion>

								<Accordion expanded>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header">
										<FranchiseDetailsHeader>
											Pre-opening assistance
										</FranchiseDetailsHeader>
									</AccordionSummary>
									<AccordionDetails>
										<Typography>
											Franchisors help in the pre-opening needs of their
											franchisees such as design, evaluation, and construction.
											Some franchise packages also includes assistance for the
											grand opening.
										</Typography>
									</AccordionDetails>
								</Accordion>
							</AccordionCont>
						</Grid>
					</Grid>

					<PaidAds adsType="horizontal" />
					<br />
					<br />
					<br />
				</Container>
			</FranchiseBodyCont>

			<FeaturedAds />
		</div>
	);
};

export default WhyFranchise;
