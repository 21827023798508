import React, {
	useState,
	useContext,
	useCallback,
	useEffect,
	useRef,
} from 'react';
import {
	UserDataContext,
	clearNewBizData,
} from '../../UniversalContext/userData';
import {
	PageDataContext,
	ErrorHandlerContext,
	ErrorHandlerFunc,
} from 'back/context/pageData';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import {
	Grid,
	Button,
	NavigateBeforeIcon,
	NavigateNextIcon,
} from 'back/imports/materialUi';

import {
	Stepper,
	NewBizFormInfo,
	NewBizFormMoney,
	NewBizFormImage,
	NewBizFormLast,
} from 'back/imports/components';
import { Send } from '@material-ui/icons';
import { uniqueStyles } from './viewStyle';
import { AxiosConfigSecured } from 'config/axiosConfig';
import _ from 'lodash';
import { NewBizForm } from 'back/components/newBizForms';
import { BusinessListContext } from 'back/context/businessList';
import ActiveBreadcrumbs from '../components/Breadcrumbs/Breadcrumbs';

const NewBusinessRender = () => {
	const [stepsValue, setStepsValue] = useState(0);
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const classes = uniqueStyles();
	const axiosIntance = AxiosConfigSecured();
	const [checkBoxes, setCheckBoxes] = useState({
		responsible: false,
		terms: false,
	});
	const history = useHistory();
	const { errorHandler, setErrorHandler } = useContext(ErrorHandlerContext);
	const { setBusinessListValue } = useContext(BusinessListContext);
	let unblock = useRef();

	const handleBeforeUnloadAlert = (e) => {
		e.preventDefault();
		e.returnValue = '';
	};

	useEffect(() => {
		if (
			userDataValue.UserInfo.accessToken !== '' &&
			userDataValue.UserInfo.permission === 1
		) {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Unverified account',
					message: `Please verify your account first before creating your business franchise advertisement.`,
				},
			}));

			history.push('/account/businesses');
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	useEffect(() => {
		if (userDataValue.NewBizData.bizId) {
			window.addEventListener('beforeunload', handleBeforeUnloadAlert);

			unblock.current = history.block((tx) => {
				if (tx.pathname === '/login') {
					unblock.current();
					return true;
				} else {
					setPageDataValue((prevState) => ({
						...prevState,
						dialogBox: {
							color: 'warning',
							status: true,
							buttonName: 'Leave',
							title: 'Leaving unfinished form!',
							message:
								'You are not yet finish creating new business advertisement, all unsaved details will be lost once you leave. are you sure you want to leave this page?',
							payloadFunc: unblock.current,
							payloadData: tx.pathname,
						},
					}));
					return false;
				}
			});
		}

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnloadAlert);
		};
		//eslint-disable-next-line
	}, [userDataValue.NewBizData.bizId]);

	const handleSaveBusiness = (bizDetails) => {
		if (_.includes(checkBoxes, false)) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customColor: 'error',
					customMessage: 'Please check all the boxes.',
				},
			}));

			return false;
		}

		unblock.current();
		axiosIntance
			.post(
				'/business/addDetails',
				{ ...userDataValue },
				{ withCredentials: true }
			)
			.then((res) => {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customColor: 'success',
						customMessage: 'Business advertisement was successfully created.',
					},
					backdrop: false,
				}));
				setUserDataValue((prevState) => ({
					...prevState,
					...clearNewBizData(),
				}));
				setBusinessListValue(res.data);

				history.push('/account/businesses');
			});
	};

	const handleCheckbox = ({ name, state }) => {
		setCheckBoxes((prevState) => ({
			...prevState,
			[name]: state,
		}));
	};

	const handleNextBtn = () => {
		if (
			_.includes(errorHandler[stepsValue], 'empty') ||
			_.includes(errorHandler[stepsValue], true)
		) {
			setErrorHandler((prevState) => {
				Object.entries(prevState[stepsValue]).map((data) => {
					data[1] === 'empty' && (prevState[stepsValue][data[0]] = true);
					return data; //dont mind this, this is just to return something
				});
				return { ...prevState };
			});
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackbar,
					customSnackbar: true,
					customColor: 'error',
					customMessage:
						stepsValue === 2
							? 'Please provide alteast 1 photo'
							: 'Incomplete details, Please provide all needed information.',
				},
			}));

			return false;
		}

		setStepsValue(stepsValue + 1);
		stepsValue === 3 && handleSaveBusiness();
	};

	return !userDataValue.NewBizData.bizId ? (
		<NewBizForm />
	) : (
		<>
			<ActiveBreadcrumbs />

			<Stepper stepsValue={stepsValue} />
			{stepsValue === 0 ? <NewBizFormInfo stepsValue={stepsValue} /> : null}
			{stepsValue === 1 ? <NewBizFormMoney /> : null}
			{stepsValue === 2 ? <NewBizFormImage /> : null}
			{stepsValue === 3 ? (
				<NewBizFormLast
					handleCheckbox={handleCheckbox}
					checkBoxes={checkBoxes}
				/>
			) : null}
			<Grid
				container
				justifyContent="center"
				style={{ marginBottom: '30px', marginTop: '30px' }}>
				<Grid item lg={4} xs={12} align="center">
					<Button
						className={classes.buttonOverride}
						style={{ marginRight: '20px' }}
						variant="contained"
						color="default"
						disabled={stepsValue < 1 ? true : false}
						onClick={() => setStepsValue(stepsValue - 1)}
						startIcon={<NavigateBeforeIcon />}>
						Back
					</Button>
					{stepsValue === 3 ? (
						<Button
							className={clsx([
								classes.buttonOverride,
								{ rightBtn: !_.includes(checkBoxes, false) },
							])}
							variant="contained"
							color="primary"
							endIcon={<Send />}
							disabled={_.includes(checkBoxes, false) ? true : false}
							onClick={handleSaveBusiness}>
							Submit
						</Button>
					) : (
						<Button
							className={clsx(classes.buttonOverride, 'rightBtn')}
							variant="contained"
							color="primary"
							endIcon={<NavigateNextIcon />}
							disabled={stepsValue >= 4 ? true : false}
							onClick={handleNextBtn}>
							Next
						</Button>
					)}
				</Grid>
			</Grid>
		</>
	);
};

const NewBusiness = () => {
	const [errorHandler, setErrorHandler] = ErrorHandlerFunc();
	const errorChecker = useCallback((setState, page, name, value) => {
		setState((prevState) => {
			prevState[page][name] = value;
			return prevState;
		});
	}, []);

	return (
		<>
			<ErrorHandlerContext.Provider
				value={{ errorHandler, setErrorHandler, errorChecker }}>
				<NewBusinessRender />
			</ErrorHandlerContext.Provider>
		</>
	);
};

export default NewBusiness;
