import React, { useContext, useState } from 'react';
import {
	BreadcrumbsContainer,
	DialogModal,
	ReportLabel,
	StyledBreadcrumb,
} from './activeBreadcrumbsStyle';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import {
	Button,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Slide,
	TextField,
} from '@material-ui/core';
import { ReportProblem } from '@material-ui/icons';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { useHistory, useParams } from 'react-router-dom';
import { SocketContext } from 'back/context/socket';
import { SearchContext } from 'front/context/searchContext';
import { useLocation } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const ActiveBreadcrumbs = () => {
	const [open, setOpen] = useState(false);
	const { setPageDataValue } = useContext(PageDataContext);
	const axiosInstance = AxiosConfigUnsecured();
	const params = useParams();
	const [message, setMessage] = useState('');
	const [timer, setTimer] = useState(
		parseInt(localStorage.getItem('reportAdsInterval')) || null
	);
	const [email, setEmail] = useState('');
	const { socket } = useContext(SocketContext);
	const history = useHistory();
	const { searchDataValue } = useContext(SearchContext);
	const location = useLocation();
	let re =
		/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	function handleSubmit() {
		if (!re.test(email)) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: 'Please provide valid email address',
					customColor: 'error',
				},
			}));

			return false;
		}

		if (message.length <= 5) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage:
						'Please provide your reason for reporting this Advertisement',
					customColor: 'error',
				},
			}));

			return false;
		}

		const getCurrentTime = new Date().getTime();

		if (timer + 60000 >= getCurrentTime) {
			const getRemainingTime = (timer + 60000 - getCurrentTime) / 1000;

			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: `Please wait ${parseInt(
						getRemainingTime
					)} seconds before sending another report`,
					customColor: 'warning',
				},
			}));
			return false;
		}

		setOpen(false);
		setPageDataValue((prevState) => ({ ...prevState, backdrop: true }));

		axiosInstance
			.post('/public/reportAds', { bizId: params.id, message, email })
			.then((res) => {
				setMessage('');

				localStorage.setItem('reportAdsInterval', getCurrentTime);

				socket.emit('reportAds:updateCount');

				setPageDataValue((prevState) => ({
					...prevState,
					backdrop: false,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customMessage:
							'Report was successfully submitted to admin for review.',
						customColor: 'success',
					},
				}));

				setTimer(getCurrentTime);
			});
	}

	function handleChange(e) {
		if (e.target.name === 'message') {
			setMessage(e.target.value.length < 350 ? e.target.value : message);
		} else {
			setEmail(e.target.value.length < 75 ? e.target.value : email);
		}
	}

	return (
		<>
			<Grid container>
				<Grid item lg={10} xs={12}>
					<BreadcrumbsContainer>
						<Breadcrumbs aria-label="breadcrumb">
							<StyledBreadcrumb
								component="a"
								onClick={() => history.push('/')}
								label="Home"
							/>

							<StyledBreadcrumb
								component="a"
								onClick={() =>
									history.push(
										`/bt/search/keyword=${encodeURIComponent(
											searchDataValue.keyword
										)}&&investFrom=${searchDataValue.investFrom}&&investTo=${
											searchDataValue.investTo
										}&&page=${0}&&category=${encodeURIComponent(
											searchDataValue.category
										)}&&searchType=${searchDataValue.searchType}`
									)
								}
								label="Search"
							/>
							{location.pathname.split('/')[2] !== 'share' && (
								<StyledBreadcrumb
									component="a"
									label={decodeURIComponent(params.name)}
								/>
							)}
						</Breadcrumbs>
					</BreadcrumbsContainer>
				</Grid>
				<Grid item lg={2} xs={12} align="right">
					<ReportLabel onClick={handleClickOpen}>
						<span>
							<ReportProblem />
						</span>
						Report Ads
					</ReportLabel>
				</Grid>
			</Grid>
			<hr style={{ borderColor: '#003366' }} /> <br />
			<DialogModal
				open={open}
				TransitionComponent={Transition}
				keepMounted
				onClose={handleClose}
				aria-labelledby="alert-dialog-slide-title"
				aria-describedby="alert-dialog-slide-description">
				<DialogTitle id="alert-dialog-slide-title">
					<ReportProblem /> Report this advertisement
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-slide-description">
						By reporting this advertisement, System admin will review and do the
						necessary action once proven that this advertisement violated any
						rules set by Biztayo.ph
						<br />
						<br />
						If you have any proof, please send it to <b>
							support@biztayo.ph
						</b>{' '}
						together with screenshot of this advertisement as reference.
					</DialogContentText>
					<TextField
						fullWidth
						variant="outlined"
						label="Email"
						name="email"
						placeholder="Provide Email"
						size="small"
						value={email}
						onChange={handleChange}
					/>
					<TextField
						fullWidth
						variant="outlined"
						label="Reason"
						name="message"
						placeholder="Provide Reason"
						size="small"
						multiline
						rows={5}
						value={message}
						onChange={handleChange}
					/>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose} color="primary">
						Cancel
					</Button>
					<Button onClick={handleSubmit} color="primary">
						Submit
					</Button>
				</DialogActions>
			</DialogModal>
		</>
	);
};

export default ActiveBreadcrumbs;
