import { Skeleton } from '@material-ui/lab';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { useState, useEffect } from 'react';

export const ImgDisplay = ({ imageId, skeletonComponent, ...rest }) => {
	const axiosInstance = AxiosConfigUnsecured();
	const [imageUrl, setImageUrl] = useState(null);
	// const [imageIdHolder, setImageIdHolder] = useState(
	// 	encodeURIComponent(imageId)
	// );

	useEffect(() => {
		(async () => {
			setImageUrl(null);

			try {
				const signedUrl = await axiosInstance.get(
					`/getBusinessImage?imageId=${encodeURIComponent(imageId)}`
				);

				setImageUrl(signedUrl.data);
			} catch (err) {
				console.log(err);
			}
		})();

		//eslint-disable-next-line
	}, [imageId]);

	return imageUrl ? (
		<img
			src={imageUrl}
			alt={`ads_logo_${encodeURIComponent(imageId)}`}
			{...rest}
		/>
	) : (
		<Skeleton {...skeletonComponent} />
	);
};
