import { useContext } from 'react';
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { UserDataContext, clearUserDataValue } from 'UniversalContext/userData';
import { useCookies } from 'react-cookie';
import { PageDataContext } from 'back/context/pageData';

export const BackdropDialog = (props) => {
	const history = useHistory();
	const axiosInstance = AxiosConfigSecured();
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [, , removeCookies] = useCookies(['tokenExpiration']);

	const handleLink = async (e) => {
		if (e.currentTarget.name === 'login') {
			await setUserDataValue(clearUserDataValue());
			removeCookies('tokenExpiration', {
				path: '/',
				sameSite: 'Strict',
				secure: true,
				domain: 'biztayo.ph',
			});

			//delete secureCookie via serverside
			axiosInstance.post('/logout').then(() => {
				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				history.push('/login');
			});
		} else {
			axiosInstance.post('/logout').then(() => {
				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				history.push('/');
			});
		}
	};

	return (
		<Dialog
			open={
				JSON.parse(process.env.REACT_APP_DISABLED_SECURITY)
					? false
					: !userDataValue.isLogin
			}
			fullWidth
			aria-labelledby="simple-dialog-title">
			<DialogTitle
				id="simple-dialog-title"
				style={{ backgroundColor: '#eee', color: 'red' }}>
				Login Status
			</DialogTitle>
			<DialogContent className="mt-2">
				Your session has expired, Please log-in again.
			</DialogContent>
			<DialogActions className="p-3">
				<Button
					variant="outlined"
					color="primary"
					name="login"
					onClick={handleLink}>
					Login
				</Button>
				<Button
					variant="outlined"
					color="primary"
					name="home"
					onClick={handleLink}>
					Return to home page
				</Button>
			</DialogActions>
		</Dialog>
	);
};
