import { makeStyles } from '@material-ui/core';

export const generalStyle = makeStyles((theme) => ({
	/************** error textbox ****************/

	errorTextbox: {
		//static red
		'& .MuiInputLabel-root': { color: 'red' },
		'& .MuiInputLabel-outlined.Mui-focused': {
			backgroundColor: 'red !important',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid red',
		},
		'& svg ': {
			color: 'red',
		},

		//focused red
		'& .MuiOutlinedInput-root.Mui-focused': {
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid red',
			},
		},

		//hover red
		'& :hover': {
			'& .MuiInputLabel-root': { color: 'red' },
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid red',
			},
		},
	},
}));
