import styled from 'styled-components';

export const BrowseCategoryDiv = styled.div`
	display: grid;
	position: absolute;
	top: 50%;
	left: 50%;
	width: 85%;
	height: 70%;
	align-items: center;
	justify-content: center;
	z-index: 2;
	background: rgba(0, 0, 0, 0.3);

	border-radius: 10px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;

	transform: translate(-50%, -50%);

	&:hover {
		background: rgba(0, 0, 0, 0.7);
	}
`;

export const BrowserChoicesBG = styled.div`
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	height: inherit;
	width: inherit;
	z-index: 1;
	& img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		position: absolute;
		z-index: 1;
	}
`;
