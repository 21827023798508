import { Button, Checkbox, makeStyles, Paper } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));

export const InquiryTable = styled.table`
	width: 100%;
	& tr {
		border-bottom: 1px solid white;
	}
	& tr.active {
		background-color: #dfecf9 !important;
	}
	& tr:hover {
		background-color: #cbe3fa !important;
	}
	& th {
		width: 50px;
	}
	& td {
		padding: 10px;
		cursor: pointer;
		& span {
			font-size: 12px;
			display: block;
			margin-top: 5px;
			cursor: pointer;
		}
		& label {
			font-weight: bold;
			display: block;
			margin-bottom: 5px;
			cursor: pointer;
			overflow: hidden;
			margin-right: 10px;
		}
		& i {
			color: green;
			font-weight: bold;
			font-size: 15px;
			margin-left: 5px;
		}
	}

	& tr:nth-child(even) {
		background-color: #eee;
	}
`;

export const BoxAction = styled.div`
	display: block;
	margin-top: 0px;
	float: left;
	& label {
		margin-left: 20px;
		& svg {
			font-size: 30px;
		}
	}
	& .MuiIconButton-root {
		margin-top: 5px;
		& svg {
			font-size: 30px;
		}
	}
`;

export const PaginationBox = styled.div`
	float: right;
	height: 50px;
	margin-top: 10px;
`;

export const ActionContainer = styled.div`
	height: 50px;
	width: 100%;
	& .backBtn {
		float: left;
		margin: 20px;
	}
	& .deleteBtn {
		float: right;
		margin: 20px;
	}
`;

export const DetailsCont = styled.div`
	width: 100%;
	font-size: 18px;
	padding: 20px;
	& .inquiryDate {
		display: block;
		float: right;
		text-align: right;
		margin-top: 50px;
	}
	& .inquiryTitle {
		display: block;
		text-align: center;
		font-weight: bold;
		margin-top: 40px;
		& span {
			font-size: 12px;
			display: block;
			color: #bbb;
		}
	}
	& .inquiryGreetings {
		display: block;
		margin-top: 50px;
	}
	& .inquiryDescription {
		display: block;
		margin-top: 50px;
		text-indent: 50px;
		text-align: justify;
		white-space: pre-line;
	}

	& .inquirySender {
		display: block;
		margin-top: 100px;
		margin-bottom: 50px;
		text-align: right;
	}
`;

export const CheckboxAll = styled(Checkbox)`
	margin-top: 8px !important;
	margin-left: -12px !important;
	float: left;
	& svg {
		background-color: white;
		border-radius: 3px;
	}
`;

export const FilterButton = styled(Button)`
	float: left;
	margin-left: 15px !important;
`;

export const FilterModal = styled(Paper)`
	padding: 30px;
	margin: 20px;
	& .modalTitle {
		font-size: 20px;
		font-weight: bold;
		margin-bottom: 20px;
	}
`;

export const FilterButtonDone = styled(Button)`
	height: 60px;
	margin-top: 15px !important;
`;

export const CheckboxSettingPaper = styled(Paper)`
	width: 200px;
	& label {
		padding: 5px;
		display: block;
		cursor: pointer;
		width: 100%;
		text-align: left;
		& span {
			margin-right: 5px;
		}
	}
	& button:nth-child(odd) {
		border-bottom: 1px solid #ccc;
		color: red;
		background-color: ;
	}
	& button:nth-child(even) {
		color: green;
	}
`;

export const MoreVertSettingPaper = styled(Paper)`
	& label {
		padding: 5px;
		display: block;
		cursor: pointer;
		width: 100%;
		text-align: left;
		& span {
			margin-right: 5px;
		}
	}
	& button:nth-child(odd) {
		border-bottom: 1px solid #ccc;
		color: red;
		background-color: ;
	}
	& button:nth-child(even) {
		color: green;
	}
`;

export const NoDataContainer = styled.div`
	display: block;
	text-align: center;
	padding: 80px 0 70px 0;
	& label {
		font-size: 30px;
		& span svg {
			font-size: 50px;
		}
	}
`;
