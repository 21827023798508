import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	parentPaper: {
		position: 'relative',
		padding: '15px',
		'& .parentPaperTitle': {
			display: 'inline-block',
			position: 'absolute',
			backgroundColor: theme.palette.success.main,
			color: 'white',
			padding: '5px 15px 5px 15px',
			borderTopLeftRadius: '5px',
			borderTopRightRadius: '5px',
			marginTop: '-45px',
			marginLeft: '-15px',
			fontStyle: 'italic',
		},
	},
	activeButton: {
		height: '60px',
		backgroundColor: 'green !important',
		color: 'white',
	},
	displayButton: {
		height: '60px',
	},
	actionContainer: {
		backgroundColor: 'white',
		display: 'inline-block',
		borderRadius: '30px',
		border: '1px solid #ddd',
	},
	iconButtonDelete: {
		color: 'red',
		'& span svg': {
			fontSize: '30px',
		},
	},
	iconButtonUpdate: {
		color: 'green',
		'& span svg': {
			fontSize: '30px',
		},
	},
}));

export const ImgContainer = styled.div`
	display: grid;
	height: inherit;
	width: inherit;
	max-height: inherit;
	justify-content: center;
	align-content: center;
	position: relative;
`;

export const ImgDiv = styled.div`
	display: block;
	max-height: inherit;
	width: inherit;
	margin: auto auto;
	padding: 20px;
	& img {
		max-width: 100%;
		max-height: inherit;
	}
`;

export const ImgPrimaryIndicator = styled.label`
	position: absolute;
	margin-top: 40%;
	display: block;
	width: 100%;
	text-align: center;
	z-index: 10;
	background-color: green;
	opacity: 0.4;
	font-size: 25px;
	font-weight: bold;
	color: white;
	padding: 10px 0 10px 0;
	border-radius: 5px;
	&:hover {
		opacity: 0.6;
	}
`;

export const PaperContent = styled.div`
	display: block;
	padding: 15px;
`;

export const PaperDescHeader = styled.div`
	display: block;
	font-size: 12px;
	color: green;
	font-weight: bold;
`;

export const PaperDescValue = styled.div`
	display: block;
	font-size: 18px;
	width: 100%;
	padding: 0px 15px 5px 15px;
	border-bottom: 3px solid #eee;
	margin: 7px 0 15px 0;
	white-space: pre-line;
	text-align: justify;
	font-size: 12px;
	word-wrap: break-word;
	overflow: hidden;
	& ul li {
		margin-top: 7px;
	}
`;

export const GuideContainer = styled.div`
	width: 100%;
	height: 100%;
	background-color: #eee;
	border-radius: 5px;
	padding: 15px;
	& table tr {
		height: 50px;
		border-bottom: 1px solid #ccc;
	}
	& table tr td {
		vertical-align: top;
		padding-top: 10px;
		padding-bottom: 10px;
	}
	& table tr th {
		width: 30%;
		vertical-align: top;
		color: green;
		padding-top: 10px;
		padding-bottom: 10px;
	}
`;

export const StatusBox = styled.label`
	padding: 5px 10px 5px 10px;
	border-radius: 5px;
	margin-top: 15px;
	font-weight: bold;
	&.display_rejected,
	&.display_expired {
		background-color: rgb(253, 236, 234);
		border: 1px solid red;
	}
	&.display_pending {
		background-color: #cecdcd;
		border: 1px solid gray;
	}
	&.display_active {
		background-color: #adfcad;
		border: 1px solid darkgreen;
	}
`;
