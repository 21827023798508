import { Paper, Button } from '@material-ui/core';
import React, { useContext } from 'react';
import { generalStyle } from 'back/assets/style/generalStyle';
import { useHistory } from 'react-router-dom';
import { PageDataContext } from 'back/context/pageData';

const AdminActionCenter = ({ panelValues }) => {
	const generalClasses = generalStyle();
	const history = useHistory();
	const { setPageDataValue } = useContext(PageDataContext);

	function handleApproveButton() {
		if (panelValues.pendingAds) {
			history.push('/account/adminPanel/adsReview/');
		} else {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'warning',
					status: true,
					title: 'No Pending Advertisement',
					message: 'As of now. You have 0 pending advertisement for approval',
				},
			}));
		}
	}

	function handleUserAccountSettingButton() {
		history.push('/account/adminPanel/UserAccountSetting');
	}
	return (
		<>
			<Paper>
				<label className={generalClasses.paperTitle}>Action Center</label>

				<div className={generalClasses.paperContent}>
					<Button
						variant="outlined"
						color="primary"
						fullWidth
						size="large"
						onClick={handleApproveButton}>
						Approve advertisement
					</Button>
					<Button
						variant="outlined"
						color="primary"
						fullWidth
						size="large"
						className="mt-3"
						onClick={handleUserAccountSettingButton}>
						Account Management
					</Button>
				</div>
				<br />
			</Paper>
		</>
	);
};

export default AdminActionCenter;
