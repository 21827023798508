import { Grid } from '@material-ui/core';
import { MoneyOff, VerifiedUser } from '@material-ui/icons';
import {
	AboutBox,
	AboutTitle,
	AboutUsCont,
	BelowDetailsCont,
	DetailsContainer,
	ImageContainer,
	OurTeamDetails,
	OurTeamTitle,
} from 'front/assets/styles/aboutStyle';
import FeaturedAds from 'front/components/featuredAds/featuredAds';
import React from 'react';
import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCity } from '@fortawesome/free-solid-svg-icons';
import whoWeAre from 'front/assets/images/whoWeAre.jpg';
import visionBg from 'front/assets/images/vision_bg.jpg';
import rodImg from 'front/assets/images/ourTeam/rod.jpg';
import blankImg from 'front/assets/images/ourTeam/blank.jpg';

const About = () => {
	return (
		<div>
			<div className="mainBody-bg" />
			<br />
			<AboutUsCont>
				<AboutTitle>Get to know us!</AboutTitle>

				<Container>
					<Grid
						container
						spacing={3}
						style={{ marginBottom: '80px' }}
						justifyContent="space-around">
						<Grid item lg={3} xs={12}>
							<AboutBox>
								<div className="topBox">
									<VerifiedUser style={{ fontSize: '130px' }} />
								</div>
								<div className="bottomBox">
									<label>Secured platform</label>
									By using Biztayo.ph, you are bound to follow the rules and
									regulations of our platform. Securing your information inside
									our system is our top priority.
								</div>
							</AboutBox>
						</Grid>
						<Grid item lg={3} xs={12}>
							<AboutBox>
								<div className="topBox">
									<FontAwesomeIcon
										icon={faCity}
										style={{ fontSize: '90px', marginTop: '40px' }}
									/>
								</div>
								<div className="bottomBox">
									<label>Pool of businesses</label>
									Providing pool of businesses for you to franchise, We're
									helping the filipino people to find the best business. also,
									Helping the business owner to reach out from their possible
									franchisee.
								</div>{' '}
							</AboutBox>
						</Grid>
						<Grid item lg={3} xs={12}>
							<AboutBox>
								<div className="topBox">
									<MoneyOff style={{ fontSize: '130px' }} />
								</div>
								<div className="bottomBox">
									<label>Free Posting</label>
									We're giving you the opportunity to post your business
									franchise here in our platform for free!{' '}
									<b>1 year duration</b> of your advertisement without giving us
									any payment.
								</div>{' '}
							</AboutBox>
						</Grid>
					</Grid>
				</Container>

				<BelowDetailsCont style={{ backgroundColor: '#eee' }}>
					<Container>
						<Grid container spacing={3}>
							<Grid item lg={6} xs={12}>
								<ImageContainer bgColor="#eee">
									<img src={whoWeAre} alt="whoWeAre_bg" />
								</ImageContainer>
							</Grid>
							<Grid item lg={6} xs={12}>
								<DetailsContainer>
									<span>Who We Are</span>
									<br />
									<br />
									<p>
										Biztayo.ph is a secured website where you can post your
										business franchise advertisement for free. This is the
										single place where all businesses around the philippines can
										be searched and can be discovered by business minded people.
									</p>
								</DetailsContainer>
							</Grid>
						</Grid>
					</Container>
				</BelowDetailsCont>

				<BelowDetailsCont>
					<Container>
						<Grid container spacing={3} direction="row-reverse">
							<Grid item lg={6} xs={12}>
								<ImageContainer bgColor="white">
									<img src={visionBg} alt="visionBg" />
								</ImageContainer>
							</Grid>
							<Grid item lg={6} xs={12}>
								<DetailsContainer style={{ textAlign: 'right' }}>
									<span>Our Vision</span>
									<br />
									<br />
									<p>
										To help every Filipino in giving access to all available
										business franchises here in the Philippines. Providing a
										pool of businesses where business minded people can choose
										from. Give knowledge and encourage them to start their own
										business franchise.
									</p>
								</DetailsContainer>
							</Grid>
						</Grid>
					</Container>
				</BelowDetailsCont>

				<BelowDetailsCont style={{ backgroundColor: '#eee' }}>
					<Container>
						<OurTeamTitle>Our Team</OurTeamTitle>

						<Grid container>
							<Grid item lg={3} md={6} xs={12} style={{ padding: '40px' }}>
								<OurTeamDetails>
									<img src={rodImg} alt="Rod Balbin" />

									<div>
										<label>Rodolfo D. Balbin</label>
										<span>Founder and Developer</span>

										<p>
											Our main goal is to provide information that can help
											business minded people to acquire the best business
											suitable on their needs.
										</p>
									</div>
								</OurTeamDetails>
							</Grid>

							<Grid item lg={3} md={6} xs={12} style={{ padding: '40px' }}>
								<OurTeamDetails>
									<img src={blankImg} alt="blankOurTeam" />

									<div>
										<label>Available position</label>
										<span>Marketing support</span>

										<p>
											Want to be part of our team? please send your proposal to
											this email "support@biztayo.ph".
										</p>
									</div>
								</OurTeamDetails>
							</Grid>

							<Grid item lg={3} md={6} xs={12} style={{ padding: '40px' }}>
								<OurTeamDetails>
									<img src={blankImg} alt="blankOurTeam" />

									<div>
										<label>Available position</label>
										<span>Digital designer</span>

										<p>
											Want to be part of our team? please send your proposal to
											this email "support@biztayo.ph".
										</p>
									</div>
								</OurTeamDetails>
							</Grid>

							<Grid item lg={3} md={6} xs={12} style={{ padding: '40px' }}>
								<OurTeamDetails>
									<img src={blankImg} alt="blankOurTeam" />

									<div>
										<label>Available position</label>
										<span>Technical support</span>

										<p>
											Want to be part of our team? please send your proposal to
											this email "support@biztayo.ph".
										</p>
									</div>
								</OurTeamDetails>
							</Grid>
						</Grid>
					</Container>
				</BelowDetailsCont>
			</AboutUsCont>

			<FeaturedAds />
		</div>
	);
};

export default About;
