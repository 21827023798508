import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Popper from '@material-ui/core/Popper';
import { PopperDiv, TitleGuide, TitleValue } from './styleMenuProfile';
import {
	AccountCircle,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
} from 'back/imports/materialUi';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { UserDataContext, clearUserDataValue } from 'UniversalContext/userData';
import { useCookies } from 'react-cookie';
import { SocketContext } from 'back/context/socket';
import { PageDataContext } from 'back/context/pageData';

export const MenuProfilePopper = (props) => {
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const axiosInstance = AxiosConfigSecured();
	const { setPageDataValue } = useContext(PageDataContext);
	const history = useHistory();
	const [, , removeCookies] = useCookies(['tokenExpiration']);
	const { socket } = useContext(SocketContext);

	const open = props.activeMenu === 'myAccount' ? true : false;
	const id = open ? 'transitions-popper' : undefined;

	const handleLogout = (data) => {
		axiosInstance
			.post('/logout', {}, { withCredentials: true })
			.then(async (res) => {
				await setUserDataValue(clearUserDataValue());
				removeCookies('tokenExpiration', { path: '/' });

				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));

				socket.emit('user:logout', { userId: userDataValue.UserInfo.userID });

				history.push('/login');
			})
			.catch((err) => console.log(err));
	};

	const handleProfileClick = () => {
		props.handleClosePopper();
		history.push('/account/myProfile');
	};

	return (
		<>
			<Popper
				id={id}
				open={open}
				anchorEl={props.anchorData}
				style={{ position: 'relative', zIndex: '10' }}>
				<PopperDiv>
					<List style={{ padding: 0 }}>
						<ListItem>
							<TitleGuide>
								Logged in as:
								<TitleValue>
									{userDataValue.UserInfo.fname +
										' ' +
										userDataValue.UserInfo.lname}
								</TitleValue>
							</TitleGuide>
						</ListItem>
						<hr />
						<ListItem button onClick={handleProfileClick}>
							<ListItemIcon>
								<AccountCircle />
							</ListItemIcon>
							<ListItemText primary="My Account" />
						</ListItem>
						<hr />
						<ListItem button onClick={handleLogout}>
							<ListItemIcon>
								<ExitToAppIcon />
							</ListItemIcon>
							<ListItemText primary="Logout" />
						</ListItem>
					</List>
				</PopperDiv>
			</Popper>
		</>
	);
};

// const MenuProfile = (props) => {
// 	const [anchorData, setAnchorData] = useState(null);
// 	const handleClickedPopper = (event) => {
// 		setAnchorData(anchorData ? null : event.currentTarget);
// 	};

// 	const handleClosePopper = (data) => {
// 		setAnchorData(null);
// 	};
// 	return (
// 		<ClickAwayListener onClickAway={handleClosePopper}>
// 			<IconButton
// 				edge="end"
// 				aria-label="account of current user"
// 				aria-haspopup="true"
// 				color="inherit"
// 				onClick={handleClickedPopper}>
// 				<AccountCircle />

// 				<MenuProfilePopper
// 					anchorData={anchorData}
// 					handleClosePopper={handleClosePopper}
// 				/>
// 			</IconButton>
// 		</ClickAwayListener>
// 	);
// };

// export default MenuProfile;
