import { Grid } from '@material-ui/core';
import React from 'react';
import { NewBizFormMoney } from '../newBizForms';

const UpdateBizFormMoney = () => {
	return (
		<>
			<Grid container spacing={3}>
				<Grid item lg={12} xs={12}>
					<NewBizFormMoney />
				</Grid>
			</Grid>
		</>
	);
};

export default UpdateBizFormMoney;
