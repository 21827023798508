import { useState } from 'react';
import { createContext } from 'react';

export const SearchContext = createContext();

export const SearchContextValue = () => {
	const [searchDataValue, setSearchDataValue] = useState({
		keyword: '',
		investFrom: 0,
		investTo: 0,
		searchType: 0,
		category: 'All',
		searchResultList: [],
		searchResultSortedList: [],
		page: 0,
		perPage: 4,
		loading: false,
		freshEntry: true,
	});

	return { searchDataValue, setSearchDataValue };
};
