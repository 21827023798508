import { makeStyles } from "back/imports/materialUi.js";

// add center props to add auto auto in margin:

export const LogoStyle = makeStyles((theme) => ({
	logoCont: {
		margin: (props) => (props.center ? "auto auto" : null),
		marginTop: "5px",
		marginLeft: "5px",
		textAlign: "center",
		fontWeight: "bold",
		fontSize: "25px",
		"& .logoBorder": {
			borderRadius: "5px",
			overflow: "hidden",
		},
		"& .firstWord": {
			color: theme.palette.primary.main,
			padding: "0 5px 0px 5px ",
			borderTop: "4px solid " + theme.palette.primary.main,
			borderLeft: "3px solid " + theme.palette.primary.main,
			borderBottom: "4px solid " + theme.palette.primary.main,
		},
		"& .secondWord": {
			backgroundColor: theme.palette.primary.main,
			padding: "4px 10px 4px 3px",
			color: "white",
		},
	},
}));
