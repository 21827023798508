import { Popper } from '@material-ui/core';
import {
	AccountCircle,
	Business,
	Message,
	NotificationsActive,
} from '@material-ui/icons';
import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserDataContext } from 'UniversalContext/userData';
import {
	NoNotifLabel,
	PopperContent,
	PopperDiv,
	PopperIcon,
	SeeMoreButton,
	uniqueStyle,
} from './NotifStyle';

const NotifMenuPopper = (props) => {
	const open = props.activeMenu === 'myNotif' ? true : false;
	const id = open ? 'transitions-popper' : undefined;
	const history = useHistory();
	const { userDataValue } = useContext(UserDataContext);
	const classes = uniqueStyle();
	const [notifCounter, setNotifCounter] = useState({ new: 0, old: 0 });

	function handleSeeAll() {
		history.push('/account/myProfile');
		props.handleClosePopper();
	}

	function getNumberOfNewNotif(notifArray) {
		var newNotifCounter = 0,
			oldNotifCounter = 0;
		// notifArray.forEach((notif) => {
		// 	notif.status === 'new' && newNotifCounter++;
		// });

		for (const notif of notifArray) {
			notif.status === 'new' && newNotifCounter++;
		}

		for (const notif of notifArray) {
			notif.status === 'old' && oldNotifCounter++;
		}

		return { newNotifCounter, oldNotifCounter };
	}

	function getNumberOfOldNotif({ newNotifCounter, oldNotifCounter }) {
		//5 total slot if all notif is old,
		if (newNotifCounter < 5) {
			//if new notif is less than 5, it will display the new notif + old notif for the remaining slot(total of 5 slot)
			if (newNotifCounter + oldNotifCounter <= 5) {
				return oldNotifCounter;
			} else {
				return 5 - newNotifCounter;
			}
		} else {
			//return 0 if newNotif has occupied all slot(total of 5 slot)
			return 0;
		}
	}

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			const { newNotifCounter, oldNotifCounter } = getNumberOfNewNotif(
				userDataValue.UserInfo.notif
			);

			setNotifCounter({
				new: newNotifCounter,
				old: getNumberOfOldNotif({
					newNotifCounter,
					oldNotifCounter,
				}),
			});
		}
	}, [userDataValue.UserInfo.accessToken, userDataValue.UserInfo.notif]);

	return (
		<Popper
			id={id}
			open={open}
			anchorEl={props.anchorData}
			style={{ position: 'relative', zIndex: '10' }}>
			<PopperDiv>
				<div
					className={clsx([
						{ [classes.notifContainer]: notifCounter.new > 5 },
					])}>
					{userDataValue.UserInfo.accessToken &&
						!userDataValue.UserInfo.notif.length && (
							<NoNotifLabel>
								<span>
									<NotificationsActive />
								</span>
								No Notification Right Now!
							</NoNotifLabel>
						)}

					{/* Displaying new notif first */}
					{userDataValue.UserInfo.accessToken &&
						[...Array(notifCounter.new)].map((data, index) => {
							const {
								time,
								date,
								description,
								type,
								payloadStatus,
								payloadId,
							} = userDataValue.UserInfo.notif[index];
							return (
								<div key={`notifNew_${index}`}>
									{type === 'business' && (
										<>
											<Link
												to={`/account/businesses/details/${payloadId}`}
												onClick={() => props.handleClosePopper()}>
												<PopperContent className="new">
													<PopperIcon
														color={
															payloadStatus === 'active' ? 'green' : 'red'
														}>
														<Business />
													</PopperIcon>
													<label>
														<b>
															{payloadStatus === 'active' &&
																'Advertisement approved'}
															{payloadStatus === 'rejected' &&
																'Advertisement disapproved'}
															{payloadStatus === 'expired' &&
																'Advertisement expired'}
														</b>
														<br /> {description}
														<span>{`${date} - ${time}`}</span>
													</label>
													<div style={{ clear: 'both' }} />
												</PopperContent>
											</Link>
											<hr />
										</>
									)}
									{type === 'user' && (
										//sample output if notif from inquiry
										<>
											<Link
												to="/account/myProfile"
												onClick={() => props.handleClosePopper()}>
												<PopperContent className="new">
													<PopperIcon
														color={
															payloadStatus === 'enable' ? 'green' : 'red'
														}>
														<AccountCircle />
													</PopperIcon>
													<label>
														<b>
															{payloadStatus === 'disable'
																? 'Account disabled'
																: 'Account reactivated'}
														</b>
														<br /> {description}
														<span>{`${date} - ${time}`}</span>
													</label>
													<div style={{ clear: 'both' }} />
												</PopperContent>
											</Link>
											<hr />
										</>
									)}
									{type === 'inquiry' && (
										//sample output if notif from inquiry
										<>
											<Link
												to={`/account/inquiry/${payloadId}`}
												onClick={() => props.handleClosePopper()}>
												<PopperContent className="new">
													<PopperIcon color="gray">
														<Message />
													</PopperIcon>
													<label>
														<b>Inquiry received</b>
														<br /> {description}
														<span>{`${date} - ${time}`}</span>
													</label>
													<div style={{ clear: 'both' }} />
												</PopperContent>
											</Link>
											<hr />
										</>
									)}
								</div>
							);
						})}

					{/* Displaying old notif here */}
					{userDataValue.UserInfo.accessToken &&
						[...Array(notifCounter.old)].map((data, index) => {
							const {
								time,
								date,
								description,
								type,
								payloadStatus,
								payloadId,
							} = userDataValue.UserInfo.notif[index + notifCounter.new];

							return (
								<div key={`notifOld_${index}`}>
									{type === 'business' && (
										<>
											<Link
												to={`/account/businesses/details/${payloadId}`}
												onClick={() => props.handleClosePopper()}>
												<PopperContent>
													<PopperIcon
														color={
															payloadStatus === 'active' ? 'green' : 'red'
														}>
														<Business />
													</PopperIcon>
													<label>
														<b>
															{payloadStatus === 'active' &&
																'Advertisement approved'}
															{payloadStatus === 'rejected' &&
																'Advertisement disapproved'}
															{payloadStatus === 'expired' &&
																'Advertisement expired'}
														</b>
														<br /> {description}
														<span>{`${date} - ${time}`}</span>
													</label>
													<div style={{ clear: 'both' }} />
												</PopperContent>
											</Link>
											<hr />
										</>
									)}
									{type === 'user' && (
										//sample output if notif from inquiry
										<>
											<Link
												to="/account/myProfile"
												onClick={() => props.handleClosePopper()}>
												<PopperContent>
													<PopperIcon
														color={
															payloadStatus === 'enable' ? 'green' : 'red'
														}>
														<AccountCircle />
													</PopperIcon>
													<label>
														<b>
															{payloadStatus === 'disable'
																? 'Account disabled'
																: 'Account reactivated'}
														</b>
														<br /> {description}
														<span>{`${date} - ${time}`}</span>
													</label>
													<div style={{ clear: 'both' }} />
												</PopperContent>
											</Link>
											<hr />
										</>
									)}
									{type === 'inquiry' && (
										//sample output if notif from inquiry
										<>
											<Link
												to={`/account/inquiry/${payloadId}`}
												onClick={() => props.handleClosePopper()}>
												<PopperContent>
													<PopperIcon color="gray">
														<Message />
													</PopperIcon>
													<label>
														<b>Inquiry received</b>
														<br /> {description}
														<span>{`${date} - ${time}`}</span>
													</label>
													<div style={{ clear: 'both' }} />
												</PopperContent>
											</Link>
											<hr />
										</>
									)}
								</div>
							);
						})}
				</div>

				{userDataValue.UserInfo.accessToken &&
				userDataValue.UserInfo.notif.length ? (
					<SeeMoreButton onClick={handleSeeAll}>See all</SeeMoreButton>
				) : (
					''
				)}
			</PopperDiv>
		</Popper>
	);
};

export default NotifMenuPopper;
