import 'front/assets/styles/shared.css';
import 'bootstrap/dist/css/bootstrap.css';

import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faSearch,
	faHandsHelping,
	faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

import clsx from 'clsx';
import { useContext } from 'react';
import { UserDataContext } from 'UniversalContext/userData';
import Dashboard from '@material-ui/icons/Dashboard';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import biztayologo from 'front/assets/images/biztayo_fb.png';

export const NavbarFont = (props) => {
	const [scrollLoc, setScrollLoc] = useState(true);
	const [headerHeight, setHeaderHeight] = useState({
		height: '13vh',
		titleSize: '50px',
	});
	const { userDataValue } = useContext(UserDataContext);
	const currentTime = new Date().getTime() / 1000;
	const [cookies] = useCookies(['tokenExpiration']);
	const history = useHistory();
	const [navbarToggle, setNavbarToggle] = useState(false);
	const currentLocation = useLocation();

	function handleLink(path) {
		setNavbarToggle(false);

		window.scrollTo({ top: 0, behavior: 'smooth' });
		history.push(path);
	}

	function handleReconfigNavbarButton(e) {
		setNavbarToggle(!navbarToggle);
	}

	useEffect(() => {
		scrollLoc
			? setHeaderHeight({ height: '140px', titleSize: '50px' })
			: setHeaderHeight({ height: '90px', titleSize: '25px' });
	}, [scrollLoc]);

	useEffect(() => {
		const scrollEventHandler = (event) => {
			if (window.scrollY === 0) {
				setScrollLoc(true);
			} else if (window.scrollY !== 0 && scrollLoc === true) {
				setScrollLoc(false);
			}
		};

		window.addEventListener('scroll', scrollEventHandler);

		return () => {
			window.removeEventListener('scroll', scrollEventHandler);
		};
	}, [scrollLoc, setScrollLoc]);

	return (
		<>
			<div
				className="header-bg"
				style={{
					minHeight: headerHeight.height,
					boxShadow: !scrollLoc || navbarToggle ? '5px 2px 9px black' : 'none',
					background: !scrollLoc
						? '#003366'
						: 'linear-gradient(to right, rgba(0, 51, 102, 100%) 30%, rgba(0, 70, 140, 100%) 50%, rgba(0, 70, 140, 100%) 60%) ',
				}}>
				<div
					className="container-invi-height"
					style={{ minHeight: headerHeight.height }}></div>
				<Container>
					<div
						className="header-body"
						style={{
							minHeight: headerHeight.height,
						}}>
						<Navbar variant="dark" expanded={navbarToggle} expand="lg">
							<Navbar.Brand
								className="mr-auto cursor-pointer"
								onClick={() => handleLink('/')}>
								<label
									className="header-title cursor-pointer"
									style={{ fontSize: headerHeight.titleSize }}>
									<img
										src={biztayologo}
										alt="biztayoLogo"
										rel="no-referer"
										className={clsx({
											'header-title-img-scrolled': !scrollLoc,
										})}
									/>
									<label
										style={{
											color: 'white',
											margin: '0',
											paddingLeft: '10px',
										}}>
										BIZTAYO
									</label>
									<span
										className={clsx({
											'header-title-span-scrolled': !scrollLoc,
										})}>
										.PH
									</span>
								</label>
							</Navbar.Brand>
							<Navbar.Toggle
								aria-controls="basic-navbar-nav"
								id="navbar-toggle-button"
								onClick={handleReconfigNavbarButton}
							/>
							<Navbar.Collapse
								id="basic-navbar-nav"
								className="justify-content-end">
								<Nav className="ml-auto">
									<label
										onClick={() => handleLink('/bt/search')}
										className={clsx([
											'navButton',
											{ selected: currentLocation.pathname === '/bt/search' },
										])}>
										<Nav.Link style={{ color: 'white' }}>
											&nbsp;&nbsp;
											<FontAwesomeIcon
												icon={faSearch}
												style={{ fontSize: '15px', color: 'white' }}
											/>
											&nbsp;Own a Business&nbsp;&nbsp;
										</Nav.Link>
									</label>
									<label
										to="#noLink"
										className={clsx([
											'navButton',
											{
												selected:
													currentLocation.pathname === '/bt/whyFranchise',
											},
										])}
										onClick={() => handleLink('/bt/whyFranchise')}>
										<Nav.Link style={{ color: 'white' }}>
											&nbsp;&nbsp;
											<FontAwesomeIcon
												icon={faHandsHelping}
												style={{ fontSize: '15px', color: 'white' }}
											/>
											&nbsp;Why Franchise?&nbsp;&nbsp;
										</Nav.Link>
									</label>
									{userDataValue.UserInfo.accessToken ||
									cookies.tokenExpiration > currentTime ? (
										<label
											onClick={() => handleLink('/login')}
											className={clsx([
												'navButtonLogin',
												// 'navButtonLoginScrolled',
											])}>
											<Nav.Link style={{ color: 'white' }}>
												&nbsp;&nbsp;
												<Dashboard
													style={{
														fontSize: '18px',
														marginTop: '-3px',
														marginRight: '3px',
														color: 'white',
													}}
												/>
												&nbsp; My Dashboard&nbsp;&nbsp;
											</Nav.Link>
										</label>
									) : (
										<label
											className={clsx([
												'navButtonLogin',
												// 'navButtonLoginScrolled',
											])}
											onClick={() => handleLink('/login')}>
											<Nav.Link style={{ color: 'white' }}>
												&nbsp;&nbsp;
												<FontAwesomeIcon
													icon={faSignInAlt}
													style={{ fontSize: '15px', color: 'white' }}
												/>
												&nbsp;Log-in&nbsp;&nbsp;
											</Nav.Link>
										</label>
									)}
								</Nav>
							</Navbar.Collapse>
						</Navbar>
					</div>
				</Container>
			</div>
		</>
	);
};
