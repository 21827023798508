import { makeStyles, Button } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	adsContainer: {
		backgroundColor: '#6699cc',
		paddingBottom: '50px',
		// WebkitBoxShadow: '0px 14px 11px -8px rgba(0,0,0,0.64) inset',
		// boxShadow: '0px 14px 11px -8px rgba(0,0,0,0.64) inset',
	},
}));

export const FreepostContainer = styled.div`
	display: block;
	background-color: #fff2cc;
	color: #003366;
	font-weight: bold;
	font-size: 40px;
	text-align: center;
	padding: 50px 10px;
	position: relative;
	& img {
		position: absolute;
		display: block;
		max-width: 100%;
		margin-bottom: -13px;
		bottom: 0;
	}
`;

export const QuantityContainer = styled.div`
	display: block;
	background-color: white;
	padding-top: 150px;
	padding-bottom: 150px;
	& .quantityDiv {
		border: 4px dashed #ddd;
		padding: 50px;
		border-radius: 20px;
		position: relative;
		&:hover {
			border: 4px dashed green;
		}
		&:hover label {
			color: green;
		}
	}
	& label {
		display: block;
		text-align: center;
		font-size: 30px;
		color: #ddd;
		font-weight: bold;
		position: absolute;
		background-color: white;
		padding: 0 30px;
		top: -25px;
		right: 50%;
		transform: translate(50%, 0%);
	}
	& span {
		display: block;
		text-align: center;
		font-size: 100px;
		color: green;
		font-weight: bold;
	}
`;

export const FreepostDetails = styled.div`
	display: block;
	position: relative;

	padding: 70px 10px;
	& span {
		font-size: 30px;
		font-weight: normal;
	}
	& label {
		diplay: inline-block;
		font-size: 30px;
		padding: 10px 20px;
		color: #003366;
		border: 4px solid #003366;
		margin-top: 20px;
		border-radius: 10px;
		font-weight: normal;
		cursor: pointer;
		&:hover {
			// border: 4px solid #003366;

			// padding: 8px 18px;

			color: green;
			border-color: green;
		}
	}
`;

export const FeateuredContainer = styled.div`
	margin-top: 50px;
	margin-bottom: 60px;
`;

export const FeaturedTitle = styled.label`
	display: block;
	font-size: 35px;
	margin-bottom: 50px;
	color: white;
	font-weight: bold;
`;

export const FeaturedBox = styled.div`
	width: 100%;
	padding-bottom: 30px !important;
	padding: 15px;
	border-right: 1px solid #eee;

	background-color: white;

	& .imgCont {
		height: 100px;
		position: relative;
		display: block;
		overflow: hidden;
		& img {
			display: block;
			position: absolute;
			max-width: 100%;
			max-height: 100px;
			margin: auto auto;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
		}
	}
`;

export const FeaturedAdsName = styled.label`
	display: block;
	font-size: 14px;
	font-weight: bold;
	text-align: center;
	margin-top: 30px;
	height: 43px;
	overflow: hidden;
	word-wrap: break-word;
`;

export const FeaturedAdsInvest = styled.label`
	display: block;
	color: green;
	text-align: left;
	font-size: 14px;
	margin-top: 10px;
	font-weight: bold;
	& span {
		display: block;
		color: black;
		font-size: 12px;
		font-weight: normal;
	}
	& b span {
		font-weight: bold;
		color: green;
	}
`;

export const FeaturedButton = styled(Button)`
	display: block !important;
	margin: auto auto !important;
	margin-top: 20px !important;
	font-size: 10px !important;
	font-weight: bold !important;
`;
