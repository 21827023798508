import { Button, Grid, Paper } from '@material-ui/core';
import { ActiveBreadcrumbs, generalStyle } from 'back/imports/components';
import { UserDataContext, clearNewBizData } from 'UniversalContext/userData';
import { BusinessListContext } from 'back/context/businessList';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AxiosConfigSecured } from 'config/axiosConfig';
import UpdateBizFormInfo from 'back/components/updateBizForms/updateBizFormInfo';
import {
	ErrorHandlerContext,
	ErrorHandlerFunc,
	PageDataContext,
} from 'back/context/pageData';
import _ from 'lodash';
import UpdateBizFormPhoto from 'back/components/updateBizForms/updateBizFormPhoto';
import UpdateBizFormMoney from 'back/components/updateBizForms/updateBizFormMoney';

const UpdateBusinessRender = () => {
	const { type, id } = useParams();
	const generalClasses = generalStyle();
	const { businessListValue, setBusinessListValue } =
		useContext(BusinessListContext);
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { errorHandler, setErrorHandler } = useContext(ErrorHandlerContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [businessDetails, setBusinessDetails] = useState(null);
	const axiosInstance = AxiosConfigSecured();
	const history = useHistory();
	let typeInWord;

	if (type === 1) {
		typeInWord = 'Business Info';
	} else if (type === 2) {
		typeInWord = 'Photos';
	} else {
		typeInWord = 'Money Talk';
	}

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			(async () => {
				//enter here if businessListValue is populated
				if (businessListValue.length) {
					const business = await businessListValue.find(
						(business) => business._id === id
					);

					if (!business) {
						history.push('/account/businesses');
					}

					setBusinessDetails(business);
				} else {
					// enter here if from page refresh
					const business = await axiosInstance.get('/business/getBusiness', {
						withCredentials: true,
					});

					const selectedBusiness = await business.data.find(
						(business) => business._id === id
					);

					if (!selectedBusiness) {
						history.push('/account/businesses');
					}

					setBusinessDetails(selectedBusiness);
				}

				// set all errorHandler value to false on first load since all data are populated
				setErrorHandler((prevState) => {
					Object.entries(prevState[type - 1]).map((data) => {
						prevState[type - 1][data[0]] = false;

						return data;
					});

					return prevState;
				});
			})();
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	useEffect(() => {
		//eslint-disable-next-line
		if (businessDetails && type == 1) {
			setUserDataValue((prevState) => ({
				...prevState,
				NewBizData: {
					...prevState.NewBizData,
					bizId: businessDetails._id,
					basicInfo: {
						...prevState.NewBizData.basicInfo,
						bizName: businessDetails.name,
						bizDescription: businessDetails.description,
						bizAddress: businessDetails.address,
						bizDateEst: businessDetails.date,
						bizCategory: businessDetails.category,
						requirements: businessDetails.requirement,
						fullName: businessDetails.fullname,
						emailAddress: businessDetails.email,
						contactNumber: businessDetails.contactNum,
						facebookLink: businessDetails.facebook,
						instagramLink: businessDetails.instagram,
						twitterLink: businessDetails.twitter,
						websiteLink: businessDetails.website,
					},
				},
			}));
			//eslint-disable-next-line
		} else if (businessDetails && type == 2) {
			setUserDataValue((prevState) => ({
				...prevState,
				NewBizData: {
					...prevState.NewBizData,
					bizId: businessDetails._id,
					moneyTalk: {
						franchiseTerm: businessDetails.franchiseTerm,
						initialInvestment: businessDetails.initialInvest,
						franchiseFee: businessDetails.franchiseFee,
						marketingFee: businessDetails.marketingFee,
						royaltyFee: businessDetails.royaltyFee,
					},
				},
			}));
			//eslint-disable-next-line
		} else if (businessDetails && type == 3) {
			setUserDataValue((prevState) => ({
				...prevState,
				NewBizData: {
					...prevState.NewBizData,
					bizId: businessDetails._id,
					images: [...businessDetails.images],
					status: businessDetails.status,
				},
			}));
		}

		return () =>
			//removing data in NewBizData when leaving component
			setUserDataValue((prevState) => ({
				...prevState,
				...clearNewBizData(),
			}));
		//eslint-disable-next-line
	}, [businessDetails]);

	function handleUpdate() {
		if (_.includes(errorHandler[type - 1], true)) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackbar,
					customSnackbar: true,
					customColor: 'error',
					customMessage:
						type === 2
							? 'Please provide alteast 1 photo'
							: 'Incomplete details, Please provide all needed information.',
				},
			}));

			return false;
		}
		axiosInstance
			.post(
				'/business/updateBusinessInfo',
				{ bizId: id, bizDetails: userDataValue.NewBizData, bizType: type },
				{ withCredentials: true }
			)
			.then((res) => {
				setBusinessListValue(res.data);
				setPageDataValue((prevState) => ({
					...prevState,
					backdrop: false,
					snackBar: { ...prevState, success: true },
				}));
				history.push(`/account/businesses/details/${id}`);
			});
	}

	return (
		<>
			<ActiveBreadcrumbs />

			<Grid container spacing={3}>
				<Grid item lg={12} xs={12}>
					<Paper className="mt-4">
						<label className={generalClasses.paperTitle}>
							Updating {businessDetails?.name} ({typeInWord})
						</label>
					</Paper>
				</Grid>
			</Grid>

			{
				//eslint-disable-next-line
				userDataValue.NewBizData.bizId && type == 1 && (
					<UpdateBizFormInfo businessDetails={businessDetails} />
				)
			}

			{
				//eslint-disable-next-line
				userDataValue.NewBizData.bizId && type == 2 && (
					<UpdateBizFormMoney businessDetails={businessDetails} />
				)
			}

			{
				//eslint-disable-next-line
				userDataValue.NewBizData.bizId && type == 3 && (
					<UpdateBizFormPhoto businessDetails={businessDetails} />
				)
			}

			<Grid container spacing={3} justifyContent="center">
				<Grid
					item
					lg={12}
					xs={12}
					align="center"
					style={{ margin: '20px 0 20px 0' }}>
					<label>
						Once updated, this advertisement will be tagged as pending for
						approval for Admin's review.
					</label>
				</Grid>
			</Grid>
			<Grid container spacing={3} justifyContent="center">
				<Grid
					item
					lg={2}
					xs={12}
					align="center"
					style={{ marginBottom: '30px' }}>
					{
						//eslint-disable-next-line
						type != 3 && (
							<Button
								className="m-2"
								variant="contained"
								color="default"
								onClick={() =>
									history.push(`/account/businesses/details/${id}`)
								}>
								Cancel
							</Button>
						)
					}
					{
						//eslint-disable-next-line
						type != 3 ? (
							<Button
								className="m-2"
								variant="contained"
								color="primary"
								onClick={handleUpdate}>
								Update
							</Button>
						) : (
							<Button
								className="m-2"
								variant="contained"
								color="primary"
								onClick={() =>
									history.push(`/account/businesses/details/${id}`)
								}>
								Done
							</Button>
						)
					}
				</Grid>
			</Grid>
		</>
	);
};

const UpdateBusiness = () => {
	const [errorHandler, setErrorHandler] = ErrorHandlerFunc();
	const errorChecker = useCallback((setState, page, name, value) => {
		setState((prevState) => {
			prevState[page][name] = value;
			return prevState;
		});
	}, []);

	return (
		<>
			<ErrorHandlerContext.Provider
				value={{ errorHandler, setErrorHandler, errorChecker }}>
				<UpdateBusinessRender />
			</ErrorHandlerContext.Provider>
		</>
	);
};

export default UpdateBusiness;
