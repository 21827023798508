import React, { useContext, useState } from 'react';
import { UserDataContext } from 'UniversalContext/userData';
import { PageDataContext, maxLengthHandler } from 'back/context/pageData';
import { BusinessListContext } from 'back/context/businessList';
import { generalStyle } from 'back/imports/components';
import {
	Grid,
	Button,
	Paper,
	TextField,
	FormControl,
	InputLabel,
	MenuItem,
	Select,
} from '@material-ui/core';
import { NotePhrase, NewBizFormStyle } from './NewBizFormStyle';
import { AxiosConfigSecured } from 'config/axiosConfig';
import clsx from 'clsx';
import MaxLengthDisplay from 'back/MicroComponents/maxLengthDisplay';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import { siteSupportEmail } from 'back/context/biztayoDetails';
import { useEffect } from 'react';

const NewBizForm = () => {
	const generalClasses = generalStyle();
	const classes = NewBizFormStyle();
	const [bizName, setBizName] = useState('');
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const { setBusinessListValue } = useContext(BusinessListContext);
	const [bizCategory, setBizCategory] = useState('');
	const axiosInstance = AxiosConfigSecured();
	const [bizDetailError, setBizDetailError] = useState({
		duplicate: false,
		nameIsEmpty: false,
		nameError: false,
		categoryisEmpty: false,
	});
	const history = useHistory();

	const handleChange = (e) => {
		if (e.target.name === 'bizName') {
			//check if name is empty
			e.target.name.length === 0
				? setBizDetailError((prevState) => ({
						...prevState,
						nameIsEmpty: true,
				  }))
				: setBizDetailError((prevState) => ({
						...prevState,
						nameIsEmpty: false,
				  }));

			bizDetailError.duplicate &&
				setBizDetailError((prevState) => ({ ...prevState, duplicate: false }));

			e.target.value.length > maxLengthHandler.bizName
				? setBizDetailError((prevState) => ({
						...prevState,
						nameError: true,
				  }))
				: setBizDetailError((prevState) => ({
						...prevState,
						nameError: false,
				  }));

			setBizName(e.target.value);
		}
		if (e.target.name === 'bizCategory') {
			e.target.name.length === 0
				? setBizDetailError((prevState) => ({
						...prevState,
						categoryisEmpty: true,
				  }))
				: setBizDetailError((prevState) => ({
						...prevState,
						categoryisEmpty: false,
				  }));

			setBizCategory(e.target.value);
		}
	};

	const handleClick = () => {
		bizName.length === 0 &&
			setBizDetailError((prevState) => ({ ...prevState, nameIsEmpty: true }));

		bizCategory.length === 0 &&
			setBizDetailError((prevState) => ({
				...prevState,
				categoryisEmpty: true,
			}));

		if (
			bizName.length === 0 ||
			bizCategory.length === 0 ||
			bizName.length >= 76
		) {
			return false;
		}

		axiosInstance
			.post(
				'/business/saveBusinessTitle',
				{ bizName, bizCategory },
				{ withCredentials: true }
			)
			.then((res) => {
				const { error, payload } = res.data;

				if (!error) {
					setBizDetailError((prevState) => ({
						...prevState,
						duplicate: false,
					}));
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
					}));
					setUserDataValue((prevState) => ({
						...prevState,
						NewBizData: {
							...prevState.NewBizData,
							bizId: payload._id,
							basicInfo: {
								...prevState.NewBizData.basicInfo,
								bizName,
								bizCategory,
							},
						},
					}));
					setBusinessListValue((prevState) => [...prevState, payload]);
				} else {
					setBizDetailError((prevState) => ({
						...prevState,
						duplicate: true,
					}));
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
					}));
				}
			});
	};

	useEffect(() => {
		if (userDataValue.UserInfo.status === 'disabled') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Disabled account',
					message: `Please email ${siteSupportEmail} for reactivation of account.`,
				},
			}));

			history.push('/account/businesses');
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	const selectMenuList = [
		'House Related',
		'Food/Beverages',
		'Health Industry',
		'Car Industry',
		'Manpower',
		'Art Related',
		'Apparel',
		'Others',
	];

	return (
		<>
			<ActiveBreadcrumbs />

			<Paper style={{ marginTop: '30px' }}>
				<label className={generalClasses.paperTitle}>
					Create new business listing
				</label>
				<Grid
					container
					justifyContent="center"
					className="mb-5 p-3"
					style={{ marginTop: '100px' }}>
					<Grid item lg={5} xs={12}>
						<TextField
							className={clsx([
								generalClasses.textFieldSpacing,
								{
									[generalClasses.errorTextbox]:
										bizDetailError.nameIsEmpty ||
										bizDetailError.nameError ||
										bizDetailError.duplicate,
								},
							])}
							label="Business Name"
							variant="outlined"
							fullWidth
							name="bizName"
							value={bizName}
							onChange={handleChange}
							autoFocus
						/>
						<MaxLengthDisplay boxName="bizName" valueLength={bizName.length} />

						<FormControl
							variant="outlined"
							style={{ width: '100%' }}
							className={clsx([
								generalClasses.textFieldSpacing,
								{
									[generalClasses.errorTextbox]: bizDetailError.categoryisEmpty,
								},
							])}>
							<InputLabel id="businessCategorySelect">
								Business Category
							</InputLabel>
							<Select
								label="Business Category"
								id="businessCategerySelectID"
								name="bizCategory"
								value={bizCategory}
								onChange={handleChange}>
								{selectMenuList.map((data, index) => (
									<MenuItem value={data} key={'select_' + index}>
										{data}
									</MenuItem>
								))}
							</Select>
						</FormControl>

						<NotePhrase>
							<ul>
								<li
									className={clsx({
										error: bizName.length > maxLengthHandler.bizName,
									})}>
									75 characters limit
								</li>
								{bizDetailError.duplicate ? (
									<li className={clsx({ error: 'error' })}>
										Business name already exist in its category, kindly provide
										new name.
									</li>
								) : (
									<li>Business name must be unique in its category.</li>
								)}

								<li>
									Once all details needed are provided, Newly created ads will
									be tagged as <b>Pending</b> while BisTayo team are reviewing
									the business details.
								</li>
								<br />
								<li>
									Example Franchise Name:
									<br />
									<ul>
										<li>
											<b style={{ textDecoration: 'underline' }}>Siomai Lord</b>
											<ul>
												<li>
													If business name is not available try: <br />{' '}
													[Business name] by [nickname] ={' '}
													<b style={{ textDecoration: 'underline' }}>
														Siomai lord by BiztayoPh
													</b>{' '}
												</li>
											</ul>
										</li>

										<li>
											<b style={{ textDecoration: 'underline' }}>
												ShawarmAttack
											</b>
											<ul>
												<li>
													If business name is not available try: <br />
													[Business name] by [nickname] ={' '}
													<b style={{ textDecoration: 'underline' }}>
														ShawarmAttack by BiztayoPh
													</b>{' '}
												</li>
											</ul>
										</li>
									</ul>
								</li>
							</ul>
						</NotePhrase>
						<Button
							className={classes.greenBtn}
							variant="contained"
							color="primary"
							size="large"
							onClick={handleClick}
							fullWidth>
							Create
						</Button>
					</Grid>
				</Grid>
				<br />
				<br />
				<br />
				<br />
				<br />
			</Paper>
		</>
	);
};

export default NewBizForm;
