import { makeStyles } from 'back/imports/materialUi';
import styled from 'styled-components';

export const NewBizFormStyle = makeStyles((theme) => ({
	BlankImgContainer: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		border: '4px dashed #aaa',
		height: '340px',
		borderRadius: '10px',
		cursor: 'pointer',
		'& p': {
			fontSize: '20px',
			color: '#aaa',
			textAlign: 'center',
			padding: '10px',
		},
	},
	errorBlankImgContainer: {
		border: '4px dashed red !important',
		'& p': {
			color: 'red !important',
		},
	},
	PreviewImgPrimary: {
		'& .PreviewImgContainer': {
			height: '300px',
			border: '4px dashed ' + theme.palette.warning.dark,
			borderTop: 'none',
			borderTopLeftRadius: '0px',
			borderTopRightRadius: '0px',
			display: 'flex',
			borderRadius: '10px',
			backgroundColor: '#eee',
			justifyContent: 'center',
			alignItems: 'center',
			minWidth: 0,
			overflow: 'hidden',
			'& .imgContainer': {
				minWidth: 0,
				padding: '20px',
				'& img': {
					display: 'block',
					height: 'auto',
					width: '100%',
					maxHeight: '270px',
				},
			},
		},
		'& .PreviewImgHeader': {
			display: 'flex',
			backgroundColor: theme.palette.warning.dark,
			height: '40px',
			color: 'white',
			borderTopLeftRadius: '10px',
			borderTopRightRadius: '10px',
			overflow: 'hidden',
			alignItems: 'center',
			padding: '10px',
			'& div': {
				width: '50%',
			},
			'& div:nth-child(2)': {
				textAlign: 'right',
			},
		},
	},
	PreviewImgNormal: {
		'& .PreviewImgContainer': {
			height: '300px',
			border: '4px dashed #aaa',
			borderTop: 'none',
			borderTopLeftRadius: '0px',
			borderTopRightRadius: '0px',
			display: 'flex',
			borderRadius: '10px',
			backgroundColor: '#eee',
			justifyContent: 'center',
			alignItems: 'center',
			minWidth: 0,
			overflow: 'hidden',
			'& .imgContainer': {
				minWidth: 0,
				padding: '20px',
				'& img': {
					display: 'block',
					height: 'auto',
					width: '100%',
					maxHeight: '270px',
				},
			},
		},
		'& .PreviewImgHeader': {
			display: 'flex',
			backgroundColor: '#aaa',
			height: '40px',
			color: 'white',
			borderTopLeftRadius: '10px',
			borderTopRightRadius: '10px',
			overflow: 'hidden',
			alignItems: 'center',
			padding: '10px',
			'& div': {
				width: '50%',
			},
			'& div:nth-child(2)': {
				textAlign: 'right',
			},
		},
	},
	greenBtn: {
		backgroundColor: 'green !important',
		marginTop: '20px',
	},
}));

export const NotePhrase = styled.div`
	display: block;
	margin-top: 30px;
	& ul {
		list-style-type: square;
	}
	& .error {
		color: red;
	}
`;
