import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	Paper,
	TextField,
} from '@material-ui/core';
import {
	AccountCircle,
	AccountTree,
	ArrowBack,
	Block,
	Business,
	DeleteForever,
} from '@material-ui/icons';
import { Skeleton } from '@material-ui/lab';
import { generalStyle } from 'back/assets/style/generalStyle';
import {
	ComplaintUserOverview,
	CompliantDetailsDiv,
} from 'back/components/administrator/AdministratorStyle';
import ActiveBreadcrumbs from 'back/components/Breadcrumbs/Breadcrumbs';
import GlobalAlert from 'back/components/GlobalAlert/GlobalAlert';
import {
	PageDataContext,
	pageDataDialogBoxCleanup,
} from 'back/context/pageData';
import { SocketContext } from 'back/context/socket';
import { currentTime, getFullDate } from 'back/imports/logicCode';
import { AxiosConfigSecured } from 'config/axiosConfig';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UserDataContext } from 'UniversalContext/userData';

const ReportModal = (props) => {
	const [message, setMessage] = useState('');
	const axiosInstance = AxiosConfigSecured();
	const { setPageDataValue } = useContext(PageDataContext);
	const { socket } = useContext(SocketContext);

	function handleChange(e) {
		setMessage(e.target.value);
	}

	function handleDisable() {
		axiosInstance
			.post(
				'/administrator/disableAccount',
				{
					referenceData: props.ownerDetails.userId, //can pass either email or userId
					reason: message,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				if (res.data.error === 'disabled') {
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Account is already disabled',
							customColor: 'error',
						},
					}));
				} else {
					//sending notif to owner of disabled account
					socket.emit('user:notif', {
						userId: props.ownerDetails.userId,
					});

					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Successfully disabling the account.',
							customColor: 'success',
						},
					}));
					setMessage('');
					props.handleCloseModal();
				}
			});
	}

	return (
		<Dialog
			open={props.open}
			onClose={props.handleCloseModal}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description">
			{' '}
			<DialogTitle id="alert-dialog-title" style={{ color: 'red' }}>
				<Block /> Disabling account
			</DialogTitle>
			<DialogContent>
				<DialogContentText
					style={{ fontSize: '14px' }}
					id="alert-dialog-description">
					Disabled account will experience the following.
					<ul>
						<li>Unable to create new business listing.</li>
						<li>
							Existing business/es will be tagged as <b>disabled</b> and will
							not be viewable by from others
						</li>
					</ul>
					Are you sure you want to disable this account?
					<TextField
						fullWidth
						variant="outlined"
						label="Reason"
						name="reason"
						placeholder="Provide Reason"
						size="small"
						multiline
						rows={5}
						value={message}
						onChange={handleChange}
					/>
				</DialogContentText>
			</DialogContent>
			<DialogActions
				style={{
					justifyContent: 'space-between',
					padding: '0 25px 25px 25px',
				}}>
				<Button
					onClick={props.handleCloseModal}
					variant="outlined"
					color="default">
					Cancel
				</Button>
				<Button
					onClick={handleDisable}
					variant="outlined"
					color="secondary"
					autoFocus>
					Disable
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const AdminComplaint = () => {
	const generalClasses = generalStyle();
	const history = useHistory();
	const { userDataValue } = useContext(UserDataContext);
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const axiosInstance = AxiosConfigSecured();
	const params = useParams();
	const [reportDetails, setReportDetails] = useState();
	const [loading, setLoading] = useState(true);
	const [reportCounts, setReportCounts] = useState({
		totalForBiz: 0,
		totalForUser: 0,
		breakdownValues: [],
	});
	const [open, setOpen] = useState(false);
	const [ownerDetails, setOwnerDetails] = useState({ email: '', userId: '' });
	const { socket } = useContext(SocketContext);

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			axiosInstance
				.post('/account/getReportedAdsDetails', {
					reportId: params.id,
				})
				.then((res) => {
					if (res.data) {
						socket.emit('reportAds:updateCount');

						setReportDetails(res.data.reportDetails);
						setReportCounts({
							totalForBiz: res.data.numberOfComplaintForBiz,
							totalForUser: res.data.numberOfComplaintForOwner,
							breakdownValues: [...res.data.breakdownValues],
						});
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
						}));
						setOwnerDetails({
							email: res.data.ownerEmail,
							userId: res.data.ownerUserId,
						});
						setLoading(false);
					} else {
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
							snackBar: {
								...prevState.snackBar,
								customSnackbar: true,
								customMessage: 'Complaint details not existing.',
								customColor: 'error',
							},
						}));
						history.push('/account/adminPanel/UserAccountSetting');
					}
				});
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	function handleCloseModal() {
		setOpen(false);
	}

	function handleDelete() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState,
				color: 'error',
				status: true,
				title: 'Deleting Business Complaint',
				message: 'Are you sure you want to delete this complaint?',
				buttonName: 'Delete Complaint',
				payloadId: params.id,
			},
		}));
	}

	//listener once deleting task has been completed.
	useEffect(() => {
		if (pageDataValue.dialogBox.taskStatus) {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: pageDataDialogBoxCleanup,
			}));
			history.push('/account/adminPanel/UserAccountSetting');
		}
		//eslint-disable-next-line
	}, [pageDataValue.dialogBox.taskStatus]);

	return (
		<>
			<ActiveBreadcrumbs />

			<GlobalAlert />

			<Grid container spacing={3} className="mt-4">
				<Grid item lg={12} xs={12}>
					<Paper>
						<label className={generalClasses.paperTitle}>
							Complaint Details
						</label>
					</Paper>

					<Grid container justifyContent="space-between">
						<Grid item lg={2} xs={6}>
							<Button
								variant="outlined"
								color="inherit"
								onClick={() =>
									history.push('/account/adminPanel/UserAccountSetting/')
								}
								startIcon={<ArrowBack />}
								className="mt-4">
								Back
							</Button>
						</Grid>
						<Grid item lg={2} xs={6} style={{ textAlign: 'right' }}>
							<Button
								variant="outlined"
								color="secondary"
								onClick={handleDelete}
								startIcon={<DeleteForever />}
								className="mt-4">
								Delete
							</Button>
						</Grid>
					</Grid>

					<br />
					{!loading && (
						<Grid container spacing={3}>
							<Grid item lg={6} xs={12}>
								<CompliantDetailsDiv>
									<table>
										<tbody>
											<tr>
												<th>Email:</th>
												<td>{reportDetails.email}</td>
											</tr>
											<tr>
												<th>Complaint:</th>
												<td style={{ whiteSpace: 'pre-wrap' }}>
													{reportDetails.message}
												</td>
											</tr>
											<tr>
												<th>Date and Time: </th>
												<td>{`${getFullDate(
													reportDetails.date
												)} - ${currentTime(reportDetails.date)}`}</td>
											</tr>

											<tr>
												<th>Business Name:</th>
												<td>{reportDetails.bizName}</td>
											</tr>

											<tr>
												<th>Franchisor's Name:</th>
												<td>{reportDetails.bizOwner}</td>
											</tr>
										</tbody>
									</table>
								</CompliantDetailsDiv>
							</Grid>

							<Grid item lg={6} xs={12}>
								<ComplaintUserOverview>
									<b>
										<Business />
									</b>
									<label>
										Complaints for {reportDetails.bizName}: &nbsp;
										<span>{reportCounts.totalForBiz}</span>{' '}
									</label>
									<div style={{ clear: 'both' }} />
								</ComplaintUserOverview>

								<ComplaintUserOverview>
									<b>
										<AccountCircle />
									</b>
									<label>
										Complaints received by owner:{' '}
										<span>{reportCounts.totalForUser}</span>
									</label>

									<div style={{ clear: 'both' }} />
								</ComplaintUserOverview>

								<ComplaintUserOverview>
									<b>
										<AccountTree />
									</b>
									<label>
										Breakdown of Complaint from owned businesses
										<table>
											<tbody>
												{reportCounts.breakdownValues.map((data) => (
													<tr>
														<th>{data.bizName}</th>

														<td>: {data.total}</td>
													</tr>
												))}
											</tbody>
										</table>
									</label>

									<div style={{ clear: 'both' }} />
								</ComplaintUserOverview>

								<Button
									variant="contained"
									color="secondary"
									fullWidth
									size="large"
									startIcon={<Block />}
									onClick={() => setOpen(true)}>
									Disable this account
								</Button>

								<ReportModal
									open={open}
									ownerDetails={ownerDetails}
									handleCloseModal={handleCloseModal}
								/>
							</Grid>
						</Grid>
					)}

					{/* skeleton part */}
					{loading && (
						<Grid container spacing={3}>
							<Grid item lg={6} xs={12}>
								<CompliantDetailsDiv>
									<table>
										<tbody>
											<tr>
												<th>Email:</th>
												<td width={'auto'}>
													<Skeleton
														variant="rect"
														style={{
															height: '20px',
														}}
													/>
												</td>
											</tr>
											<tr>
												<th>Complaint:</th>
												<td>
													<Skeleton
														variant="rect"
														style={{
															height: '20px',
														}}
													/>
												</td>
											</tr>
											<tr>
												<th>Date and Time: </th>
												<td>
													<Skeleton
														variant="rect"
														style={{
															height: '20px',
														}}
													/>
												</td>
											</tr>

											<tr>
												<th>Business Name:</th>
												<td>
													<Skeleton
														variant="rect"
														style={{
															height: '20px',
														}}
													/>
												</td>
											</tr>

											<tr>
												<th>Franchisor's Name:</th>
												<td>
													<Skeleton
														variant="rect"
														style={{ height: '20px', width: '100%' }}
													/>
												</td>
											</tr>
										</tbody>
									</table>
								</CompliantDetailsDiv>
							</Grid>

							<Grid item lg={6} xs={12}>
								<ComplaintUserOverview>
									<b>
										<Business />
									</b>
									<label>
										Complaints for Jollibee Food Company:{' '}
										<span>
											<CircularProgress size={25} />
										</span>{' '}
									</label>
									<div style={{ clear: 'both' }} />
								</ComplaintUserOverview>

								<ComplaintUserOverview>
									<b>
										<AccountCircle />
									</b>
									<label>
										Complaints received by owner:{' '}
										<span>
											<CircularProgress size={25} />
										</span>
									</label>

									<div style={{ clear: 'both' }} />
								</ComplaintUserOverview>

								<ComplaintUserOverview>
									<b>
										<AccountTree />
									</b>
									<label>
										Breakdown of Complaint from owned businesses
										<table>
											<tbody>
												<tr>
													<th>
														<Skeleton variant="text" />
													</th>

													<td>
														: &nbsp;
														<CircularProgress size={20} />
													</td>
												</tr>
												<tr>
													<th>
														<Skeleton variant="text" />
													</th>
													<td>
														: &nbsp;
														<CircularProgress size={20} />
													</td>
												</tr>
												<tr>
													<th>
														<Skeleton variant="text" />
													</th>
													<td>
														: &nbsp;
														<CircularProgress size={20} />
													</td>
												</tr>
											</tbody>
										</table>
									</label>

									<div style={{ clear: 'both' }} />
								</ComplaintUserOverview>
							</Grid>
						</Grid>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default AdminComplaint;
