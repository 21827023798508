import { makeStyles } from '@material-ui/core';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	tabPanel: {
		padding: '0px',
		paddingTop: '25px',
	},
	//for loading effect when inquiry submitted
	wrapper: {
		margin: theme.spacing(1),
		position: 'relative',
	},
	buttonSuccess: {
		backgroundColor: '#003366',
	},
	fabProgress: {
		color: '#003366',
		position: 'absolute',
		top: -6,
		left: -6,
		zIndex: 1,
	},
	buttonProgress: {
		color: '#003366',
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -4,
		marginLeft: -12,
	},
	buttonDisabled: {
		backgroundColor: '#999',
		color: 'black',
		marginTop: '15px',
	},
	buttonActive: { backgroundColor: '#003366', marginTop: '15px' },
}));

export const AdsLogo = styled.div`
	display: block;
	height: 250px;
	width: 100%;
	text-align: center;
	overflow: hidden;
	& img {
		max-height: 100%;
		max-width: 100%;
	}
`;

export const PartitionTitle = styled.div`
	display: inline-block;
	padding-right: 35px;
	height: 10px;
	border-radius: 50px;
	background-color: #7cb7f2;
	margin-top: 20px;
	margin-bottom: 20px;
	& label {
		background-color: white;
		float: left;
		margin-top: -13px;
		margin-left: 35px;
		padding: 5px 15px 5px 15px;
		border-radius: 50px;
		font-weight: bold;
		color: black;
	}
`;

export const AdsDetailsDiv = styled.div`
	font-size: 14px;
	padding: 10px;
	text-align: justify;
	text-indent: 20px;
	white-space: pre-line;
	word-wrap: break-word;
`;

export const ContactDetails = styled.div`
	display: block;
	margin-top: 10px;
	margin-bottom: 10px;
	& svg {
		font-size: 30px;
		color: #7cb7f2;
		margin-right: 10px;
	}
`;

export const InfoLabel = styled.div`
	& span {
		font-size: 12px;
		font-weight: bold;
		& svg {
			margin-right: 5px;
		}
	}
	& label {
		margin-top: 5px;
		margin-left: 5px;
		font-size: 14px;
		display: block;
	}
`;

export const OtherImagesContainer = styled.div`
	display: block;
	padding: 30px 20px 30px 20px;
	// border: 1px solid #eee;
	// box-shadow: 5px 5px 6px -2px rgba(0, 0, 0, 0.64);
	// -webkit-box-shadow: 5px 5px 6px -2px rgba(0, 0, 0, 0.64);
	// -moz-box-shadow: 5px 5px 6px -2px rgba(0, 0, 0, 0.64);

	& .image-gallery {
		width: 100%;
	}
	& .image-gallery-slide img {
		height: 300px;
	}
	& .image-gallery-thumbnails img {
		max-height: 80px;
		max-width: 80px;
	}
	& .fullscreen .image-gallery-slide img {
		min-height: 100vh;
	}
`;

export const AdsTitle = styled.label`
	display: block;
	text-align: center;
	font-size: 30px;
	font-weight: bold;
	margin-top: 20px;
	word-wrap: break-word;
`;

export const TableDesign = styled.table`
	width: 100%;
	& tr:nth-child(odd) {
		background-color: #eeeeee;
	}

	& th {
		padding-top: 15px;
		padding-bottom: 15px;
		width: 50%;
		vertical-align: top;
		padding-left: 10px;
	}
	& td {
		padding-top: 15px;
		padding-bottom: 15px;
		word-wrap: break-word;
	}
`;

export const InquiryContainer = styled.div`
	display: block;
	border: 3px dashed #eee;
	border-radius: 10px;
	padding: 20px;
	& .inquiryTitle {
		display: block;
		font-weight: bold;
	}
`;

export const PaidAdsContainer = styled.div`
	width: 100%;
	height: 600px;
	background-color: #7cb7f2;
	display: block;
	margin-top: 50px;
`;
