import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const uniqueStyle = makeStyles((theme) => ({
	buttonApprove: {
		backgroundColor: 'green',
		color: 'white',
		'&:hover ': {
			backgroundColor: 'darkgreen',
		},
	},
	buttonDisapprove: {
		backgroundColor: 'red',
		color: 'white',
		'&:hover ': {
			backgroundColor: 'darkred',
		},
	},
}));

export const DisabledUserListCont = styled.div`
	display: block;
	height: 195px;
	width: 100%;
	border: 1px solid #bbb;
	border-radius: 5px;
	overflow-y: scroll;
	& label {
		display: block;
		padding: 5px 10px 5px 10px;
		cursor: pointer;
		&:hover {
			background-color: #cbe3fa;
		}
		&.selected {
			background-color: #cbe3fa;
		}
	}
`;

export const ComplaintListDiv = styled.div`
	max-height: 893px;
	overflow-y: auto;

	& table {
		width: 100%;

		& tr:nth-child(even) {
			background-color: #eee;
		}
		& tr th {
			width: 50px;
		}
		& tr td {
			padding: 10px 0 10px 0;
			cursor: pointer;
			& label {
				font-weight: bold;
				cursor: pointer;

				& span {
					font-size: 12px;
					color: #aaa;
					display: block;
					font-weight: normal;
					margin-bottom: 5px;
					cursor: pointer;
					& i {
						color: green;
						font-weight: bold;
						font-size: 14px;
						cursor: pointer;
					}
				}
			}
		}
	}
`;

export const EmptyComplaintListDiv = styled.div`
display:block
	height:150px;
	text-align:center;
	padding:50px 10px 50px 10px;
	color:#ccc;
	& label {
		font-size:30px;
		font-weight:bold;
		& span {
			margin-right:10px;
		}
		& span svg {
			font-size:50px;
		}
	}
`;

export const CompliantDetailsDiv = styled(Paper)`
	padding: 15px;
	& table {
		width: 100%;
	}

	& table tr:nth-child(even) {
		border-bottom: 1px solid #ddd;
		border-top: 1px solid #ddd;
	}
	& table th {
		padding: 15px 0 15px 0;
		width: 150px;
		vertical-align: top;
		text-align: right;
		padding-right: 15px;
	}
	& table td {
		vertical-align: top;
		text-align: justify;
		padding: 15px 0 15px 0;
	}
`;

export const ComplaintUserOverview = styled(Paper)`
	padding: 15px;
	border-left: 8px solid green;
	margin-bottom: 20px;

	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;

	& b svg {
		font-size: 30px;
		margin-right: 15px;
		color: #aaa;
		float: left;
	}
	& label {
		float: left;
		font-size: 20px;
		color: #aaa;
		& span {
			font-size: 25px;
			width: 80px;
			font-weight: bold;
			margin-right: 10px;
			color: black;
			display: block;
		}
		& table tr th {
			font-size: 14px;
			font-weight: normal;
			padding-right: 30px;
		}
		& table tr td {
			color: black;
			font-weight: bold;
		}
	}
`;

export const SelectedSettingCont = styled.div`
	margin-top: 5px;
	& button {
		float: left;
	}
	& label {
		float: left;
		margin-top: 15px;
	}
`;
