import { useContext } from 'react';
import { Snackbar, IconButton } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/alert';
import CloseIcon from '@material-ui/icons/Close';
import { PageDataContext } from 'back/context/pageData';

const CustomSnackbar = () => {
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const sbStatus = pageDataValue.snackBar.customSnackbar;

	const handleClose = () => {
		setPageDataValue((prevState) => ({
			...prevState,
			snackBar: { ...prevState.snackBar, customSnackbar: false },
		}));
	};

	const CloseBtn = () => {
		return (
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				style={{ marginLeft: '10px', marginTop: '-5px' }}
				onClick={handleClose}>
				<CloseIcon fontSize="small" />
			</IconButton>
		);
	};

	return (
		<>
			<Snackbar
				open={sbStatus}
				autoHideDuration={10000}
				onClose={handleClose}
				style={{ marginTop: '15px' }}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<MuiAlert
					elevation={6}
					variant="filled"
					severity={pageDataValue.snackBar.customColor}>
					{pageDataValue.snackBar.customMessage}
					<CloseBtn />
				</MuiAlert>
			</Snackbar>
		</>
	);
};

export default CustomSnackbar;
