import { io } from 'socket.io-client';
import { createContext } from 'react';

export const socketValue = io.connect(
	process.env.REACT_APP_BUILD_STATUS === 'DEV'
		? 'http://localhost:5000/'
		: 'https://backend.biztayo.ph/'
);

export const SocketContext = createContext();
