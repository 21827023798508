import React, { useState, useContext } from 'react';
import {
	Grid,
	OutlinedInput,
	Paper,
	FormControl,
	InputLabel,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
	InputAdornment,
} from '@material-ui/core';
import {
	LockRounded,
	Visibility,
	VisibilityOff,
	Close,
	Check,
} from '@material-ui/icons';
import { PasswordGuide, uniqueStyle } from './myProfileStyle';
import { useHistory, Link } from 'react-router-dom';
import { UserDataContext } from 'UniversalContext/userData';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { PageDataContext } from 'back/context/pageData';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';

const ProfileChangePass = () => {
	const classes = uniqueStyle();
	const history = useHistory();
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [rawUserData, setRawUserData] = useState({
		oldPass: '',
		newPass: '',
		retypePass: '',
	});
	const [showPass, setShowPass] = useState({
		oldPass: false,
		newPass: false,
		retypePass: false,
	});
	const [errorPass, setErrorPass] = useState({
		length: false,
		spelChar: false,
		num: false,
		match: false,
	});
	const [checkedBtnHandler, setCheckedBtnHandler] = useState(true);
	const axiosInstance = AxiosConfigSecured();
	const [oldPassStatus, setOldPassStatus] = useState(false);

	const passwordChecker = (data) => {
		const charRegEx = /[\W_]/;
		const numRegEx = /\d/;
		var checkLength = false;
		var checkSpelChar = false;
		var checkNum = false;

		// check password length
		checkLength = data.length > 8 ? true : false;

		// check if password contains special char
		checkSpelChar = charRegEx.test(data);

		// check if password contains num
		checkNum = numRegEx.test(data);

		return [checkLength, checkSpelChar, checkNum];
	};

	const handleChange = async (e) => {
		// for new password and retype password only
		if (e.target.name === 'newPass') {
			const [checkLength, checkSpelChar, checkNum] = passwordChecker(
				e.target.value
			);

			setErrorPass((prevState) => ({
				...prevState,
				length: checkLength,
				spelChar: checkSpelChar,
				num: checkNum,
			}));

			//check if password matched when change in newPass was made
			if (
				e.target.value !== rawUserData.retypePass &&
				rawUserData.retypePass.length !== 0
			) {
				setErrorPass((prevState) => ({ ...prevState, match: false }));
			} else {
				setErrorPass((prevState) => ({ ...prevState, match: true }));
			}
		}

		if (e.target.name === 'retypePass') {
			if (
				e.target.value !== rawUserData.newPass &&
				e.target.value.length !== 0
			) {
				setErrorPass((prevState) => ({
					...prevState,
					match: false,
				}));
			} else {
				setErrorPass((prevState) => ({
					...prevState,
					match: true,
				}));
			}
		}

		setRawUserData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	};

	const handleShowPass = (name) => {
		Object.entries(showPass).forEach((data) => {
			if (name === data[0]) {
				setShowPass((prevState) => ({
					...prevState,
					[name]: !data[1],
				}));
				return false;
			}
		});
	};

	const handleUpdate = () => {
		setOldPassStatus(false);
		setPageDataValue((prevState) => ({
			...prevState,
			backdrop: true,
		}));

		// validation if password requirement doesn't met
		//errCount > 1 if there is an error
		const errCount = Object.values(errorPass).filter((data) => data === false);
		if (errCount.length > 0) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: { ...prevState.snackBar, actionError: true },
				backdrop: false,
			}));
			return false;
		}

		// validation if old password is empty
		if (rawUserData.oldPass.length === 0) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: { ...prevState.snackBar, actionError: true },
				backdrop: false,
			}));
			setOldPassStatus(true);

			return false;
		}

		axiosInstance
			.post('user/changePass', { rawUserData }, { withCredentials: true })
			.then((res) => {
				if (res.data) {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: { ...prevState.snackBar, success: true },
						backdrop: false,
					}));

					history.push('/account/myProfile');
				} else {
					setOldPassStatus(true);

					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: { ...prevState.snackBar, actionError: true },
						backdrop: false,
					}));
				}
			});
	};

	const ShowPassLogic = (props) => {
		var showHolder = false;

		for (var [key, value] of Object.entries(showPass)) {
			if (key === props.name) {
				showHolder = value;
			}
		}
		return (
			<>
				{showHolder ? (
					<Visibility
						className="cursor-pointer"
						style={{ color: '#aaa' }}
						onClick={() => handleShowPass(props.name)}
					/>
				) : (
					<VisibilityOff
						className="cursor-pointer"
						style={{ color: '#aaa' }}
						onClick={() => handleShowPass(props.name)}
					/>
				)}
			</>
		);
	};

	return (
		<>
			<ActiveBreadcrumbs />

			<Grid container style={{ marginTop: '50px' }} justifyContent="center">
				<Grid item lg={5} xs={12}>
					<Paper>
						<center>
							<LockRounded className={classes.lockLogo} />
						</center>

						<label className={classes.updateTitle}>
							Change Account Password
						</label>

						<div className="p-3">
							<Grid container spacing={1}>
								<Grid item lg={12} xs={12}>
									<FormControl variant="outlined" style={{ width: '100%' }}>
										<InputLabel htmlFor="outlined-adornment-password">
											Email address
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="Email"
											name="email"
											value={userDataValue.UserInfo.email}
											disabled
										/>
									</FormControl>
								</Grid>
								<Grid item lg={12} xs={12}>
									<FormControl
										variant="outlined"
										style={{ width: '100%' }}
										className={oldPassStatus ? classes.errorTextbox : ''}>
										<InputLabel htmlFor="outlined-adornment-password">
											Old Password
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="oldPass"
											name="oldPass"
											onChange={handleChange}
											required
											value={rawUserData.oldPass}
											type={showPass.oldPass ? 'text' : 'password'}
											inputProps={{ maxLength: 40 }}
											endAdornment={
												<InputAdornment position="end">
													<ShowPassLogic name="oldPass" />
												</InputAdornment>
											}
										/>
									</FormControl>

									<PasswordGuide className="mt-3 title">
										Password requirement
									</PasswordGuide>
									<PasswordGuide>
										{!errorPass.length ? (
											<Close className="guideIconClose" />
										) : (
											<Check className="guideIconCheck" />
										)}
										Minimum of 9 Characters
									</PasswordGuide>
									<PasswordGuide>
										{!errorPass.num ? (
											<Close className="guideIconClose" />
										) : (
											<Check className="guideIconCheck" />
										)}
										Must have atleast one(1) number
									</PasswordGuide>
									<PasswordGuide>
										{!errorPass.spelChar ? (
											<Close className="guideIconClose" />
										) : (
											<Check className="guideIconCheck" />
										)}
										Must have atleast one(1) Special character
									</PasswordGuide>

									<FormControl variant="outlined" style={{ width: '100%' }}>
										<InputLabel htmlFor="outlined-adornment-password">
											New Password
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="newPass"
											name="newPass"
											type={showPass.newPass ? 'text' : 'password'}
											required
											onChange={handleChange}
											value={rawUserData.newPass}
											inputProps={{ maxLength: 40 }}
											endAdornment={
												<InputAdornment position="end">
													<ShowPassLogic name="newPass" />
												</InputAdornment>
											}
										/>
									</FormControl>

									<FormControl
										variant="outlined"
										style={{ width: '100%' }}
										className={
											errorPass.match === false &&
											rawUserData.newPass.length >= 8 &&
											rawUserData.retypePass.length !== 0
												? classes.errorTextbox
												: ''
										}>
										<InputLabel htmlFor="outlined-adornment-password">
											Re-try Password
										</InputLabel>
										<OutlinedInput
											variant="outlined"
											label="retypePass"
											name="retypePass"
											type={showPass.retypePass ? 'text' : 'password'}
											required
											disabled={rawUserData.newPass.length === 0 ? true : false}
											onChange={handleChange}
											value={rawUserData.retypePass}
											inputProps={{ maxLength: 40 }}
											endAdornment={
												<InputAdornment position="end">
													<ShowPassLogic name="retypePass" />
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
								<Grid item lg={12} xs={12} align="center" className=" mb-3">
									<FormGroup className="mt-2 mb-3">
										<center>
											<FormControlLabel
												control={
													<Checkbox
														checked={rawUserData.checkedBtn}
														color="primary"
														name="checkBtn"
														onChange={(event) =>
															setCheckedBtnHandler(!event.target.checked)
														}
													/>
												}
												label={
													<>
														I agree to{' '}
														<Link to="/bt/terms&Conditions" target="_blank">
															Terms and Conditions
														</Link>{' '}
														of this platform.
													</>
												}
											/>
										</center>
									</FormGroup>

									<Button
										variant="contained"
										size="large"
										onClick={() => history.push('/account/myProfile')}
										style={{ marginRight: '15px' }}>
										Cancel
									</Button>
									<Button
										variant="contained"
										size="large"
										color="primary"
										onClick={handleUpdate}
										disabled={checkedBtnHandler}>
										Update
									</Button>
								</Grid>
							</Grid>
						</div>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default ProfileChangePass;
