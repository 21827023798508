import React, { useState } from 'react';
import { Container } from 'react-bootstrap';

import { SearchKeyword } from 'front/components/search/searchKeyword';
import { SearchResult } from 'front/components/search/searchResult';
import FeaturedAds from 'front/components/featuredAds/featuredAds';
import PaidAds from 'front/components/paidAds/paidAds';

export const SearchBody = (props) => {
	const [businessListResult, setBusinessListResult] = useState([]);

	return (
		<React.Fragment>
			<div className="mainBody-bg"></div>
			<br />
			<div className="searchBody-bg">
				<Container>
					<SearchKeyword
						businessListResult={businessListResult}
						setBusinessListResult={setBusinessListResult}
					/>
					<div className="coveringWhiteDiv" />
				</Container>
			</div>

			<div className="searchResult-bg">
				<Container>
					<SearchResult businessListResult={businessListResult} />

					<PaidAds adsType="horizontal" />
				</Container>
			</div>
			<FeaturedAds />
		</React.Fragment>
	);
};
