import { makeStyles } from 'back/imports/materialUi.js';
import styled from 'styled-components';

export const generalStyle = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	content: {
		//if sidebar is displayed
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingBottom: '60px',
		width: `calc(100% - 240px)`,
		maxWidth: '2100px',
		margin: 'auto auto',
	},
	contentShift: {
		//if sidebar is minimized
		flexGrow: 1,
		padding: theme.spacing(3),
		paddingBottom: '60px',
		width: `calc(100% - 80px)`,
		maxWidth: '2100px',
		margin: 'auto auto',
	},
	paperTitle: {
		fontSize: '15px',
		padding: '15px 15px 10px 15px',
		display: 'block',
		width: '100%',
		fontWeight: 'bold',
	},
	paperContent: {
		fontSize: '15px',
		padding: '15px 15px 10px 15px',
	},
	gridContent: {
		paddingLeft: '15px',
		paddingRight: '15px',
	},

	/************************* configuration ************************ */
	center: {
		textAlign: 'center',
	},
	centerElement: {
		display: 'block',
		margin: 'auto auto',
	},
	right: {
		textAlign: 'right',
	},
	textFieldSpacing: {
		marginTop: '20px',
	},
	verticalAlignItems: {
		display: 'grid',
		alignContent: 'center',
		height: 'inherit',
	},
	horizontalAlignItems: {
		display: 'grid',
		justifyContent: 'center',
		height: 'inherit',
	},

	/************ SPACING ********/
	marginTop1: {
		marginTop: '1em',
	},
	marginTop2: {
		marginTop: '2em',
	},
	padding1: {
		padding: '1em !important',
	},
	padding2: {
		padding: '2em !important',
	},

	/************ TEXT ********/
	guidePhrase: {
		fontSize: '12px',
	},

	/********** backdropLoading*********/
	backdropLoading: {
		zIndex: 10000,
		background: 'red',
	},

	/************** error textbox ****************/

	errorTextbox: {
		//static red
		'& .MuiInputLabel-root': { color: 'red' },
		'& .MuiInputLabel-outlined.Mui-focused': {
			backgroundColor: 'red !important',
		},
		'& .MuiOutlinedInput-notchedOutline': {
			border: '1px solid red',
		},
		'& svg ': {
			color: 'red',
		},

		//focused red
		'& .MuiOutlinedInput-root.Mui-focused': {
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid red',
			},
		},

		//hover red
		'& :hover': {
			'& .MuiInputLabel-root': { color: 'red' },
			'& .MuiOutlinedInput-notchedOutline': {
				border: '1px solid red',
			},
		},
	},
}));

export const PaperTitleCont = styled.div`
	background-color: #3f6f9e;
	padding: 10px;
`;

export const PaperTitle = styled.div`
	color: white;
`;

export const PaperNavbar = styled.div`
	height: 60px;
	color: white;
	padding-left: 15px;
	padding-right: 15px;
	background-color: #3f6f9e;
	& .MuiButton-root {
		margin-top: 11px;
	}
`;

export const AnnouncementCont = styled.div`
	display: block;
	background-color: #eee;
	padding: 20px;
	font-size: 14px;
	position: relative;
	margin-bottom: 20px;
	& .anmt-logo {
		position: absolute;
		color: #ddd;
		z-index: 1;
		right: 0;
		margin-top: -40px;
		text-align: center;
		& svg {
			font-size: 100px;
			z-index: 1;
		}
	}
	& .Anmt-title {
		// position: relative;
		margin-bottom: 10px;
		z-index: 2;
		font-weight: bold;
	}
	& ul {
		position: relative;
		z-index: 2;
	}
`;
