import { Button, Grid, Paper } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';
import { generalStyle } from 'back/assets/style/generalStyle';
import { UserDataContext } from 'UniversalContext/userData';
import BusinessDisplayButton from '../businessDisplay/BusinessDisplayButton';
import BusinessDisplayDesc from '../businessDisplay/BusinessDisplayDesc';
import { AxiosConfigSecured } from 'config/axiosConfig';
import BusinessDisplayMoney from '../businessDisplay/BusinessDisplayMoney';
import BusinessDisplayImage from '../businessDisplay/BusinessDisplayImage';
import { uniqueStyle } from './AdministratorStyle';
import { ThumbDown, ThumbUp } from '@material-ui/icons';
import {
	PageDataContext,
	pageDataDialogBoxCleanup,
} from 'back/context/pageData';
import { useHistory } from 'react-router-dom';
import { SocketContext } from 'back/context/socket';

const AdminApproveAds = () => {
	const generalClasses = generalStyle();
	const classes = uniqueStyle();
	const { userDataValue } = useContext(UserDataContext);
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const [displayChoices, setDisplayChoices] = useState(1);
	const [businessValue, setBusinessValue] = useState(null);
	const axiosInstance = AxiosConfigSecured();
	const history = useHistory();
	const { socket } = useContext(SocketContext);

	function handleChangeDisplay(data) {
		setDisplayChoices(data);
	}

	function handleApprove() {
		axiosInstance
			.post(
				'/administrator/updateBusinessStatus',
				{
					bizId: businessValue.business._id,
					bizStatus: 'active',
					reason: null,
				},
				{ withCredentials: true }
			)
			.then((res) => {
				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
				//updating notif via socketIO
				socket.emit('user:notif', { userId: businessValue.businessOwnerId });

				if (res.data) {
					setBusinessValue({
						business: res.data.business,
						businessOwnerId: res.data.businessOwnerId,
					});
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							success: true,
						},
					}));
				} else {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customColor: 'warning',
							customMessage: 'No pending advertisement',
						},
					}));
					history.push('/account/adminPanel');
				}
			});
	}

	function handleDisapprove() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState.dialogBox,
				status: true,
				title: 'Rejecting Advertisement',
				message: `You are disapproving ${businessValue.business.name} to be displayed in our system`,
				buttonName: 'Disapprove',
				textboxName: 'Please provide reason',
				color: 'error',
				payloadId: businessValue.business._id,
			},
		}));
	}

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			if (userDataValue.UserInfo.permission !== 3) {
				history.push('/account/dashboard');
			}

			axiosInstance
				.get('/administrator/getAdsForApproval', { withCredentials: true })
				.then(async (res) => {
					if (res.data) {
						setBusinessValue({
							business: res.data.business,
							businessOwnerId: res.data.businessOwnerId,
						});
					} else {
						setPageDataValue((prevState) => ({
							...prevState,
							snackBar: {
								...prevState.snackBar,
								customSnackbar: true,
								customColor: 'warning',
								customMessage: 'No pending advertisement',
							},
						}));
						history.push('/account/adminPanel');
					}
				});
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	//listener if dialogBox is completed for disapproving ads
	useEffect(() => {
		if (pageDataValue.dialogBox.taskStatus) {
			if (pageDataValue.dialogBox.returnPayload) {
				setBusinessValue(pageDataValue.dialogBox.returnPayload);
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: { ...prevState.snackBar, success: true },
					dialogBox: pageDataDialogBoxCleanup,
				}));
			} else {
				setPageDataValue((prevState) => ({
					...prevState,
					snackBar: {
						...prevState.snackBar,
						customSnackbar: true,
						customColor: 'warning',
						customMessage: 'No pending advertisement',
					},
					dialogBox: pageDataDialogBoxCleanup,
				}));
				history.push('/account/adminPanel');
			}
		}
		//eslint-disable-next-line
	}, [pageDataValue.dialogBox.taskStatus]);

	return (
		<>
			<ActiveBreadcrumbs />

			<Grid container spacing={3} className="mt-4">
				<Grid item lg={12} xs={12}>
					<Paper>
						<label className={generalClasses.paperTitle}>
							Reviewing Advertisement
						</label>
					</Paper>

					<BusinessDisplayButton
						handleChangeDisplay={handleChangeDisplay}
						displayChoices={displayChoices}
					/>

					{businessValue?.business && displayChoices === 1 && (
						<BusinessDisplayDesc
							businessValue={businessValue.business}
							setBusinessValue={setBusinessValue}
						/>
					)}
					{businessValue?.business && displayChoices === 2 && (
						<BusinessDisplayMoney
							businessValue={businessValue.business}
							setBusinessValue={setBusinessValue}
						/>
					)}
					{businessValue?.business && displayChoices === 3 && (
						<BusinessDisplayImage
							businessValue={businessValue.business}
							setBusinessValue={setBusinessValue}
						/>
					)}
				</Grid>

				<Grid
					container
					spacing={3}
					justifyContent="center"
					className="mt-4 mb-5">
					<Grid item lg={2} md={6} xs={12}>
						<Button
							variant="contained"
							fullWidth
							size="large"
							onClick={handleApprove}
							className={classes.buttonApprove}>
							<ThumbUp /> &nbsp; Approve
						</Button>
					</Grid>
					<Grid item lg={2} md={6} xs={12}>
						<Button
							variant="contained"
							fullWidth
							size="large"
							onClick={handleDisapprove}
							className={classes.buttonDisapprove}>
							<ThumbDown /> &nbsp; Disapprove
						</Button>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default AdminApproveAds;
