import React, { useContext, useState } from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
} from '@material-ui/core';
import { uniqueClasses } from './DialogBoxStyle';
import {
	PageDataContext,
	pageDataDialogBoxCleanup,
} from 'back/context/pageData';
import { UserDataContext, clearNewBizData } from 'UniversalContext/userData';
import { BusinessListContext } from 'back/context/businessList';
import clsx from 'clsx';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { useHistory } from 'react-router-dom';
import { Error, WarningRounded } from '@material-ui/icons';
import { SocketContext } from 'back/context/socket';

const DialogBox = () => {
	const classes = uniqueClasses();
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const { setUserDataValue } = useContext(UserDataContext);
	const { setBusinessListValue } = useContext(BusinessListContext);
	const axiosInstance = AxiosConfigSecured();
	const history = useHistory();
	const [textboxValue, setTextboxValue] = useState('');
	const { socket } = useContext(SocketContext);

	const handleClose = () => {
		setTextboxValue('');
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: pageDataDialogBoxCleanup,
		}));
	};

	const handleMainButton = () => {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState.dialogBox,
				status: false,
			},
		}));

		// when button is intended for leaving page
		if (pageDataValue.dialogBox.buttonName === 'Leave') {
			const { payloadFunc, payloadData } = pageDataValue.dialogBox;

			setUserDataValue((prevState) => ({
				...prevState,
				...clearNewBizData(),
			}));
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: pageDataDialogBoxCleanup,
			}));

			payloadFunc(); //unblock() is stored
			history.push(payloadData);
		}

		// when button is intended for deleting business ads
		if (pageDataValue.dialogBox.buttonName === 'Delete') {
			axiosInstance
				.post(
					'/business/delete',
					{ bizId: pageDataValue.dialogBox.payloadId },
					{ withCredentials: true }
				)
				.then((res) => {
					setBusinessListValue(res.data.businessList);
					setUserDataValue((prevState) => ({
						...prevState,
						UserInfo: {
							...prevState.UserInfo,
							inquiry: res.data.inquiryList.reverse(),
						},
					}));
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: { ...prevState.snackBar, success: true },
					}));
					history.push('/account/businesses');
				});
		}

		// when button is intended for disapproving business ads
		if (pageDataValue.dialogBox.buttonName === 'Disapprove') {
			axiosInstance
				.post('/administrator/updateBusinessStatus', {
					bizId: pageDataValue.dialogBox.payloadId,
					bizStatus: 'rejected',
					reason: textboxValue,
				})
				.then((res) => {
					//notifying current active user for this action
					socket.emit('user:notif:unknown', {
						type: 'advertisement',
						payloadId: pageDataValue.dialogBox.payloadId,
					});

					//sending back the data from actual page,
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						dialogBox: {
							...prevState.dialogBox,
							taskStatus: true,
							returnPayload: res.data,
						},
					}));
					setTextboxValue('');
				});
		}

		// when button is intended for Deleting inquiries
		if (pageDataValue.dialogBox.buttonName === 'Delete inquiry') {
			axiosInstance
				.post('/account/inquiry/deleteInquiry', {
					inquiryId: pageDataValue.dialogBox.payloadId,
				})
				.then((res) => {
					setUserDataValue((prevState) => ({
						...prevState,
						UserInfo: { ...prevState.UserInfo, inquiry: res.data.reverse() },
					}));
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						dialogBox: {
							...prevState.dialogBox,
							taskStatus: true,
							returnPayload: {
								deleteIsArray: Array.isArray(pageDataValue.dialogBox.payloadId),
							},
						},
						snackBar: { ...prevState.snackBar, success: true },
					}));
				});
		}

		if (pageDataValue.dialogBox.buttonName === 'Delete Complaint') {
			axiosInstance
				.post(
					'/account/deleteComplaint',
					{ reportId: pageDataValue.dialogBox.payloadId },
					{ withCredentials: true }
				)
				.then((res) => {
					setPageDataValue((prevState) => ({
						...prevState,
						backdrop: false,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Successfully deleted complaint.',
							customColor: 'success',
						},
						dialogBox: {
							...prevState,
							taskStatus: true,
							returnPayload: res.data,
						},
					}));
				});
		}
	};

	return (
		<>
			<Dialog
				open={pageDataValue.dialogBox.status}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				className={clsx({
					[classes.errorDialogBox]: pageDataValue.dialogBox.color === 'error',
					[classes.warningDialogBox]:
						pageDataValue.dialogBox.color === 'warning',
				})}
				transitionDuration={{ enter: 300, exit: 0 }}>
				<DialogTitle>
					{pageDataValue.dialogBox.color === 'error' ? (
						<Error style={{ marginTop: '-3px', fontSize: '35px' }} />
					) : (
						<WarningRounded style={{ marginTop: '-3px', fontSize: '35px' }} />
					)}
					&nbsp;
					{pageDataValue.dialogBox.title}
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						<br />
						{pageDataValue.dialogBox.message}
					</DialogContentText>
					{pageDataValue.dialogBox.textboxName && (
						<TextField
							autoFocus
							margin="dense"
							label={pageDataValue.dialogBox.textboxName}
							name="textbox"
							type="text"
							value={textboxValue}
							fullWidth
							onChange={(e) => setTextboxValue(e.target.value)}
						/>
					)}
				</DialogContent>
				<DialogActions style={{ marginBottom: '20px' }}>
					<Button variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
					{pageDataValue.dialogBox.buttonName && (
						<Button
							className="mainButton"
							variant="outlined"
							onClick={handleMainButton}>
							{pageDataValue.dialogBox.buttonName}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		</>
	);
};

export default DialogBox;
