import { useContext, useEffect, useRef, useState } from 'react';
import { Paper, Grid, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import {
	LocationOn,
	VerifiedUser,
	ContactPhone,
	AccountCircle,
	Email,
	EditRounded,
	VpnKeyRounded,
} from '@material-ui/icons';

import {
	uniqueStyle,
	UserFullName,
	UserDetails,
} from 'back/components/myProfile/myProfileStyle';
import { UserDataContext } from 'UniversalContext/userData';
import { Skeleton } from '@material-ui/lab';
import { PageDataContext } from 'back/context/pageData';

const ProfileInfo = () => {
	const classes = uniqueStyle();
	const history = useHistory();
	const { setPageDataValue } = useContext(PageDataContext);
	const { userDataValue } = useContext(UserDataContext);
	const loading = useRef(userDataValue.UserInfo.accessToken ? false : true);
	const [, setCounterLoading] = useState(true);

	const handleUpdate = () => {
		if (userDataValue.UserInfo.platform !== 'local') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					title: 'Invalid Action',
					status: true,
					color: 'error',
					message: `Unable to proceed with your desired action. You are currently logged in using ${userDataValue.UserInfo.platform} account.`,
				},
			}));

			return false;
		}

		history.push('/account/myProfile/update');
	};

	const handleChangePass = () => {
		if (userDataValue.UserInfo.platform !== 'local') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					title: 'Invalid Action',
					status: true,
					color: 'error',
					message: `Unable to proceed with your desired action. You are currently logged in using ${userDataValue.UserInfo.platform} account.`,
				},
			}));

			return false;
		}

		history.push('/account/myProfile/changePass');
	};

	useEffect(() => {
		loading.current = false;
		setCounterLoading(false);
	}, []);

	return (
		<>
			<Grid container className={classes.topPaperBg}>
				<Grid item lg={3} xl={2} md={3} xs={12} align="center">
					<AccountCircle className={classes.userLogo} />
				</Grid>
				<Grid item lg={9} xl={10} md={9} xs={12}>
					<UserFullName>
						{loading.current ? (
							<Skeleton
								variant="rect"
								style={{ width: '100%', height: '50px' }}
							/>
						) : (
							userDataValue.UserInfo.fname + ' ' + userDataValue.UserInfo.lname
						)}
					</UserFullName>
				</Grid>
			</Grid>
			<Paper className={classes.paperBg}>
				<Grid container>
					<Grid item lg={2} xs={12}></Grid>
					<Grid item lg={10} xs={12} className="p-3">
						<UserDetails>
							<Grid container>
								<Grid item lg={6} xs={12}>
									<ContactPhone />{' '}
									<label>
										{loading.current ? (
											<Skeleton variant="text" style={{ width: '100%' }} />
										) : (
											<>
												{userDataValue.UserInfo.contactNum
													? '+63' + userDataValue.UserInfo.contactNum
													: 'N/a'}
											</>
										)}
									</label>
									<LocationOn />
									<label>
										{loading.current ? (
											<Skeleton variant="text" style={{ width: '100%' }} />
										) : (
											<>
												{userDataValue.UserInfo.address
													? userDataValue.UserInfo.address
													: 'N/a'}
											</>
										)}
									</label>
								</Grid>
								<Grid item lg={6} xs={12}>
									<Email />{' '}
									<label>
										{' '}
										{loading.current ? (
											<Skeleton variant="text" style={{ width: '100%' }} />
										) : (
											<>
												{userDataValue.UserInfo.email
													? userDataValue.UserInfo.email
													: 'N/a'}
											</>
										)}{' '}
									</label>
									<VerifiedUser />{' '}
									<label>
										{loading.current ? (
											<Skeleton variant="text" style={{ width: '100%' }} />
										) : (
											'Permission level ' + userDataValue.UserInfo.permission
										)}
									</label>
								</Grid>
							</Grid>
						</UserDetails>
					</Grid>
				</Grid>
			</Paper>

			<div className={classes.botPaperBg}>
				<Grid container>
					<Grid item lg={12} xs={12} align="right">
						<Button
							align="left"
							variant="outlined"
							onClick={handleUpdate}
							className={classes.button}
							startIcon={<EditRounded />}>
							Update profile
						</Button>

						<Button
							variant="outlined"
							className={classes.button}
							onClick={handleChangePass}
							startIcon={<VpnKeyRounded />}>
							Change Password
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	);
};

export default ProfileInfo;
