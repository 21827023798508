import { createContext, useState } from 'react';

export const UserDataContext = createContext();

export const clearUserDataValue = () => ({
	UserInfo: {
		accessToken: '',
		userID: '',
		fname: '',
		lname: '',
		address: 'N/a',
		email: '',
		contactNum: 'N/a',
		permission: 0,
		status: '',
		notif: [],
		platform: '',
	},
	NewBizData: {
		bizId: '',
		basicInfo: { requirements: [] },
		moneyTalk: {},
		images: [],
	},
	isLogin: true, // true,false,expired
});

export const clearNewBizData = () => ({
	NewBizData: {
		bizId: '',
		basicInfo: { requirements: [] },
		moneyTalk: {},
		images: [],
	},
});

const UserData = () => {
	const [userDataValue, setUserDataValue] = useState({
		UserInfo: {
			accessToken: '',
			userID: '',
			fname: '',
			lname: '',
			address: 'N/a',
			email: '',
			contactNum: 'N/a',
			permission: 0,
			status: '',
			notif: [],
			platform: '',
		},
		NewBizData: {
			bizId: '',
			basicInfo: { requirements: [] },
			moneyTalk: {},
			images: [],
		},
		isLogin: true, // true,false,expired
	});

	return [userDataValue, setUserDataValue];
};

export default UserData;
