import React, { useState, useContext, useEffect } from 'react';
import {
	Container,
	Form,
	Button,
	InputGroup,
	OverlayTrigger,
	Tooltip,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory, Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faLock,
	faEye,
	faEyeSlash,
	faCheck,
	faTimes,
	faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

import loginIcon from 'front/assets/images/loginIcon.png';

import 'front/assets/styles/loginPage.css';
import { CircularProgress } from '@material-ui/core';
import { CustomSnackbar } from 'back/components/snackbar';
import {
	PasswordGuideDiv,
	uniqueClasses,
} from 'front/assets/styles/loginStyle';
import clsx from 'clsx';
import _ from 'lodash';
import BackdropLoading from 'back/components/backdropLoading/backdropLoading';

const PasswordResetPage = (props) => {
	const history = useHistory();
	const params = useParams();
	const { setPageDataValue } = useContext(PageDataContext);
	const axiosInstance = AxiosConfigUnsecured();
	const [loading] = useState(false);
	const classes = uniqueClasses();
	const [showPassword, setShowPassword] = useState({
		pass: false,
		retype: false,
	});
	const [user, setUser] = useState({ password: '', retypePassword: '' });
	const [errorPass, setErrorPass] = useState({
		length: false,
		spelChar: false,
		num: false,
		match: false,
		null: true,
	});
	const [prId] = useState(params?.prId || null);

	useEffect(() => {
		setPageDataValue((prevState) => ({
			...prevState,
			backdrop: true,
		}));

		if (prId) {
			axiosInstance
				.post('/public/user/getResetPasswordStatus', { prId })
				.then((res) => {
					if (!res.data) {
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
							snackBar: {
								...prevState.snackBar,
								customMessage: 'Expired or invalid link detected.',
								customSnackbar: true,
								customColor: 'error',
							},
						}));
						history.push('/login');
					} else {
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
						}));
					}
				});
		} else {
			setPageDataValue((prevState) => ({
				...prevState,
				backdrop: false,
				snackBar: {
					...prevState.snackBar,
					customMessage: 'Expired or invalid link detected.',
					customSnackbar: true,
					customColor: 'error',
				},
			}));
			history.push('/login/');
		}
		//eslint-disable-next-line
	}, []);

	const passwordChecker = (data) => {
		const charRegEx = /[\W_]/;
		const numRegEx = /\d/;
		var checkLength = false;
		var checkSpelChar = false;
		var checkNum = false;

		// check password length
		checkLength = data.length > 8 ? true : false;

		// check if password contains special char
		checkSpelChar = charRegEx.test(data);

		// check if password contains num
		checkNum = numRegEx.test(data);

		return [checkLength, checkSpelChar, checkNum];
	};

	function handleChange(e) {
		setUser((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));

		if (e.target.name === 'password') {
			//checking for password requirement
			const [lengthRes, spelcharRes, numRes] = passwordChecker(e.target.value);

			setErrorPass({ length: lengthRes, spelChar: spelcharRes, num: numRes });

			//checking if password matched
			if (user.password.length >= 8 && user.retypePassword !== e.target.value) {
				setErrorPass((prevState) => ({ ...prevState, match: false }));
			} else {
				setErrorPass((prevState) => ({ ...prevState, match: true }));
			}
		}

		//validation for password
		if (e.target.name === 'retypePassword') {
			if (user.password !== null && user.password === e.target.value) {
				setErrorPass((prevState) => ({ ...prevState, match: true }));
			} else {
				setErrorPass((prevState) => ({ ...prevState, match: false }));
			}
		}
	}

	function handleResetPass(e) {
		e.preventDefault();
		if (_.includes(errorPass, false)) {
			setPageDataValue((prevState) => ({
				...prevState,
				backdrop: false,
				snackBar: {
					...prevState.snackBar,
					customMessage: 'Invalid password! Please check it again.',
					customSnackbar: true,
					customColor: 'error',
				},
			}));
		} else {
			setPageDataValue((prevState) => ({
				...prevState,
				backdrop: true,
			}));

			axiosInstance
				.post('/public/user/resetPassword', { ...user, prId })
				.then((res) => {
					if (res.data === 'inactive') {
						setPageDataValue((prevState) => ({
							...prevState,
							backdrop: false,
							snackBar: {
								...prevState.snackBar,
								customMessage: 'Expired or invalid link detected.',
								customSnackbar: true,
								customColor: 'error',
							},
						}));
						history.push('/login');
					} else {
						setPageDataValue((prevState) => ({
							...prevState,

							backdrop: false,
							snackBar: {
								...prevState.snackBar,
								customMessage: 'Password has been reset successfully',
								customSnackbar: true,
								customColor: 'success',
							},
						}));
						history.push('/login');
					}
				});
		}
	}

	return (
		<React.Fragment>
			<div className="login-page-bg"></div>
			<div className="loginBody-cont">
				<br />
				<center>
					<LinkContainer to="/">
						<div className="login-header">
							<label className="title-1 cursor-pointer">BIZ</label>
							<label className="title-2 cursor-pointer">TAYO</label>
							<label className="title-3 cursor-pointer">.PH</label>
						</div>
					</LinkContainer>
				</center>

				<div className="login-body">
					<img src={loginIcon} alt="login_img" />
					<Container>
						<Form onChange={handleChange} onSubmit={handleResetPass}>
							<label
								className="text-center d-block font-weight-bold mb-3 mt-2"
								style={{ fontWeight: 'bold' }}>
								Password Reset
							</label>

							<label style={{ fontSize: '14px', textAlign: 'justify' }}>
								Please provide your new desired password for your account.
							</label>
							<br />
							<br />
							<PasswordGuideDiv>
								<label className="title">Password requirements</label>
								<label className="list">
									{errorPass.length ? (
										<FontAwesomeIcon icon={faCheck} className="green" />
									) : (
										<FontAwesomeIcon icon={faTimes} className="red" />
									)}
									Minimum of 9 Characters
								</label>
								<label className="list">
									{errorPass.num ? (
										<FontAwesomeIcon icon={faCheck} className="green" />
									) : (
										<FontAwesomeIcon icon={faTimes} className="red" />
									)}
									Must have atleast one(1) number
								</label>
								<label className="list">
									{errorPass.spelChar ? (
										<FontAwesomeIcon icon={faCheck} className="green" />
									) : (
										<FontAwesomeIcon icon={faTimes} className="red" />
									)}
									Must have atleast one(1) Special character
								</label>
							</PasswordGuideDiv>

							<InputGroup>
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faLock} />
									</label>
								</InputGroup.Text>
								<FloatingLabel
									className="flex-grow-1"
									style={{ cursor: 'pointer' }}
									label="Password">
									<Form.Control
										name="password"
										placeholder="Password"
										aria-label="Password"
										aria-describedby="basic-addon1"
										type={showPassword.pass ? 'textbox' : 'password'}
									/>
								</FloatingLabel>
								<InputGroup.Text
									onClick={() =>
										setShowPassword((prevState) => ({
											...prevState,
											pass: !prevState.pass,
										}))
									}>
									<label style={{ cursor: 'pointer' }}>
										<FontAwesomeIcon
											icon={showPassword.pass ? faEyeSlash : faEye}
										/>
									</label>
								</InputGroup.Text>
							</InputGroup>

							<InputGroup
								className={clsx({
									[classes.errorBox]: !errorPass.match && user.retypePassword,
								})}>
								<InputGroup.Text>
									<label>
										<FontAwesomeIcon icon={faLock} />
									</label>
								</InputGroup.Text>
								<FloatingLabel
									className="flex-grow-1"
									style={{ cursor: 'pointer' }}
									label="Re-type password">
									<Form.Control
										name="retypePassword"
										placeholder="Re-type password"
										aria-label="Re-type password"
										aria-describedby="basic-addon1"
										type={showPassword.retype ? 'textbox' : 'password'}
									/>
								</FloatingLabel>{' '}
								<InputGroup.Text
									onClick={() =>
										setShowPassword((prevState) => ({
											...prevState,
											retype: !prevState.retype,
										}))
									}>
									<label style={{ cursor: 'pointer' }}>
										<FontAwesomeIcon
											icon={showPassword.retype ? faEyeSlash : faEye}
										/>
									</label>
								</InputGroup.Text>
								{user.retypePassword && !errorPass.match && (
									<InputGroup.Text>
										<OverlayTrigger
											key="top"
											placement="top"
											overlay={
												<Tooltip id={`tooltip-top`}>
													Password Didn't matched, Please try again.
												</Tooltip>
											}>
											<label>
												<FontAwesomeIcon icon={faExclamationCircle} />
											</label>
										</OverlayTrigger>
									</InputGroup.Text>
								)}
							</InputGroup>

							<div className="d-grid gap-2">
								<Button
									type="submit"
									className="mt-3"
									disabled={loading}
									style={{ backgroundColor: '#003366', position: 'relative' }}>
									Reset password
									{loading && (
										<CircularProgress
											size={24}
											thickness={5}
											style={{
												position: 'absolute',
												// top: '0%',
												left: '50%',
												color: 'white',
											}}
										/>
									)}
								</Button>
							</div>
							<label className=" d-block mt-2 text-left">
								<small>
									Back to{' '}
									<Link to="/login" style={{ textDecoration: 'none' }}>
										log-in
									</Link>
								</small>
							</label>
							<label className=" d-block text-left">
								<small>
									Don't have an account yet?{' '}
									<Link to="/register">
										<label
											className="text-primary font-weight-bold"
											style={{ cursor: 'pointer' }}>
											Sign-up now
										</label>
									</Link>
								</small>
							</label>
						</Form>
					</Container>
				</div>
				<br />
				<br />
			</div>

			<CustomSnackbar />
			<BackdropLoading />
		</React.Fragment>
	);
};

export default PasswordResetPage;
