import React, { useContext, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import LoginPage from 'front/views/login';
import RegisterPage from 'front/views/register';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import AdminPage from 'back/';
import { CookiesProvider } from 'react-cookie';
import { useCookies } from 'react-cookie';

import userContext, { UserDataContext } from './UniversalContext/userData';
import PageData, { PageDataContext } from 'back/context/pageData';
import FrontHome from 'front';
import { SocketContext, socketValue } from 'back/context/socket';
import ForgotPasswordPage from 'front/views/forgotPass';
import PasswordResetPage from 'front/views/passwordReset';
import VerifiedAlert from 'front/views/verifiedAlert';

const MainRoute = () => {
	const { userDataValue } = useContext(UserDataContext);
	const [cookies, setCookies] = useCookies(['tokenExpiration']);
	const currentTime = new Date().getTime() / 1000;
	const { socket } = useContext(SocketContext);

	const redirectToAdmin = (Component) => {
		if (
			userDataValue.UserInfo.accessToken ||
			cookies.tokenExpiration > currentTime
		) {
			return <Redirect to="/account/dashboard" />;
		}

		return <Component />;
	};

	const redirectToLogin = (Component) => {
		if (
			!userDataValue.UserInfo.accessToken &&
			(!cookies.tokenExpiration || cookies.tokenExpiration < currentTime)
		) {
			return <Redirect to="/login" />;
		}

		return <Component />;
	};

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			//saving business list in context
			socket.emit('user:addNew', { userId: userDataValue?.UserInfo.userID });

			socket.on('connect', function () {
				//enter here if connected
			});

			socket.io.on('reconnect_attempt', () => {
				//prevent the reconnection attempt
				socket.disconnect();

				//add userId to the query for user configuration in the server
				socket.io.opts.query = `userId=${userDataValue.UserInfo.userID}`;

				//reconnect from the server again
				socket.connect();
			});

			socket.on('disconnect', () => {
				//enter here if disconnected
			});
		} else {
			if (!cookies['visitorSocketSession']) {
				socket.emit('user:newVisitor');

				socket.on('user:newVisitor:response', ({ jwtRes }) => {
					setCookies('visitorSocketSession', jwtRes, {
						path: '/',
						sameSite: true,
						secure: true,
					});
				});
			} else {
				socket.emit('user:oldVisitor', {
					jwt: cookies['visitorSocketSession'],
				});
			}
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	return (
		<BrowserRouter>
			<Switch>
				{/* unable to use this route for other link due to its "exact attribute" */}
				<Route exact path="/" component={FrontHome} />

				{/* to accomodate all link inside FrontHome */}
				<Route path="/bt/" component={FrontHome} />

				{/* <Route exact path="/search" component={FrontHome} /> */}
				<Route path="/login" render={() => redirectToAdmin(LoginPage)} />
				<Route path="/register" render={() => redirectToAdmin(RegisterPage)} />
				<Route
					path="/forgotPassword"
					render={() => redirectToAdmin(ForgotPasswordPage)}
				/>
				<Route
					path="/passwordReset/:prId?"
					render={() => redirectToAdmin(PasswordResetPage)}
				/>

				<Route path="/account" render={() => redirectToLogin(AdminPage)} />
				<Route path="/verifyAccount/:userId" render={() => <VerifiedAlert />} />
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</BrowserRouter>
	);
};

// () => {
// 	return ? (
// 		<>
// 			<AdminPage />
// 		</>
// 	) : (
// 		<Redirect to="/login" />
// 	);
// }

// {
// 	/* <BrowserRouter>
// 			<Route exact path="/" component={HomePage} />
// 			<Route exact path="/search" component={SearchPage} />
// 			<Route exact path="/login" component={LoginPage} />
// 			<Route exact path="/register" component={RegisterPage} />

// 			<Route path="/admin" component={AdminPage} />
// 		</BrowserRouter> */
// }

const MainIndex = () => {
	const [userDataValue, setUserDataValue] = userContext();
	const [pageDataValue, setPageDataValue] = PageData();

	return (
		<CookiesProvider>
			<SocketContext.Provider value={{ socket: socketValue }}>
				<PageDataContext.Provider value={{ pageDataValue, setPageDataValue }}>
					<UserDataContext.Provider value={{ userDataValue, setUserDataValue }}>
						<MainRoute />
					</UserDataContext.Provider>
				</PageDataContext.Provider>
			</SocketContext.Provider>
		</CookiesProvider>
	);
};

ReactDOM.render(<MainIndex />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
