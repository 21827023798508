import styled from 'styled-components';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import { Button, Dialog } from '@material-ui/core';

export const BreadcrumbsContainer = styled.div`
	margin-top: 40px;
	display: block;
	& div {
		display: inline-block;
		background-color: #eee;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		overflow: hidden;
	}
	& label {
		padding: 5px 20px 5px 20px;
		border-top-right-radius: 50px;
		border-bottom-right-radius: 50px;
		// border-right: 3px solid white;

		box-shadow: 18px -1px 21px -5px rgba(0, 0, 0, 0.61);
		-webkit-box-shadow: 18px -1px 21px -5px rgba(0, 0, 0, 0.61);
		-moz-box-shadow: 18px -1px 21px -5px rgba(0, 0, 0, 0.61);
	}
	& span {
		margin-right: 5px;
		margin-left: 5px;
	}
`;

export const StyledBreadcrumb = withStyles((theme) => ({
	root: {
		backgroundColor: theme.palette.grey[100],
		height: theme.spacing(3),
		color: theme.palette.grey[800],
		fontWeight: theme.typography.fontWeightRegular,
		'&:hover, &:focus': {
			backgroundColor: theme.palette.grey[300],
		},
		'&:active': {
			boxShadow: theme.shadows[1],
			backgroundColor: emphasize(theme.palette.grey[300], 0.12),
		},
	},
}))(Chip);

export const ReportLabel = styled(Button)`
	display: block !important;
	text-align: right !important;
	color: red !important;
	margin-top: 40px !important;
	cursor: pointer !important;
	font-size:12px !important;
	& span {
		margin-right: 5px !important;
		& svg {
			margin-top:-3px !important;
			width: 15px !important;
		}
	
`;

export const DialogModal = styled(Dialog)`
	& .MuiDialogTitle-root {
		color: red;
		& svg {
			margin: -3px 5px 0 0;
		}
	}
	& .MuiDialogContent-root p {
		font-size: 14px;
	}
	& .MuiFormControl-root {
		margin-top: 30px !important;
	}
`;
