import { useState, memo, useContext, useEffect } from 'react';

import {
	Grid,
	MenuOpenIcon,
	Popover,
	FindInPageIcon,
	DeleteForeverIcon,
} from 'back/imports/materialUi';
import { NoteAdd, Menu } from '@material-ui/icons';
import {
	ImgContainer,
	ImgContainerBg,
	DetailsCont,
	DetailsDesc,
	DetailsFooter,
	DetailsFooterLeft,
	DetailsFooterRight,
	PopoverChoices,
	ButtonLearnMore,
} from 'back/components/AdsBox/AdsBoxStyle';
import { PageDataContext } from 'back/context/pageData';
import { UserDataContext } from 'UniversalContext/userData';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { Link, useHistory } from 'react-router-dom';
import Skeleton from '@material-ui/lab/Skeleton';
import { siteSupportEmail } from 'back/context/biztayoDetails';
import emptyAds_bg from 'front/assets/images/emptyAds_bg.jpg';
import { ImgDisplay } from 'front/components/imgDisplay/imgDisplay';

const AdsBoxPopper = (props) => {
	const [anchorEl, setAncorE1] = useState(null);
	const { setPageDataValue } = useContext(PageDataContext);
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const axiosInstance = AxiosConfigSecured();
	const history = useHistory();

	const handlePopper = (event) => {
		setAncorE1(event.currentTarget);
	};

	const handleClose = () => {
		setAncorE1(null);
	};

	const handleDelete = () => {
		setAncorE1(null);
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				payloadId: props.bizId,
				status: true,
				color: 'error',
				buttonName: 'Delete',
				message: `Are you sure you want to delete ${props.bizName}?`,
				title: 'Deleting Business Advertisement',
			},
		}));
	};

	function handleSeeMore() {
		history.push(`businesses/details/${props.bizId}`);
	}

	const handleFinishAds = () => {
		if (userDataValue.UserInfo.status === 'disabled') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Disabled account',
					message: `Please email ${siteSupportEmail} for reactivation of account.`,
				},
			}));
			setAncorE1(null);

			return false;
		}

		axiosInstance
			.post(
				'/business/getIncompleteDetails',
				{ bizId: props.bizId },
				{ withCredentials: true }
			)
			.then((res) => {
				const { _id, name, category, images } = res.data[0];

				setUserDataValue((prevState) => ({
					...prevState,
					NewBizData: {
						...prevState.NewBizData,
						bizId: _id,
						basicInfo: {
							...prevState.NewBizData.basicInfo,
							bizName: name,
							bizCategory: category,
						},
						images,
					},
				}));

				history.push('businesses/new');

				setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
			});
	};

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;
	return (
		<>
			<DetailsFooterRight onClick={handlePopper}>
				{open ? <MenuOpenIcon /> : <Menu />}
			</DetailsFooterRight>
			<Popover
				id={id}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}>
				{props.adsStatus.status === 'Incomplete' ? (
					<PopoverChoices onClick={handleFinishAds}>
						<span>
							<NoteAdd />
						</span>
						Finish Ads
					</PopoverChoices>
				) : (
					<PopoverChoices onClick={handleSeeMore}>
						<span>
							<FindInPageIcon />
						</span>
						See More
					</PopoverChoices>
				)}
				<PopoverChoices delete onClick={handleDelete}>
					<span>
						<DeleteForeverIcon />
					</span>
					Delete
				</PopoverChoices>
			</Popover>
		</>
	);
};

export const AdsBoxSkeleton = () => {
	return (
		<ImgContainerBg>
			<Grid container style={{ height: 'inherit' }}>
				<Grid item xs={3} style={{ padding: '15px' }}>
					<ImgContainer>
						<Skeleton
							variant="rect"
							style={{ width: '100%', height: '100%' }}
						/>
					</ImgContainer>
				</Grid>
				<Grid item xs={9} style={{ padding: '15px', paddingLeft: '0px' }}>
					<DetailsCont>
						<label className="title">
							<Skeleton variant="text" style={{ width: '100%' }} />
						</label>
						<br />

						<DetailsDesc>
							<Skeleton variant="text" style={{ width: '100%' }} />
							<Skeleton variant="text" style={{ width: '100%' }} />
						</DetailsDesc>
						<DetailsFooter>
							<DetailsFooterLeft>
								<Skeleton variant="text" style={{ width: '50%' }} />
							</DetailsFooterLeft>
						</DetailsFooter>
					</DetailsCont>
				</Grid>
			</Grid>
		</ImgContainerBg>
	);
};

const AdsBoxStatus = (props) => {
	const { userDataValue } = useContext(UserDataContext);
	var adsDetails;

	if (userDataValue.UserInfo.status === 'disabled') {
		adsDetails = { styleColor: 'red', adsStatus: 'Disabled' };
	} else {
		adsDetails = {
			styleColor: props.adsStatus.color,
			adsStatus: props.adsStatus.status,
		};
	}

	return (
		<>
			Status: &nbsp;
			<b style={{ color: adsDetails.styleColor }}>{adsDetails.adsStatus}</b>
		</>
	);
};

const AdsBox = (props) => {
	const [adsDetails, setAdsDetails] = useState(props);

	useEffect(() => {
		setAdsDetails(props);

		//eslint-disable-next-line
	}, [props]);

	return (
		<ImgContainerBg>
			<Grid container style={{ height: 'inherit' }}>
				<Grid item xs={5} md={4} lg={4} xl={3} style={{ padding: '15px' }}>
					<ImgContainer>
						{adsDetails?.adsStatus?.status === 'Incomplete' ? (
							<img src={adsDetails.image} alt="incomplete_Logo" />
						) : (
							<ImgDisplay
								imageId={adsDetails.image}
								skeletonComponent={{
									variant: 'rect',
									style: { width: '100%', height: '100%' },
								}}
							/>
						)}
					</ImgContainer>
				</Grid>
				<Grid
					item
					xs={7}
					md={8}
					lg={8}
					xl={9}
					style={{ padding: '15px', paddingLeft: '0px' }}>
					<DetailsCont>
						<label className="title">{adsDetails.title}</label>
						<br />

						<DetailsDesc>{adsDetails.description}</DetailsDesc>
						<DetailsFooter>
							<DetailsFooterLeft>
								{adsDetails.totalInvestment && (
									<>
										<label style={{ fontSize: '12px' }}>
											Initial Investment:
										</label>{' '}
										<span style={{ display: 'inline-block' }}>
											<b style={{ color: 'green' }}>
												{adsDetails.totalInvestment}
											</b>
										</span>
									</>
								)}

								{adsDetails.adsStatus && <AdsBoxStatus {...adsDetails} />}
							</DetailsFooterLeft>
							{adsDetails.showLearnMore && (
								<Link
									style={{ color: 'black' }}
									to={`/bt/show/search=${
										adsDetails.id
									}&&name=${encodeURIComponent(
										adsDetails.title
									)}&&keyword=&&investFrom=0&&investTo=0&&page=0&&category=All&&searchType=0`}
									target="_blank">
									<ButtonLearnMore
										variant="contained"
										color="inherit"
										size="small">
										See more
									</ButtonLearnMore>
								</Link>
							)}
							{adsDetails.showMenu && (
								<AdsBoxPopper
									bizId={adsDetails.bizId}
									bizName={adsDetails.title}
									adsStatus={adsDetails.adsStatus}
									image={adsDetails.image}
								/>
							)}
						</DetailsFooter>
					</DetailsCont>
				</Grid>
			</Grid>
		</ImgContainerBg>
	);
};

export const AdsBoxEmpty = () => {
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const history = useHistory();

	function handleAdsBoxEmptyRedirect() {
		if (userDataValue.UserInfo.permission === 1) {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Unverified account',
					message: `Please verify your account first before creating your business franchise advertisement.`,
				},
			}));

			return false;
		}

		if (userDataValue.UserInfo.status === 'disabled') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Disabled account',
					message: `Please email ${siteSupportEmail} for reactivation of account.`,
				},
			}));

			return false;
		}

		history.push('/account/businesses/new');
	}

	return (
		<>
			<div
				onClick={handleAdsBoxEmptyRedirect}
				style={{ textDecoration: 'none', color: 'black', cursor: 'pointer' }}>
				<ImgContainerBg>
					<Grid container style={{ height: 'inherit' }}>
						<Grid item xs={5} md={4} lg={4} xl={3} style={{ padding: '15px' }}>
							<ImgContainer>
								<img
									src={emptyAds_bg}
									alt={`emptyAds_bg`}
									style={{
										objectFit: 'cover',
										width: '100%',
										height: '100%',
										filter: 'blur(2px)',
									}}
								/>
								<div
									style={{
										height: 'inherit',
										width: '100%',
										position: 'absolute',
									}}>
									<label
										style={{
											display: 'inline-block',
											verticalAlign: 'middle',
											height: '100%',
										}}
									/>
									<label
										style={{
											display: 'inline-block',
											verticalAlign: 'middle',
											width: '80%',
											marginLeft: '10%',
											textAlign: 'center',
											padding: '10px 0',
											border: '1px solid white',
											color: 'white',
											background: 'rgba(0,0,0, .5)',
										}}>
										Available slot
									</label>
								</div>
							</ImgContainer>
						</Grid>
						<Grid
							item
							xs={7}
							md={8}
							lg={8}
							xl={9}
							style={{ padding: '15px', paddingLeft: '0px' }}>
							<DetailsCont>
								<label className="title">
									Possible slot for your franchise
								</label>
								<br />

								<DetailsDesc style={{ overflow: 'visible', textAlign: 'left' }}>
									This is <b style={{ color: 'green' }}>FREE.</b> No payment or
									Credit Card needed. <br />
									Post your advertisement now!!!! <br /> Click anywhere in this
									box
								</DetailsDesc>
								<DetailsFooter>
									<DetailsFooterLeft></DetailsFooterLeft>
								</DetailsFooter>
							</DetailsCont>
						</Grid>
					</Grid>
				</ImgContainerBg>
			</div>
		</>
	);
};

export default memo(AdsBox);
