import React, { useState, useContext, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import {
	BusinessTableStyle,
	DisabledHoverButton,
} from 'back/components/myBusinessTable/BusinessTableStyle';
import {
	Grid,
	TablePagination,
	Divider,
	Button,
	Paper,
	AddBoxIcon,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from 'back/imports/materialUi';

import { generalStyle } from 'back/imports/components';
import BusinessList from './BusinessList';
import { BusinessListContext } from 'back/context/businessList';
import { UserDataContext } from 'UniversalContext/userData';
import { PageDataContext } from 'back/context/pageData';
import BusinessListEmpty from './BusinessListEmpty';
import { AdsBoxSkeleton } from '../AdsBox/AdsBox';
import { siteSupportEmail } from 'back/context/biztayoDetails';

const BusinessTable = () => {
	const uniqueClasses = BusinessTableStyle();
	const generalClasses = generalStyle();
	const [showAdsStatus, setShowAdsStatus] = useState('ALL');
	const { businessListValue } = useContext(BusinessListContext);
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const history = useHistory();
	const [adsPerPage, setAdsPerPage] = useState(6);
	const [currentPage, setCurrentPage] = useState(0);
	const [, forRerender] = useState();
	//based in accessToken if populated meaning component doesn't opened via page refresh
	const loading = useRef(businessListValue.length ? false : true);

	const showStatusHandler = (e) => {
		const status = e.target.innerText;

		setShowAdsStatus(status);
		setCurrentPage(0);
		setAdsPerPage(6);
	};

	const handleChangeSelectAdsStats = (e) => {
		setShowAdsStatus(e.target.value);

		setCurrentPage(0);
		setAdsPerPage(6);
	};

	function handleChangeAdsPerPage(e) {
		setCurrentPage(0);
		setAdsPerPage(e.target.value);
	}

	function handleNewBusinessBtn() {
		if (userDataValue.UserInfo.status === 'disabled') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Disabled account',
					message: `Please email ${siteSupportEmail} for reactivation of account.`,
				},
			}));

			return false;
		}
		if (userDataValue.UserInfo.permission === 1) {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Unverified account',
					message: `Please verify your account first before creating your business franchise advertisement.`,
				},
			}));

			return false;
		}

		history.push('/account/businesses/new');
	}

	const filteredList = businessListValue.filter((data) => {
		if (showAdsStatus.toLowerCase() === 'all') {
			return true;
		}
		return data.status === showAdsStatus.toLowerCase();
	});

	let filteredListPerPage = filteredList.slice(
		currentPage * adsPerPage,
		adsPerPage * (currentPage + 1)
	);

	useEffect(() => {
		loading.current = false;
		forRerender('');

		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	return (
		<>
			<Paper>
				<label className={clsx(generalClasses.paperTitle)}>
					List of Businesses
				</label>
				<Divider />
				<Grid
					container
					justifyContent="space-between"
					className={uniqueClasses.SortContainer}>
					<Grid item lg={3} xl={2} xs={12} md={4} style={{ padding: '15px' }}>
						<Button
							variant="contained"
							size="large"
							color="primary"
							className={clsx(uniqueClasses.newBusinessBtn)}
							startIcon={<AddBoxIcon />}
							onClick={handleNewBusinessBtn}>
							New Business
						</Button>
					</Grid>
				</Grid>
				<Grid
					container
					style={{ padding: '15px' }}
					className={clsx(uniqueClasses.bgBlack, uniqueClasses.textWhite)}>
					<Grid item lg={12} md={12} xs={12}>
						<table style={{ width: '100%' }}>
							<tbody>
								<tr>
									<td style={{ width: '60px' }}>
										<label>Show: </label>
									</td>
									<td>
										{window.innerWidth <= 1000 ? (
											<div style={{ marginTop: '-10px' }}>
												<FormControl
													variant="outlined"
													color="inherit"
													style={{
														positino: 'relative',
													}}>
													<div
														style={{
															backgroundColor: 'white',
															position: 'absolute',
															width: '100%',
															height: '100%',
															marginTop: '-3px',
															borderRadius: '5px',
														}}
													/>
													<InputLabel
														id="demo-simple-select-outlined-label"
														style={{
															borderTopLeftRadius: '5px',
															borderTopRightRadius: '5px',
														}}>
														Status
													</InputLabel>
													<Select
														labelId="demo-simple-select-outlined-label"
														id="demo-simple-select-outlined"
														value={showAdsStatus}
														onChange={handleChangeSelectAdsStats}
														label="Status">
														<MenuItem value={'ALL'}>ALL</MenuItem>
														<MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
														<MenuItem value={'PENDING'}>PENDING</MenuItem>
														<MenuItem value={'EXPIRED'}>EXPIRED</MenuItem>
														<MenuItem value={'REJECTED'}>REJECTED</MenuItem>
														<MenuItem value={'INCOMPLETE'}>INCOMPLETE</MenuItem>
													</Select>
												</FormControl>
											</div>
										) : (
											<>
												<DisabledHoverButton
													variant="contained"
													active={
														showAdsStatus === 'ALL' || !showAdsStatus
															? '#115293'
															: null
													}
													onClick={showStatusHandler}>
													All
												</DisabledHoverButton>
												&nbsp;&nbsp;
												<DisabledHoverButton
													variant="contained"
													active={showAdsStatus === 'ACTIVE' ? 'green' : null}
													onClick={showStatusHandler}>
													Active
												</DisabledHoverButton>
												&nbsp;&nbsp;
												<DisabledHoverButton
													variant="contained"
													active={showAdsStatus === 'PENDING' ? 'orange' : null}
													onClick={showStatusHandler}>
													Pending
												</DisabledHoverButton>
												&nbsp;&nbsp;
												<DisabledHoverButton
													variant="contained"
													active={showAdsStatus === 'EXPIRED' ? 'red' : null}
													onClick={showStatusHandler}>
													Expired
												</DisabledHoverButton>
												&nbsp;&nbsp;
												<DisabledHoverButton
													variant="contained"
													active={showAdsStatus === 'REJECTED' ? 'red' : null}
													onClick={showStatusHandler}>
													Rejected
												</DisabledHoverButton>
												&nbsp;&nbsp;
												<DisabledHoverButton
													variant="contained"
													active={
														showAdsStatus === 'INCOMPLETE' ? 'gray' : null
													}
													onClick={showStatusHandler}>
													Incomplete
												</DisabledHoverButton>
											</>
										)}
									</td>
								</tr>
							</tbody>
						</table>
					</Grid>
				</Grid>
			</Paper>

			{loading.current ? (
				<>
					<br />
					<Grid container spacing={3}>
						{[...Array(6)].map((data, index) => {
							return (
								<Grid item lg={4} md={6} xs={12} key={'text' + index}>
									<AdsBoxSkeleton />
								</Grid>
							);
						})}
					</Grid>

					<br />
				</>
			) : (
				<>
					{businessListValue && (
						<BusinessList
							filteredList={filteredListPerPage}
							adsPerPage={adsPerPage}
							currentPage={currentPage}
						/>
					)}
					{!businessListValue && <BusinessListEmpty />}
				</>
			)}

			<Paper>
				<TablePagination
					component="div"
					rowsPerPageOptions={[6, 9, 12]}
					count={filteredList.length}
					page={currentPage}
					onPageChange={(e, newPage) => setCurrentPage(newPage)}
					rowsPerPage={adsPerPage}
					onRowsPerPageChange={handleChangeAdsPerPage}
					labelRowsPerPage="Ads per page"
				/>
			</Paper>
		</>
	);
};

export default BusinessTable;
