import styled from 'styled-components';
import { Button, makeStyles } from 'back/imports/materialUi';

export const ImgContainerBg = styled.div`
	border: 1px solid #eee;
	height: 220px;
	background-color: white;
	width: 100%;
`;

export const ImgContainer = styled.div`
	height: 100%;
	width: 100%;
	position: relative;
	& img {
		position: absolute;
		margin: auto auto;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		max-width: 100%;
		max-height: 100%;
	}
`;

export const DetailsCont = styled.div`
	width: 100%;
	height: 100%;
	background-color: #fafafa;
	padding: 10px;
	position: relative;
	overflow: hidden;
	& label.title {
		display: block;
		font-weight: bold;
		width: inherit;
		overflow: hidden;
		height: 40px;
		word-wrap: break-word;
	}
`;

export const DetailsDesc = styled.div`
	display: block;
	height: 60px;
	width: inherit;
	overflow: hidden;
	text-align: justify;
	height: 40px;
	word-wrap: break-word;
`;

export const DetailsFooter = styled.div`
	position: absolute;
	bottom: 5px;
	width: 100%;
`;

export const DetailsFooterLeft = styled.label`
	float: left;
	display: block;
	margin-top: 5px;
	width: 70%;
`;

export const DetailsFooterRight = styled.svg`
	float: right;
	margin-right: 20px;
	width: 30px;
	height: 30px;
	color: white;
	background-color: #115293;
	cursor: pointer;
	padding: 3px;
	border-radius: 5px;
	margin-bottom: 10px;
	&:hover {
		background-color: #196cbd;
	}
`;

export const PopoverChoices = styled.label`
	display: block;
	border-bottom: 1px solid #ccc;
	padding: 12px 15px 0px 15px;
	height: 50px;
	font-size: 16px;
	margin-bottom: 0px;
	cursor: pointer;
	color: ${(props) => props.delete && 'red'};
	&:hover {
		background-color: #ccc;
	}
	& span svg {
		margin-top: -3px;
		margin-right: 5px;
		width: 25px;
		height: 25px;
	}
`;

export const ButtonLearnMore = styled(Button)`
	display: block !important;
	float: right !important;
	margin-right: 20px !important;
	margin-bottom: 10px !important;
	border: 1px solid white !important;
	& span {
		font-size: 10px;
	}
`;

export const AdsBoxStyle = makeStyles((theme) => ({
	adminFeaturedAdsBox: {
		height: '200px',
	},
}));
