import { Button, Grid } from '@material-ui/core';
import { Description, MonetizationOn, PhotoLibrary } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import { uniqueStyle } from './BusinessDisplayStyle';

const BusinessDisplayButton = ({ displayChoices, handleChangeDisplay }) => {
	const classes = uniqueStyle();

	return (
		<Grid container spacing={3} className="mt-4">
			<Grid item lg={4} md={12} xs={12}>
				<Button
					variant="contained"
					// color="secondary"
					size="large"
					fullWidth
					className={clsx([
						{ [classes.activeButton]: displayChoices === 1 },
						classes.displayButton,
					])}
					// style={{ height: '60px', backgroundColor: 'green', color: 'white' }}
					onClick={(e) => handleChangeDisplay(1)}>
					<Description /> &nbsp; Business Information
				</Button>
			</Grid>
			<Grid item lg={4} md={12} xs={12}>
				<Button
					variant="contained"
					color="default"
					size="large"
					fullWidth
					className={clsx([
						{ [classes.activeButton]: displayChoices === 2 },
						classes.displayButton,
					])}
					onClick={(e) => handleChangeDisplay(2)}>
					<MonetizationOn /> &nbsp; Money Talk
				</Button>
			</Grid>
			<Grid item lg={4} md={12} xs={12}>
				<Button
					variant="contained"
					color="default"
					size="large"
					fullWidth
					className={clsx([
						{ [classes.activeButton]: displayChoices === 3 },
						classes.displayButton,
					])}
					onClick={(e) => handleChangeDisplay(3)}>
					<PhotoLibrary /> &nbsp; Photos
				</Button>
			</Grid>
		</Grid>
	);
};

export default BusinessDisplayButton;
