import About from './views/about';
import AdsDisplay from './views/adsDisplay';
import WhyFranchise from './views/franchiseGuide';
import { LandingPage } from './views/landingPage';
import Privacy from './views/privacy';
import { SearchBody } from './views/search';
import Terms from './views/terms';

export const routes = [
	{
		path: '/',
		component: LandingPage,
	},
	{
		path: '/bt',
		component: LandingPage,
	},
	{
		path: '/bt/search/',
		component: SearchBody,
	},
	{
		path: '/bt/WhyFranchise/',
		component: WhyFranchise,
	},
	{
		path: '/bt/aboutUs/',
		component: About,
	},
	{
		path: '/bt/terms&Conditions/',
		component: Terms,
	},

	{
		path: '/bt/privacyPolicy/',
		component: Privacy,
	},
	{
		path: '/bt/share/:id',
		component: AdsDisplay,
	},
	{
		path: '/bt/search/keyword=:keyword?&&investFrom=:investFrom?&&investTo=:investTo?&&page=:page?&&category=:category?&&searchType=:searchType?',
		component: SearchBody,
	},
	{
		path: '/bt/show/search=:id&&name=:name?&&keyword=:keyword?&&investFrom=:investFrom?&&investTo=:investTo?&&page=:page?&&category=:category&&searchType=:searchType?',
		component: AdsDisplay,
	},
];
