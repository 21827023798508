import { Divider, Grid, IconButton, Paper, Tooltip } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { generalStyle } from 'back/assets/style/generalStyle';
import { BusinessListContext } from 'back/context/businessList';
import { DeleteForever, Edit } from '@material-ui/icons';
import BusinessDisplayDesc, {
	BusinessDisplayDescSkeleton,
} from './BusinessDisplayDesc';
import BusinessDisplayImage from './BusinessDisplayImage';
import { StatusBox, uniqueStyle } from './BusinessDisplayStyle';
import BusinessDisplayMoney from './BusinessDisplayMoney';
import { AxiosConfigSecured } from 'config/axiosConfig';
import { UserDataContext } from 'UniversalContext/userData';
import { PageDataContext } from 'back/context/pageData';
import ActiveBreadcrumbs from '../Breadcrumbs/Breadcrumbs';
import BusinessDisplayButton from './BusinessDisplayButton';
import { Alert, AlertTitle } from '@material-ui/lab';
import { siteSupportEmail } from 'back/context/biztayoDetails';
import GlobalAlert from '../GlobalAlert/GlobalAlert';

const BusinessDisplay = () => {
	const generalClasses = generalStyle();
	const classes = uniqueStyle();
	const history = useHistory();
	const { id } = useParams();
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const { businessListValue, setBusinessListValue } =
		useContext(BusinessListContext);
	const [businessValue, setBusinessValue] = useState(null);
	const [displayChoices, setDisplayChoices] = useState(1);
	const axiosInstance = AxiosConfigSecured();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		//get business list on page refresh
		if (!businessListValue.length && userDataValue.UserInfo.accessToken) {
			axiosInstance
				.get('/business/getBusiness', { withCredentials: true })
				.then((res) => {
					const selectedBusiness = res.data.find((data) => data._id === id);
					!selectedBusiness && history.push('/account/businesses');

					setBusinessListValue(res.data);
				});
		}

		// set businessValue to selected business once businessListValue is populated
		if (businessListValue.length && userDataValue.UserInfo.accessToken) {
			setLoading(true);

			const selectedBusiness = businessListValue.find(
				(data) => data._id === id
			);
			!selectedBusiness && history.push('/account/businesses');

			setBusinessValue(selectedBusiness);
			setLoading(false);

			//remove backdrop if request for reapproval
			setPageDataValue((prevState) => ({ ...prevState, backdrop: false }));
		}

		//listen if business id was changed in url via clicking notif

		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken, businessListValue, id]);

	// useEffect(() => {
	// 	if (businessValue) {
	// 		setLoading(true);
	// 		setBusinessValue(null);
	// 	}
	// 	//eslint-disable-next-line
	// }, [id]);

	function handleChangeDisplay(val) {
		setDisplayChoices(val);
	}

	function handleDelete() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				payloadId: id,
				status: true,
				color: 'error',
				buttonName: 'Delete',
				message: `Are you sure you want to delete ${businessValue.name}?`,
				title: 'Deleting Business Advertisement',
			},
		}));
	}

	function handleUpdate() {
		if (userDataValue.UserInfo.status === 'disabled') {
			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: {
					...prevState.dialogBox,
					color: 'error',
					status: true,
					title: 'Disabled account',
					message: `Please email ${siteSupportEmail} for reactivation of account.`,
				},
			}));

			return false;
		}

		history.push(`/account/businesses/update/${displayChoices}/${id}`);
	}

	function getStatusBoxDetails() {
		var style, context, tooltip;
		if (userDataValue.UserInfo.status === 'disabled') {
			style = 'display_rejected';
			context = 'Account disabled';
			tooltip = 'Your account is disabled';
		} else if (businessValue.status === 'rejected') {
			style = 'display_rejected';
			context = 'Rejected by Admin';
			tooltip = businessValue.reason;
		} else if (businessValue.status === 'expired') {
			style = 'display_rejected';
			context = 'Expired Ads';
			tooltip = 'Ads posting time has expired';
		} else if (businessValue.status === 'active') {
			style = 'display_active';
			context = 'Active Ads';
			tooltip = 'Ads is viewable from franchisee';
		} else {
			style = 'display_pending';
			context = 'Pending for approval';
			tooltip = 'Waiting for the approval of admin';
		}

		return { style, context, tooltip };
	}

	function handleReapproval() {
		axiosInstance
			.post(
				'/business/requestReapproval',
				{ bizId: businessValue._id },
				{ withCredentials: true }
			)
			.then((res) => {
				setLoading(true);
				setBusinessValue(null);
				setBusinessListValue(res.data);
			});
	}

	return (
		<>
			<ActiveBreadcrumbs />
			<GlobalAlert />
			<Grid container style={{ marginTop: '20px' }}>
				<Grid item lg={12} xs={12}>
					<Paper>
						<label className={generalClasses.paperTitle}>
							Business Overview
						</label>
						<Divider />
					</Paper>
				</Grid>
			</Grid>
			{businessValue?.status === 'rejected' && (
				<Grid container spacing={3} className="mt-3">
					<Grid item lg={12} xs={12}>
						<Alert severity="error">
							<AlertTitle>Rejected</AlertTitle>
							{businessValue.reason}
							<br />
							<label
								style={{ fontSize: '12px', marginTop: '10px', color: '#555' }}>
								Note: Please update your details based on the comment of the
								Administrator, It will automatically tag as{' '}
								<b>Pending for approval</b> once updated.
							</label>
						</Alert>
					</Grid>
				</Grid>
			)}

			{!loading && businessValue?.status === 'expired' && (
				<Grid container spacing={3} className="mt-3">
					<Grid item lg={12} xs={12}>
						<Alert severity="warning">
							<AlertTitle>Expired</AlertTitle>
							This advertisement is not visible from the franchisee while it is
							in <b>Expired state</b>
							<br />
							<label
								style={{ fontSize: '12px', marginTop: '10px', color: '#555' }}>
								Note: For re-approval, Click{' '}
								<span
									onClick={handleReapproval}
									style={{
										textDecoration: 'underline',
										color: 'blue',
										fontWeight: 'bold',
										cursor: 'pointer',
									}}>
									Request for re-approval
								</span>
								, or update this advertisement, subject for review by the Admin
							</label>
						</Alert>
					</Grid>
				</Grid>
			)}
			<BusinessDisplayButton
				handleChangeDisplay={handleChangeDisplay}
				displayChoices={displayChoices}
			/>

			{!loading && (
				<Grid container spacing={3} justifyContent="space-between">
					<Grid item lg={2}>
						<Tooltip
							title={getStatusBoxDetails().tooltip}
							placement="bottom-start">
							<StatusBox className={getStatusBoxDetails().style}>
								{getStatusBoxDetails().context}
							</StatusBox>
						</Tooltip>
					</Grid>
					<Grid item lg={2} style={{ textAlign: 'right' }}>
						<div className={classes.actionContainer}>
							<Tooltip title="Update details">
								<IconButton
									component="span"
									className={classes.iconButtonUpdate}
									onClick={handleUpdate}>
									<Edit />
								</IconButton>
							</Tooltip>
							<Tooltip title="Delete Ads">
								<IconButton
									component="span"
									onClick={handleDelete}
									className={classes.iconButtonDelete}>
									<DeleteForever />
								</IconButton>
							</Tooltip>
						</div>
					</Grid>
				</Grid>
			)}
			{loading && <BusinessDisplayDescSkeleton />}
			{businessValue && displayChoices === 1 && (
				<BusinessDisplayDesc
					businessValue={businessValue}
					setBusinessValue={setBusinessValue}
				/>
			)}
			{businessValue && displayChoices === 3 && (
				<BusinessDisplayImage
					businessValue={businessValue}
					setBusinessValue={setBusinessValue}
				/>
			)}
			{businessValue && displayChoices === 2 && (
				<BusinessDisplayMoney
					businessValue={businessValue}
					setBusinessValue={setBusinessValue}
				/>
			)}
		</>
	);
};

export default BusinessDisplay;
