import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';

export const uniqueClasses = makeStyles({
	errorBox: {
		'& span': {
			borderBottom: ' 2px solid red !important',
			color: 'red',
		},
		'& input': {
			borderBottom: ' 2px solid red !important',
		},
	},
});

export const PasswordGuideDiv = styled.div`
	margin-top: 10px;
	margin-bottom: 10px;
	& .title {
		font-size: 15px;
		font-weight: bold;
	}
	& .list {
		font-size: 14px;
		color: #888;
		display: block;
		& svg {
			font-size: 14px;
			width: 20px;
			text-align: center;
			margin-right: 5px;
		}
		& svg.green {
			color: green;
		}
		& svg.red {
			color: red;
		}
	}
`;
