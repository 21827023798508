import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from 'back/imports/materialUi.js';
import { createTheme } from '@material-ui/core';
import {
	Navbar,
	SideBar,
	generalStyle,
	routes,
	SidebarStyle,
	UserDataContext,
	PageDataContext,
} from 'back/imports/components.js';
import { TokenVerify } from 'auth/TokenVerify';
import Footer from 'back/views/Footer';
import { BackdropDialog } from 'back/components/backdropDialog/backdropDialog';
import {
	ErrorConnectionBar,
	SuccessBar,
	ActionErrorBar,
	CustomSnackbar,
} from 'back/components/snackbar/';

import '@fontsource/roboto';
import 'back/assets/style/generalStyle.css';
import BackdropLoading from './components/backdropLoading/backdropLoading';
import { DialogBox } from './components';
import { AxiosConfigSecured } from 'config/axiosConfig';
import {
	BusinessListContext,
	BusinessListData,
} from 'back/context/businessList';
import clsx from 'clsx';
import { SocketContext } from './context/socket';
import { clearUserDataValue } from 'UniversalContext/userData';

const theme = createTheme({ palette: { primary: { main: '#003366' } } });

const ViewRoute = () => {
	return (
		<Switch>
			{routes.map((data, index) => {
				return data.title !== 'divider' ? (
					<Route
						exact
						path={data.path}
						key={data.path + index}
						component={data.component}
					/>
				) : null;
			})}
			<Route render={() => <Redirect to="/account/dashboard" />} />
		</Switch>
	);
};

function AdminHomeForContext() {
	const universalClasses = generalStyle();
	const sideBarClasses = SidebarStyle();
	const axiosInstance = AxiosConfigSecured();
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const { setBusinessListValue } = useContext(BusinessListContext);
	const { pageDataValue } = useContext(PageDataContext);
	const { socket } = useContext(SocketContext);

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			//saving business list in context
			axiosInstance
				.get('/business/getBusiness', { withCredentials: true })
				.then((res) => {
					setBusinessListValue(res.data);
				});
		}

		//once logout, all tabs will show backdropDialog if in account page,
		//it will also clear data in UserDataContext
		socket.on('user:logout', () => {
			setUserDataValue((prevState) => ({
				...clearUserDataValue(),
				isLogin: false,
			}));
		});

		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	//Verify if logged in
	const verifyRes =
		(!JSON.parse(process.env.REACT_APP_DISABLED_SECURITY) && TokenVerify()) ||
		false;

	if (verifyRes.disconnect) {
		return <Redirect to={'/login'} />;
	}

	return (
		<div className={universalClasses.root}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Footer />
				<Navbar />

				<SideBar />

				<main
					className={clsx({
						[universalClasses.content]: pageDataValue.drawerStatus,
						[universalClasses.contentShift]: !pageDataValue.drawerStatus,
					})}>
					<div className={sideBarClasses.toolbar} />

					<ViewRoute />
				</main>

				<ErrorConnectionBar />
				<SuccessBar />
				<ActionErrorBar />
				<CustomSnackbar />
				<BackdropDialog />
				<BackdropLoading />
				<DialogBox />
			</ThemeProvider>
		</div>
	);
}

const AdminHome = () => {
	const [businessListValue, setBusinessListValue] = BusinessListData();

	return (
		<>
			<BusinessListContext.Provider
				value={{ businessListValue, setBusinessListValue }}>
				<AdminHomeForContext />
			</BusinessListContext.Provider>
		</>
	);
};

export default AdminHome;
