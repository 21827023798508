import React, { useContext, useEffect, useState, useRef } from 'react';
import {
	Paper,
	Button,
	Checkbox,
	Grid,
	Select,
	MenuItem,
	FormControl,
	IconButton,
	TablePagination,
	Modal,
	Backdrop,
	Fade,
	InputLabel,
	Popover,
} from '@material-ui/core';
import {
	Comment,
	Delete,
	FilterList,
	MoreVert,
	Settings,
	SubdirectoryArrowRight,
} from '@material-ui/icons';
import { generalStyle, PaperNavbar } from 'back/assets/style/generalStyle';
import {
	BoxAction,
	FilterButton,
	FilterModal,
	InquiryTable,
	PaginationBox,
	uniqueStyle,
	CheckboxSettingPaper,
	CheckboxAll,
	FilterButtonDone,
	NoDataContainer,
	MoreVertSettingPaper,
} from './inquiryDisplayStyle';
import { UserDataContext } from 'UniversalContext/userData';
import { Skeleton } from '@material-ui/lab';
import { BusinessListContext } from 'back/context/businessList';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import {
	PageDataContext,
	pageDataDialogBoxCleanup,
} from 'back/context/pageData';
import clsx from 'clsx';

const FilterDisplay = (props) => {
	const classes = uniqueStyle();
	const generalClasses = generalStyle();
	const { businessListValue } = useContext(BusinessListContext);
	const [filterData, setFilterData] = useState({});
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);

	useEffect(() => {
		setFilterData(props.filterData);
	}, [props.filterData]);

	const handleFilterInquiries = () => {
		//can use inquiries or userDataValue.UserInfo.inquiry for this
		let clonedInquiries = _.cloneDeep(userDataValue.UserInfo.inquiry);
		let finalFilterInquiries;

		//config for Sort
		if (filterData.sort === 'Oldest to Newest') {
			clonedInquiries.reverse();
		}

		//config show
		if (filterData.show !== 'All') {
			finalFilterInquiries = clonedInquiries.filter(
				(inquiry) => inquiry.bizName === filterData.show
			);
		} else {
			finalFilterInquiries = clonedInquiries;
		}

		return finalFilterInquiries;
	};

	function handleChange(e) {
		setFilterData((prevState) => ({
			...prevState,
			[e.target.name]: e.target.value,
		}));
	}

	function handleButton() {
		//checking if there are something to show on selected filter
		if (handleFilterInquiries().length === 0) {
			setPageDataValue((prevState) => ({
				...prevState,
				snackBar: {
					...prevState.snackBar,
					customSnackbar: true,
					customMessage: 'No Inquiry to show on selected filter',
					customColor: 'warning',
				},
			}));

			return false;
		}

		props.setFilterData(filterData);
		props.setFilterModal(false);

		//cleanup if choose to change filter
		props.setChecked({});
		props.setCheckedAll(false);
		props.setSelectedInquiry([]);
		props.setPage(0);
	}

	return (
		<Modal
			aria-labelledby="transition-modal-title"
			aria-describedby="transition-modal-description"
			className={classes.modal}
			open={props.filterModal}
			onClose={() => props.setFilterModal(false)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}>
			<Grid container justifyContent="center" alignItems="center">
				<Grid item lg={6} xs={12}>
					<Fade in={props.filterModal}>
						<FilterModal>
							<label className="modalTitle">Filter Setting</label>

							<Grid container spacing={3}>
								<Grid item lg={5} xs={12}>
									<FormControl
										variant="outlined"
										className={generalClasses.textFieldSpacing}>
										<InputLabel>Sort By</InputLabel>
										<Select
											label="Sort By"
											id="businessCategerySelectID"
											value={filterData.sort}
											name="sort"
											onChange={handleChange}>
											<MenuItem value="Default">Default</MenuItem>
											<MenuItem value="Oldest to Newest">
												Oldest to Newest
											</MenuItem>
										</Select>
									</FormControl>
								</Grid>
								<Grid item lg={5} xs={12}>
									<FormControl
										variant="outlined"
										className={generalClasses.textFieldSpacing}>
										<InputLabel>Show Business</InputLabel>
										<Select
											label="Show Business"
											id="businessCategerySelectID"
											value={filterData.show}
											onChange={handleChange}
											name="show">
											<MenuItem value="All">All</MenuItem>
											{businessListValue.map((business, index) => (
												<MenuItem
													value={business.name}
													key={`businessFilter_${index}`}>
													{business.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
								<Grid item lg={2} xs={12}>
									<FilterButtonDone
										variant="contained"
										color="primary"
										onClick={handleButton}
										fullWidth>
										Show
									</FilterButtonDone>
								</Grid>
							</Grid>
						</FilterModal>
					</Fade>
				</Grid>
			</Grid>
		</Modal>
	);
};

const InquiryDisplay = () => {
	const generalClasses = generalStyle();
	const { userDataValue } = useContext(UserDataContext);
	const [inquiries, setInquiries] = useState([]);
	const [page, setPage] = useState(0);
	const loading = useRef(true);
	const [filterModal, setFilterModal] = useState(false);
	const history = useHistory();
	const [filterData, setFilterData] = useState({
		sort: 'Default',
		show: 'All',
	});
	const [checked, setChecked] = useState({});
	const [checkedAll, setCheckedAll] = useState(false);
	const [selectedInquiry, setSelectedInquiry] = useState([]);
	const [checkboxSettingOpen, setCheckboxSettingOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState('');
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const [moreVertSettingOpen, setMoreVertSettingOpen] = useState(false);
	const [deleteSingleInquiryId, setDeleteSingleInquiryId] = useState();

	const handleFilterInquiries = () => {
		//can use inquiries or userDataValue.UserInfo.inquiry for this
		let clonedInquiries = _.cloneDeep(userDataValue.UserInfo.inquiry);
		let finalFilterInquiries;

		//config for Sort
		if (filterData.sort === 'Oldest to Newest') {
			clonedInquiries.reverse();
		}

		//config show
		if (filterData.show !== 'All') {
			finalFilterInquiries = clonedInquiries.filter(
				(inquiry) => inquiry.bizName === filterData.show
			);
		} else {
			finalFilterInquiries = clonedInquiries;
		}

		return finalFilterInquiries;
	};

	const handleGetFilteredInquiriesId = (filteredInquiry, currentPage) => {
		const inquiryCurrentlyShowed = filteredInquiry.slice(
			currentPage * 5,
			(currentPage + 1) * 5
		);
		const inquiryCurrentlyShowedId = [];

		for (const inquiry of inquiryCurrentlyShowed) {
			inquiryCurrentlyShowedId.push(inquiry._id);
		}

		return inquiryCurrentlyShowedId;
	};

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			loading.current = false;
			setInquiries(userDataValue.UserInfo.inquiry);
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken, userDataValue.UserInfo.inquiry]);

	//this will detect if user deleted some inquiry
	useEffect(() => {
		// if (userDataValue.UserInfo.accessToken && inquiries.length) {
		if (pageDataValue.dialogBox.taskStatus) {
			if (pageDataValue.dialogBox.returnPayload.deleteIsArray) {
				//deleteIsArray variable from dialogBox is value of returnPayload
				//enter here if the deleted inquiries are deleted via multiple deletion
				setSelectedInquiry([]);
				setCheckboxSettingOpen(false);
				setCheckedAll(false);
			} else {
				//enter here if the deleted inquiriy is deleted via single deletion
				setSelectedInquiry((prevState) => {
					//config for check/uncheck of checkboxAll if all visible data are checked
					const inquiryCurrentlyShowedId = handleGetFilteredInquiriesId(
						handleFilterInquiries(),
						page
					);

					const filterRes = prevState.filter(
						(inquiry) => inquiry !== deleteSingleInquiryId
					);
					//check all filterRes(new selectedInquiry without the deleted single inquiry) if all inquiryCurrentlyShowedId are saved
					//if all inquiryCurrentlyShowedId are saved, checkboxAll must be true
					inquiryCurrentlyShowedId.every((id) => filterRes.indexOf(id) >= 0)
						? setCheckedAll(true)
						: setCheckedAll(false);

					return filterRes;
				});

				setMoreVertSettingOpen(false);
				setDeleteSingleInquiryId('');
			}

			//change to sort=default and show=all once filtered is 0
			handleFilterInquiries().length === 0 &&
				setFilterData({ sort: 'Default', show: 'All' });
			setPage(handleFilterInquiries().length < 5 ? 0 : page);

			setInquiries(userDataValue.UserInfo.inquiry);

			setPageDataValue((prevState) => ({
				...prevState,
				dialogBox: pageDataDialogBoxCleanup,
			}));
		}
		//eslint-disable-next-line
	}, [pageDataValue.dialogBox.taskStatus]);

	function shortingMessage(message) {
		const splittedMessage = message.split(' ');

		if (splittedMessage.length >= 7) {
			const shortenMessage = splittedMessage.slice(0, 7).join(' ');
			return shortenMessage;
		} else {
			const shortenMessage = splittedMessage.join(' ');
			return shortenMessage;
		}
	}

	function handleShowMessage(inquiryId) {
		history.push(`/account/inquiry/${inquiryId}`);
	}

	function handleChangeCheckbox(inquiryId) {
		const inquiryCurrentlyShowedId = handleGetFilteredInquiriesId(
			handleFilterInquiries(),
			page
		);

		//saving data for sending IDs in server
		if (!selectedInquiry.find((prevData) => prevData === inquiryId)) {
			//if no equal ID in selectedInquiry, add ID
			setSelectedInquiry((prevState) => {
				const updatedSelectedInquiry = [...prevState, inquiryId];

				//checking CheckboxAll if all is checked
				//checking done inside setSelectedInquiry() so we can use updatedSelectedInquiry array
				if (
					inquiryCurrentlyShowedId.every(
						(id) => updatedSelectedInquiry.indexOf(id) >= 0
					)
				) {
					setCheckedAll(true);
				}

				return updatedSelectedInquiry;
			});
		} else {
			//if ID exist in selectedInquiry, remove ID
			const filteredSelectedInquiry = selectedInquiry.filter(
				(prevData) => prevData !== inquiryId
			);

			//unchecking checkboxAll if one of checkboxes showed are unchecked
			if (
				!inquiryCurrentlyShowedId.every(
					(id) => filteredSelectedInquiry.indexOf(id) >= 0
				)
			) {
				setCheckedAll(false);
			}

			setSelectedInquiry(filteredSelectedInquiry);
		}

		//saving data for checkbox status
		setChecked((prevState) => ({
			...prevState,
			[inquiryId]: prevState[inquiryId] ? false : true,
		}));
	}

	function handleCheckboxAll() {
		const inquiryCurrentlyShowed = handleFilterInquiries().slice(
			page * 5,
			(page + 1) * 5
		);
		const idToPush = [];

		if (!checkedAll) {
			//enter here if checkbox All was checked
			for (const inquiry of inquiryCurrentlyShowed) {
				if (!selectedInquiry.find((prevData) => prevData === inquiry._id)) {
					idToPush.push(inquiry._id);
				}
			}

			setSelectedInquiry((prevState) => [...prevState, ...idToPush]);
			setChecked((prevState) => {
				for (const id of idToPush) {
					prevState[id] = true;
				}

				return prevState;
			});
		} else {
			//enter here if checkbox All was unchecked
			setSelectedInquiry((prevState) => {
				const newSelectedInquiry = selectedInquiry.filter((inquiryId) => {
					var match = false;
					for (const inquiry of inquiryCurrentlyShowed) {
						if (inquiry._id === inquiryId) {
							match = true;
						}
					}

					return !match;
				});

				return newSelectedInquiry;
			});

			setChecked((prevState) => {
				for (const inquiry of inquiryCurrentlyShowed) {
					prevState[inquiry._id] = false;
				}

				return prevState;
			});
		}

		setCheckedAll(!checkedAll);
	}

	function handleCheckboxSetting(e) {
		setAnchorEl(e.currentTarget);

		setCheckboxSettingOpen(!checkboxSettingOpen);
	}

	function handleChangePage(e, newPage) {
		const inquiryCurrentlyShowedId = handleGetFilteredInquiriesId(
			handleFilterInquiries(),
			newPage
		);

		setPage(newPage);
		if (
			inquiryCurrentlyShowedId.every((id) => selectedInquiry.indexOf(id) >= 0)
		) {
			setCheckedAll(true);
		} else {
			setCheckedAll(false);
		}
	}

	function handleMoreVertSetting(e, inquiryId) {
		setAnchorEl(e.currentTarget);

		setMoreVertSettingOpen(!moreVertSettingOpen);
		!moreVertSettingOpen && setDeleteSingleInquiryId(inquiryId);
	}

	function handleMultipleDelete() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState.dialogBox,
				status: true,
				color: 'error',
				title: 'Deleting inquiries',
				message: `Are you sure you want to delete ${selectedInquiry.length} selected inquiries?`,
				buttonName: 'Delete inquiry',
				payloadId: selectedInquiry,
			},
		}));
	}

	function handleSingleDelete() {
		setPageDataValue((prevState) => ({
			...prevState,
			dialogBox: {
				...prevState.dialogBox,
				status: true,
				color: 'error',
				title: 'Deleting inquiry',
				message: `Are you sure you want to delete this inquiry?`,
				buttonName: 'Delete inquiry',
				payloadId: deleteSingleInquiryId,
			},
		}));
	}

	return (
		<Paper style={{ paddingBottom: '20px' }}>
			<label className={generalClasses.paperTitle}>List of inquiries</label>

			<PaperNavbar>
				<CheckboxAll
					color="secondary"
					checked={checkedAll}
					onChange={handleCheckboxAll}
					disabled={loading.current || inquiries?.length === 0 ? true : false}
				/>

				<FilterButton
					variant="outlined"
					color="inherit"
					onClick={() => setFilterModal(true)}
					disabled={loading.current || inquiries?.length === 0 ? true : false}
					startIcon={<FilterList />}>
					Filter
				</FilterButton>
				<FilterDisplay
					filterModal={filterModal}
					setFilterModal={setFilterModal}
					filterData={filterData}
					//seding below setStates to FilterDisplay component for cleanup
					setFilterData={setFilterData}
					setChecked={setChecked}
					setCheckedAll={setCheckedAll}
					setSelectedInquiry={setSelectedInquiry}
					setPage={setPage}
				/>
			</PaperNavbar>

			{!loading.current && inquiries?.length !== 0 && (
				<>
					<InquiryTable>
						<tbody>
							{handleFilterInquiries()
								.slice(page * 5, (page + 1) * 5)
								.map((inquiry, index) => (
									<tr className={clsx({ active: checked[inquiry._id] })}>
										<th>
											<Checkbox
												color="secondary"
												checked={checked[inquiry._id] ? true : false}
												onChange={() => handleChangeCheckbox(inquiry._id)}
											/>
										</th>
										<td onClick={() => handleShowMessage(inquiry._id)}>
											<Grid container>
												<Grid item lg={6} xs={12}>
													<span>
														Short description{' '}
														{inquiry.status === 'new' && <i>New!</i>}
													</span>
													<label>{shortingMessage(inquiry.message)} ...</label>
												</Grid>
												<Grid item lg={4} xs={12}>
													<span>Business</span>
													<label>{inquiry.bizName}</label>
												</Grid>
												<Grid item lg={2} xs={12}>
													<span>Date</span>
													<label>{inquiry.date}</label>
												</Grid>
											</Grid>
										</td>
										<th>
											<center>
												{/* button for popover single inquiry deletion */}
												<IconButton
													onClick={(e) =>
														handleMoreVertSetting(e, inquiry._id)
													}>
													<MoreVert />
												</IconButton>
											</center>
										</th>
									</tr>
								))}
						</tbody>
					</InquiryTable>

					<BoxAction>
						<label>
							<SubdirectoryArrowRight />
						</label>
						<IconButton
							color="inherit"
							onClick={handleCheckboxSetting}
							disabled={selectedInquiry.length === 0 ? true : false}>
							<Settings />
						</IconButton>
						<Popover
							open={checkboxSettingOpen}
							anchorEl={anchorEl}
							onClose={handleCheckboxSetting}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left',
							}}>
							<CheckboxSettingPaper>
								<Button size="small" fullWidth onClick={handleMultipleDelete}>
									<label>
										<span>
											<Delete />
										</span>{' '}
										Delete Selected
									</label>
								</Button>
							</CheckboxSettingPaper>
						</Popover>
					</BoxAction>
					<PaginationBox>
						<TablePagination
							component="div"
							count={handleFilterInquiries().length}
							rowsPerPageOptions={[]}
							page={page}
							onPageChange={handleChangePage}
							rowsPerPage={5}
							labelRowsPerPage={false}
						/>
					</PaginationBox>
				</>
			)}

			{!loading.current && inquiries?.length === 0 && (
				<NoDataContainer>
					<label>
						<span>
							<Comment />
						</span>{' '}
						No inquiry as of the moment
					</label>
				</NoDataContainer>
			)}

			{loading.current && (
				<InquiryTable>
					<tbody>
						{[...Array(5)].map(() => (
							<tr>
								<th>
									<Checkbox color="secondary" disabled />
								</th>
								<td>
									<Grid container>
										<Grid item lg={6} xs={12}>
											<span>Short description</span>
											<label>
												<Skeleton variant="text" style={{ width: '90%' }} />
											</label>
										</Grid>
										<Grid item lg={4} xs={12}>
											<span>Business</span>
											<label>
												<Skeleton variant="text" style={{ width: '90%' }} />
											</label>
										</Grid>
										<Grid item lg={2} xs={12}>
											<span>Date</span>
											<label>
												<Skeleton variant="text" style={{ width: '90%' }} />
											</label>
										</Grid>
									</Grid>
								</td>
								<th>
									<center>
										<IconButton disabled>
											<MoreVert />
										</IconButton>
									</center>
								</th>
							</tr>
						))}
					</tbody>
				</InquiryTable>
			)}

			{/* Popover for single inquiry deletion */}
			<Popover
				open={moreVertSettingOpen}
				anchorEl={anchorEl}
				onClose={handleMoreVertSetting}
				anchorOrigin={{
					vertical: 'center',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}>
				<MoreVertSettingPaper>
					<Button size="small" fullWidth onClick={handleSingleDelete}>
						<label>
							<span>
								<Delete />
							</span>{' '}
							Delete
						</label>
					</Button>
				</MoreVertSettingPaper>
			</Popover>

			<div style={{ clear: 'both' }} />
		</Paper>
	);
};

export default InquiryDisplay;
