import styled from 'styled-components';
import { makeStyles } from '@material-ui/styles';

export const uniqueStyle = makeStyles({
	notifContainer: {
		maxHeight: '495px',
		overflowY: 'auto',
	},
	notifContainerNew: {
		'& a ': {
			backgroundColor: '#cbe3fa',
		},
	},
});

export const PopperDiv = styled.div`
	display: block;
	position: relative;
	z-index: 10;
	margin-top: 5px;
	border: 1px solid black;
	padding: 5px;
	background-color: white;
	width: 300px;
	margin-right: 5px;
	color: black;
	border-radius: 3px;
	box-shadow: -2px 2px 10px #555;
	overflow: hidden;

	& hr {
		margin: 0;
	}
	& .MuiListItemIcon-root {
		min-width: 40px;
	}
	& hr {
		clear: both;
	}
`;

export const PopperContent = styled.div`
	padding: 10px;
	overflow: hidden;
	word-wrap: break-word;
	& label {
		float: left;
		margin-left: 10px;
		width: 75%;
		font-size: 13px;
		cursor: pointer;
		color: black;
	}
	& span {
		display: block;
		font-size: 12px;
		color: #999;
		margin-top: 5px;
	}
	&:hover {
		background-color: #cbe3fa;
		cursor: pointer;
	}
	&.new {
		background-color: #dfebf7;
		&:hover {
			background-color: #cbe3fa;
		}
	}
`;

export const PopperIcon = styled.div`
	padding: 5px;
	background-color: ${(prop) => prop.color};
	color: white;
	border-radius: 100px;
	float: left;
	cursor: pointer;
`;

export const SeeMoreButton = styled.label`
	display: block;
	text-align: center;
	margin: 5px 0 5px 0;
	cursor: pointer;
	& a {
		text-decoration: none;
	}
`;

export const NoNotifLabel = styled.label`
	display: block;
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	font-style: italic;
	& span {
		display: block;
		color: #ccc;
		margin-bottom: 20px;
		& svg {
			font-size: 60px;
		}
	}
`;
