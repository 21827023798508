import { Grid, useTheme } from '@material-ui/core';
import { Person, Store } from '@material-ui/icons';
import React from 'react';
import InfoBox from '../InfoBox/InfoBox';

const AdminPanel = ({ panelValues, loading }) => {
	const theme = useTheme();

	return (
		<>
			<Grid container spacing={3} className="mt-3">
				<Grid item lg={3} md={6} sm={6} xs={12} className="mt-4">
					<InfoBox
						myIcon={<Store />}
						loading={loading.current}
						title="Total Active Ads"
						content={panelValues.activeAds}
						subContent=" "
						footer="&nbsp;"
						link=" "
						firstColor="green"
						secondColor="darkgreen"
					/>
				</Grid>
				<Grid item lg={3} md={6} xs={12} sm={6} className="mt-4">
					<InfoBox
						myIcon={<Store />}
						loading={loading.current}
						title="Ads For Approval"
						content={panelValues.pendingAds}
						subContent=" "
						footer="Start approving now"
						link="/admin/dashboard"
						firstColor={theme.palette.primary.main}
						secondColor={theme.palette.primary.dark}
					/>
				</Grid>

				<Grid item lg={3} md={6} xs={12} sm={6} className="mt-4">
					<InfoBox
						myIcon={<Person />}
						loading={loading.current}
						title="Active Accounts"
						content={panelValues.activeAcc}
						subContent=" "
						footer=" &nbsp;"
						link=" "
						firstColor={theme.palette.info.main}
						secondColor={theme.palette.info.dark}
					/>
				</Grid>
				<Grid item lg={3} md={6} xs={12} sm={6} className="mt-4">
					<InfoBox
						myIcon={<Person />}
						loading={loading.current}
						title="Disabled Accounts"
						content={panelValues.disabledAcc}
						subContent=" "
						footer="See list"
						link=" "
						firstColor={theme.palette.error.main}
						secondColor={theme.palette.error.dark}
					/>
				</Grid>
			</Grid>
		</>
	);
};

export default AdminPanel;
