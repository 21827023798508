import React, { useContext } from 'react';
import { Backdrop, LinearProgress } from '@material-ui/core';
import { generalStyle } from 'back/imports/components';
import { PageDataContext } from 'back/context/pageData';

const BackdropLoading = () => {
	const generalClasses = generalStyle();
	const { pageDataValue } = useContext(PageDataContext);
	return (
		<>
			<Backdrop style={{ zIndex: 10000 }} open={pageDataValue.backdrop}>
				<div className={generalClasses.center}>
					<label
						style={{
							color: 'white',
							fontSize: '25px',
							display: 'block',
							background: '#003366',
							padding: '10px 20px 20px 20px ',
							borderRadius: '10px',
						}}>
						Processing data...
						<br />
						<LinearProgress style={{ color: '#003366' }} />
					</label>
				</div>
			</Backdrop>
		</>
	);
};

export default BackdropLoading;
