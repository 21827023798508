import React, { useContext, useEffect } from 'react';
import { Container, Button } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import { UserDataContext } from '../../UniversalContext/userData';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';

import loginIcon from 'front/assets/images/loginIcon.png';

import 'front/assets/styles/loginPage.css';
import { CustomSnackbar } from 'back/components/snackbar';

import { useCookies } from 'react-cookie';
import { VerifiedUser } from '@material-ui/icons';
import biztayologo from 'front/assets/images/biztayo_fb.png';

const VerifiedAlert = (props) => {
	const history = useHistory();
	const { userDataValue } = useContext(UserDataContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const axiosInstance = AxiosConfigUnsecured();
	const params = useParams();
	const [cookies] = useCookies(['tokenExpiration']);
	const currentTime = new Date().getTime() / 1000;

	useEffect(() => {
		axiosInstance
			.get(`/user/verifyLink/${encodeURIComponent(params.userId)}`)
			.then((res) => {
				if (!res.data) {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: {
							...prevState.snackBar,
							customSnackbar: true,
							customMessage: 'Invalid URL detected',
							customColor: 'error',
						},
					}));
					history.push('/login');
				}
			});
		//eslint-disable-next-line
	}, []);

	return (
		<React.Fragment>
			<div className="login-page-bg"></div>
			<div className="loginBody-cont">
				<br />
				<center>
					<LinkContainer to="/">
						<div className="login-header">
							<img src={biztayologo} alt="biztayo_logo" />
							<label className="title-1 cursor-pointer">BIZTAYO</label>
							<label className="title-3 cursor-pointer">.PH</label>
						</div>
					</LinkContainer>
				</center>

				<div className="login-body">
					<img src={loginIcon} alt="login_img" />
					<Container>
						<label
							className="text-center d-block font-weight-bold mt-2"
							style={{ fontWeight: 'bold' }}>
							Welcome to Biztayo.ph
						</label>
						<div className="d-grid gap-2">
							<br />
							<span style={{ display: 'block', textAlign: 'center' }}>
								<VerifiedUser style={{ fontSize: '100px', color: 'green' }} />
							</span>
							<br />

							<label
								style={{
									display: 'block',
									textAlign: 'center',
									color: 'green',
									fontWeight: 'bold',
								}}>
								Your account is now verified.
							</label>
							<br />
							<p style={{ textAlign: 'center' }}>
								Your account now have the permission to post business franchise
								advertisements in our system.
							</p>

							{userDataValue.UserInfo.accessToken ||
							cookies.tokenExpiration > currentTime ? (
								<a href="/account/dashboard" style={{ display: 'block' }}>
									<Button
										type="submit"
										className="mt-3"
										style={{
											backgroundColor: '#003366',
											position: 'relative',
											width: '100%',
										}}>
										Proceed to Dashboard
									</Button>
								</a>
							) : (
								<a href="/login" style={{ display: 'block' }}>
									<Button
										type="submit"
										className="mt-3"
										style={{
											backgroundColor: '#003366',
											position: 'relative',
											width: '100%',
										}}>
										back to sign-in page
									</Button>
								</a>
							)}
						</div>
					</Container>
				</div>
				<br />
				<br />
			</div>

			<CustomSnackbar />
		</React.Fragment>
	);
};

export default VerifiedAlert;
