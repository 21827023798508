import 'front/assets/styles/searchPage.css';
import 'bootstrap/dist/css/bootstrap.css';

import React from 'react';
import FooterBody from './components/footer/Footer';
import { NavbarFont } from 'front/components/navbar/Navbar';
import CustomSnackbar from 'back/components/snackbar/customDetails';

import { Redirect, Route, Switch } from 'react-router-dom';
import { routes } from './routes';
import { FrontBusinessContext, BusinessValueHolder } from './context/business';
import BackdropLoading from 'back/components/backdropLoading/backdropLoading';
import { SearchContext, SearchContextValue } from './context/searchContext';

const ViewLink = () => {
	return (
		<>
			<Switch>
				{routes.map((route, index) => {
					return (
						<Route
							exact
							path={route.path}
							key={`route_${index}`}
							component={route.component}
						/>
					);
				})}

				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</>
	);
};

const FrontHome = () => {
	const { frontBusinessListValue, setFrontBusinessListValue } =
		BusinessValueHolder();
	const { searchDataValue, setSearchDataValue } = SearchContextValue();

	return (
		<>
			<SearchContext.Provider value={{ searchDataValue, setSearchDataValue }}>
				<FrontBusinessContext.Provider
					value={{ frontBusinessListValue, setFrontBusinessListValue }}>
					<NavbarFont />
					<div className="mainBody">
						<ViewLink />

						<FooterBody />
					</div>
					<CustomSnackbar />
					<BackdropLoading />
				</FrontBusinessContext.Provider>
			</SearchContext.Provider>
		</>
	);
};

export default FrontHome;
