import React from 'react';
import styled from 'styled-components';
import { maxLengthHandler } from 'back/context/pageData';

const PhraseGuide = styled.label`
	display: block;
	text-align: right;
	font-size: 12px;
	padding-top: 5px;
	color: #888;
`;

const PhraseGuideError = styled.label`
	display: block;
	text-align: right;
	font-size: 12px;
	padding-top: 5px;
	color: red;
`;

const MaxLengthDisplay = ({ boxName, valueLength }) => {
	return (
		<>
			{valueLength > maxLengthHandler[[boxName]] ? (
				<PhraseGuideError>
					Exceeded character limit({maxLengthHandler[[boxName]]})
				</PhraseGuideError>
			) : (
				<PhraseGuide>
					{maxLengthHandler[[boxName]] - valueLength} Chars remaining
				</PhraseGuide>
			)}
		</>
	);
};

export default MaxLengthDisplay;

// sample:
// <MaxLengthDisplay boxName={name} valueLength={textboxValue.length} />
