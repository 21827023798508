import { Grid, Paper } from '@material-ui/core';
import { HandlePesoRender } from 'back/MicroComponents/numbersFormat';
import React from 'react';
import {
	GuideContainer,
	PaperDescHeader,
	PaperDescValue,
	uniqueStyle,
} from './BusinessDisplayStyle';

const BusinessDisplayMoney = ({ businessValue }) => {
	const uniqueClasses = uniqueStyle();
	return (
		<>
			<Grid container className="mt-5" spacing={3}>
				<Grid item lg={12}>
					<Paper className={uniqueClasses.parentPaper}>
						<Grid
							container
							className="mt-5 mb-5"
							justifyContent="center"
							spacing={3}>
							<Grid item lg={4} xs={12}>
								<PaperDescHeader>Franchise Term:</PaperDescHeader>
								<PaperDescValue>
									{businessValue.franchiseTerm
										? businessValue.franchiseTerm + ' Year/s'
										: 'N/a'}
								</PaperDescValue>

								<PaperDescHeader>Initial investment:</PaperDescHeader>
								<PaperDescValue>
									{businessValue.initialInvest ? (
										<HandlePesoRender value={businessValue.initialInvest} />
									) : (
										'N/a'
									)}
								</PaperDescValue>
								<PaperDescHeader>Franchise fee:</PaperDescHeader>
								<PaperDescValue>
									{businessValue.franchiseFee ? (
										<HandlePesoRender value={businessValue.franchiseFee} />
									) : (
										'N/a'
									)}
								</PaperDescValue>
								<PaperDescHeader>Marketing fee:</PaperDescHeader>
								<PaperDescValue>
									{businessValue.marketingFee
										? businessValue.marketingFee + ' %'
										: 'N/a'}
								</PaperDescValue>
								<PaperDescHeader>Royalte fee:</PaperDescHeader>
								<PaperDescValue>
									{businessValue.royaltyFee
										? businessValue.royaltyFee + ' %'
										: 'N/a'}
								</PaperDescValue>
							</Grid>
							<Grid item lg={4} xs={12}>
								<GuideContainer>
									<table>
										<tbody>
											<tr>
												<th>Franchise term</th>
												<td>- Number of years for franchise agreement.</td>
											</tr>
											<tr>
												<th>Initial investment</th>
												<td>- Possible cost in opening a new franchise.</td>
											</tr>
											<tr>
												<th>Franchise fee</th>
												<td>
													- One time payment given to the franchisor for getting
													the rights to use the product or services.
												</td>
											</tr>
											<tr>
												<th>Marketing fee</th>
												<td>- Percentage on franchisee's monthly income</td>
											</tr>
											<tr>
												<th>Royalte fee:</th>
												<td>- Percentage on franchisee's monthly income</td>
											</tr>
										</tbody>
									</table>
								</GuideContainer>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default BusinessDisplayMoney;
