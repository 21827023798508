//get new AccessToken on page refresh
//check refresh token if valid as well

import { useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { clearUserDataValue, UserDataContext } from 'UniversalContext/userData';
import { PageDataContext } from 'back/context/pageData';
import { checkNull } from 'back/imports/logicCode';

export const TokenVerify = async (accessToken) => {
	const axiosInstance = AxiosConfigUnsecured();
	const [cookies, , removeCookies] = useCookies([]);
	const { setPageDataValue } = useContext(PageDataContext);
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const currentTime = new Date().getTime() / 1000;

	useEffect(() => {
		if (
			cookies.tokenExpiration < currentTime &&
			userDataValue.UserInfo.accessToken
		) {
			//if tokenExpiration is expired and isLogin:true
			setUserDataValue((prevState) => ({
				...prevState,
				isLogin: false,
			}));
		}

		// eslint-disable-next-line
	}, [cookies.tokenExpiration < currentTime]);

	useEffect(() => {
		async function func() {
			if (
				cookies.tokenExpiration > currentTime &&
				!userDataValue.UserInfo.accessToken
			) {
				//if tokenExpiration is not expired and isLogin:true
				//to get new accessToken

				try {
					const tokenRes = await axiosInstance.get('/refreshAccessToken');

					if (tokenRes.data.isLogin) {
						setUserDataValue((prevState) => ({
							...prevState,
							UserInfo: {
								...prevState.UserInfo,
								email: tokenRes.data.email,
								accessToken: tokenRes.data.newAccessToken,
								userID: tokenRes.data.userID,
								fname: tokenRes.data.fname,
								lname: tokenRes.data.lname,
								address: tokenRes.data.address,
								contactNum: checkNull(tokenRes.data.contactNum),
								permission: tokenRes.data.permission,
								notif: [...tokenRes.data.notif.reverse()],
								status: tokenRes.data.status,
								disableReason: tokenRes.data.disableReason,
								inquiry: tokenRes.data.inquiry.reverse(),
								platform: tokenRes.data.platform,
							},
							isLogin: true,
						}));
					} else {
						removeCookies('tokenExpiration', { path: '/' });
						setUserDataValue((prevState) => ({
							...clearUserDataValue(),
							isLogin: false,
						}));
					}
				} catch {
					setPageDataValue((prevState) => ({
						...prevState,
						snackBar: { ...prevState.snackBar, error: true },
					}));
				}
			}
		}
		func();

		//eslint-disable-next-line
	}, []);
};
