import React from 'react';

import 'front/assets/styles/shared.css';
import { Container } from 'react-bootstrap';
import AdsDisplayDetails from 'front/components/adsDisplayDetails/adsDisplayDetails';
import FeaturedAds from 'front/components/featuredAds/featuredAds';
import ActiveBreadcrumbs from 'front/components/breadcrumbs/activeBreadcrumbs';

const AdsDisplay = () => {
	return (
		<>
			<div className="navbar-height-filler" />

			<Container>
				<ActiveBreadcrumbs />

				<AdsDisplayDetails />
			</Container>

			<FeaturedAds />
		</>
	);
};

export default AdsDisplay;
