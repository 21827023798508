import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import {
	AppBar,
	Toolbar,
	IconButton,
	MenuIcon,
	Typography,
	Badge,
	NotificationsIcon,
	AccountCircle,
	ClickAwayListener,
} from 'back/imports/materialUi.js';
import {
	routes,
	PageDataContext,
	NavbarStyle,
	UserDataContext,
} from 'back/imports/components.js';
import { MenuProfilePopper } from 'back/components/menuProfile/menuProfile';
import { useStyles } from 'back/components/menuProfile/styleMenuProfile';
import NotifMenuPopper from '../notification/NotifMenu';
import { SocketContext } from 'back/context/socket';
import _ from 'lodash';
import { BusinessListContext } from 'back/context/businessList';
import { ReportProblem } from '@material-ui/icons';

function Navbar(props) {
	const classes = NavbarStyle();
	const uniqueClasses = useStyles();
	const location = useLocation();
	const { pageDataValue, setPageDataValue } = useContext(PageDataContext);
	const [anchorData, setAnchorData] = useState(null);
	const [disableBtnHover, setDisableBtnHover] = useState({
		status: false,
		btnName: null,
	});
	const [activeMenu, setActiveMenu] = useState(null);
	const { socket } = useContext(SocketContext);
	const { userDataValue, setUserDataValue } = useContext(UserDataContext);
	const [notifBadge, setNotifBadge] = useState(0);
	const [reportCount, setReportCount] = useState(0);
	const { setBusinessListValue } = useContext(BusinessListContext);
	const history = useHistory();

	const handleClickedPopper = (event) => {
		const btnClicked = event.currentTarget.name;
		setAnchorData(event.currentTarget);
		setDisableBtnHover((prevState) =>
			prevState.btnName === btnClicked
				? { status: false, btnName: null }
				: { status: true, btnName: btnClicked }
		);

		//updating database once notif was since and closed via clicking from notif button
		activeMenu === 'myNotif' &&
			_.some(userDataValue.UserInfo.notif, { status: 'new' }) &&
			socket.emit('user:notifClicked', {
				userId: userDataValue.UserInfo.userID,
			});

		//setting to button's name to open the popper
		setActiveMenu((prevState) =>
			btnClicked !== prevState ? btnClicked : false
		);

		//removing notifBadge once notif opened
		if (btnClicked === 'myNotif' && notifBadge) {
			setNotifBadge(0);
		}
	};

	const handleClosePopper = (data) => {
		//checking if notif popper was closed by clicking outside of div, updating database if true
		if (
			activeMenu === 'myNotif' &&
			_.some(userDataValue.UserInfo.notif, { status: 'new' })
		) {
			socket.emit('user:notifClicked', {
				userId: userDataValue.UserInfo.userID,
			});
		}

		//setting to default value to closed open popper
		setDisableBtnHover({
			status: false,
			btnName: null,
		});
		setActiveMenu(null);
	};

	const handleDrawer = () => {
		setPageDataValue({
			...pageDataValue,
			drawerStatus: !pageDataValue.drawerStatus,
		});
	};

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			// socket.emit('user:notif', { userId: userDataValue.UserInfo.userID });
			const newNotifHolderFromRefresh = userDataValue.UserInfo.notif.filter(
				(notif) => notif.status === 'new'
			);

			setNotifBadge(newNotifHolderFromRefresh.length);

			socket.on('user:notif', async (data) => {
				const newNotifHolder = await data.userDetails.notif.filter(
					(notif) => notif.status === 'new'
				);

				setNotifBadge(newNotifHolder.length);
				setUserDataValue((prevState) => ({
					...prevState,
					UserInfo: {
						...prevState.UserInfo,
						status: data.userDetails.status,
						disableReason: data.userDetails.disableReason,
						notif: [...data.userDetails.notif.reverse()],
						inquiry: [...data.userDetails.inquiry.reverse()],
					},
				}));

				data.businessDetails &&
					setBusinessListValue(data.businessDetails.businessList);
			});

			/**************** for reportAds notif **************/
			socket.emit('reportAds:updateCount');

			socket.on('reportAds:notif', async ({ newReportCount }) => {
				setReportCount(newReportCount);
			});
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken]);

	return (
		<>
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]:
						pageDataValue.drawerStatus &&
						pageDataValue.userViewpoint === 'browser',
				})}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawer}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]:
								pageDataValue.drawerStatus &&
								pageDataValue.userViewpoint === 'browser',
						})}>
						<MenuIcon />
					</IconButton>
					<Typography variant="h6" noWrap>
						{routes.map((data) => {
							const urlMatch = matchPath(location.pathname, data.path);
							return urlMatch?.isExact ? data.title : null;
						})}
					</Typography>
					<div className={classes.grow} />
					<ClickAwayListener
						onClickAway={handleClosePopper}
						mouseEvent={anchorData ? 'onClick' : false}>
						<div>
							{userDataValue.UserInfo.accessToken &&
								userDataValue.UserInfo.permission === 3 && (
									<IconButton
										color="inherit"
										onClick={() =>
											history.push('/account/adminPanel/UserAccountSetting')
										}>
										<Badge badgeContent={reportCount} color="secondary">
											<ReportProblem />
										</Badge>
									</IconButton>
								)}
							<IconButton
								aria-label={`show ${notifBadge} new notifications`}
								color="inherit"
								name="myNotif"
								className={
									disableBtnHover.status &&
									disableBtnHover.btnName === 'myNotif'
										? uniqueClasses.activeIconBtn
										: null
								}
								onClick={handleClickedPopper}>
								<Badge badgeContent={notifBadge} color="secondary">
									<NotificationsIcon />
								</Badge>
							</IconButton>
							<NotifMenuPopper
								anchorData={anchorData}
								activeMenu={activeMenu}
								handleClosePopper={handleClosePopper}
							/>
							<IconButton
								edge="end"
								aria-label="account of current user"
								aria-haspopup="true"
								color="inherit"
								name="myAccount"
								className={
									disableBtnHover.status &&
									disableBtnHover.btnName === 'myAccount'
										? uniqueClasses.activeIconBtn
										: null
								}
								onClick={handleClickedPopper}>
								<AccountCircle />
							</IconButton>
							<MenuProfilePopper
								anchorData={anchorData}
								activeMenu={activeMenu}
								handleClosePopper={handleClosePopper}
							/>
						</div>
					</ClickAwayListener>
				</Toolbar>
			</AppBar>
		</>
	);
}

export default Navbar;
