import React from 'react';

import { Grid } from 'back/imports/materialUi';
import { AdsBox } from 'back/components';
import BusinessListEmpty from './BusinessListEmpty';

import unfinishedLogo1 from 'back/assets/img/unfinishedLogo.jpg';

const adsBoxDetails = (data) => {
	const image = data.images.filter((data) => data.primaryPhoto);

	function getAdsStatus() {
		switch (data.status) {
			case 'pending':
				return {
					status: 'Pending',
					color: 'orange',
				};
			case 'active':
				return { status: 'Active', color: 'green' };
			case 'expired':
				return { status: 'Expired', color: 'red' };
			case 'rejected':
				return { status: 'Rejected', color: 'red' };
			case 'incomplete':
				return {
					status: 'Incomplete',
					color: 'grey',
				};
			default:
				return false;
		}
	}

	let imageHolder =
		data.status !== 'incomplete' ? image[0].imageName : unfinishedLogo1;

	return {
		title: data.name,
		description: data.description,
		bizId: data._id,
		adsStatus: getAdsStatus(),
		showMenu: true,
		image: imageHolder,
	};
};

const BusinessList = ({ filteredList }) => {
	return (
		<>
			{!filteredList.length && <BusinessListEmpty />}
			<br />
			<Grid container spacing={3}>
				{filteredList.map((data, index) => (
					<Grid item lg={4} md={6} xs={12} key={'filteredList' + index}>
						<AdsBox {...adsBoxDetails(data)} />
					</Grid>
				))}
			</Grid>
			<br />
		</>
	);
};

export default BusinessList;
