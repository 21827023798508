import styled from 'styled-components';

export const DBGraphHeaderCont = styled.div`
	text-align: center;
	margin-bottom: 0px;
`;

export const DBGraphHeader = styled.label`
	text-align: center;
	margin-bottom: 5px;
	font-size: 18px;
`;
