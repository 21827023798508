import React from 'react';

import { InfoBoxStyle } from 'back/imports/components';
import { Paper, Grid } from 'back/imports/materialUi.js';
import { BoxContent, BoxSubContent } from './InfoBoxStyle';
import { useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';

const InfoBox = (props) => {
	const componentStyle = InfoBoxStyle.InfoStyle({ color: props.secondColor });
	const history = useHistory();

	return (
		<Paper elevation={5}>
			<InfoBoxStyle.Container>
				<InfoBoxStyle.Title
					firstColor={props.firstColor}
					secondColor={props.secondColor}>
					{props.title}
				</InfoBoxStyle.Title>
				<br />
				<InfoBoxStyle.Content>
					<Grid container>
						<Grid item xs={6}>
							<BoxContent>
								{props.loading ? (
									<CircularProgress style={{ marginTop: '14px' }} />
								) : (
									<>
										{props.content}
										<BoxSubContent>{props.subContent}</BoxSubContent>
									</>
								)}
							</BoxContent>
						</Grid>
						<Grid item xs={6} className={componentStyle.InfoIconCont}>
							{props.myIcon}
						</Grid>
					</Grid>
				</InfoBoxStyle.Content>
				<InfoBoxStyle.Footer onClick={() => history.push(props.link)}>
					{props.footer}
				</InfoBoxStyle.Footer>
			</InfoBoxStyle.Container>
		</Paper>
	);
};

/****
 *	firstColor & secondColor  : linear-gradient for title design
 *	title / content / footer : text
 *
 *
 */

/** Sample component
	<InfoBox
		myIcon={<MessageIcon />} 		-> Icon
		loading={loading.current} 	-> loading status
		title="Inquiries"						-> title
		content="204"								-> content
		subContent="(5 New)"				-> subContent
		footer="See all inquiries"	-> footer content
		link="/admin/dashboard"			-> link
		firstColor="green"					-> for gradient
		secondColor="darkgreen"			-> for gradient
	/> 
	
	**/

export default InfoBox;
