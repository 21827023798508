import { Grid, Paper, TextField, Tooltip } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React from 'react';
import {
	PaperDescHeader,
	PaperDescValue,
	uniqueStyle,
} from './BusinessDisplayStyle';
import { getFullDate } from 'back/imports/logicCode';
import { FileCopyRounded } from '@material-ui/icons';
import { TitleGuide } from '../menuProfile/styleMenuProfile';
import { PageDataContext } from 'back/context/pageData';
import { useContext } from 'react';
import styled from 'styled-components';

export const BusinessDisplayDescSkeleton = () => {
	const classes = uniqueStyle();
	return (
		<>
			<Grid container className="mt-5" spacing={3}>
				<Grid item lg={8} xs={12}>
					<Paper
						className={classes.parentPaper}
						style={{ marginBottom: '25px' }}>
						<label className="parentPaperTitle">About my Business</label>

						<PaperDescHeader>Name:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescHeader>Description:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton
								variant="rect"
								style={{ width: '100%', height: '200px' }}
							/>
						</PaperDescValue>

						<Grid container spacing={3}>
							<Grid item lg={6} xs={12}>
								<PaperDescHeader>Address:</PaperDescHeader>
								<PaperDescValue>
									<Skeleton variant="text" style={{ width: '100%' }} />
								</PaperDescValue>
								<PaperDescHeader>Date established:</PaperDescHeader>
								<PaperDescValue>
									<Skeleton variant="text" style={{ width: '100%' }} />
								</PaperDescValue>
								<PaperDescHeader>Category:</PaperDescHeader>
								<PaperDescValue>
									<Skeleton variant="text" style={{ width: '100%' }} />
								</PaperDescValue>
							</Grid>
							<Grid item lg={6} xs={12}>
								<PaperDescHeader>Requirements:</PaperDescHeader>
								<PaperDescValue>
									<ul>
										<Skeleton variant="text" style={{ width: '100%' }} />
									</ul>
									<ul>
										<Skeleton variant="text" style={{ width: '100%' }} />
									</ul>
									<ul>
										<Skeleton variant="text" style={{ width: '100%' }} />
									</ul>
								</PaperDescValue>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item lg={4} xs={12}>
					<Paper className={classes.parentPaper}>
						<label className="parentPaperTitle">Contact Info</label>

						<PaperDescHeader>Owner's Name:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescHeader>Email Address:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescHeader>Contact Number:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
					</Paper>

					<Paper className={classes.parentPaper} style={{ marginTop: '50px' }}>
						<label className="parentPaperTitle">Links</label>

						<PaperDescHeader>Facebook:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescHeader>Instagram:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescHeader>Twitter:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
						<PaperDescHeader>Website:</PaperDescHeader>
						<PaperDescValue>
							<Skeleton variant="text" style={{ width: '100%' }} />
						</PaperDescValue>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

const BusinessDisplayDesc = (props) => {
	const classes = uniqueStyle();
	const { setPageDataValue } = useContext(PageDataContext);

	function getShareLink(id) {
		if (process.env.REACT_APP_BUILD_STATUS === 'DEV') {
			return `http://localhost:3000/bt/share/${id}`;
		} else {
			return `https://www.biztayo.ph/bt/share/${id}`;
		}
	}

	const CopyLabel = styled.label`
		padding: 10px;
		margin-left: 10px;
		margin-top: -5px;
		background-color: #eee;
		cursor: pointer;
		border-radius: 20px;

		&:hover {
			color: black;
			background-color: #ccc;
		}
	`;

	function handleCopy(link) {
		navigator.clipboard.writeText(link);

		setPageDataValue((prevState) => ({
			...prevState,
			snackBar: {
				...prevState.snackBar,
				customColor: 'success',
				customMessage: 'Share link copied to clipboard',
				customSnackbar: true,
			},
		}));
	}

	return (
		<>
			<Grid container className="mt-5" spacing={3}>
				<Grid item lg={8} xs={12}>
					<Paper
						className={classes.parentPaper}
						style={{ marginBottom: '25px' }}>
						<label className="parentPaperTitle">About my Business</label>

						<PaperDescHeader>Name:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.name}</PaperDescValue>
						<PaperDescHeader>Description:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.description}</PaperDescValue>

						<Grid container spacing={3}>
							<Grid item lg={6} xs={12}>
								<PaperDescHeader>Address:</PaperDescHeader>
								<PaperDescValue>{props.businessValue.address}</PaperDescValue>
								<PaperDescHeader>Date established:</PaperDescHeader>
								<PaperDescValue>
									{getFullDate(props.businessValue.date)}
								</PaperDescValue>
								<PaperDescHeader>Category:</PaperDescHeader>
								<PaperDescValue>{props.businessValue.category}</PaperDescValue>
							</Grid>
							<Grid item lg={6} xs={12}>
								<PaperDescHeader>Requirements:</PaperDescHeader>
								<PaperDescValue>
									<ul>
										{props.businessValue.requirement.map((data, index) => (
											<li key={`requirement_${index}`}>{data}</li>
										))}
									</ul>
								</PaperDescValue>
							</Grid>
						</Grid>
					</Paper>
				</Grid>
				<Grid item lg={4} xs={12}>
					{props.businessValue.status === 'active' && (
						<Paper
							className={classes.parentPaper}
							style={{ marginBottom: '50px' }}>
							<label className="parentPaperTitle">Shareable link</label>
							<TextField
								InputProps={{
									endAdornment: (
										<Tooltip title="Copy link" placement="top">
											<CopyLabel>
												<FileCopyRounded
													onClick={() =>
														handleCopy(getShareLink(props.businessValue._id))
													}
												/>
											</CopyLabel>
										</Tooltip>
									),
								}}
								variant="outlined"
								disabled
								fullWidth
								value={getShareLink(props.businessValue._id)}
							/>
							<TitleGuide>
								Use this to share your biztayo.ph advertisement posting to other
								platform <br /> ex: (FB / Instagram / Youtube / etc...)
								<br />
								<a
									href={getShareLink(props.businessValue._id)}
									rel="noreferrer"
									target="_blank">
									Try this link
								</a>
							</TitleGuide>
						</Paper>
					)}

					<Paper className={classes.parentPaper}>
						<label className="parentPaperTitle">Contact Info</label>

						<PaperDescHeader>Owner's Name:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.fullname}</PaperDescValue>
						<PaperDescHeader>Email Address:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.email}</PaperDescValue>
						<PaperDescHeader>Contact Number:</PaperDescHeader>
						<PaperDescValue>
							+63 {props.businessValue.contactNum}
						</PaperDescValue>
					</Paper>

					<Paper className={classes.parentPaper} style={{ marginTop: '50px' }}>
						<label className="parentPaperTitle">Links</label>

						<PaperDescHeader>Facebook:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.facebook}</PaperDescValue>
						<PaperDescHeader>Instagram:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.instagram}</PaperDescValue>
						<PaperDescHeader>Twitter:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.twitter}</PaperDescValue>
						<PaperDescHeader>Website:</PaperDescHeader>
						<PaperDescValue>{props.businessValue.website}</PaperDescValue>
					</Paper>
				</Grid>
			</Grid>
		</>
	);
};

export default BusinessDisplayDesc;
