import React, { useEffect, useState, useContext } from 'react';

import { Grid, IconButton, Paper } from '@material-ui/core';
import {
	AccountCircle,
	Business,
	ChevronLeft,
	ChevronRight,
	Message,
	NotificationsActive,
} from '@material-ui/icons';
import { PaperTitle, PaperTitleCont } from 'back/assets/style/generalStyle';
import { UserDataContext } from 'UniversalContext/userData';
import {
	IconCont,
	NavBottomDiv,
	NoNotifLabel,
	uniqueStyle,
} from './myProfileStyle';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';

const ProfileHistory = () => {
	const { userDataValue } = useContext(UserDataContext);
	const [currentPage, setCurrentPage] = useState(1);
	const [maxPage, setMaxPage] = useState(0);
	const classes = uniqueStyle();
	const history = useHistory();

	useEffect(() => {
		if (userDataValue.UserInfo.accessToken) {
			const maxPageRaw = Math.ceil(userDataValue.UserInfo.notif.length / 5);

			setMaxPage(maxPageRaw);
		}
		//eslint-disable-next-line
	}, [userDataValue.UserInfo.accessToken, userDataValue.UserInfo.notif]);

	const handleButton = (data) => {
		if (data === 'nextBtn') {
			setCurrentPage((prevState) => prevState + 1);
		} else {
			setCurrentPage((prevState) => prevState - 1);
		}
	};

	function getFullDate(data) {
		const date = new Date(data);
		return `${date.toLocaleString('default', {
			month: 'long',
		})} ${date.getDate()}, ${date.getFullYear()}`;
	}

	function handleClicked({ type, payloadId }) {
		type === 'business' &&
			history.push(`/account/businesses/details/${payloadId}`);
		type === 'inquiry' && history.push(`/account/inquiry/${payloadId}`);
	}

	const notifHandler = userDataValue.UserInfo.notif;

	return (
		<>
			<Paper className="mt-5 overflow-hidden">
				<PaperTitleCont>
					<PaperTitle>
						<NotificationsActive /> Notification Center
					</PaperTitle>
				</PaperTitleCont>
				<Grid container>
					<Grid item lg={12} xs={12}>
						{!notifHandler.length && (
							<NoNotifLabel>
								<span>
									<NotificationsActive />
								</span>
								No Notification Right Now!
							</NoNotifLabel>
						)}

						{notifHandler
							.slice((currentPage - 1) * 5, currentPage * 5)
							.map((val, index) => {
								return (
									<Grid
										onClick={() =>
											handleClicked({
												type: val.type,
												payloadId: val.payloadId,
											})
										}
										key={'activity_' + index}
										container
										alignItems="center"
										className={clsx([classes.notifRow, { odd: index % 2 }])}>
										<Grid item lg={1} xs={2}>
											<IconCont color="green">
												{val.type === 'business' && (
													<Business
														style={{
															backgroundColor:
																val.payloadStatus === 'rejected' ||
																val.payloadStatus === 'expired'
																	? 'red'
																	: '',
														}}
													/>
												)}
												{val.type === 'user' && (
													<AccountCircle
														style={{
															backgroundColor:
																val.payloadStatus === 'disable'
																	? 'red'
																	: 'green',
														}}
													/>
												)}
												{val.type === 'inquiry' && (
													<Message style={{ backgroundColor: 'gray' }} />
												)}
											</IconCont>
										</Grid>
										<Grid item xs={10} lg={11} className="p-3">
											<Grid container>
												<Grid item lg={3} xs={12}>
													{`${getFullDate(val.date)} - ${val.time}`}
												</Grid>
												<Grid
													item
													lg={9}
													xs={12}
													style={{
														wordWrap: 'break-word',
														overflow: 'hidden',
													}}>
													{val.description}
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								);
							})}
					</Grid>
				</Grid>
			</Paper>

			{notifHandler.length ? (
				<NavBottomDiv>
					<IconButton
						className={
							currentPage !== 1 && currentPage <= maxPage ? 'active' : ''
						}
						color={
							currentPage !== 1 && currentPage <= maxPage
								? 'inherit'
								: 'default'
						}
						disabled={currentPage === 1 ? true : false}
						onClick={(e) => handleButton('prevBtn')}>
						<ChevronLeft />
					</IconButton>

					<IconButton
						className={
							(currentPage !== maxPage) & (currentPage < maxPage)
								? 'active'
								: ''
						}
						color={
							(currentPage !== maxPage) & (currentPage < maxPage)
								? 'inherit'
								: 'default'
						}
						disabled={currentPage === maxPage ? true : false}
						onClick={(e) => handleButton('nextBtn')}>
						<ChevronRight />
					</IconButton>
				</NavBottomDiv>
			) : (
				''
			)}
		</>
	);
};

export default ProfileHistory;
