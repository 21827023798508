import {
	Button,
	CardActions,
	CardContent,
	Grid,
	Typography,
} from '@material-ui/core';
import { HandlePesoRender } from 'back/MicroComponents/numbersFormat';
import { SearchContext } from 'front/context/searchContext';
import React from 'react';
import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { ImgDisplay } from '../imgDisplay/imgDisplay';
import { CardResultGrid } from './searchStyle';

export const SearchResultGrid = (props) => {
	const { searchDataValue } = useContext(SearchContext);
	const history = useHistory();

	function UrlSearchOption() {
		return `keyword=${encodeURIComponent(
			searchDataValue.keyword
		)}&&investFrom=${searchDataValue.investFrom}&&investTo=${
			searchDataValue.investTo
		}&&page=${searchDataValue.page}&&category=${encodeURIComponent(
			searchDataValue.category
		)}&&searchType=${searchDataValue.searchType}`;
	}

	return (
		<div className="searchResultGrid-cont">
			<Grid container spacing={3}>
				{searchDataValue.searchResultSortedList
					.slice(
						searchDataValue.perPage * searchDataValue.page,
						searchDataValue.perPage * (searchDataValue.page + 1)
					)
					.map((business, index) => {
						const getPrimaryImage = business.images.filter(
							(image) => image.primaryPhoto === 1
						);

						return (
							<Grid
								item
								lg={3}
								xs={12}
								style={{ height: 'inherit', marginBottom: '30px' }}
								key={`resultGrid_${index}`}>
								<CardResultGrid>
									<ImgDisplay
										imageId={getPrimaryImage[0].imageName}
										height="300"
										className="MuiCardMedia-root MuiCardMedia-media MuiCardMedia-img"
										style={{ objectFit: 'cover', width: '100%' }}
										skeletonComponent={{
											variant: 'rect',
											style: { height: '300px', width: '100%' },
										}}
									/>

									<CardContent>
										<Typography
											gutterBottom
											variant="h5"
											component="h2"
											style={{
												wordWrap: 'break-word',
											}}>
											{business.name}
										</Typography>
										<Typography
											variant="body2"
											color="textSecondary"
											component="p"
											style={{
												wordWrap: 'break-word',
											}}>
											{business.description.split(' ').slice(0, 40).join(' ')}{' '}
											...
										</Typography>
									</CardContent>
									<div className="cardInvestDiv">
										<label>Initial Investment</label>
										<span>
											{HandlePesoRender({
												value: business.initialInvest || business.franchiseFee,
											})}
										</span>
									</div>
									<CardActions
										style={{
											position: 'absolute',
											bottom: 0,
										}}>
										<Button
											size="small"
											color="primary"
											onClick={() =>
												history.push(
													`/bt/show/search=${
														business._id
													}&&name=${encodeURIComponent(
														business.name
													)}&&${UrlSearchOption()}`
												)
											}>
											Learn More
										</Button>
									</CardActions>
								</CardResultGrid>
							</Grid>
						);
					})}
			</Grid>
		</div>
	);
};
