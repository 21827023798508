import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';

import 'front/assets/styles/shared.css';
import { BrowseCategory } from 'front/components/browseCategory/browseCategory';
import FeaturedAds from 'front/components/featuredAds/featuredAds';
import { Button, Grid, TextField } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Link, useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useContext } from 'react';
import { SearchContext } from 'front/context/searchContext';
import { PageDataContext } from 'back/context/pageData';
import { AxiosConfigUnsecured } from 'config/axiosConfig';
import clsx from 'clsx';

export const LandingPage = () => {
	const history = useHistory();
	const [keyword, setKeyword] = useState('');
	const { setSearchDataValue } = useContext(SearchContext);
	const { setPageDataValue } = useContext(PageDataContext);
	const [, setBusinessNameList] = useState([]);
	const axiosInstance = AxiosConfigUnsecured();
	const [, setLoading] = useState(false);

	function handleChange(e) {
		setKeyword(e.target.value);
	}

	function handleClick(e) {
		e.preventDefault();

		// if (keyword.length === 0 || keyword === '' || keyword === null) {
		// 	setPageDataValue((prevState) => ({
		// 		...prevState,
		// 		snackBar: {
		// 			...prevState.snackBar,
		// 			customSnackbar: true,
		// 			customMessage: 'Please provide business name.',
		// 			customColor: 'error',
		// 		},
		// 	}));
		// 	return false;
		// }

		setSearchDataValue((prevState) => ({
			...prevState,
			keyword,
			investFrom: 0,
			investTo: 0,
			searchType: 0,
			category: 'All',
			searchResultList: [],
			searchResultSortedList: [],
			page: 0,
			perPage: 4,
			freshEntry: false,
			loading: true,
		}));

		history.push(
			`/bt/search/keyword=${encodeURIComponent(
				keyword
			)}&&investFrom=&&investTo=&&page=&&category=All&&searchType=0`
		);
	}

	const browseFranchises = () => {
		setSearchDataValue((prevState) => ({
			...prevState,
			keyword,
			investFrom: 0,
			investTo: 0,
			searchType: 0,
			category: 'All',
			searchResultList: [],
			searchResultSortedList: [],
			page: 0,
			perPage: 4,
			freshEntry: false,
			loading: true,
		}));
		history.push(`/bt/search/`);
	};

	useEffect(() => {
		axiosInstance.get('/public/getFeaturedBiz').then((res) => {
			var rawListHandler = [];

			setLoading(false);
			for (const businessOwner of res.data) {
				for (const business of businessOwner.businessList) {
					if (business.status === 'active') {
						rawListHandler.push(business.name);
					}
				}
			}
			setBusinessNameList(rawListHandler);
		});
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<div className="pageBody-bg">
				{/* <div className="landingWhiteBg" /> */}

				<div className="landingPageBgBuilding" />

				<div className="landingWhiteBgAlt" />

				<br />
				<Container>
					<Grid container>
						<Grid item lg={6} md={6} xs={12}>
							<div className="landingContentBody">
								<div className="greetingPhrase">
									<label>
										Build Wealth <br />
										Seize Opportunities
										<span> Start Franchise now</span>
									</label>
								</div>{' '}
								<form onSubmit={handleClick}>
									<div
										className={clsx(['LandingPageTextboxContainer'], {
											'textbox-with-value': keyword,
										})}>
										<TextField
											variant="outlined"
											type="text"
											placeholder="Search from 60+ Franchises"
											onChange={handleChange}
											value={keyword}
											InputProps={{ disableUnderline: true }}
										/>
										<span>
											<Search onClick={handleClick} />
										</span>
										<label className={clsx({ 'textbox-with-value': keyword })}>
											Search from 60+ Franchises
										</label>
									</div>
								</form>
								<span
									className="browseAllFranchiseButton"
									onClick={browseFranchises}>
									EXPLORE AVAILABLE FRANCHISES
								</span>
								<br />
								<br />
								<hr style={{ borderTop: '2px solid white' }} />
								<label>OR</label>
								<br />
								<Link to={'/account/businesses/new'}>
									<Button
										variant="contained"
										size="large"
										color="secondary"
										style={{
											float: 'right',
											borderColor: 'white',
											color: 'white',
											fontSize: '30px',
										}}>
										Post your franchise
										<label>For Free</label>
									</Button>
								</Link>
							</div>
						</Grid>
						<Grid item lg={6} md={6} xs={0}>
							<div className="landingContentBodyRight">
								<div className="contentRightCont">
									<label>
										Explore over <br />
										60+ franchises
									</label>
								</div>
							</div>
						</Grid>
					</Grid>
				</Container>
			</div>

			<BrowseCategory />

			<FeaturedAds />
		</>
	);
};
