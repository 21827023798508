import { Grid } from '@material-ui/core';
import React from 'react';
import { Container } from 'react-bootstrap';
import {
	FeateuredContainer,
	uniqueStyle,
	FeaturedBox,
	FeaturedTitle,
	FeaturedAdsName,
	FeaturedAdsInvest,
	FeaturedButton,
	FreepostContainer,
	FreepostDetails,
} from './featuredAdsStyle';

import { AxiosConfigUnsecured } from 'config/axiosConfig';
import { useState } from 'react';
import { HandlePesoRender } from 'back/MicroComponents/numbersFormat';
import { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { SearchContext } from 'front/context/searchContext';
import { Skeleton } from '@material-ui/lab';
import emptyAds_bg from 'front/assets/images/emptyAds_bg.jpg';
import { ImgDisplay } from '../imgDisplay/imgDisplay';
import lineChartUp from 'front/assets/images/lineChartUp.png';

const SkeletonFeaturedAds = () => {
	return (
		<>
			{[...Array(6)].map((data, index) => (
				<Grid item lg={2} md={3} xs={4} key={`skeletonFeatured_${index}`}>
					<FeaturedBox>
						<div className="imgCont">
							<Skeleton
								variant="circle"
								style={{
									width: '100px',
									height: '100px',
									margin: 'auto auto',
								}}
							/>
						</div>
						<FeaturedAdsName>
							<Skeleton
								variant="rect"
								style={{ height: '25px', width: '100%' }}
							/>{' '}
						</FeaturedAdsName>
						<FeaturedAdsInvest>
							<span>Initial Investment: </span>
							<b>
								<Skeleton
									variant="rect"
									style={{ height: '15px', width: '50%' }}
								/>
							</b>
						</FeaturedAdsInvest>
						<FeaturedButton variant="outlined" color="primary" size="small">
							See more
						</FeaturedButton>
					</FeaturedBox>
				</Grid>
			))}
		</>
	);
};

const FeaturedAdsEmpty = (props) => {
	const history = useHistory();

	return (
		<>
			{[...Array(props.emptyCount)].map((business, index) => {
				return (
					<Grid item lg={2} md={3} xs={6} key={`featured_${index}`}>
						<FeaturedBox style={{ height: 'inherit' }}>
							<div className="imgCont">
								<img
									src={emptyAds_bg}
									alt={`ads_otherImage_${index}`}
									style={{
										objectFit: 'cover',
										width: '100%',
										filter: 'blur(2px)',
									}}
								/>
								<div
									style={{
										height: 'inherit',
										width: '100%',
										position: 'absolute',
									}}>
									<label
										style={{
											display: 'inline-block',
											verticalAlign: 'middle',
											height: '100%',
										}}
									/>
									<label
										style={{
											display: 'inline-block',
											verticalAlign: 'middle',
											width: '80%',
											marginLeft: '10%',
											textAlign: 'center',
											padding: '10px 0',
											border: '1px solid white',
											color: 'white',
											background: 'rgba(0,0,0, .5)',
										}}>
										Available slot
									</label>
								</div>
							</div>
							<FeaturedAdsName>Available franchise slot</FeaturedAdsName>
							<FeaturedAdsInvest style={{ textAlign: 'center' }}>
								<span>
									Post your franchise for{' '}
									<b style={{ color: 'green' }}>FREE!! </b>&nbsp;
								</span>
								<b>&nbsp; </b>
							</FeaturedAdsInvest>
							<FeaturedButton
								variant="contained"
								color="primary"
								size="small"
								onClick={() => {
									window.scrollTo({ top: 0, behavior: 'smooth' });
									history.push('/register');
								}}>
								Create acc & Post now!
							</FeaturedButton>
						</FeaturedBox>
					</Grid>
				);
			})}
		</>
	);
};

const FeaturedAds = () => {
	const classes = uniqueStyle();
	const axiosInstance = AxiosConfigUnsecured();
	const [featuredBusinessList, setFeaturedBusinessList] = useState([]);
	const history = useHistory();
	const { searchDataValue } = useContext(SearchContext);
	const [loading, setLoading] = useState(true);

	function UrlSearchOption() {
		return `keyword=${encodeURIComponent(
			searchDataValue.keyword
		)}&&investFrom=${searchDataValue.investFrom}&&investTo=${
			searchDataValue.investTo
		}&&page=${searchDataValue.page}&&category=${encodeURIComponent(
			searchDataValue.category
		)}&&searchType=${searchDataValue.searchType}`;
	}

	useEffect(() => {
		axiosInstance.get('/public/getFeaturedBiz').then((res) => {
			const bizOwnerList = res.data;
			const businessListHolder = [];

			for (const businesses of bizOwnerList) {
				for (const business of businesses.businessList) {
					if (business.status === 'active') {
						businessListHolder.push(business);
					}
				}
			}

			const finalSixBusinessList = businessListHolder
				.sort((a, b) => b.points - a.points)
				.slice(0, 6);

			setFeaturedBusinessList(finalSixBusinessList);
			setLoading(false);
		});
		//eslint-disable-next-line
	}, []);

	return (
		<>
			<FreepostContainer>
				<Container>
					<Grid container justifyContent="center">
						<Grid item xl={10} xs={12}>
							<img src={lineChartUp} alt="chartLineUp" />
							<FreepostDetails>
								Want to expand your business?
								<br />
								<span>
									Post your franchise for <b>FREE!!!</b>
								</span>
								<br />
								<Link to="/login">
									<label>Create Franchise Ads</label>
								</Link>
							</FreepostDetails>
						</Grid>
					</Grid>
				</Container>
			</FreepostContainer>

			<div className={classes.adsContainer}>
				<Container>
					<br />
					<FeateuredContainer>
						<FeaturedTitle>Most Viewed Franchises</FeaturedTitle>

						<Grid container>
							{!loading &&
								featuredBusinessList.length !== 0 &&
								featuredBusinessList.map((business, index) => {
									const getPrimaryImage = business.images.filter(
										(image) => image.primaryPhoto === 1
									);

									return (
										<Grid
											item
											lg={2}
											md={3}
											xs={6}
											key={`featured_${index}`}
											style={{ height: 'inherit' }}>
											<FeaturedBox style={{ height: '100%' }}>
												<div className="imgCont">
													<ImgDisplay
														imageId={getPrimaryImage[0].imageName}
														skeletonComponent={{
															variant: 'circle',
															style: {
																width: '100px',
																height: '100px',
																margin: 'auto auto',
															},
														}}
													/>
												</div>
												<FeaturedAdsName>{business.name} </FeaturedAdsName>
												<FeaturedAdsInvest>
													<span>Initial Investment: </span>
													<b>
														{HandlePesoRender({
															value:
																business.initialInvest || business.franchiseFee,
														})}
													</b>
												</FeaturedAdsInvest>
												<FeaturedButton
													variant="contained"
													color="primary"
													size="small"
													onClick={() =>
														history.push(
															`/bt/show/search=${
																business._id
															}&&name=${encodeURIComponent(
																business.name
															)}&&${UrlSearchOption()}`
														)
													}>
													See more
												</FeaturedButton>
											</FeaturedBox>
										</Grid>
									);
								})}
							{!loading && (
								<FeaturedAdsEmpty
									emptyCount={6 - featuredBusinessList.length}
								/>
							)}
							{loading && <SkeletonFeaturedAds />}
						</Grid>
					</FeateuredContainer>
				</Container>
			</div>
		</>
	);
};

export default FeaturedAds;
