import React from 'react';
import { BusinessEmptyList } from './BusinessTableStyle';
import { Grid } from '@material-ui/core';

const BusinessListEmpty = () => {
	return (
		<>
			<Grid container justifyContent="center">
				<Grid item xl={4} sm={4} className="text-center">
					<BusinessEmptyList>
						<label style={{ display: 'block' }}>
							No business advertisement to show,&nbsp;
						</label>
					</BusinessEmptyList>
				</Grid>
			</Grid>
		</>
	);
};

export default BusinessListEmpty;
