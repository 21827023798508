import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import 'front/assets/styles/shared.css';
import { useHistory } from 'react-router-dom';
import { useContext } from 'react';
import { SearchContext } from 'front/context/searchContext';
import { BrowseCategoryDiv, BrowserChoicesBG } from './browserCategoryStyle';
import house from 'front/assets/images/resized_browse_images/house.jpg';
import food from 'front/assets/images/resized_browse_images/food.jpg';
import health from 'front/assets/images/resized_browse_images/health.jpg';
import cars from 'front/assets/images/resized_browse_images/cars.jpg';
import manpower from 'front/assets/images/resized_browse_images/manpower.jpg';
import art from 'front/assets/images/resized_browse_images/art.jpg';
import apparel from 'front/assets/images/resized_browse_images/apparel.jpg';
import others from 'front/assets/images/resized_browse_images/others.jpg';

export const BrowseCategory = () => {
	const history = useHistory();
	const { setSearchDataValue } = useContext(SearchContext);

	function handleClick(data) {
		setSearchDataValue((prevState) => ({
			...prevState,
			keyword: '',
			investFrom: 0,
			investTo: 0,
			freshEntry: false,
		}));

		history.push(
			`/bt/search/keyword=&&investFrom=&&investTo=&&page=0&&category=${encodeURIComponent(
				data
			)}&&searchType=0`
		);
	}

	return (
		<div className="lower-body-cont">
			<div className="lower-body">
				<Container>
					<br />
					<h2
						className="text-center font-weight-bold mt-5 mb-5"
						style={{ fontSize: '35px', fontWeight: 'bold' }}>
						LINE OF BUSINESS
					</h2>

					<Row className="justify-content-center">
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('House Related')}>
								<BrowserChoicesBG>
									<img src={house} alt="browseCategoryImg1" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">
										House Related
									</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Food/Beverages')}>
								<BrowserChoicesBG>
									<img src={food} alt="browseCategoryImg2" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">
										Food / Beverages
									</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Health Industry')}>
								<BrowserChoicesBG>
									<img src={health} alt="browseCategoryImg3" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">
										Health Industry
									</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Car Industry')}>
								<BrowserChoicesBG>
									<img src={cars} alt="browseCategoryImg4" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">
										Car Industry
									</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Manpower')}>
								<BrowserChoicesBG>
									<img src={manpower} alt="browseCategoryImg5" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">Manpower</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Art Related')}>
								<BrowserChoicesBG>
									<img src={art} alt="browseCategoryImg6" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">Art Related</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Apparel')}>
								<BrowserChoicesBG>
									<img src={apparel} alt="browseCategoryImg7" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">Apparel</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
						<Col lg={3} md={4} xs={6} className="mt-3">
							<div
								className="lowerBody-category"
								onClick={() => handleClick('Others')}>
								<BrowserChoicesBG>
									<img src={others} alt="browseCategoryImg" />
								</BrowserChoicesBG>
								<BrowseCategoryDiv>
									<label className="lowerBody-categoryTitle">Others</label>
								</BrowseCategoryDiv>
							</div>
						</Col>
					</Row>
				</Container>
				<br />
			</div>
		</div>
	);
};
