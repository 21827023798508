import { useState, useContext, memo } from 'react';

import { TextField, OutlinedInput } from 'back/imports/materialUi';
import { useDebounceTextbox, UserDataContext } from 'back/imports/components';
import { ErrorHandlerContext } from 'back/context/pageData';
import { generalStyle } from 'back/assets/style/generalStyle';
import { maxLengthHandler } from 'back/context/pageData';
import MaxLengthDisplay from './maxLengthDisplay';
import clsx from 'clsx';
import _ from 'lodash';

//boxType = regularBox - if textbox is not requirement box otherwise value is "requirements"
// boxType and totalReq from requirement textbox props
// This Textbox component was only used in New business listing form

export const MCTextBox = memo(
	({ boxBelong, ReqLocationIndex = '', name, className, ...rest }) => {
		const { userDataValue, setUserDataValue } = useContext(UserDataContext);
		const [textboxValue, setTextboxValue] = useState(() => {
			if (boxBelong === 'requirements') {
				return (
					userDataValue.NewBizData.basicInfo.requirements[ReqLocationIndex] ??
					''
				);
			} else if (boxBelong === 'infoBox') {
				return userDataValue.NewBizData.basicInfo[name] ?? '';
			} else if (boxBelong === 'moneyTalk') {
				return parseInt(userDataValue.NewBizData.moneyTalk[name]) ?? '';
			} else {
				return '';
			}
		});

		const { errorHandler, setErrorHandler, errorChecker } =
			useContext(ErrorHandlerContext);

		const generalClasses = generalStyle();

		//check if what box is being typed
		const boxBelongChecker = () => {
			if (boxBelong === 'requirements') {
				return { boxBelong: 'requirements', ReqLocationIndex };
			} else if (boxBelong === 'moneyTalk') {
				return { boxBelong: 'moneyTalk' };
			} else {
				return { boxBelong: 'infoBox' };
			}
		};

		const pageChecker = () => {
			return boxBelong === 'infoBox' || boxBelong === 'requirements' ? 0 : 1;
		};

		const debouncedHandler = useDebounceTextbox(
			boxBelongChecker(),
			userDataValue,
			setUserDataValue,
			300
		);

		const handleChange = (e) => {
			//if value is null, send (name,true) to parent(NewBizForm) errorChecker fucntion

			if (e.target.value.length === 0) {
				!errorHandler[pageChecker()][name] &&
					errorChecker(setErrorHandler, pageChecker(), name, true);
			} else if (
				e.target.value.length >
				maxLengthHandler[boxBelong === 'requirements' ? 'requirement' : [name]]
			) {
				!errorHandler[pageChecker()][name] &&
					errorChecker(setErrorHandler, pageChecker(), name, true);
			} else {
				errorHandler[pageChecker()][name] &&
					errorChecker(setErrorHandler, pageChecker(), name, false);
			}

			setTextboxValue(e.target.value);
			debouncedHandler(e);
		};

		return (
			<>
				<TextField
					onChange={handleChange}
					value={textboxValue}
					name={name}
					className={clsx([
						className,
						{
							[generalClasses.errorTextbox]: _.isMatch(
								errorHandler[pageChecker()],
								{
									[name]: true,
								}
							),
						},
					])}
					{...rest}
				/>
				{/* dont display maxLengthDisplay if not included in maxLengthHandler and not moneyTalk */}
				{maxLengthHandler[
					boxBelong === 'requirements' ? 'requirement' : name
				] &&
					boxBelong !== 'moneyTalk' && (
						<MaxLengthDisplay
							boxName={boxBelong === 'requirements' ? 'requirement' : name}
							valueLength={textboxValue.length}
						/>
					)}
			</>
		);
	}
);

export const MCLinkTextbox = memo(
	({ boxBelong = 'regularBox', ReqLocationIndex, name, ...rest }) => {
		const { userDataValue, setUserDataValue } = useContext(UserDataContext);
		const [textboxValue, setTextboxValue] = useState(
			userDataValue.NewBizData.basicInfo[name]
				? userDataValue.NewBizData.basicInfo[name]
				: ''
		);

		const { errorHandler, setErrorHandler, errorChecker } =
			useContext(ErrorHandlerContext);
		const debounceHandler = useDebounceTextbox(
			{},
			userDataValue,
			setUserDataValue,
			300
		);

		const handleChange = (e) => {
			//if value is null, send (name,true) to parent(NewBizForm) errorChecker fucntion
			if (e.target.value.length === 0) {
				!errorHandler[0][name] && errorChecker(setErrorHandler, 0, name, true);
			} else if (
				e.target.value.length >
				maxLengthHandler[boxBelong === 'requirements' ? 'requirement' : [name]]
			) {
				!errorHandler[0][name] && errorChecker(setErrorHandler, 0, name, true);
			} else {
				errorHandler[0][name] && errorChecker(setErrorHandler, 0, name, false);
			}

			setTextboxValue(e.target.value);
			debounceHandler(e);
		};

		return (
			<>
				<OutlinedInput
					onChange={handleChange}
					value={textboxValue}
					name={name}
					{...rest}
				/>
				<MaxLengthDisplay boxName={name} valueLength={textboxValue.length} />
			</>
		);
	}
);
