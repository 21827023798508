import { makeStyles } from '@material-ui/styles';

export const uniqueStyles = makeStyles((theme) => ({
	buttonOverride: {
		'& span span': {
			marginTop: '-3px',
		},
		'&.rightBtn': {
			backgroundColor: 'green',
		},
	},
}));
